import { AfterViewInit, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProductPreorderRes, ProductsPreorderResponse } from '../../models/product-preorder-response';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { PreorderService } from '../../services/preorder.service';

@Component({
  selector: 'app-product-preorder-table',
  templateUrl: './product-preorder-table.component.html',
  styleUrls: ['./product-preorder-table.component.css']
})
export class ProductPreorderTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['pallet', 'boxes', 'product', 'supplier',  'total', 'actions'];
  products?: ProductsPreorderResponse | null;
  data: ProductPreorderRes[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('filter') filter: {preorder: string} = { preorder: ''}
  @Input('statusPreorder') statusPreorder?: string
  @Output() editProduct = new EventEmitter<number>();


  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private pos: PreorderService
  ) { }

  ngAfterViewInit() {
    this.getProducts()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.statusPreorder = changes['statusPreorder'].currentValue
  }


  getProducts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.pos.searchProductsPreorder(
            this.filter,
            this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.editProduct.emit(id)

  deleteProduct(id: number): void {
    if (this.statusPreorder === 'GENERATED') return

    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete this product?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.pos.deleteProductToPreorder(id).subscribe(() => this.getProducts())
      }
    })
  }

}


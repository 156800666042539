<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-12/12">
    <mat-label>Name</mat-label>
    <input matInput formControlName="first_name" placeholder="Juan">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Username</mat-label>
    <input matInput formControlName="username" placeholder="juan">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Password</mat-label>
    <input matInput type="password" formControlName="password" placeholder="*******">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-select formControlName="group">
      <mat-option [value]="g.id" *ngFor="let g of groups">{{g.name}}</mat-option>
    </mat-select>

  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(user) ? 'Updated' :
      'Create'}}
      User </button>
  </div>
</form>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Preorder } from '../../models/pre-order';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'src/app/features/admin/services/clients.service';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { MatDialog } from '@angular/material/dialog';
import { PreorderService } from '../../services/preorder.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { LogisticOrderFrmComponent } from 'src/app/shared/components/logistic-order-frm/logistic-order-frm.component';
import { LogisticService } from 'src/app/features/logistic/services/logistic.service';

@Component({
  selector: 'app-preorder-frm',
  templateUrl: './preorder-frm.component.html',
  styleUrls: ['./preorder-frm.component.css']
})
export class PreorderFrmComponent implements OnInit {

  public frm?: FormGroup;
  asTemplate = new FormControl(false)
  arrivalDays = 3

  @Input() preorder?:Preorder
  @Output() sendData = new EventEmitter<Preorder>()

  public clientId?: number;
  public clientName?: string;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private rt: Router,
    private ar: ActivatedRoute,
    private cs: ClientsService,
    private pos: PreorderService,
    private ls: LogisticService,
    private ss: SnackbarsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this._createForm();
    if (this.preorder){
      this._setPreorder(this.preorder)
    }

    if (this.ar.snapshot.queryParams['type']){
      this.frm!.patchValue({typeOfPreOrder: this.ar.snapshot.queryParams['type']})
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      code: [''],
      name: [''],
      typeOfPreOrder: ['COMMISSION', Validators.required],
      status: ['DRAFT', Validators.required],
      client_id: ['', Validators.required],
      po: [''],
      poClient: [''],
      notes: [''],
      departureDate: ['', Validators.required],
      arrivalDate: ['', Validators.required],
      paymentMethod: ['TRANSFER', Validators.required],
      palletCost: ['180', Validators.required],
      currencyChange: ['1.18', Validators.required],
      jcvPercentage: [0],
      comercialDiscount: [0],
      saleComission: [0]
    });
  }

  _setPreorder(preorder: Preorder): void{
      this.frm!.patchValue({
        ...preorder,
        client_id: preorder.client.id,
      })
      if (preorder.status === 'TEMPLATE') this.asTemplate.setValue(true)
      if (preorder.status === 'GENERATED') this.asTemplate.disable()

      this.clientId = preorder.client.id
      this.clientName = preorder.client.name
  }


  setClient = (client: string): void => {
    if (client) this._infoClient(parseInt(client))
    this.frm!.get('client_id')!.setValue(client)
  }


  private _infoClient(client:number) {
    this.cs.getClient(client).subscribe( ({dayDeparture}) => {
      this.arrivalDays = dayDeparture
      if (this.frm?.get('departureDate')?.value){
        this.setArrivalDate({value: this.frm?.get('departureDate')?.value})
      }
    })
  }

  setArrivalDate = (event: any): void => {

    if (event.value){
      const arrival = moment(event.value).add(this.arrivalDays, 'days')
      const arrivalDate = new Date( arrival.year(), arrival.month(), arrival.date())
      this.frm!.patchValue({ arrivalDate}, {emitEvent: false});
    }
  }

  setTemplate = () => {

    if (this.frm?.get('status')?.value === 'GENERATED') return;

    if (this.frm?.get('status')?.value === 'DRAFT'){
      this.frm!.patchValue({status: "TEMPLATE"})
    }else{
      this.frm!.patchValue({status: 'DRAFT'})
    }


  }


  deletePreorder(): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete preorder',
        message: 'Are you sure you want to delete this preorder?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.pos.deletePreorder(this.preorder!.id).subscribe(() => {
          this.rt.navigateByUrl('/dash/pre-orders')
          this.ss.nuevaSnack('Preorder deleted', 'snack-success')
        })

      }
    })
  }

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      departureDate: moment(this.frm!.value.departureDate).format('YYYY-MM-DD'),
      arrivalDate: moment(this.frm!.value.arrivalDate).format('YYYY-MM-DD'),
    }

    this.sendData.emit(data);
  }

  private _createLogisticOrder(data:any){
    this.ls.addLogisticOrder(data).subscribe({
      next: (r) => this.ss.nuevaSnack(`${r.code} has been created`, 'snack-success'),
      error: (e) => this.ss.nuevaSnack('Error to create the logistic order', 'snack-error')
    })
  }

  createBlankLogisticOrder(){
    this.dialog.open(LogisticOrderFrmComponent, {
      width: '30vw',
      data: {preorder: this.preorder!.id}
    }).afterClosed().subscribe((data) => {
      if (data){
        this._createLogisticOrder(data)
      }
    })

  }


}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationsService } from '../../services/notifications.service';
import { SnackbarsService } from '../../services/snackbars.service';

@Component({
  selector: 'app-email-logistic-modal',
  templateUrl: './email-logistic-modal.component.html',
  styleUrls: ['./email-logistic-modal.component.css']
})
export class EmailLogisticModalComponent implements OnInit {

  frm?: FormGroup;

  isSending = false
  isClientNotified: boolean = false
  isSuppliersNotified: boolean = false
  isDeliveredNotified: boolean = false

  sent:boolean = false

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
    'undo',
    'redo',
    'strikeThrough',
    'subscript',
    'superscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertUnorderedList',
    'insertOrderedList',
    'heading',
    'fontName'
  ],
  [
    'fontSize',
    'textColor',
    'customClasses',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'insertHorizontalRule',
    'removeFormat',
    'toggleEditorMode'
  ]
    ],
    customClasses: []
  };

  constructor(
    public dialogRef: MatDialogRef<EmailLogisticModalComponent>,
    private fb: FormBuilder,
    private ss: SnackbarsService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data:{id:number, title:string, isClientNotified: boolean, isSuppliersNotified: boolean, isDeliveredNotified: boolean },
  ) { }

  ngOnInit(): void {
    this.isClientNotified = this.data.isClientNotified
    this.isSuppliersNotified = this.data.isSuppliersNotified
    this.isDeliveredNotified = this.data.isDeliveredNotified
    this._createForm();
  }


  private _createForm(): void {
    this.frm  = this.fb.group({
      title: [this.data.title, Validators.required],
      to: ['', Validators.required],
      cc: [''],
      cco: [''],
      body: ['Buenas, <p>Please see attached logistic order.</p>Gracias', Validators.required],
    })
  }


  send(){
    if(this.frm?.invalid) return;
    this.isSending = true
    this.sent = false

    this.ns.sendLogistic(this.data.id, this.frm!.value).subscribe({
      next: (resp) => {
        this.isSending = false
        this.sent = true
        if (this.frm?.get('to')?.value.includes('CLIENT')) this.isClientNotified = true
        if (this.frm?.get('to')?.value.includes('DELIVERY')) this.isDeliveredNotified = true
        if (this.frm?.get('to')?.value.includes('SUPPLIERS')) this.isSuppliersNotified = true
      },
      error: (e) => {
        this.isSending = false
        this.ss.nuevaSnack('Error to send email', 'snack-error')
      }
    })

  }


  get to() { return this.frm?.get('to') }
}

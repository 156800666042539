<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Size</mat-label>
    <input matInput formControlName="size" placeholder="XXL">
  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(size) ? 'Updated' :
      'Create'}}
      Size </button>
  </div>
</form>

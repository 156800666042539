import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { ReportsService } from 'src/app/features/reports/services/reports.service';
import { ResumenHome } from '../../models/resumen-home';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  userName = this.as.usuario?.name || localStorage.getItem('jcv_n');
  now = this.datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm');
  is_admin = (localStorage.getItem('is_adm') === 'true') ? true : false;

  links = [
    { name: 'Commission Pre Order', url: '/dash/pre-orders/add', params:null, icon: 'preorder', color: 'bg-blue-500', description: 'You can create a Commission Pre Order' },
    { name: 'Buy Pre Order', url: '/dash/pre-orders/add', params:{type:'BUY'}, icon: 'preorder', color: 'bg-green-500', description: 'You can create a Buy Pre Order' },
    { name: 'Second Line Order', url: '/dash/second-line/create', params:null, icon: 'preorder', color: 'bg-purple-500', description: 'You can create a Second Line Order' },
    { name: 'Logistic Tracking', url: '/dash/logistic', params:null, icon: 'logistic', color: 'bg-indigo-500', description: 'Track Logistic Order' },
    { name: 'Traceability', url: '/dash/traceability/trace', params:null, icon: 'trace', color: 'bg-yellow-500', description: 'You can show product traceability' },
    { name: 'Invoices', url: '/dash/invoices', params:null, icon: 'invoices', color: 'bg-orange-500', description: 'Access to generated invoices' },
  ]

  homeReport: ResumenHome = {
    commission: 0,
    sales: 0,
    purchases: 0,
    second_line: 0,
    orders: 0,
    count_second_line: 0,
    count_compilance: 0,
  }

  constructor(
    public as: AuthService,
    private rt: Router,
    private datePipe: DatePipe,
    private rs: ReportsService
  ) { }

  ngOnInit(): void {
    this.rs.homeResumen().subscribe( r => this.homeReport = r)
  }

  goto = (url:string) => this.rt.navigateByUrl(url)

}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Non Conformities
      <small class="block font-light text-xs text-slate-500 w-full"> List of Complaints, Non-conformities, Corrective
      Actions and Preventive Actions.</small>
    </h2>

    <button mat-raised-button class="bg-green-700 text-white w-2/12" (click)="goto('/dash/traceability/non-conformities/create')">Add
      Non Conformity</button>

  </div>

  <!-- Search -->
  <div class="my-8">
    <app-simple-search (searched)="search($event)"></app-simple-search>
  </div>

  <!-- List Clients -->
  <app-non-conformities-table [searched]="searched"></app-non-conformities-table>
</main>

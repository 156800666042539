import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {}

  get token(): string | null{
    return localStorage.getItem('token');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if(request.url.includes('login')) return next.handle(request);

    const headers = new HttpHeaders({
      'Authorization': `token ${ this.token }`
    });


    // Se hace un clon de la request porque una vez modificada no se puede "usar"
    // Se podrían añadir los params
    const reqClone = request.clone({headers});

    return next.handle(reqClone).pipe(
      catchError( this.manejarError )
    );
  }

  manejarError( error: HttpErrorResponse): Observable<never>{
    throw error
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Load, LogisticListResponse, LogisticOrder, LogisticOrderBase } from '../models/logistic-list-response';
import { SearchLogisticFrm } from '../models/search-logistic-frm';
import { LogisticOrderbaseRes } from '../models/logistic-list-baseres';
import { LogisticTracking } from '../models/logistic-tracking';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class LogisticService {

  constructor(
    private http: HttpClient
  ) { }

  generateInitialLogistic(supplierId: string, data: any, preorder: number) :Observable<any>{
    return this.http.post(`${baseUrl}/logistic/initial/${supplierId}/${preorder}`, data)
  }

  assignProductsToLogistic(orderId: string, data: any): Observable<any>{
    return this.http.post(`${baseUrl}/logistic/assign/${orderId}`, data)
  }

  searchLogisticOrder(search:string, page: number, page_size = 20): Observable<LogisticListResponse>{
    const params = new HttpParams()
      .set('search', search ?? '')
      .set('page', page.toString() ?? '')
      .set('page_size', page_size.toString() ?? '')

    return this.http.get<LogisticListResponse>(`${baseUrl}/logistic/search`, {params})
  }

  searchLogisticOrderByPreorder(data:SearchLogisticFrm, page: number, page_size = 20): Observable<LogisticListResponse>{
    const params = new HttpParams()
      .set('preorder', data.preorder ?? '')
      .set('page', page.toString() ?? '')
      .set('page_size', page_size.toString() ?? '')

    return this.http.get<LogisticListResponse>(`${baseUrl}/logistic/search-preorder`, {params})
  }

  logisticOrderByOrder(order: number): Observable<LogisticOrder[]>{
    return this.http.get<LogisticOrder[]>(`${baseUrl}/logistic/by-order/${order}`)
  }

  updateLogistic(id: number, data:LogisticOrderBase): Observable<LogisticOrder>{
    return this.http.patch<LogisticOrder>(`${baseUrl}/logistic/${id}/`, data)
  }

  generateAllPdfsLogistic(preorder: number): Observable<any>{
    return this.http.get<any>(`${baseUrl}/logistic/${preorder}/all-pdf`)
  }

  generateSinglePdfLogistic(id: number): Observable<any>{
    return this.http.get<any>(`${baseUrl}/logistic/pdf/${id}`)
  }

  moveLoad(data: {load:number, order:number}): Observable<any>{
    return this.http.post<any>(`${baseUrl}/logistic/move-load`, data)
  }

  searchLogisticOrderBase(data: {search: string}): Observable<LogisticOrderbaseRes>{
    const params = new HttpParams()
      .set('search', data.search ?? '')
    return this.http.get<LogisticOrderbaseRes>(`${baseUrl}/logistic/search-simple`, {params})
  }

  logisticTrackDetail(id: number): Observable<LogisticTracking>{
    return this.http.get<LogisticTracking>(`${baseUrl}/logistic/track/${id}`)
  }

  updateLogisticLoad(id: number, data:any): Observable<Load>{
    return this.http.patch<Load>(`${baseUrl}/logistic-load/${id}/`, data)
  }

  addLogisticOrder(data: any): Observable<LogisticOrder>{
    return this.http.post<LogisticOrder>(`${baseUrl}/logistic/`, data)
  }

}

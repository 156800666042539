<div class="flex mt-6">

  <div *ngIf="barChartData" class="w-6/12 text-center">
    <h4 class="!text-base !text-green-900 !font-bold"> Commission Loads</h4>
      <canvas
      baseChart class="chart"
      [data]="barChartData"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"
      ></canvas>
  </div>
  <div *ngIf="barChartDataSale" class="w-6/12 text-center">
    <h4 class="!text-base !text-green-900 !font-bold"> Sales Loads</h4>
      <canvas
      baseChart class="chart"
      [data]="barChartDataSale"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [type]="barChartType"

      ></canvas>
  </div>

</div>

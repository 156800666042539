import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterOrder, FilterProductOrder } from '../models/filter-order';
import { ListOrders, Order, OrderBaseFrm } from '../models/list-orders';
import { ProductOrderFrm } from '../models/product-order-frm';
import { ProductOrderRes } from '../models/product-order-res';
import { ProductsOrderByPreorderResponse } from '../models/porducts_order_by_preorder_res';
import { OrderFilter } from '../models/order-filter';
import { ResumenByIntrastat, ResumenOrderRes } from '../../preorder/models/resumen-order';
import { OrderCost, OrderCostFrm, OrderCostList } from '../models/order-costs';
import { OrdersToInvoiceResponse } from '../models/orders-invoice';


const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor( private http: HttpClient) { }

searchOrders(filters: OrderFilter, page:number = 1): Observable<ListOrders>{
    const params = new HttpParams({
      fromObject: {
      'page': page.toString(),
      'client':  filters.client || '',
      'preorder':  filters.preorder || '',
      'departureDate_after':  filters.departureDate_after || '',
      'departureDate_before':  filters.departureDate_before || '',
      'hasLogistic':  filters.hasLogistic || '',
      'isInvoiced':  filters.isInvoiced || '',
      'search':  filters.search || '',
      'supplier':  filters.supplier || '',
      'plate':  filters.plate || '',
      'invoice':  filters.invoice || '',
      'preType': filters.preType || [],
      'status':  filters.status || [],
      'page_size':  filters.page_size || 60
      }
    })


    return this.http.get<ListOrders>(`${baseUrl}/order/search`, {params})
  }

  searchProductsOrder(filters: FilterProductOrder): Observable<any> {
    const params = new HttpParams()
        .set('order', filters.order || '')

    return this.http.get<any>(`${baseUrl}/product-order/search`, {params})
  }

  addSupplierOrder(data: OrderBaseFrm): Observable<Order> {
    return this.http.post<Order>(`${baseUrl}/order/`, data)
  }

  getOrder(id: number): Observable<Order> {
    return this.http.get<Order>(`${baseUrl}/order/${id}/`)
  }

  updateOrder(id: number, data:any): Observable<Order> {
    return this.http.patch<Order>(`${baseUrl}/order/${id}/`, data)
  }

  deleteOrder(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/order/${id}/`)
  }

  getProductOrder(id: number): Observable<ProductOrderRes> {
    return this.http.get<ProductOrderRes>(`${baseUrl}/order-products/${id}`)
  }

  addProductOrder(data: ProductOrderFrm): Observable<ProductOrderRes> {
    return this.http.post<ProductOrderRes>(`${baseUrl}/order-products/`, data)
  }

  updateProductOrder(id: number, data: ProductOrderFrm): Observable<ProductOrderRes> {
    return this.http.put<ProductOrderRes>(`${baseUrl}/order-products/${id}/`, data)
  }

  deleteProductOrder(id: number): Observable<any> {
    return this.http.delete(`${baseUrl}/order-products/${id}/`)
  }


  generateOrdersPdfByPreorder(id: number): Observable<any>{
    return this.http.get(`${baseUrl}/pre-order/${id}/pdf`)
  }


  getProductsOrderByPreoder(id: number, hasLogistic = 'False'): Observable<ProductsOrderByPreorderResponse>{
    const params = new HttpParams()
      .set('preorder', id.toString())
      .set('hasLogistic', hasLogistic)
      .set('page_size', '40')

    return this.http.get<ProductsOrderByPreorderResponse>(`${baseUrl}/order-products/pre-order`, { params })
  }

  getResumenOrderByPreoder(id: number): Observable<ResumenOrderRes>{
    const params = new HttpParams()
      .set('preorder', id.toString())
      .set('page_size', '40')

    return this.http.get<ResumenOrderRes>(`${baseUrl}/order/resumen`, { params })
  }

  getResumenByIntrastat(id: number): Observable<ResumenByIntrastat[]>{
    return this.http.get<ResumenByIntrastat[]>(`${baseUrl}/order/preorder/products-resumen/${id}`)
  }


  getOrderCostByOrder(order:string, page=1): Observable<OrderCostList>{
    const params = new HttpParams()
      .set('order', order)
      .set('page_size', '20')
      .set('page', page.toString())

    return this.http.get<OrderCostList>(`${baseUrl}/order/list-costs`, { params })
  }

  getOrderCost(id: number): Observable<OrderCost>{
    return this.http.get<OrderCost>(`${baseUrl}/order-cost/${id}`)
  }


  addOrderCost(data: OrderCostFrm): Observable<OrderCost>{
    return this.http.post<OrderCost>(`${baseUrl}/order-cost/`, data)
  }

  updateOrderCost(id: number, data: OrderCostFrm): Observable<OrderCost>{
    return this.http.patch<OrderCost>(`${baseUrl}/order-cost/${id}/`, data)
  }

  deleteOrderCost(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/order-cost/${id}/`)
  }

  generateFixedCost(id: number): Observable<any>{
    return this.http.post(`${baseUrl}/order/generate-fixed-cost/${id}`, {})
  }



  searchOrdersToInvoice(filters: OrderFilter, page:number = 1): Observable<OrdersToInvoiceResponse>{
    const params = new HttpParams({
      fromObject: {
      'page': page.toString(),
      'client':  filters.client || '',
      'preorder':  filters.preorder || '',
      'departureDate_after':  filters.departureDate_after || '',
      'departureDate_before':  filters.departureDate_before || '',
      'hasLogistic':  filters.hasLogistic || '',
      'isInvoiced':  filters.isInvoiced || '',
      'search':  filters.search || '',
      'supplier':  filters.supplier || '',
      'plate':  filters.plate || '',
      'invoice':  filters.invoice || '',
      'preType': filters.preType || [],
      'status':  filters.status || [],
      'page_size':  filters.page_size || 60
      }
    })


    return this.http.get<OrdersToInvoiceResponse>(`${baseUrl}/invoices/orders`, {params})
  }



}

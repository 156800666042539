import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { ProductOrderFiltered, ProductOrderSearchResponse } from 'src/app/features/order/models/products_order_search_response';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { FilterProductOrderReport } from '../../models/filter-product-order-report';

@Component({
  selector: 'app-table-products',
  templateUrl: './table-products.component.html',
  styleUrls: ['./table-products.component.css']
})
export class TableProductsComponent implements AfterViewInit {

  displayedColumns: string[] = ['product', 'client', 'supplier'];
  orders?: ProductOrderSearchResponse | null;
  data: ProductOrderFiltered[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: FilterProductOrderReport = {}


  constructor(
    private rs: ReportsService,
  ) { }

  ngAfterViewInit() {
    this._getOrders()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrders()
    }
  }

  private _getOrders(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.rs.filterProductOrder(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


}

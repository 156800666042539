import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CostType, CostTypeFrm } from '../../models/cost-types';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-frm-cost-type',
  templateUrl: './frm-cost-type.component.html',
  styleUrls: ['./frm-cost-type.component.css']
})
export class FrmCostTypeComponent implements OnInit {

  public frm?: FormGroup;

  @Input() costType?:CostType
  @Output() sendData = new EventEmitter<CostTypeFrm>()

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.costType){
      this._setCostType(this.costType)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  _setCostType(data: CostType): void{
      this.frm!.patchValue({
        ...data,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Supplier</mat-label>
    <input type="text" placeholder="Search supplier" matInput [formControl]="supplier" [matAutocomplete]="autoSupplier">
    <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="showSupplier" (optionSelected)="changeSupplier($event)">
      <mat-option *ngFor="let option of suppliersFilter" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

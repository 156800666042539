

export interface FilterOrder {
  status?:     string;
  client?:     number;
  preorder:    number;
}


export interface FilterProductOrder {
  order: number;
  limit: number;
}

import { Component, OnInit } from '@angular/core';
import { CreditNoteFilter } from '../../models/credit-filter';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-home',
  templateUrl: './credit-home.component.html',
  styleUrls: ['./credit-home.component.css']
})
export class CreditHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Credit Notes'},
  ]

  public searched: CreditNoteFilter = { search: '' }


  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  search = (data: CreditNoteFilter) => this.searched = data

  goto = (url:string) => this.rt.navigateByUrl(url)
}

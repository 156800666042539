import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from '../../../services/clients.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Client } from '../../../models/clients-response';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {

  idClient = this.ar.snapshot.params['id']
  client?: Client

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Clients', url: '/dash/admin/clients'},
    {name: 'Update Client'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private cs: ClientsService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void{

    this._getClient()
  }

  private _getClient(): void {
    this.cs.getClient(this.idClient!).subscribe({
      next: (resp) => {
        this.client = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting client','snack-error')
        this.rt.navigate(['/dash/admin/clients'])
      }
    })
  }


  updateClient(client: Client): void {
    this.cs.updateClient(this.idClient, client).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Client ${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating client','snack-error')
      }
    })
  }

}

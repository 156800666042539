<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-8/12">
    <mat-label>Packaging</mat-label>
    <input matInput formControlName="name" placeholder="EPS 105">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Price</mat-label>
    <input matInput formControlName="price" type="number" placeholder="10">
  </mat-form-field>

  <div class="w-3/12">
    <button mat-raised-button type="submit" class="w-full !mt-3 bg-green-700 text-white"> {{(packging) ? 'Updated' :
      'Create'}}
      Packaging </button>
  </div>
</form>

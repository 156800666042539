import { Component, OnInit } from '@angular/core';
import { IntrastatSearch } from '../../models/intrastat-reports';
import { ReportsService } from '../../services/reports.service';
import { ListPoProfitRes } from '../../models/list-po-profit';

@Component({
  selector: 'app-list-profit-sales',
  templateUrl: './list-profit-sales.component.html',
  styleUrls: ['./list-profit-sales.component.css']
})
export class ListProfitSalesComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Sales Profit'}
  ]
  total_profit: number = 0
  public searched: IntrastatSearch = {}

  constructor(
    private rpts: ReportsService
  ) { }

  ngOnInit(): void {
  }

  search = (data: IntrastatSearch) => this.searched = data

  presentResumen(data:ListPoProfitRes){
    this.total_profit = 0
    data.results.forEach( item => this.total_profit += item.profit)
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreorderService } from 'src/app/features/preorder/services/preorder.service';
import { SnackbarsService } from '../../services/snackbars.service';

@Component({
  selector: 'app-moda-pay-comercial',
  templateUrl: './moda-pay-comercial.component.html',
  styleUrls: ['./moda-pay-comercial.component.css']
})
export class ModaPayComercialComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    bankAccountPartnerPaid: ['', Validators.required],
    notes: [''],
  })


  constructor(
    private dialogRef: MatDialogRef<ModaPayComercialComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }


  close = () => this.dialogRef.close()

  selectAccount = (bank: string) => this.frm.patchValue({ bankAccountPartnerPaid: bank })


  send(){
    if(this.frm.valid){
      this.dialogRef.close(this.frm.value)
    }
  }


}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12 !text-lg">
      Update Pre-Order
      <app-badget class="bg-slate-50 text-slate-700 ring-slate-600/20 !text-base ms-1"
        [text]="typePreorder"></app-badget>
      <small class="block font-light text-xs text-slate-500 w-full"> Update  general of pre-order.</small>
    </h2>


  </div>

  <!-- Form -->
  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 lg:px-8">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab>
          <ng-template mat-tab-label> General</ng-template>
          <ng-template matTabContent>
            <app-preorder-frm (sendData)="updatePreorder($event)" [preorder]="preOrder"
              *ngIf="preOrder"></app-preorder-frm>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label><mat-icon svgIcon="preorder"></mat-icon></ng-template>
          <ng-template matTabContent>
            <app-order-list [preorder]="idPreorder" [typePreorder]="typePreorder"></app-order-list>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <svg class="mx-auto h-8 w-8 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
            </svg>
          </ng-template>
          <ng-template matTabContent>
            <app-logistic-preorder-list></app-logistic-preorder-list>
          </ng-template>
        </mat-tab>


      </mat-tab-group>

    </div>
  </div>


</main>

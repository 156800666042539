import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogisticSupplier } from '../../models/logistic-suppliers';

@Component({
  selector: 'app-logistic-supplier-frm',
  templateUrl: './logistic-supplier-frm.component.html',
  styleUrls: ['./logistic-supplier-frm.component.css']
})
export class LogisticSupplierFrmComponent implements OnInit {

  public frm?: FormGroup;

  @Input() supplier?:LogisticSupplier
  @Output() sendData = new EventEmitter<LogisticSupplier>()

  public countryId?: number;
  public countryName?: string;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.supplier){
      this._setSupplier(this.supplier)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      vatNumber: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      country_id: ['', Validators.required],
      province: ['', Validators.required],
      loadsCount: [0, Validators.required],
    });
  }

  _setSupplier(client: LogisticSupplier): void{
      this.frm!.patchValue({
        ...client,
        country_id: client.country.id,
      })

      this.countryId = client.country.id
      this.countryName = client.country.name
  }


  setCountry = (country: string): void => this.frm!.get('country_id')!.setValue(country)

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BuySalePreorder, PreOrders, Preorder, ProductPreorderFrm } from '../models/pre-order';
import { Observable, from } from 'rxjs';
import { PreorderFilter } from '../models/preorder-filter';
import { ProductPreorderRes, ProductsPreorderResponse } from '../models/product-preorder-response';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class PreorderService {

  constructor(private http: HttpClient) { }


  searchPreorders(filters: PreorderFilter, page: number): Observable<PreOrders>{
    const params = new HttpParams({
      fromObject: {
        'page': page.toString(),
        'search': filters.search || '',
        'client': filters.client || '',
        'page_size': (filters.page_size)? filters.page_size.toString() : '20',
        'departureDate_after': filters.departureDate_after || '',
        'departureDate_before': filters.departureDate_before || '',
        'typeOfPreOrder': filters.typeOfPreOrder || ['COMMISSION', 'BUY', 'SELL'],
        'status': filters.status || ['DRAFT', 'GENERATED', 'TEMPLATE', 'CANCELED']
      }
    })

    return this.http.get<PreOrders>(`${baseUrl}/pre-orders/search`, {params})
  }


  createPreorder(preorder: Preorder): Observable<Preorder>{
    return this.http.post<Preorder>(`${baseUrl}/pre-orders/`, preorder)
  }

  createBuySalePreorder(preorder: BuySalePreorder): Observable<number>{
    return this.http.post<number>(`${baseUrl}/pre-order/generate-buy-sale/`, preorder)
  }

  updatePreorder(pid: string, preorder: Preorder): Observable<Preorder>{
    return this.http.put<Preorder>(`${baseUrl}/pre-orders/${pid}/`, preorder)
  }

  updatePartialPreorder(pid: string, preorder: any): Observable<Preorder>{
    return this.http.patch<Preorder>(`${baseUrl}/pre-orders/${pid}/`, preorder)
  }



  deletePreorder(pid: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/pre-orders/${pid}/`)
  }

  getPreoder(preorderId: number): Observable<Preorder>{
    return this.http.get<Preorder>(`${baseUrl}/pre-orders/${preorderId}`)
  }

  // PRODUCTS BY PREORDER

  addProductToPreorder(data: ProductPreorderFrm): Observable<ProductPreorderRes>{
    return this.http.post<ProductPreorderRes>(`${baseUrl}/pre-orders-products/`, data)
  }

  updateProductToPreorder(idProductPreorder: number, data: ProductPreorderFrm): Observable<ProductPreorderRes>{
    return this.http.put<ProductPreorderRes>(`${baseUrl}/pre-orders-products/${idProductPreorder}/`, data)
  }

  getProductPreorder(id: number): Observable<ProductPreorderRes>{
    return this.http.get<ProductPreorderRes>(`${baseUrl}/pre-orders-products/${id}`)
  }


  deleteProductToPreorder(idProductPreorder: number): Observable<any>{
    return this.http.delete(`${baseUrl}/pre-orders-products/${idProductPreorder}/`)
  }

  searchProductsPreorder(filter: {preorder:string}, page = 1): Observable<ProductsPreorderResponse>{
    const params = new HttpParams({
      fromObject: {
        'preorder': filter.preorder,
        'page': page.toString(),
      }
    })
    return this.http.get<ProductsPreorderResponse>(`${baseUrl}/pre-orders/products`, {params})
  }


  generateInitialOrder(idPreorder: number): Observable<any>{
    return this.http.post<any>(`${baseUrl}/order/generate/${idPreorder}`, {})
  }


  duplicatePreOrder(idPreorder: number, dates: {arrivalDate: string, departureDate: string}): Observable<any>{
    return this.http.post<any>(`${baseUrl}/pre-order/duplicate/${idPreorder}`, dates)
  }

  generateSalePreorder(idPreorder: number, data: {client: number, arrivalDate:string, departureDate:string}): Observable<boolean>{
    return this.http.post<boolean>(`${baseUrl}/pre-order/sale/${idPreorder}`, data)
  }


  payPartnersByPo(data:any): Observable<any>{
    return this.http.post<any>(`${baseUrl}/pay-partners`, data)
  }

  payPartnersByClient(data:any): Observable<any>{
    return this.http.post<any>(`${baseUrl}/pay-byclient`, data)
  }

  payComercial(data: any): Observable<any> {
    return this.http.post<any>(`${baseUrl}/pay-comercial`, data)
  }

}

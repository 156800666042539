import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../../services/reports.service';
import { PoReport } from '../../models/report-po';

@Component({
  selector: 'app-sale-profit',
  templateUrl: './sale-profit.component.html',
  styleUrls: ['./sale-profit.component.css']
})
export class SaleProfitComponent implements OnInit {

  po: string = this.ar.snapshot.params['id'];
  poReport?: PoReport[];
  isLoading: boolean = true;
  global_result: number = 0;

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'List of Sale Profit', url: '/dash/reports/list-profit-sales'},
    {name: `Detail of ${this.po}`}
  ]

  constructor(
    private ar: ActivatedRoute,
    private rs: ReportsService
  ) { }

  ngOnInit(): void {
    this._loadDetails()
  }

  private _loadDetails() {
    this.rs.showEconomicPoReport(this.po).subscribe({
      next: (r) => {
        this.poReport = r
        this.isLoading = false
        this.global_result = this.poReport.reduce((a, b) => a + (b['profit'] || 0), 0)
      },
      error: (e) => console.error(e)
    })
  }

}

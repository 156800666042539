import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PoTrace } from 'src/app/features/traceability/models/search-po';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css']
})
export class PoListComponent implements OnInit {

  @Input() listPo: PoTrace[] = [];
  @Output() onSelect = new EventEmitter<string>();
  selectedPo?: string;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listPo']) {
      this.listPo = changes['listPo'].currentValue;
    }
  }

  ngOnInit(): void {
  }

  select = (po: string) => {
    this.selectedPo = po;
    this.onSelect.emit(po);
  }

}

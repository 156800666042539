import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminHomeComponent } from './pages/admin-home/admin-home.component';
import { JcvComponent } from './pages/jcv/jcv.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { JcvGeneralFormComponent } from './components/jcv-general-form/jcv-general-form.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JcvBankAccountFormComponent } from './components/jcv-bank-account-form/jcv-bank-account-form.component';
import { JcvBanksAccountComponent } from './components/jcv-banks-account/jcv-banks-account.component';
import { ClientsTableComponent } from './components/clients-table/clients-table.component';
import { NewClientComponent } from './pages/clients/new-client/new-client.component';
import { EditClientComponent } from './pages/clients/edit-client/edit-client.component';
import { FrmClientComponent } from './components/frm-client/frm-client.component';
import { ContactsClientsComponent } from './components/contacts-clients/contacts-clients.component';
import { ContactClientFrmComponent } from './components/contact-client-frm/contact-client-frm.component';
import { LogisticClientsComponent } from './components/logistic-clients/logistic-clients.component';
import { LogistiClientFrmComponent } from './components/logisti-client-frm/logisti-client-frm.component';
import { InstrastatListComponent } from './pages/intrastat/instrastat-list/instrastat-list.component';
import { InstrastatCreateComponent } from './pages/intrastat/instrastat-create/instrastat-create.component';
import { InstrastatEditComponent } from './pages/intrastat/instrastat-edit/instrastat-edit.component';
import { IntrastatsTableComponent } from './components/intrastats-table/intrastats-table.component';
import { FrmIntrastatComponent } from './components/frm-intrastat/frm-intrastat.component';
import { SizesListComponent } from './pages/sizes/sizes-list/sizes-list.component';
import { SizesCreateComponent } from './pages/sizes/sizes-create/sizes-create.component';
import { SizesEditComponent } from './pages/sizes/sizes-edit/sizes-edit.component';
import { FrmSizesComponent } from './components/frm-sizes/frm-sizes.component';
import { SizessTableComponent } from './components/sizess-table/sizess-table.component';
import { ProductsListComponent } from './pages/products/products-list/products-list.component';
import { ProductsCreateComponent } from './pages/products/products-create/products-create.component';
import { ProductsEditComponent } from './pages/products/products-edit/products-edit.component';
import { FrmProductComponent } from './components/frm-product/frm-product.component';
import { TableProductsComponent } from './components/table-products/table-products.component';
import { CertificationListComponent } from './pages/certifications/certification-list/certification-list.component';
import { CertificationCreateComponent } from './pages/certifications/certification-create/certification-create.component';
import { CertificationEditComponent } from './pages/certifications/certification-edit/certification-edit.component';
import { CertificationsTableComponent } from './components/certifications-table/certifications-table.component';
import { FrmCertificationComponent } from './components/frm-certification/frm-certification.component';
import { LogisticListComponent } from './pages/logisticSuppliers/logistic-list/logistic-list.component';
import { LogisticCreateComponent } from './pages/logisticSuppliers/logistic-create/logistic-create.component';
import { LogisticEditComponent } from './pages/logisticSuppliers/logistic-edit/logistic-edit.component';
import { LogisticSupplierTableComponent } from './components/logistic-supplier-table/logistic-supplier-table.component';
import { LogisticSupplierFrmComponent } from './components/logistic-supplier-frm/logistic-supplier-frm.component';
import { LogisticSupplierContactFrmComponent } from './components/logistic-supplier-contact-frm/logistic-supplier-contact-frm.component';
import { LogisticSupplierContactTableComponent } from './components/logistic-supplier-contact-table/logistic-supplier-contact-table.component';
import { SupplierListComponent } from './pages/suppliers/supplier-list/supplier-list.component';
import { SupplierCreateComponent } from './pages/suppliers/supplier-create/supplier-create.component';
import { SupplierEditComponent } from './pages/suppliers/supplier-edit/supplier-edit.component';
import { SupplierTableComponent } from './components/supplier-table/supplier-table.component';
import { SupplierFrmComponent } from './components/supplier-frm/supplier-frm.component';
import { SupplierContactsComponent } from './components/supplier-contacts/supplier-contacts.component';
import { SupplierContactFrmComponent } from './components/supplier-contact-frm/supplier-contact-frm.component';
import { SupplierLogisticComponent } from './components/supplier-logistic/supplier-logistic.component';
import { SupplierLogisticFrmComponent } from './components/supplier-logistic-frm/supplier-logistic-frm.component';
import { SupplierCertificationsComponent } from './components/supplier-certifications/supplier-certifications.component';
import { SupplierCertificationsFrmComponent } from './components/supplier-certifications-frm/supplier-certifications-frm.component';
import { SupplierProductsComponent } from './components/supplier-products/supplier-products.component';
import { SupplierProductsFrmComponent } from './components/supplier-products-frm/supplier-products-frm.component';
import { PackagingCreateComponent } from './pages/packaging/packaging-create/packaging-create.component';
import { PackagingEditComponent } from './pages/packaging/packaging-edit/packaging-edit.component';
import { PackagingListComponent } from './pages/packaging/packaging-list/packaging-list.component';
import { PackagingTableComponent } from './components/packaging-table/packaging-table.component';
import { FrmPackagingComponent } from './components/frm-packaging/frm-packaging.component';
import { CostTypeListComponent } from './pages/costTypes/cost-type-list/cost-type-list.component';
import { CostTypeEditComponent } from './pages/costTypes/cost-type-edit/cost-type-edit.component';
import { CostTypeCreateComponent } from './pages/costTypes/cost-type-create/cost-type-create.component';
import { CostTypeTableComponent } from './components/cost-type-table/cost-type-table.component';
import { FrmCostTypeComponent } from './components/frm-cost-type/frm-cost-type.component';
import { CreatePartnerComponent } from './pages/partner/create-partner/create-partner.component';
import { EditPartnerComponent } from './pages/partner/edit-partner/edit-partner.component';
import { ListPartnerComponent } from './pages/partner/list-partner/list-partner.component';
import { PartnersTableComponent } from './components/partners-table/partners-table.component';
import { PartnerFrmComponent } from './components/partner-frm/partner-frm.component';
import { FrmProductTranslateComponent } from './components/frm-product-translate/frm-product-translate.component';
import { ProductTranslateHomeComponent } from './components/product-translate-home/product-translate-home.component';
import { UserCreateComponent } from './pages/users/user-create/user-create.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { UserFrmComponent } from './components/user-frm/user-frm.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { ExceptionPriceHomeComponent } from './pages/products/exception-price-home/exception-price-home.component';
import { ExceptionPriceTableComponent } from './components/products/exception-price-table/exception-price-table.component';
import { ExceptionPriceFrmComponent } from './components/products/exception-price-frm/exception-price-frm.component';

@NgModule({
  declarations: [
    AdminHomeComponent,
    JcvComponent,
    ClientsComponent,
    JcvGeneralFormComponent,
    JcvBankAccountFormComponent,
    JcvBanksAccountComponent,
    ClientsTableComponent,
    NewClientComponent,
    EditClientComponent,
    FrmClientComponent,
    ContactsClientsComponent,
    ContactClientFrmComponent,
    LogisticClientsComponent,
    LogistiClientFrmComponent,
    InstrastatListComponent,
    InstrastatCreateComponent,
    InstrastatEditComponent,
    IntrastatsTableComponent,
    FrmIntrastatComponent,
    SizesListComponent,
    SizesCreateComponent,
    SizesEditComponent,
    FrmSizesComponent,
    SizessTableComponent,
    ProductsListComponent,
    ProductsCreateComponent,
    ProductsEditComponent,
    FrmProductComponent,
    TableProductsComponent,
    CertificationListComponent,
    CertificationCreateComponent,
    CertificationEditComponent,
    CertificationsTableComponent,
    FrmCertificationComponent,
    LogisticListComponent,
    LogisticCreateComponent,
    LogisticEditComponent,
    LogisticSupplierTableComponent,
    LogisticSupplierFrmComponent,
    LogisticSupplierContactFrmComponent,
    LogisticSupplierContactTableComponent,
    SupplierListComponent,
    SupplierCreateComponent,
    SupplierEditComponent,
    SupplierTableComponent,
    SupplierFrmComponent,
    SupplierContactsComponent,
    SupplierContactFrmComponent,
    SupplierLogisticComponent,
    SupplierLogisticFrmComponent,
    SupplierCertificationsComponent,
    SupplierCertificationsFrmComponent,
    SupplierProductsComponent,
    SupplierProductsFrmComponent,
    PackagingCreateComponent,
    PackagingEditComponent,
    PackagingListComponent,
    PackagingTableComponent,
    FrmPackagingComponent,
    CostTypeListComponent,
    CostTypeEditComponent,
    CostTypeCreateComponent,
    CostTypeTableComponent,
    FrmCostTypeComponent,
    CreatePartnerComponent,
    EditPartnerComponent,
    ListPartnerComponent,
    PartnersTableComponent,
    PartnerFrmComponent,
    FrmProductTranslateComponent,
    ProductTranslateHomeComponent,
    UserCreateComponent,
    UserEditComponent,
    UserListComponent,
    UserFrmComponent,
    UserTableComponent,
    ExceptionPriceHomeComponent,
    ExceptionPriceTableComponent,
    ExceptionPriceFrmComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
  ],
})
export class AdminModule { }

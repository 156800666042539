<div class="frm-login">
  <div class="logo">
    <img src="assets/img/logo.svg" />
  </div>

  <form [formGroup]="loginForm" (ngSubmit)="login()">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Username</mat-label>
        <input matInput placeholder="User" formControlName="username">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput placeholder="Password" type="password" formControlName="password">
      </mat-form-field>
    </div>

    <div>
        <button mat-flat-button color="primary" type="submit">Access</button>
    </div>

  </form>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LogisticOrderBase } from 'src/app/features/logistic/models/logistic-list-baseres';

import { LogisticService } from 'src/app/features/logistic/services/logistic.service';

@Component({
  selector: 'app-input-logistic-order',
  templateUrl: './input-logistic-order.component.html',
  styleUrls: ['./input-logistic-order.component.css']
})
export class InputLogisticOrderComponent implements OnInit {

  @Output('onSelect') onSearch = new EventEmitter<string>()

  logisticOrder = new FormControl('', Validators.required)
  logisticOrdersFilter?: LogisticOrderBase[]
  logisticOrderSelected?: any

  constructor(
    private ls: LogisticService,
  ) { }

  ngOnInit(): void {
    this._listener()
  }


  private _listener():void{
    this.logisticOrder.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.ls.searchLogisticOrderBase({search:resp}).subscribe({
            next: (r) => {
              this.logisticOrdersFilter = r.results
            }
          })
        }
      }
    })
  }


  showLogisticOrder = (valor: any): string => {
    this.logisticOrdersFilter?.filter( (m) => {
      if(m.id === valor){
        this.logisticOrderSelected = m.code
      }
    })
    return this.logisticOrderSelected;
  }

  clean():void{
    this.logisticOrder.reset()
    this.logisticOrderSelected = null
    this.logisticOrdersFilter = []
    this.onSearch.emit('')
  }

  changeLogisticOrder = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

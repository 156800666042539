import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ContactClient, ContactsClients } from '../../models/contacts-clients';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ClientsService } from '../../services/clients.service';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, merge, of, startWith, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ContactClientFrmComponent } from '../contact-client-frm/contact-client-frm.component';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-contacts-clients',
  templateUrl: './contacts-clients.component.html',
  styleUrls: ['./contacts-clients.component.css']
})
export class ContactsClientsComponent implements AfterViewInit {

  idClient = this.ar.snapshot.params['id']

  displayedColumns: string[] = ['name', 'email', 'phone', 'toSend', 'actions'];
  contacts?: ContactsClients | null;
  data: ContactClient[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private ar: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private cs: ClientsService,
    private is: CustomIconsService,
  ) {
  }

  ngAfterViewInit() {
    this._getContacts()
  }


  private _getContacts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cs.getContactsByClient(
            this.idClient
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  openContactFrm(id = null): void {
    const accountSheet = this._bottomSheet.open(ContactClientFrmComponent, {
      disableClose: true,
      data: {
        id,
        client: this.idClient,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getContacts()
    }
    );

  }

  deleteContact(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete contact',
        message: 'Are you sure you want to delete this client contact?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.cs.deleteContact(id).subscribe(() => this._getContacts())
      }
    })
  }

}


import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ItemMenu } from 'src/app/core/models/item-menu';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  menu: ItemMenu[] = [];

  constructor(
    private http: HttpClient,
    private icon: CustomIconsService,
    private as: AuthService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('is_adm') === 'true') {
      this.http.get<ItemMenu[]>('assets/data/menu.json').subscribe( resp =>  this.menu = resp );
    }else{
      this.http.get<ItemMenu[]>('assets/data/menu_tecnico.json').subscribe( resp =>  this.menu = resp );

    }
  }


  logout(): void{
    this.as.logout();
  }

}

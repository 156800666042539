import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryFrm } from '../../models/delivery-frm';
import { DeliveryDetail, ProductElementDelivery } from '../../models/delivery-detail';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Incoterm } from 'src/app/features/admin/models/incoterms';
import { IncotermsService } from '../../../admin/services/incoterms.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ModalPropertiesLoadComponent } from '../modal-properties-load/modal-properties-load.component';
import { DataLoadDeliveryFrm } from '../../models/data-load-delivery-frm';
import { DeliveryService } from '../../services/delivery.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-delivery-frm',
  templateUrl: './delivery-frm.component.html',
  styleUrls: ['./delivery-frm.component.css']
})
export class DeliveryFrmComponent implements OnInit {

  public frm?: FormGroup;
  public incoterms: Incoterm[] = []

  @Input() delivery?:DeliveryDetail
  @Output() sendData = new EventEmitter<DeliveryFrm>()
  @Output() refresh = new EventEmitter<boolean>()

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheet,
    private is: IncotermsService,
    private ds: DeliveryService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._loadIncoterms()
    this._createForm();
    this._setDelivery(this.delivery!)
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      incoterms_id: [''],
      certifiedPhyto: [''],
      notes: ['']
    })
  }

  private _loadIncoterms(): void{
    this.is.getAll().subscribe(r => this.incoterms = r.results)
  }

  private _setDelivery(order: DeliveryDetail): void{
    this.frm!.patchValue({
      incoterms_id: (order.incoterms) ? order.incoterms.id : '',
      certifiedPhyto: order.certifiedPhyto,
      notes: order.notes
    })
  }

  private _updateDataPoProducts(data: DataLoadDeliveryFrm){
    this.ds.changeDataLoads(this.delivery!.id, data).subscribe(r => {
      if (r){
        this.ss.nuevaSnack('Data updated successfully','snack-success')
        this.refresh.emit(true)
      }
    })


  }


  editPropertiesLoad(load: ProductElementDelivery): void{
    this.bsheet.open(ModalPropertiesLoadComponent, {
      data: {
        load: load.id,
        product: load.product.name,
        lot: load.lot,
        grossWeight: load.grossWeight,
        netWeight: load.netWeight,
        origin: load.origin || 'Spain',
        packaging: (load.packaging) ? load.packaging.id : null,
      }
    }).afterDismissed().subscribe(r => {
      if (r) {
        this._updateDataPoProducts(r)
      }
    })

  }

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
    }

    this.sendData.emit(data);
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Preorder } from 'src/app/features/preorder/models/pre-order';
import { PreorderService } from 'src/app/features/preorder/services/preorder.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-add-supplier-order-frm',
  templateUrl: './add-supplier-order-frm.component.html',
  styleUrls: ['./add-supplier-order-frm.component.css']
})
export class AddSupplierOrderFrmComponent implements OnInit {

  frm?:FormGroup

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {preorder: number},
    private _bottomRef: MatBottomSheetRef<AddSupplierOrderFrmComponent>,
    private fb: FormBuilder,
    private ps: PreorderService,
    private ss: SnackbarsService,
    private os: OrderService,
  ) { }

  ngOnInit(): void {
    this._formBuilder()
    this._getBasePreorderData();
  }

  private _formBuilder(){
    this.frm = this.fb.group({
      arrivalDate: ['', Validators.required],
      client_id: ['', Validators.required],
      currencyChange: ['', Validators.required],
      departureDate: ['', Validators.required],
      notes: [''],
      preorder_id: ['', Validators.required],
      supplier_id: ['', Validators.required],
    })
  }


  private _getBasePreorderData(){
    if (!this.data.preorder) return

    this.ps.getPreoder(this.data.preorder).subscribe({
      next: (data: Preorder) => {
        this.frm?.patchValue({
        arrivalDate: data.arrivalDate,
        client_id: data.client.id,
        currencyChange: data.currencyChange,
        departureDate: data.departureDate,
        preorder_id: this.data.preorder
      })
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting preorder base data', 'snack-error')
        this._bottomRef.dismiss()
      }
    })
  }

  setSupplier = (supplier: string): void => this.frm!.get('supplier_id')!.setValue(supplier)

  close(){
    this._bottomRef.dismiss()
  }

  send(){
    this.os.addSupplierOrder(this.frm!.value).subscribe({
      next: (data) => {
        this.ss.nuevaSnack('Supplier Added!', 'snack-success')
        this._bottomRef.dismiss(true)
      },
      error: () => this.ss.nuevaSnack('Error adding supplier', 'snack-error')
    });
  }

}

<div class="transform bg-white text-left transition-all p-4" *ngIf="productOrder">
  <form [formGroup]="frm" (ngSubmit)="sendData()" *ngIf="frm">
    <div class="space-y-4">
      <div class="border-b border-gray-900/10 pb-2">
        <h2 class="text-base font-semibold leading-7 text-slate-700">Settings</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Loading options</p>

        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
          <div class="sm:col-span-2">
            <app-input-coins [coinId]="coinId" (coinChange)="changeCoin($event)" class="w-40"></app-input-coins>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Pay Commission</mat-label>
              <mat-select formControlName="payCommission">
                <mat-option value="CLIENT">Client</mat-option>
                <mat-option value="SUPPLIER">Supplier</mat-option>
                <mat-option value="ZERO">Zero</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Pay Logistic</mat-label>
              <mat-select formControlName="payLogistic">
                <mat-option value="CLIENT">Client</mat-option>
                <mat-option value="SUPPLIER">Supplier</mat-option>
                <mat-option value="SNC">Supplier No Cost</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </div>

      <div class="border-b border-gray-900/10 pb-2">
        <h2 class="text-base font-semibold leading-7 text-green-700">Product</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Basic product information</p>

        <div class="mt-2 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <app-input-product-by-supplier [order]="data.order.id" [idSupplier]="data.order.supplier.id" [productId]="productId" [productName]="productName"
              (productChange)="changeProduct($event)"></app-input-product-by-supplier>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Product Price </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="price">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <div class="w-full self-center bg-slate-100 p-4 rounded-sm">
              <mat-checkbox formControlName="isExtra">Is Extra</mat-checkbox>
            </div>
          </div>

          <div class="sm:col-span-4 flex">
            <h2 class="text-green-800 font-semibold">{{this.data.order.supplier.name}}</h2>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Origin </mat-label>
              <input matInput type="text" formControlName="origin" placeholder="Origin country">
            </mat-form-field>
          </div>

        </div>
      </div>

      <div class="border-b border-gray-900/10 pb-2">
        <h2 class="text-base font-semibold leading-7 text-sky-700">Load & Delivery</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Loading and transport information</p>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Pallets </mat-label>
              <input matInput type="number" formControlName="pallets">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <div class="w-full self-center bg-slate-100 p-4 rounded-sm">
              <mat-checkbox formControlName="isMixed">Is Mixed</mat-checkbox>
            </div>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Pallet cost </mat-label>
              <input matInput type="number" formControlName="palletCost">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label>Packaging</mat-label>
              <mat-select formControlName="packaging">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let packaging of packaging_list" [value]="packaging.id">
                  {{packaging.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Lot / Delivery Note </mat-label>
              <input matInput type="text" formControlName="lot">
            </mat-form-field>
          </div>

          <div class="sm:col-span-3">
            <app-input-loadplaces [supplierInfo]="supplierInfo" [loadPlaceInfo]="loadPlaceInfo"
              (onSelect)="changeLoadPlace($event)"></app-input-loadplaces>
          </div>

          <div class="sm:col-span-3">
            <app-input-unloadplaces [clientInfo]="clientInfo" [unLoadPlaceInfo]="unLoadPlaceInfo"
              (onSelect)="changeUnloadPlace($event)"></app-input-unloadplaces>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Box/Pallets </mat-label>
              <input matInput type="number" formControlName="palletBox">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Kgs/Box </mat-label>
              <input matInput type="number" formControlName="kgsBox">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Total Boxes </mat-label>
              <input matInput type="number" formControlName="totalBoxes" [readonly]="!isMixed?.value">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Net Weight </mat-label>
              <input matInput type="number" formControlName="netWeight">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Gross Weight </mat-label>
              <input matInput type="number" formControlName="grossWeight">
            </mat-form-field>
          </div>

        </div>

      </div>

      <div class="border-b border-gray-900/10 pb-2">
        <h2 class="text-base font-semibold leading-7 text-red-700">Economic</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Information on commissions and prices</p>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Box costs </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="boxCost">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Logistic price </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="logisticCost">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Delivery price </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="deliveryPrice">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Price to be invoiced </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="invoicedPrice">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> % Commission </mat-label>
              <input matInput type="number" formControlName="commisionPercentage">
            </mat-form-field>
          </div>

          <div class="sm:col-span-1">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Box commission </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="boxCommission">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Total commission </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="totalCommission">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline" class="w-full">
              <mat-label> Total </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="total">
            </mat-form-field>

          </div>

        </div>

      </div>

      <div class="border-b border-gray-900/10 pb-2" *ngIf="data.order.preorder.typeOfPreOrder === 'BUY'">
        <h2 class="text-base font-semibold leading-7 text-purple-700">Sale conditions & Partnes</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Sales conditions and prices and memberships</p>
        <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline">
              <mat-label> Sale Product Price </mat-label>
              <mat-icon matSuffix>{{(productOrder.coinName === 'EUR') ? 'euro' : 'currency_pound'}}</mat-icon>
              <input matInput type="number" formControlName="sale_price">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline">
              <mat-label> Sale Pallet cost </mat-label>
              <input matInput type="number" formControlName="sale_palletCost">
            </mat-form-field>
          </div>

          <div class="sm:col-span-2">
            <mat-form-field appearance="outline">
              <mat-label>Sale Pay Logistic</mat-label>
              <mat-select formControlName="sale_payLogistic">
                <mat-option value="CLIENT">Client</mat-option>
                <mat-option value="SUPPLIER">Supplier</mat-option>
                <mat-option value="SNC">Supplier no cost</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sm:col-span-full">
            <app-input-select-partner [partnerIds]="partners_selected" (partnerChange)="changePartner($event)"></app-input-select-partner>
          </div>

        </div>

      </div>

    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <button type="button"
        class="mt-3 inline-flex mx-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        (click)="dialogRef.close()">Cancel</button>

      <button type="submit"
        class="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto">Save</button>
    </div>
  </form>
</div>



<div *ngIf="logisticOrders.length > 0; else noLogistic">
  <div *ngFor="let order of logisticOrders">
    <div class="border border-sky-700 bg-sky-200 px-4 py-5 sm:px-6 rounded mt-4 mb-1">
      <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div class="ml-4 mt-2">
          <h3 class="text-base !font-semibold leading-6 text-gray-900">
            {{order.code}} ( {{order.logisticSupplier.name}} )
          </h3>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0 flex gap-3">
          <app-badget class="!text-sm bg-slate-50 text-slate-600 ring-slate-500/10" [text]="order.totalPallets"
            subtitle="Pallets"></app-badget>
          <app-badget class="!text-sm bg-slate-50 text-slate-600 ring-slate-500/10"
            [text]="order.totalCost|currency:'EUR'"></app-badget>
          <button mat-button type="button" class="!flex bg-slate-100 ms-6 h-7 items-center" (click)="updatePlate(order.id)">
            {{order.plate ? order.plate : 'Assign Plate'}}
          </button>
          <button mat-button type="button" class="!flex bg-sky-700 text-white ms-6 h-7 items-center" (click)="sendEmails(order)">
            Send Mail
          </button>
        </div>
      </div>
    </div>

    <ul role="list"
      class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
      <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6"
        *ngFor="let load of order.loads">
        <div class="flex min-w-0 gap-x-4">
          <app-logistic-icon></app-logistic-icon>
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                {{load.product.product.name}} {{load.product.product.description}} - Size:
                {{load.product.product.size.size}}
            </p>
            <p class="mt-1 flex text-xs leading-5 text-gray-500">
              {{load.product.supplier.name}}
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <p class="text-sm leading-6 ">
              <app-badget class="bg-sky-50 text-sky-600 ring-sky-500/10" [text]="load.product.pallets"
                [subtitle]="(load.product.isMixed) ? 'Mixed Pallet' : 'Pallets'"></app-badget>
            </p>
            <p class="mt-1 text-xs leading-6">
              <app-badget class="bg-green-50 text-green-600 ring-green-500/10"
                [text]="(load.product.palletCost * load.product.pallets)|currency:'EUR'"></app-badget>

            </p>
          </div>
          <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd" />
          </svg>
        </div>
      </li>
    </ul>
  </div>
</div>


<ng-template #noLogistic>
  <div class="mt-6">
    <app-empty title="No logistic" text="This order does not have the logistics set up"></app-empty>
  </div>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { CostTypeService } from '../../../services/cost-type.service';
import { CostTypeFrm } from '../../../models/cost-types';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-cost-type-create',
  templateUrl: './cost-type-create.component.html',
  styleUrls: ['./cost-type-create.component.css']
})
export class CostTypeCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Costs Types', url: '/dash/admin/cost-type'},
    {name: 'New Cost Type'},
  ]

  constructor(
    private rt: Router,
    private cts: CostTypeService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createCostType(size: CostTypeFrm): void {
    this.cts.addCostType(size).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/cost-type/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating cost type','snack-error')
      }
    })
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-badget',
  templateUrl: './badget.component.html',
  styleUrls: ['./badget.component.css']
})
export class BadgetComponent implements OnInit {
  @Input() text?: any
  @Input() subtitle?: any
  @Input() class: string = 'bg-gray-50 text-gray-600 ring-gray-500/10'

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">
  <button mat-raised-button (click)="openContactFrm()" class="bg-green-700 text-white w-2/12">Add New Contact</button>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div *ngIf="isRateLimitReached">
      Contacts client
    </div>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row" (click)="openContactFrm(row.id)" class="hover:cursor-pointer">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let row" (click)="openContactFrm(row.id)" class="hover:cursor-pointer">{{row.email}}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let row" (click)="openContactFrm(row.id)" class="hover:cursor-pointer">{{row.phone}}</td>
      </ng-container>

      <ng-container matColumnDef="toSend">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" (click)="openContactFrm(row.id)" class="hover:cursor-pointer">
          <span
            class="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-600/20"
            *ngIf="row.toSend">Notificate</span>
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Contact" (click)="deleteContact(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Contacts found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select Contacts search results"></mat-paginator>
</div>

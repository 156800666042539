<div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">
  <button mat-raised-button (click)="openCertification()" class="bg-green-700 text-white w-2/12">Add New Certification</button>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="certification" matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="certification">
        <th mat-header-cell *matHeaderCellDef>Certification</th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer">{{row.certification.certification}}
        </td>
      </ng-container>

      <ng-container matColumnDef="identification">
        <th mat-header-cell *matHeaderCellDef>Identification</th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer">{{row.identification}}
        </td>
      </ng-container>

      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef>Start</th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer">{{row.startDate|date}}
        </td>
      </ng-container>

      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef>End</th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer">{{row.endDate|date}}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer w-48">
          <span
            class="inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset "
            [ngClass]="
              {'bg-green-50 text-green-700 ring-green-600/20': row.typeCertification === 'APPROVED',
              'bg-orange-50 text-orange-700 ring-orange-600/20': row.typeCertification === 'TEST',
              'bg-red-50 text-red-700 ring-red-600/20': row.typeCertification === 'REJECTED',
              'bg-slate-50 text-slate-700 ring-slate-600/20': row.typeCertification === 'DEHOMOLOGIZED'
            }">
            {{row.typeCertification}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="risk">
        <th mat-header-cell *matHeaderCellDef>Risk</th>
        <td mat-cell *matCellDef="let row" (click)="openCertification(row.id)" class="hover:cursor-pointer">
          <span
            class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20"
            >{{row.risk}}</span>
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Certification" (click)="deleteCertification(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Crtifications found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select Certification search results"></mat-paginator>
</div>

<div class="p-4">
<h4 class="text-base text-green-700 !font-bold"> Duplicate Preorder </h4>
<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Order Date</mat-label>
    <input matInput [matDatepicker]="departureDate" formControlName="orderDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #departureDate></mat-datepicker>
  </mat-form-field>

  <app-input-clients [clientId]="data.clientId" [clientName]="data.clientName" (onSelect)="changeClient($event)"></app-input-clients>

  <div class="actions-frm text-end my-6 w-full">
    <button mat-raised-button type="submit" class="w-full bg-green-700 text-white"> Duplicate </button>
  </div>
</form>
</div>

import { Component, OnInit } from '@angular/core';
import { FilterProductOrderReport } from '../../models/filter-product-order-report';
import { saveAs } from 'file-saver';
import { ReportsService } from '../../services/reports.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  public breadcrumb = [
    {name: 'Products Ordes Report'},
  ]

  public searched: FilterProductOrderReport = {}


  constructor(
    private rs: ReportsService,
  ) { }

  ngOnInit(): void {
  }

  search = (data: FilterProductOrderReport) => this.searched = data


  exportExcel = () => {

    this.rs.exportExcel(this.searched).subscribe({
      next: (r) => {
        saveAs(r, 'products_order.xlsx')
      },
      error: (e) => console.log('Error')
    })

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Enterprise } from '../models/jcv-response';
import { Observable } from 'rxjs';
import { JcvForm } from '../models/jcv-form';
import { BankAccount, BankAccounts } from '../models/bank-accounts';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class JcvService {

  constructor(private http: HttpClient) { }

  getConfig(): Observable<Enterprise>{
    return this.http.get<Enterprise>(`${baseUrl}/enterprise`)
  }

  updateConfig(id: number, data: JcvForm): Observable<Enterprise>{
    return this.http.put<Enterprise>(`${baseUrl}/enterprise/${id}/`, data)
  }

  getBankAccounts(): Observable<BankAccounts>{
    return this.http.get<BankAccounts>(`${baseUrl}/bank-account`)
  }

  getBankAccount(id: number): Observable<BankAccount>{
    return this.http.get<BankAccount>(`${baseUrl}/bank-account/${id}`)
  }

  createBankAccount(data: BankAccount): Observable<BankAccount>{
    return this.http.post<BankAccount>(`${baseUrl}/bank-account/`, data)
  }

  updateBankAccount(id: number, data: BankAccount): Observable<BankAccount>{
    return this.http.put<BankAccount>(`${baseUrl}/bank-account/${id}/`, data)
  }

  deleteBankAccount(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/bank-account/${id}/`)
  }

}

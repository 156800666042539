import { Component, OnInit } from '@angular/core';
import { SecondLineFilter } from '../../models/second-line-filter';
import { Router } from '@angular/router';

@Component({
  selector: 'app-second-line-home',
  templateUrl: './second-line-home.component.html',
  styleUrls: ['./second-line-home.component.css']
})
export class SecondLineHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Second Line'},
  ]

  public searched: SecondLineFilter = { search: '' }


  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  search = (data: SecondLineFilter) => this.searched = data

  goto = (url:string) => this.rt.navigateByUrl(url )
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NonConformities, NonConformity } from '../models/non-conformities';
import { ListPoTrace } from '../models/search-po';
import { TraceDetail } from '../models/trace-detail';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class TraceabilityService {

  constructor(
    private http: HttpClient
  ) { }


  searchNonConformities(search?: string, page: number = 1): Observable<NonConformities> {
    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())

    return this.http.get<NonConformities>(`${baseUrl}/search-conformities`, { params })
  }

  getNonConformities(): Observable<NonConformities> {
    return this.http.get<NonConformities>(`${baseUrl}/non-conformities/`)
  }

  getNonConformity(id: number): Observable<NonConformity> {
    return this.http.get<NonConformity>(`${baseUrl}/non-conformities/${id}`)
  }

  addNonConformity(data: NonConformity): Observable<NonConformity> {
    return this.http.post<NonConformity>(`${baseUrl}/non-conformities/`, data)
  }

  updateNonConformity(id: number, data: NonConformity): Observable<NonConformity> {
    return this.http.patch<NonConformity>(`${baseUrl}/non-conformities/${id}/`, data)
  }

  deleteNonConformity(id: number): Observable<any> {
    return this.http.delete<any>(`${baseUrl}/non-conformities/${id}/`)
  }


  searchPo(search?: string, pageSize: number = 5): Observable<ListPoTrace>{
    const params = new HttpParams()
      .set('search', search || '')
      .set('page_size', pageSize.toString())

    return this.http.get<ListPoTrace>(`${baseUrl}/search-po`, { params })
  }


  tracePo(po: string): Observable<TraceDetail>{
    const params = new HttpParams()
      .set('po', po)

    return this.http.get<TraceDetail>(`${baseUrl}/trace-info`, { params })
  }



}

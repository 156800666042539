<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8 mb-4">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

<div class="w-full flex h-fit gap-3">

  <div class="w-3/12 p-3 bg-white">
    <h4 class="!my-3 !text-base !font-bold !text-center !p-0 text-green-900"> POs</h4>
    <app-search-po-frm (onSearch)="search($event)"></app-search-po-frm>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <div class="mt-3">
      <app-po-list [listPo]="listPo" (onSelect)="addPoToReport($event)"></app-po-list>
    </div>
  </div>

  <div class="w-9/12 p-3 bg-white">
    <app-resumen-profit-pos-partners [listSelectedPos]="selectedPos" (remove)="refresh($event)"></app-resumen-profit-pos-partners>
  </div>

</div>
</main>

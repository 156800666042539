<div *ngIf="frm" class="px-3">

  <mat-accordion class="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="flex flex-row flex-wrap gap-0 gap-x-2 justify-start items-center">

          <app-input-clients (onSelect)="updateClient($event)" class="w-6/12"></app-input-clients>
          <app-input-suppliers (onSelect)="updateSupplier($event)" class="w-5/12"></app-input-suppliers>
          <app-input-products (onSelect)="updateProduct($event)" class="w-6/12"></app-input-products>

          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label> Departure </mat-label>
            <mat-date-range-input [formGroup]="frm" [rangePicker]="picker">
              <input matStartDate formControlName="departureDate_after" placeholder="After">
              <input matEndDate formControlName="departureDate_before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <div class="w-2/12">
            <mat-form-field appearance="outline">
              <mat-label>Invoiced?</mat-label>
              <mat-select formControlName="isInvoiced">
                <mat-option value="True">It's Invoiced</mat-option>
                <mat-option value="False">Not Invoiced</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline" class="w-3/12">
            <mat-label> Carrier Invoice </mat-label>
            <input matInput formControlName="carrierInvoice" placeholder="CI12312">
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-3/12">
            <mat-label> Supplier Invoice </mat-label>
            <input matInput formControlName="supplierInvoice" placeholder="SI12312">
          </mat-form-field>


          <div class="actions w-2/12 text-end pb-2">
            <button mat-raised-button class="bg-red-600 text-white" (click)="clean()">Clean</button>
          </div>


        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IntrastatService } from '../../../services/intrastat.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Intrastat } from '../../../models/intrastat';

@Component({
  selector: 'app-instrastat-create',
  templateUrl: './instrastat-create.component.html',
  styleUrls: ['./instrastat-create.component.css']
})
export class InstrastatCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Intrastats', url: '/dash/admin/intrastat'},
    {name: 'New intrastat'},
  ]

  constructor(
    private rt: Router,
    private is: IntrastatService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createIntrastat(intrastat: Intrastat): void {
    this.is.addIntrastat(intrastat).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Intrastat ${resp.code} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/intrastat/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating intrastat code','snack-error')
      }
    })
  }
}

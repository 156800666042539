import { Component, OnInit } from '@angular/core';
import { PreorderService } from '../../services/preorder.service';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Preorder } from '../../models/pre-order';

@Component({
  selector: 'app-preorder-create',
  templateUrl: './preorder-create.component.html',
  styleUrls: ['./preorder-create.component.css']
})
export class PreorderCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Pre-Orders', url: '/dash/pre-orders'},
    {name: 'New Pre-Order'},
  ]

  constructor(
    private rt: Router,
    private po: PreorderService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createPreorder(preorder: Preorder): void {
    this.po.createPreorder(preorder).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Preorder ${resp.code} created successfully`,'snack-success')
        this.rt.navigate(['/dash/pre-orders/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating preorder','snack-error')
      }
    })

  }

}

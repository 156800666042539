import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeToPound'
})
export class ChangeToPoundPipe implements PipeTransform {

  transform(value: any, changeCoin: any): number {
    return value / changeCoin
  }

}

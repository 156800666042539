import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Size } from 'src/app/features/admin/models/sizes';
import { SizesService } from 'src/app/features/admin/services/sizes.service';
import { CustomIconsService } from '../../services/custom-icons.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input-size',
  templateUrl: './input-size.component.html',
  styleUrls: ['./input-size.component.css']
})
export class InputSizeComponent implements OnInit {

  size = new FormControl('', Validators.required)

  sizes: Size[] = []
  sizesFilter?: any[]
  sizeSelected?: any

  @Input() sizeId?: number
  @Input() sizeName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private ss: SizesService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sizeId']?.currentValue){
      this.size.setValue(changes['sizeId']?.currentValue)
      this.sizeSelected = changes['sizeName']?.currentValue
    }

  }

  private _listener():void{
    this.size.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.ss.searchSizes(resp).subscribe({
            next: (r) => {
              this.sizesFilter = r.results
            }
          })
        }
      }
    })
  }


  showSize = (valor: any): string => {
    this.sizesFilter?.filter( (m) => {
      if(m.id === valor){
        this.sizeSelected = m.size
      }
    })
    return this.sizeSelected;
  }

  clean():void{
    this.size.reset()
    this.sizeSelected = null
    this.sizesFilter = []
    this.onSearch.emit('')
  }

  changeSize = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

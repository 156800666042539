<div class="w-full my-4 text-end">
  <button mat-raised-button type="button" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
    (click)="openInvoiceItem()">
    Add Concept
  </button>
</div>

<table class="min-w-full divide-y divide-gray-300" *ngIf="data">
  <thead>
    <tr>
      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Concept</th>
      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Quantity</th>
      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Price</th>
      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>

    </tr>
  </thead>
  <tbody class="bg-white">
    <tr class="even:bg-gray-50 hover:bg-slate-100 hover:cursor-pointer" *ngFor="let c of data.items">
      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3" (click)="openInvoiceItem(c)">
        {{c.description}}
      </td>
      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3" (click)="openInvoiceItem(c)">
        {{c.quantity}}
      </td>

      <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500" (click)="openInvoiceItem(c)">
        <div *ngIf="data.coin === 'EUR'; else pricePound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="c.total|currency:'EUR'"></app-badget>
        </div>

        <ng-template #pricePound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="c.unit_price|changeToEuro:data.exchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
              [text]="c.unit_price|currency:'GBP'"></app-badget>
          </div>
        </ng-template>

      </td>

      <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500" (click)="openInvoiceItem(c)">
        <div *ngIf="data.coin === 'EUR'; else totalPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="c.total|currency:'EUR'"></app-badget>
        </div>

        <ng-template #totalPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
            [text]="c.total|changeToEuro:data.exchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="c.total|currency:'GBP'"></app-badget>
          </div>
        </ng-template>

      </td>

      <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500">
        <button mat-icon-button aria-label="Delete Concept" (click)="delete(c.id)">
          <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
        </button>
      </td>

    </tr>

  </tbody>
</table>

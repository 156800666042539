import { Component, OnInit } from '@angular/core';
import { MenuAdmin } from '../../models/menu-admin';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  public items: MenuAdmin[] = [
    { title: 'Juan CampoVerde', subtitle: 'Admin Juan CampoVerde S4U', icon: 'admin', class: 'text-teal-700 bg-teal-50', path: '/dash/admin/jcv' },
    { title: 'Clients', subtitle: 'Manage Clients', icon: 'clients', class: 'bg-purple-50 text-purple-700', path: '/dash/admin/clients' },
    { title: 'Intrastat code', subtitle: 'Manage Intrastat codes', icon: 'intrastat', class: 'bg-red-50 text-red-700', path: '/dash/admin/intrastat' },
    { title: 'Products', subtitle: 'Manage Products', icon: 'products', class: 'bg-blue-50 text-blue-700', path: '/dash/admin/products' },
    { title: 'Suppliers', subtitle: 'Manage Supliers', icon: 'suppliers', class: 'bg-orange-50 text-orange-700', path: '/dash/admin/suppliers' },
    { title: 'Sizes', subtitle: 'Manage Products sizes', icon: 'intrastat', class: 'bg-red-50 text-red-700', path: '/dash/admin/sizes' },
    { title: 'Packaging', subtitle: 'Manage Packaging products', icon: 'packaging', class: 'bg-blue-50 text-blue-700', path: '/dash/admin/packaging' },
    { title: 'Logistic Supplier', subtitle: 'Manage Logistic Suppliers', icon:'logistic', class: 'bg-indigo-50 text-indigo-700', path: '/dash/admin/logistic' },
    { title: 'Certifications', subtitle: 'Manage List of Certifications', icon:'certification', class: 'bg-slate-100 text-slate-700', path: '/dash/admin/certifications' },
    { title: 'User', subtitle: 'Manage user to access application', icon:'user', class: 'bg-purple-50 text-purple-700', path: '/dash/admin/users' },
    { title: 'Cost Type', subtitle: 'Manage costs type to order cost', icon:'banknotes', class: 'bg-purple-50 text-purple-700', path: '/dash/admin/cost-type' },
    { title: 'Partners', subtitle: 'Manage partnes and number of persons', icon:'user', class: 'bg-teal-50 text-teal-700', path: '/dash/admin/partners' },
  ]

  constructor() {
  }

  ngOnInit(): void {
  }

}

<div class="p-3 bg-white" *ngIf="poDetail; else noDetail">

  <div class="text-end">

    <span
      class="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-lg font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg class="h-1.5 w-1.5" [ngClass]="{'fill-green-500': global_result > 0, 'fill-red-500': global_result < 0}"
        viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg>
      {{global_result|currency:'EUR'}}
    </span>
  </div>

  <div *ngFor="let p of poDetail">

    <!-- HEADER -->
    <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div class="ml-4 mt-4">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
              </svg>
            </div>
            <div class="ml-4">
              <h3 class="text-base font-semibold leading-6 text-gray-900">{{p.product}}</h3>
              <p class="text-sm text-gray-500">
                <a href="#">Economic Report</a>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- RESUMEN INFO -->

    <ul role="list" class="divide-y divide-gray-100">

      <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8">
        <div class="flex min-w-0 gap-x-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
          </svg>

          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                Profit
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <p
              class="text-sm leading-6 font-bold"
              [ngClass]="{'text-green-600': p.profit > 0, 'text-red-600': p.profit < 0}"
              >{{p.profit|currency:'EUR'}}</p>
          </div>
        </div>
      </li>

      <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8">
        <div class="flex min-w-0 gap-x-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
          </svg>

          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                Shared with
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <p class="text-sm leading-6 text-gray-900">
              <span
                class="ms-2 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10" *ngFor="let partner of p.shared">{{partner}}</span>

            </p>
          </div>
        </div>
      </li>

      <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8">
        <div class="flex min-w-0 gap-x-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
          </svg>

          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
                <span class="absolute inset-x-0 -top-px bottom-0"></span>
                Profit per partner
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <p
            class="text-sm leading-6 font-bold"
            [ngClass]="{'text-green-600': p.profit_shared > 0, 'text-red-600': p.profit_shared < 0}"
            >{{p.profit_shared|currency:'EUR'}}</p>
          </div>
        </div>
      </li>






    </ul>


    <!-- BUY DETAL -->

    <div class="px-4 sm:px-6 lg:px-8">
      <h3 class="!text-base !font-semibold leading-6 text-green-900">Purchase Detail</h3>
      <div class="-mx-4 flow-root sm:mx-0">
        <table class="min-w-full">
          <colgroup>
            <col class="w-full">
            <col>
          </colgroup>
          <thead class="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Concept</th>
              <th scope="col" class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Buy Product</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{p.buy.total_buy|currency:'EUR'}}</td>
            </tr>

            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Logistic Cost</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{p.buy.buy_logistic_cost|currency:'EUR'}}</td>
            </tr>

            <tr class="border-b border-gray-200" *ngFor="let c of p.buy.buy_cost">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Cost: {{c.costType}}</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{c.total|currency:'EUR'}}</td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th scope="row" colspan="3"
                class="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0">Total
              </th>
              <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">Total</th>
              <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">{{p.buy.total|currency:'EUR'}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- SALE TABLE -->

    <div class="px-4 sm:px-6 lg:px-8">
      <h3 class="!text-base !font-semibold leading-6 text-green-900">Sale Detail</h3>
      <div class="-mx-4 flow-root sm:mx-0">
        <table class="min-w-full">
          <colgroup>
            <col class="w-full">
            <col>
          </colgroup>
          <thead class="border-b border-gray-300 text-gray-900">
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Concept</th>
              <th scope="col" class="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Sale Product</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{p.sale.total_sale|currency:'EUR'}}</td>
            </tr>

            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">{{p.sale.percent_comercial_discount}}% Comercial Discount</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{-p.sale.comercial_discount|currency:'EUR'}}</td>
            </tr>

            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">{{p.sale.percent_sale_commission}}% of Sale</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{-p.sale.sale_commission|currency:'EUR'}}</td>
            </tr>


            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">{{p.sale.jcv_percent}}% of JCV </div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{-p.sale.jcv_commission|currency:'EUR'}}</td>
            </tr>



            <tr class="border-b border-gray-200">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Logistic Cost</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{-p.sale.logistic_cost|currency:'EUR'}}
              </td>
            </tr>

            <tr class="border-b border-gray-200" *ngFor="let c of p.sale.product_cost">
              <td class="max-w-0 py-5 pl-4 pr-3 text-sm sm:pl-0">
                <div class="font-medium text-gray-900">Cost: {{c.costType}}</div>
              </td>
              <td class="py-5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">{{-c.total|currency:'EUR'}}</td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th scope="row" colspan="3"
                class="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0">Total
              </th>
              <th scope="row" class="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">Total</th>
              <td class="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                {{p.sale.total|currency:'EUR'}}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

  </div>

</div>

<ng-template #noDetail>
  <div class="flex flex-col justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
      data-slot="icon" class="w-36 h-36 mt-12">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
    </svg>
    <h4 class="!text-lg !m-0 !p-0 !font-bold">Select a PO to economic report</h4>
  </div>
</ng-template>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Suppliers, Supplier } from 'src/app/features/admin/models/suppliers';
import { SupplierService } from 'src/app/features/admin/services/supplier.service';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-input-supplier-multiple',
  templateUrl: './input-supplier-multiple.component.html',
  styleUrls: ['./input-supplier-multiple.component.css']
})
export class InputSupplierMultipleComponent implements OnInit {

  supplier = new FormControl('', Validators.required)

  suppliers: Suppliers[] = []
  suppliersFilter?: Supplier[]
  supplierSelected?: any

  @Input() supplierId?: number
  @Input() supplierName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private sps: SupplierService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplierId']?.currentValue){
      this.supplier.setValue(changes['supplierId']?.currentValue)
      this.supplierSelected = changes['supplierName']?.currentValue
    }

  }

  private _listener():void{
    this.supplier.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.sps.searchSuppliers(resp).subscribe({
            next: (r) => {
              this.suppliersFilter = r.results
            }
          })
        }
      }
    })
  }


  showSupplier = (valor: any): string => {
    this.suppliersFilter?.filter( (m) => {
      if(m.id === valor){
        this.supplierSelected = m.name
      }
    })
    return this.supplierSelected;
  }

  clean():void{
    this.supplier.reset()
    this.supplierSelected = null
    this.suppliersFilter = []
    this.onSearch.emit('')
  }

  changeSupplier = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)



}

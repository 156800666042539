import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProductSupplier } from 'src/app/features/admin/models/products-supplier';
import { SupplierService } from 'src/app/features/admin/services/supplier.service';

@Component({
  selector: 'app-input-supplier-by-product',
  templateUrl: './input-supplier-by-product.component.html',
  styleUrls: ['./input-supplier-by-product.component.css']
})
export class InputSupplierByProductComponent implements OnInit {

  supplier = new FormControl('', Validators.required)

  @Input() idProduct: number = 0
  @Input() supplierId?: number
  @Output() supplierChange = new EventEmitter<{id: string, isMe: boolean, commission: number, load_place?: {id: number, name: string}}>()

  suppliers:ProductSupplier[] = []

  constructor(
    private ss: SupplierService
  ) { }

  ngOnInit(): void {
    this._loadSupplier()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['idProduct']?.currentValue === 0) {
      this.supplier.setValue('')
      this.suppliers = []
    }

    if (changes['supplierId']?.currentValue) this.supplier.setValue(changes['supplierId']?.currentValue)
    if (changes['idProduct']?.currentValue) this._loadSupplier()


  }


  private _loadSupplier() {
    this.ss.searchSupplierByProduct(this.idProduct).subscribe( resp => this.suppliers = resp.results)
  }

  onSupplierChange() {

    const isMe = this.suppliers.find( s => s.supplier.id.toString() === this.supplier.value?.toString())?.supplier.isMe || false
    const commission = this.suppliers.find( s => s.supplier.id.toString() === this.supplier.value?.toString())?.supplier.commission || 0
    const load_place = this.suppliers.find( s => s.supplier.id.toString() === this.supplier.value?.toString())?.logistic_default || undefined

    this.supplierChange.emit({id: this.supplier.value || '', isMe, commission, load_place})
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-credit-frm',
  templateUrl: './credit-frm.component.html',
  styleUrls: ['./credit-frm.component.css']
})
export class CreditFrmComponent implements OnInit {

  public frm?: FormGroup;
  @Input() creditNote?:any
  @Output() sendData = new EventEmitter<any>()

  invoiceId?:    number;
  invoiceName?:  string;
  coinId?:       number;
  coinCode?:     string;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.creditNote) this._setCreditNote(this.creditNote!)
    this._listener()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      invoice_id: ['', Validators.required],
      currencyExchange: ['', Validators.required],
      coin_id: ['', Validators.required],
      iva: [0, Validators.required],
      base: [0, Validators.required],
      total: [0, Validators.required],
      notes: [''],
    })
  }

  private _setCreditNote(creditNote:any): void{

    this.frm!.patchValue({
        ...creditNote,
        invoice_id: creditNote.invoice?.id,
        coin_id: creditNote.coin?.id,
    })
    this.invoiceId = creditNote.invoice?.id
    this.invoiceName = creditNote.invoice?.invoiceNumber
    this.coinId =  creditNote.coin?.id
    this.coinCode = creditNote.coin?.code
  }

  private _listener(): void{
    this.base?.valueChanges.subscribe((base: number) => {
      const iva = this.iva?.value
      const total = base + (base * iva / 100)
      this.total?.setValue(total)
    })

    this.iva?.valueChanges.subscribe((iva: number) => {
      const base = this.base?.value
      const total = base + (base * iva / 100)
      this.total?.setValue(total)
    })

  }


  setCoin = (coin: {id: string, code: string}): void => {
    this.frm!.get('coin_id')!.setValue(coin.id)
    this.coinCode = coin.code
  }
  setInvoice = (invoice: string): void => this.frm!.get('invoice_id')!.setValue(invoice)

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
    }

    this.sendData.emit(data);
  }


  get total(): AbstractControl | null {
    return this.frm!.get('total')
  }

  get base(): AbstractControl | null {
    return this.frm!.get('base')
  }

  get iva(): AbstractControl | null {
    return this.frm!.get('iva')
  }

  get coin(): AbstractControl | null {
    return this.frm!.get('coin_id')
  }

  get currencyExchange(): AbstractControl | null {
    return this.frm!.get('currencyExchange')
  }

}

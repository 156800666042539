<mat-toolbar class="print:hidden bg-green-600">
  <button mat-icon-button (click)="isExpanded = !isExpanded">
    <mat-icon class="text-white">menu</mat-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container style="width: 100%;" autosize>
  <mat-sidenav #sidenav mode="side" [opened]="isExpanded" class="print:hidden">
    <app-menu></app-menu>
  </mat-sidenav>
  <mat-sidenav-content class="mx-auto max-w-full sm:p-4 lg:p-4">
    <div class="bg-white p-2 rounded-md">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

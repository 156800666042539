import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PoReport } from '../../models/report-po';

@Component({
  selector: 'app-po-detail-report',
  templateUrl: './po-detail-report.component.html',
  styleUrls: ['./po-detail-report.component.css']
})
export class PoDetailReportComponent implements OnInit {

  global_result: number = 0;

  @Input() poDetail?: PoReport[]
  @Output() onRefresh = new EventEmitter<{refresh:boolean, po:string}>()

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['poDetail'].currentValue) {
      this.global_result = this.poDetail!.reduce((a, b) => a + (b['profit'] || 0), 0)
    }
  }

}

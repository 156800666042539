import { Component, OnInit } from '@angular/core';
import { IntrastatSearch } from '../../models/intrastat-reports';
import { ReportsService } from '../../services/reports.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-intrastat-home',
  templateUrl: './intrastat-home.component.html',
  styleUrls: ['./intrastat-home.component.css']
})
export class IntrastatHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Intrastat'}
  ]

  public searched: IntrastatSearch = {}

  constructor(
    private rpts: ReportsService
  ) { }

  ngOnInit(): void {
  }

  search = (data: IntrastatSearch) => this.searched = data

  export(){
    this.rpts.exportIntrastat(this.searched).subscribe(res => {
      saveAs(res, 'intrastat.xlsx')
    })

  }
}

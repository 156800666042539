import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Size, Sizes } from '../../models/sizes';
import { SizesService } from '../../services/sizes.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-sizess-table',
  templateUrl: './sizess-table.component.html',
  styleUrls: ['./sizess-table.component.css']
})
export class SizessTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['size', 'actions'];
  sizes?: Sizes | null;
  data: Size[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched = { text: ''}


  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private ss: SizesService
  ) { }

  ngAfterViewInit() {
    this._getSizes()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['searched'].firstChange){
      this._getSizes()
    }
  }

  private _getSizes(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.ss.searchSizes(
            this.searched.text,
            this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.rt.navigateByUrl(`/dash/admin/sizes/edit/${id}`)

  deleteSize(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete size',
        message: 'Are you sure you want to delete this size?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.ss.deleteSize(id).subscribe(() => this._getSizes())
      }
    })
  }

}

<div class="p-4">
<h3> Select account and Payer to be invoice </h3>

<form [formGroup]="frm" (ngSubmit)="invoice()">

  <app-input-bank-account (accountChange)="selectAccount($event)"></app-input-bank-account>

  <app-input-coins (coinChange)="selectCoin($event)"></app-input-coins>

  <mat-form-field appearance="outline">
    <mat-label>Exchange</mat-label>
    <input matInput type="number" formControlName="currencyExchange">

  </mat-form-field>

<button mat-flat-button class="bg-green-700 text-white w-full" type="submit" [disabled]="frm.invalid"> Invoice </button>

</form>
</div>

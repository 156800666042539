<div class="xl:p-3">

  <h1> Traceability</h1>
  <div
    class="top-0 z-40 flex h-16 shrink-0 mt-6 items-center gap-x-6 border-b border-white/5 shadow-sm">

    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
      <form class="flex flex-1" action="#" method="GET">
        <label for="search-field" class="sr-only">Search</label>
        <div class="relative w-full">

          <mat-form-field appearance="outline" class="w-full">
            <mat-label> Search </mat-label>
            <input matInput [formControl]="search" placeholder="PO-xxx" (keyup.enter)="onSearch()">
            <mat-hint> Press enter to search</mat-hint>
          </mat-form-field>
        </div>
      </form>

    </div>
  </div>

  <main class="mt-8">
    <div *ngIf="listPO.length > 0" class="bg-white p-5 rounded-sm transition-all duration-300 ani">
      <div class="flex gap-4">

        <span
          *ngFor="let po of listPO"
          (click)="getDataTraceability(po.po)"
          class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-100 px-2 py-1 text-xs hover:cursor-pointer font-medium text-indigo-700"
        >
            <svg class="h-1.5 w-1.5 fill-indigo-500" viewBox="0 0 6 6" aria-hidden="true">
              <circle cx="3" cy="3" r="3" />
            </svg>
            {{po.po}}
        </span>

      </div>
      <div class="w-full mt-3 text-xs text-slate-800"> Select de PO to show traceability Information </div>
    </div>
    <app-trace-detail-po [trace]="selectedPo" [loading]="loading"></app-trace-detail-po>
  </main>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-question',
  templateUrl: './modal-question.component.html',
  styleUrls: ['./modal-question.component.css']
})
export class ModalQuestionComponent implements OnInit {

  constructor(
    public modal: MatDialogRef<ModalQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, cancel: string, accept: string}
  ) { }

  ngOnInit(): void {

  }

}

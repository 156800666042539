import { Component, OnInit } from '@angular/core';
import { CreditNoteService } from '../../services/credit-note.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-credit-edit',
  templateUrl: './credit-edit.component.html',
  styleUrls: ['./credit-edit.component.css']
})
export class CreditEditComponent implements OnInit {
  idCredit = this.ar.snapshot.params['id']
  creditNote?: any

  public breadcrumb = [
    {name: 'Credit Notes', url: '/dash/credit-notes'},
    {name: 'Update Credit Note'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ss: SnackbarsService,
    private cs: CreditNoteService,
  ) { }

  ngOnInit(): void {
    this._getCreditNote()
  }


  private _getCreditNote(): void {

    this.cs.getCreditNote(this.idCredit!).subscribe({
      next: (resp) => this.creditNote = resp,
      error: (err) => {
        this.ss.nuevaSnack('Error getting credit note','snack-error')
        this.rt.navigate(['/dash/credit-notes'])
      }
    })
  }


  updateCreditNote(creditNote: any): void {
    this.cs.updateCreditNote(this.idCredit, creditNote).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack('Credit Note updated', 'snack-success')
        this.creditNote = resp
      },
      error: (err) => this.ss.nuevaSnack('Error updating credit note', 'snack-error')
    })

  }

}

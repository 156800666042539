import { Component, OnInit } from '@angular/core';
import { Client } from '../../../models/clients-response';
import { ClientsService } from '../../../services/clients.service';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.css']
})
export class NewClientComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Clients', url: '/dash/admin/clients'},
    {name: 'New Client'},
  ]

  constructor(
    private rt: Router,
    private cs: ClientsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createClient(client: Client): void {
    this.cs.addClient(client).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Client ${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/clients/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating client','snack-error')
      }
    })

  }

}

<main>
  <header class="border-b border-white/5 p-4">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-4 px-4 flex flex-row flex-wrap justify-between gap-4 w-full items-start ">

    <h2 class="m-0 p-0 w-7/12">
      Orders to be Invoiced
      <small class="block font-light text-xs text-slate-500 w-full"> Manage orders to be invoiced .</small>
    </h2>

    <!-- Actions -->

  </div>

  <!-- Filter-->
  <div class="my-3">
    <app-order-invoice-filter (onSearch)="search($event)"></app-order-invoice-filter>
  </div>

  <!-- List -->
  <app-orders-table-invoice [searched]="searched"></app-orders-table-invoice>
</main>

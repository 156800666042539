import { Component, OnInit } from '@angular/core';
import { LogisticService } from '../../services/logistic.service';
import { LogisticOrderBase } from '../../models/logistic-list-baseres';
import { LogisticTracking } from '../../models/logistic-tracking';

@Component({
  selector: 'app-logistic-tracking',
  templateUrl: './logistic-tracking.component.html',
  styleUrls: ['./logistic-tracking.component.css']
})
export class LogisticTrackingComponent implements OnInit {

  public searched?: string;
  listOrder: LogisticOrderBase[] = [];
  logisticTrack?: LogisticTracking;
  selectedOrderId?: number;

  constructor(
    private ls: LogisticService
  ) { }

  ngOnInit(): void {
  }

  search = (txt: string) => {
    this.searched = txt
    this.ls.searchLogisticOrderBase({search: txt}).subscribe({
      next: (r) => {
        this.listOrder = r.results
      },
      error: (e) => console.error(e)
    })
  };

  loadDetails(id: number) {
    this.ls.logisticTrackDetail(id).subscribe({
      next: (r) => {
        this.logisticTrack = r
      },
      error: (e) => console.error(e)
    })
  }

  refresh(data: {refresh: boolean, id: number}){
    if (data.refresh) this.loadDetails(data.id)
  }

}

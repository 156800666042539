<div class="mx-auto flex w-full max-w-7xl items-start gap-x-8 py-4">
  <main class="flex-1">
    <!-- Main area -->
    <!-- <app-empty [title]="'No Order Selected'" [text]="'Select an order from the list to view its details.'"
      *ngIf="!selectedOrder; else orderSel"></app-empty> -->

      <!-- Add Product button -->

      <app-add-product-button [order]="selectedOrder" (refresh)="reload($event)"></app-add-product-button>

      <!-- Products -->

      <div class="mt-3" *ngIf="products.length === 0; else listadoProductos">
        <app-empty [title]="'No Products'" [text]="'There are no products in this order.'"></app-empty>
      </div>

      <ng-template #listadoProductos>
        <app-product-order-table app-product-order-table [products]="products" (reload)="reload($event)"
          [currencyExchange]="currencyExchangeSelected!" [typePreorder]="typePreorder!"></app-product-order-table>
      </ng-template>



  </main>

</div>

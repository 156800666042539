// Generated by https://quicktype.io

import { Intrastat } from "src/app/features/admin/models/intrastat";
import { Size } from "src/app/features/admin/models/sizes";
import { BaseCode } from "src/app/shared/models/base-code";
import { ClientBase, ClientMinimal } from "./clients-response";

export interface Products {
  count:       number;
  next?:       string;
  previous?:   string;
  results:     Product[];
}

export interface Product {
  id:             number;
  name:           string;
  category:       string;
  temperature:    number;
  icon?:          null;
  description:    string;
  variety:        string;
  kilosBox:       number;
  kilosNet:       number;
  boxPallet:      number;
  price:          number;
  intrastat?:     Intrastat;
  size:           Size;
  intrastat_id:   number;
  size_id:        number;
  ean13?:         string;
  origin?:        string;
  packaging?:     number;
  default_net:    number;
  default_gross:  number;
  partner:        number[];
  exception_price?: { sale_price: number, buy_price: number };
}

export interface ProductMinimal {
  id:             number;
  name:           string;
  size:           Size;
  category:       string;
  description:    string;
}



// Generated by https://quicktype.io

export interface ProductsTranslations {
  count:    number;
  next:     null;
  previous: null;
  results:  ProductTranslation[];
}

export interface ProductTranslation {
  id:          number;
  language:    BaseCode;
  name:        string;
  description: string;
  variety:     string;
  product:     number;
}


export interface ProductTranslateReq {
  language_id: number;
  name:        string;
  description: string;
  variety:     string;
  product:     number;
}




export interface PricesByClient {
  count:    number;
  next:     string | null;
  previous: string | null;
  results:  ClientPriceProduct[];
}

export interface ClientPriceProduct {
  id:         number;
  product:    ProductMinimal;
  client:     ClientBase;
  buy_price:  string;
  sale_price: string;
}


export interface ExceptionsPriceClient {
  count:    number;
  next:     null;
  previous: null;
  results:  ExceptionClientPrice[];
}

export interface ExceptionClientPrice {
  id:         number;
  product:    ProductMinimal;
  client:     ClientBase;
  buy_price:  string;
  sale_price: string;
}




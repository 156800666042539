import { Component, OnInit, ViewChild } from '@angular/core';
import { Cost, CostFrm } from '../../models/cost-response';
import { CostsService } from '../../services/costs.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CostsTableComponent } from '../../components/costs-table/costs-table.component';
import { CostFrmComponent } from '../../components/cost-frm/cost-frm.component';

@Component({
  selector: 'app-costs-home',
  templateUrl: './costs-home.component.html',
  styleUrls: ['./costs-home.component.css']
})
export class CostsHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'JCV Costs'},
  ]

  public searched: any = { search: '' }

  @ViewChild(CostsTableComponent, {static : true}) child?: CostsTableComponent;

  constructor(
    private bsheet: MatBottomSheet,
    private cs: CostsService
  ) { }

  ngOnInit(): void {
  }

  search = (data: any) => this.searched = data

  editCost(cost: Cost){
    this.bsheet.open(CostFrmComponent, { data: cost }).afterDismissed().subscribe((data: CostFrm) => {
      if (data) {
        this.cs.updateCost(cost.id, data).subscribe( () => this.child?.getCosts() )
      }
    })
  }

  createCost(){
    this.bsheet.open(CostFrmComponent).afterDismissed().subscribe((data: CostFrm) => {
      if (data) {
        data = {
          ...data,
        }
        this.cs.createCost(data).subscribe( () => this.child?.getCosts() )
      }
    })
  }

}

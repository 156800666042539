<div *ngIf="frm" class="px-3">

  <mat-accordion class="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="flex flex-row flex-wrap gap-0 gap-x-2 justify-start items-center">

          <mat-form-field appearance="outline" class="w-8/12">
            <mat-label> Search </mat-label>
            <input matInput type="text" formControlName="search">
          </mat-form-field>

          <div class="actions w-2/12 text-end pb-2">
            <button mat-raised-button class="bg-red-600 text-white" (click)="clean()">Clean</button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductOrderFrmComponent } from './components/product-order-frm/product-order-frm.component';
import { OrderCardComponent } from './components/order-card/order-card.component';
import { ProductOrderTableComponent } from './components/product-order-table/product-order-table.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddSupplierOrderComponent } from './components/add-supplier-order/add-supplier-order.component';
import { AddSupplierOrderFrmComponent } from './components/add-supplier-order-frm/add-supplier-order-frm.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddProductButtonComponent } from './components/add-product-button/add-product-button.component';
import { OrderHomeComponent } from './pages/order-home/order-home.component';
import { OrderActionsComponent } from './components/order-actions/order-actions.component';
import { OrderFilterComponent } from './components/order-filter/order-filter.component';
import { OrdersTableComponent } from './components/orders-table/orders-table.component';
import { OrderEditComponent } from './pages/order-edit/order-edit.component';
import { OrderFrmComponent } from './components/order-frm/order-frm.component';
import { OrderProductsComponent } from './components/order-products/order-products.component';
import { OrderLogisticComponent } from './components/order-logistic/order-logistic.component';
import { LogisticModule } from '../logistic/logistic.module';
import { ModalDataInvoiceComponent } from './components/modal-data-invoice/modal-data-invoice.component';
import { OrderCostsComponent } from './components/order-costs/order-costs.component';
import { OrderCostsTableComponent } from './components/order-costs-table/order-costs-table.component';
import { OrderCostsFrmComponent } from './components/order-costs-frm/order-costs-frm.component';



@NgModule({
  declarations: [
    ProductOrderFrmComponent,
    OrderCardComponent,
    ProductOrderTableComponent,
    AddSupplierOrderComponent,
    AddSupplierOrderFrmComponent,
    AddProductButtonComponent,
    OrderHomeComponent,
    OrderActionsComponent,
    OrderFilterComponent,
    OrdersTableComponent,
    OrderEditComponent,
    OrderFrmComponent,
    OrderProductsComponent,
    OrderLogisticComponent,
    ModalDataInvoiceComponent,
    OrderCostsComponent,
    OrderCostsTableComponent,
    OrderCostsFrmComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    LogisticModule,
  ],
  exports: [
    ProductOrderFrmComponent,
    OrderCardComponent,
    ProductOrderTableComponent,
    AddSupplierOrderComponent,
    AddProductButtonComponent,

  ]
})
export class OrderModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductOrderFrmComponent } from '../product-order-frm/product-order-frm.component';
import { ProductOrderFrm } from '../../models/product-order-frm';
import { Order } from '../../models/list-orders';
import { OrderService } from '../../services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-add-product-button',
  templateUrl: './add-product-button.component.html',
  styleUrls: ['./add-product-button.component.css']
})
export class AddProductButtonComponent implements OnInit {

  @Input() order?: Order
  @Input() typePreorder?: string
  @Output() refresh = new EventEmitter<boolean>();

  constructor(
    private dialog: MatDialog,
    private ors: OrderService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void { }


  private _createProduct(r:ProductOrderFrm) {
    this.ors.addProductOrder(r).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('Product added. Don´t forget to generate the logistical order', 'snack-success')
        this.refresh.emit(true)
      },
      error: (err) => {
        this.ss.nuevaSnack('Error to add product', 'snack-error')
      }
    })
  }



  openProductModal() {
    this.dialog.open(ProductOrderFrmComponent, {
      height: '97vh',
      data: {
        idProductPreorder: null,
        order: this.order,
        typePreorder: this.typePreorder
      }
    }).afterClosed().subscribe( (r:ProductOrderFrm) => {
      if (r) this._createProduct(r)
    });
  }


  deleteOrder() {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete Order',
        message: 'Are you sure you want to delete this order and its related information?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.ors.deleteOrder(this.order!.id).subscribe(() => this.refresh.emit(true))
      }
    })
  }

}

import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { SecondLine, SecondLines } from '../../models/second-lines';
import { SecondLineService } from '../../services/second-line.service';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { OrderFilter } from 'src/app/features/order/models/order-filter';
import { SelectionModel } from '@angular/cdk/collections';
import { BillingService } from 'src/app/features/billing/services/billing.service';
import { SelectBankModalComponent } from 'src/app/shared/components/select-bank-modal/select-bank-modal.component';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ModelInvoiceSecondlineComponent } from '../model-invoice-secondline/model-invoice-secondline.component';
import { ModalDuplicateOrderComponent } from '../modal-duplicate-order/modal-duplicate-order.component';

@Component({
  selector: 'app-second-line-table',
  templateUrl: './second-line-table.component.html',
  styleUrls: ['./second-line-table.component.css']
})
export class SecondLineTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['select', 'date', 'payer', 'client', 'supplier', 'load', 'myInvoice', 'total', 'commission', 'actions'];
  secondLines?: SecondLines | null;
  data: SecondLine[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  selection = new SelectionModel<SecondLine>(true, [])

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: OrderFilter = { search: ''}

  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private sls: SecondLineService,
    private ss: SnackbarsService,
    private bs: BillingService
  ) { }

  ngAfterViewInit() {
    this._getOrder()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrder()
    }
  }

  private _getOrder(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sls.searchSecondLine(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.secondLines!.results.length;
    return numSelected === numRows;
  }


  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


  edit = (id: number) => this.rt.navigateByUrl(`/dash/second-line/edit/${id}`)

  deleteSecondLine(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete Order',
        message: 'Are you sure you want to delete this order?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.sls.deleteSecondLine(id).subscribe(() => this._getOrder())
      }
    })
  }


  invoice(): void {
    this.dialog.open(ModelInvoiceSecondlineComponent).afterClosed().subscribe( (r) => {
      if (r) this._invoiceSelection(r)
    })
  }


  private _invoiceSelection(r:{bank: number, coin: string, currencyExchange: number}){
    const orders_invoice = this.selection.selected.map(s => s.id)
    this.bs.generateInvoiceSecondLine(orders_invoice, r).subscribe({
      next: (r) => {
        this.ss.nuevaSnack(`Invoices ${r.invoices.join(',')}  generated successfully`, 'snack-success')
        this._getOrder()
      },
      error: (e) => {
        console.log(e);
      }
    })

  }

  private _duplicateOrder(data:{orderDate: string, client: number}, id: number){
    this.sls.duplicateSecondLine(id, data).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('Second Liner Order duplicated successfully', 'snack-success')
        this._getOrder()
      },
      error: (e) => this.ss.nuevaSnack('Error duplicating Second Liner Order', 'snack-error')

    })
  }


  openModalDuplicate(order: SecondLine){
    this.dialog.open(ModalDuplicateOrderComponent, {
      width: '400px',
      data: {
        clientId: order.client.id,
        clientName: order.client.name
      }
    }).afterClosed().subscribe( (r: {orderDate: string, client: number}) => {
      if (r) this._duplicateOrder(r, order.id)
    })

  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './pages/products/products.component';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FilterProductsComponent } from './components/filter-products/filter-products.component';
import { TableProductsComponent } from './components/table-products/table-products.component';
import { IntrastatHomeComponent } from './pages/intrastat-home/intrastat-home.component';
import { IntrastatFilterComponent } from './components/intrastat-filter/intrastat-filter.component';
import { IntrastatGraphComponent } from './components/intrastat-graph/intrastat-graph.component';
import { NgChartsModule } from 'ng2-charts';
import { ReportsHomeComponent } from './pages/reports-home/reports-home.component';
import { BuySaleComponent } from './pages/buy-sale/buy-sale.component';
import { SearchPoFrmComponent } from './components/search-po-frm/search-po-frm.component';
import { PoListComponent } from './components/po-list/po-list.component';
import { PoDetailReportComponent } from './components/po-detail-report/po-detail-report.component';
import { ListProfitSalesComponent } from './pages/list-profit-sales/list-profit-sales.component';
import { TablePoProfitsComponent } from './components/table-po-profits/table-po-profits.component';
import { SaleProfitComponent } from './pages/sale-profit/sale-profit.component';
import { PoFilterProfitComponent } from './components/po-filter-profit/po-filter-profit.component';
import { ProfitByPoComponent } from './pages/profit-by-po/profit-by-po.component';
import { ResumenProfitPosPartnersComponent } from './components/resumen-profit-pos-partners/resumen-profit-pos-partners.component';
import { RouterModule } from '@angular/router';
import { PaymentsComponent } from './pages/payments/payments.component';
import { PaymentsTableComponent } from './component/payments-table/payments-table.component';
import { DetailPaymentComponent } from './pages/payments/detail-payment/detail-payment.component';



@NgModule({
  declarations: [
    ProductsComponent,
    FilterProductsComponent,
    TableProductsComponent,
    IntrastatHomeComponent,
    IntrastatFilterComponent,
    IntrastatGraphComponent,
    ReportsHomeComponent,
    BuySaleComponent,
    SearchPoFrmComponent,
    PoListComponent,
    PoDetailReportComponent,
    ListProfitSalesComponent,
    TablePoProfitsComponent,
    SaleProfitComponent,
    PoFilterProfitComponent,
    ProfitByPoComponent,
    ResumenProfitPosPartnersComponent,
    PaymentsComponent,
    PaymentsTableComponent,
    DetailPaymentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule,
    NgChartsModule,
  ]
})
export class ReportsModule { }

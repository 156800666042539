import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Client, ClientsResponse } from 'src/app/features/admin/models/clients-response';
import { ClientsService } from 'src/app/features/admin/services/clients.service';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-input-clients',
  templateUrl: './input-clients.component.html',
  styleUrls: ['./input-clients.component.css']
})
export class InputClientsComponent implements OnInit {

  client = new FormControl('', Validators.required)

  clients: ClientsResponse[] = []
  clientsFilter?: Client[]
  clientselected?: any

  @Input() clientId?: number
  @Input() clientName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private cs: ClientsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['clientId']?.currentValue){
      this.client.setValue(changes['clientId']?.currentValue)
      this.clientselected = changes['clientName']?.currentValue
    }

  }

  private _listener():void{
    this.client.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.cs.searchClients(resp).subscribe({
            next: (r) => {
              this.clientsFilter = r.results
            }
          })
        }
      }
    })
  }


  showClient = (valor: any): string => {
    this.clientsFilter?.filter( (m) => {
      if(m.id === valor){
        this.clientselected = m.name
      }
    })
    return this.clientselected;
  }

  clean():void{
    this.client.reset()
    this.clientselected = null
    this.clientsFilter = []
    this.onSearch.emit('')
  }

  changeClient = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)



}

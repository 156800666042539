<div class="mt-8 flex flex-row flex-wrap justify-end gap-4 w-full items-end">
  <button mat-raised-button (click)="openRule()" class="bg-green-700 text-white w-2/12">Add Client Prices</button>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="openRule(row.id)">{{row.name}}
          {{row.client.name}}</td>
      </ng-container>

      <ng-container matColumnDef="buy_price">
        <th mat-header-cell *matHeaderCellDef>Buy Price</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="openRule(row.id)">{{row.buy_price|currency:'EUR'}}</td>
      </ng-container>

      <ng-container matColumnDef="sale_price">
        <th mat-header-cell *matHeaderCellDef>Sale Price</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="openRule(row.id)">{{row.sale_price|currency:'EUR'}}</td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Price Rule" (click)="deletePrice(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Prices rules found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select product search results"></mat-paginator>
</div>

<div class="p-4">
  <div class="rounded-md bg-red-50 p-2 my-5" *ngIf="!data.hasFile">
    <div class="flex">
      <div class="flex-shrink-0 animate-bounce">

        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
        </svg>



      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-red-800 !m-0">This order doesn't have any pdf attached, so you can't send it. </p>
      </div>

    </div>
  </div>

    <form [formGroup]="frm!" (ngSubmit)="send()">

      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput type="text" formControlName="title" placeholder="Title">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>To:</mat-label>
        <mat-select formControlName="to" multiple #contactsSell>
          <mat-select-trigger>
            {{to?.value[0] || ''}}
            <span *ngIf="(to?.value.length || 0) > 1">
              (+{{(to?.value.length || 0) - 1}} {{to?.value.length === 2 ? 'other' : 'others'}})
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let contact of contacts" [value]="contact.email">{{contact.email}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width: 100%;" appearance="outline">
        <mat-label>CC:</mat-label>
        <input matInput type="text" formControlName="cc" placeholder="CC">
        <mat-hint> Separate by commas the different emails</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>CCO</mat-label>
        <input matInput type="text" formControlName="cco" placeholder="CCO">
      </mat-form-field>

      <div>
        <angular-editor formControlName="body" [config]="config"></angular-editor>
      </div>

      <div class="ta-end mt-3">
        <button mat-raised-button [disabled]="!data.hasFile" color="primary" *ngIf="!isSending; else sending">Send Emails</button>
        <ng-template #sending>
          <mat-spinner diameter="30" color="primary"></mat-spinner>
        </ng-template>
      </div>
    </form>
</div>

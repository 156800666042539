import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogisticService } from '../../services/logistic.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-modal-move-load',
  templateUrl: './modal-move-load.component.html',
  styleUrls: ['./modal-move-load.component.css']
})
export class ModalMoveLoadComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    load: [this.data.load, Validators.required],
    order: ['', Validators.required]
  })

  logistic_orders: any[] = []

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ModalMoveLoadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {load: number, order: number , preorder?: string},
    private ls: LogisticService,
    private ss: SnackbarsService,

  ) { }

  ngOnInit(): void {
  }


  changeOrder = (order: string) => this.frm.patchValue({order})


  send() {
    if (this.frm.invalid) return

    this.dialogRef.close(this.frm.value)
  }

}

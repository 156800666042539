import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { DateAdapter } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { FeaturesRoutingModule } from './features/features-routing.module';
import { AdminModule } from './features/admin/admin.module';
import { SharedModule } from './shared/shared.module';
import { CustomDateAdapter } from './custom-date-adapter';
import { CoreModule } from './core/core.module';
import { PreorderModule } from './features/preorder/preorder.module';
import { TraceabilityModule } from './features/traceability/traceability.module';
import { SecondLineModule } from './features/second-line/second-line.module';
import { DeliveryModule } from './features/delivery/delivery.module';
import { ReportsModule } from './features/reports/reports.module';

import { ApiInterceptor } from './shared/interceptor/api.interceptor';
import { AppComponent } from './app.component';
import { BillingModule } from './features/billing/billing.module';
import { HomeModule } from './features/home/home.module';
import { CreditNoteModule } from './features/credit-note/credit-note.module';
import { CostsModule } from './features/costs/costs.module';
import { LogisticModule } from './features/logistic/logistic.module';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AdminModule,
    HomeModule,
    CoreModule,
    DeliveryModule,
    FeaturesRoutingModule,
    PreorderModule,
    ReportsModule,
    SecondLineModule,
    SharedModule,
    TraceabilityModule,
    BillingModule,
    CreditNoteModule,
    CostsModule,
    LogisticModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

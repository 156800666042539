import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { PreOrders, Preorder } from '../../models/pre-order';
import { PreorderService } from '../../services/preorder.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { PreorderFilter } from '../../models/preorder-filter';
import { ModalNewDatesComponent } from '../modal-new-dates/modal-new-dates.component';
import { ModalGenerateSaleComponent } from '../modal-generate-sale/modal-generate-sale.component';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ModalResumenPedidosComponent } from '../modal-resumen-pedidos/modal-resumen-pedidos.component';
import * as moment from 'moment';

@Component({
  selector: 'app-preorders-table',
  templateUrl: './preorders-table.component.html',
  styleUrls: ['./preorders-table.component.css']
})
export class PreordersTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['id', 'status', 'name', 'type', 'client', 'departure', 'arrival', 'po', 'poClient', 'clientPaid', 'actions'];
  preorders?: PreOrders | null;
  data: Preorder[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: PreorderFilter = { search: ''}


  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private pos: PreorderService,
    private ss: SnackbarsService
  ) { }

  ngAfterViewInit() {
    this._getPreorder()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getPreorder()
    }
  }

  private _getPreorder(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.pos.searchPreorders(
            this.searched,
            this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number, type:string) => {
    if (type === 'BUY') {
      this.rt.navigateByUrl(`/dash/pre-orders/buy-edit/${id}`)
      return
    }

    this.rt.navigateByUrl(`/dash/pre-orders/edit/${id}`)
  }



  duplicatePreorder(id: number): void {
    this.dialog.open(ModalNewDatesComponent, {
      data: {
        preorder: id
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.pos.duplicatePreOrder(id, result).subscribe(() => this._getPreorder())
        this.ss.nuevaSnack('Preorder duplicated', 'snack-success')
      }
    })

  }

  generateSellOrder(id: number): void {
    this.dialog.open(ModalGenerateSaleComponent, {
      width: '400px',
      data: {
        preorder: id
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.pos.generateSalePreorder(id, result).subscribe(() => this._getPreorder())
        this.ss.nuevaSnack('Sale Preorder has been generated ', 'snack-success')
      }
    })

  }


  showResumenPreorder(id: number){
    this.dialog.open(ModalResumenPedidosComponent, {
      width: '90vw',
      height: '80vh',
      data: {
        preorder: id
      }
    })

  }

}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Edit Product
      <small class="block font-light text-xs text-slate-500 w-full"> Edit info to update a product.</small>
    </h2>


  </div>

  <!-- Form -->
  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 lg:px-8">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="General">
          <ng-template matTabContent>
            <app-frm-product [product]="product" *ngIf="product" (sendData)="updateProduct($event)"></app-frm-product>
          </ng-template>
        </mat-tab>

        <mat-tab label="Translations">
          <ng-template matTabContent>
            <app-product-translate-home *ngIf="product"></app-product-translate-home>
          </ng-template>
        </mat-tab>

        <mat-tab label="Clients Prices">
          <ng-template matTabContent>
            <app-exception-price-home *ngIf="product"></app-exception-price-home>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </div>
  </div>





</main>

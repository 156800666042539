<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Logistic Orders</mat-label>
    <input type="text" placeholder="Search by Logistic order code " matInput [formControl]="logisticOrder"
      [matAutocomplete]="autoLogisticOrder">
    <mat-autocomplete #autoLogisticOrder="matAutocomplete" [displayWith]="showLogisticOrder"
      (optionSelected)="changeLogisticOrder($event)">
      <mat-option *ngFor="let option of logisticOrdersFilter" [value]="option.id">
        {{option.code}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-5/12">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name" placeholder="GreenSourcing">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Number of persona</mat-label>
    <input matInput formControlName="numberPerson" placeholder="2" type="number">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>User</mat-label>
    <mat-select formControlName="user">
      <mat-option *ngFor="let u of users" [value]="u.id">{{u.first_name}}</mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-5/12">
    <mat-label>Debt</mat-label>
    <input matInput type="number" formControlName="debt" placeholder="-200.32">
  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(partner) ? 'Updated' :
      'Create'}}
      Partner </button>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { SecondLine } from '../../models/second-lines';
import { SecondLineService } from '../../services/second-line.service';
import { SecondLineFrm } from '../../models/second-line-frm';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-second-line-edit',
  templateUrl: './second-line-edit.component.html',
  styleUrls: ['./second-line-edit.component.css']
})
export class SecondLineEditComponent implements OnInit {

  idOrder = this.ar.snapshot.params['id']
  order?: SecondLine

  public breadcrumb = [
    {name: 'Second Lines', url: '/dash/second-line'},
    {name: 'Update Second Line'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private sls: SecondLineService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getOrder()
  }

  private _getOrder(): void {
    this.sls.getSecondLine(this.idOrder).subscribe({
      next: (resp) => {
        this.order = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting second line order','snack-error')
        this.rt.navigate(['/dash/second-line'])
      }
    })
  }

  changeIndex = (event: number) => {
    (event === 0) ? this._getOrder() : null
  }


  updateOrder(order: SecondLineFrm): void {
    this.sls.updateSecondLine(this.idOrder, order).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Second Line order updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating Second Line','snack-error')
      }
    })
  }

}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DownloadPlace, LogisticClients } from '../../models/logistic-clients';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { ClientsService } from '../../services/clients.service';
import { LogistiClientFrmComponent } from '../logisti-client-frm/logisti-client-frm.component';

@Component({
  selector: 'app-logistic-clients',
  templateUrl: './logistic-clients.component.html',
  styleUrls: ['./logistic-clients.component.css']
})
export class LogisticClientsComponent implements AfterViewInit {

  idClient = this.ar.snapshot.params['id']

  displayedColumns: string[] = ['name', 'city', 'country', 'default', 'actions'];
  logistics?: LogisticClients | null;
  data: DownloadPlace[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private ar: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private cs: ClientsService,
    private is: CustomIconsService,
  ) {
  }

  ngAfterViewInit() {
    this._getLogisticsPlaces()
  }


  private _getLogisticsPlaces(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cs.getDownloadsPlacesByClient(
            this.idClient
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  openLogisticFrm(id = null): void {
    const accountSheet = this._bottomSheet.open(LogistiClientFrmComponent, {
      disableClose: true,
      data: {
        id,
        client: this.idClient,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getLogisticsPlaces()
    }
    );

  }

  deleteLogistic(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete download place',
        message: 'Are you sure you want to delete this download place?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.cs.deleteLogistic(id).subscribe(() => this._getLogisticsPlaces())
      }
    })
  }

}


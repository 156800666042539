<div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">
  <button
    *ngIf="preorderBaseDetail?.typeOfPreOrder !== 'SELL'"
    mat-raised-button
    (click)="openProductModal()"
    class="bg-green-700 text-white w-2/12"
    [disabled]="disableAddProduct">
      Add New Product
  </button>

  <button
    mat-flat-button
    (click)="generateInitialOrders()"
    class="bg-orange-500 text-white w-2/12" [disabled]="disableGenerate">
      <mat-icon svgIcon="preorder"></mat-icon>
      {{textGenerateButton}}
  </button>
</div>


  <div class="w-full mt-4">
    <app-product-preorder-table (editProduct)="openProductModal($event)" [filter]="filter" [statusPreorder]="preorderBaseDetail?.status"></app-product-preorder-table>
  </div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-logistic-load-frm',
  templateUrl: './update-logistic-load-frm.component.html',
  styleUrls: ['./update-logistic-load-frm.component.css']
})
export class UpdateLogisticLoadFrmComponent implements OnInit {

  frm: FormGroup = new FormGroup({
    mixedGroup: new FormControl('', Validators.required)
  })

  constructor(
    private dialogRef: MatDialogRef<UpdateLogisticLoadFrmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {order: number}
    ) { }

  ngOnInit(): void {
  }


  send(){
    if(this.frm.invalid) return
    this.dialogRef.close(this.frm.value)
  }

  close = () => this.dialogRef.close()

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { PreorderHomeComponent } from './pages/preorder-home/preorder-home.component';
import { PreorderCreateComponent } from './pages/preorder-create/preorder-create.component';
import { PreorderEditComponent } from './pages/preorder-edit/preorder-edit.component';
import { PreordersTableComponent } from './components/preorders-table/preorders-table.component';
import { PreorderFrmComponent } from './components/preorder-frm/preorder-frm.component';
import { PreordersActionsComponent } from './components/preorders-actions/preorders-actions.component';
import { PreordersFilterComponent } from './components/preorders-filter/preorders-filter.component';
import { PreorderProductsListComponent } from './components/preorder-products-list/preorder-products-list.component';
import { PreorderProductFrmComponent } from './components/preorder-product-frm/preorder-product-frm.component';
import { ProductPreorderTableComponent } from './components/product-preorder-table/product-preorder-table.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { OrderModule } from '../order/order.module';
import { LogisticModule } from '../logistic/logistic.module';
import { ModalNewDatesComponent } from './components/modal-new-dates/modal-new-dates.component';
import { ModalGenerateSaleComponent } from './components/modal-generate-sale/modal-generate-sale.component';
import { ResumenOrdersComponent } from './components/resumen-orders/resumen-orders.component';
import { ModalResumenPedidosComponent } from './components/modal-resumen-pedidos/modal-resumen-pedidos.component';
import { BuyPreorderCreateComponent } from './pages/buy-preorder-create/buy-preorder-create.component';
import { PreorderBuyFrmComponent } from './componets/preorder-buy-frm/preorder-buy-frm.component';
import { BuyPreorderEditComponent } from './pages/buy-preorder-edit/buy-preorder-edit.component';



@NgModule({
  declarations: [
    PreorderHomeComponent,
    PreorderCreateComponent,
    PreorderEditComponent,
    PreordersTableComponent,
    PreorderFrmComponent,
    PreordersActionsComponent,
    PreordersFilterComponent,
    PreorderProductsListComponent,
    PreorderProductFrmComponent,
    ProductPreorderTableComponent,
    OrderListComponent,
    ModalNewDatesComponent,
    ModalGenerateSaleComponent,
    ResumenOrdersComponent,
    ModalResumenPedidosComponent,
    BuyPreorderCreateComponent,
    PreorderBuyFrmComponent,
    BuyPreorderEditComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    OrderModule,
    LogisticModule,
  ]
})
export class PreorderModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-secondline-items-table',
  templateUrl: './secondline-items-table.component.html',
  styleUrls: ['./secondline-items-table.component.css']
})
export class SecondlineItemsTableComponent implements OnInit {

  @Input('data') data?: {resumen: any[], coin: string, exchange: number}

  constructor() {}

  ngOnInit() {}


}


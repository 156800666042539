import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { Intrastat, Intrastats } from '../../models/intrastat';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { IntrastatService } from '../../services/intrastat.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-intrastats-table',
  templateUrl: './intrastats-table.component.html',
  styleUrls: ['./intrastats-table.component.css']
})
export class IntrastatsTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['code', 'description', 'actions'];
  intrastats?: Intrastats | null;
  data: Intrastat[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched = { text: ''}


  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private is: IntrastatService
  ) { }

  ngAfterViewInit() {
    this._getIntrastats()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['searched'].firstChange){
      this._getIntrastats()
    }
  }

  private _getIntrastats(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.is.searchInstrastat(
            this.searched.text,
            this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.rt.navigateByUrl(`/dash/admin/intrastat/edit/${id}`)

  deleteIntrastat(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete intrastat code',
        message: 'Are you sure you want to delete this intrastat code?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.is.deleteIntrastat(id).subscribe(() => this._getIntrastats())
      }
    })
  }

}

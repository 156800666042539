<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div *ngIf="isRateLimitReached">
      Products Preorder
    </div>
</div>

<table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="product" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>Product</th>
        <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer px-2">
          {{row.product.name}} {{row.product.description}} - {{row.product.size.size}} Cat.: {{row.product.category}}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer">
          {{row.supplier.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="pallet">
        <th mat-header-cell *matHeaderCellDef>Pallets</th>
        <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class=" w-32 hover:cursor-pointer">
          <span
            class="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            {{row.pallets}}
          </span>
          <span
            *ngIf="row.isMixed"
            class="mx-2 inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/20">Mixed</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="boxes">
        <th mat-header-cell *matHeaderCellDef>Boxes</th>
        <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer">
          <span
            class="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
            {{row.totalBoxes}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer">

          <div *ngIf="row.coin.code === 'EUR'; else totalPound" class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.total|changeToPound:row.preorder.currencyChange|currency:'GBP'"> </app-badget>
          </div>
          <ng-template #totalPound>
            <div class="flex gap-2 justify-start">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|changeToEuro:row.preorder.currencyChange|currency:'EUR'"> </app-badget>
              <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.total|currency:'GBP'"> </app-badget>
            </div>
          </ng-template>




        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Product" (click)="deleteProduct(row.id)" *ngIf="statusPreorder !== 'GENERATED'">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Products found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select Contacts search results"></mat-paginator>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <app-input-products (onSelect)="setProduct($event)" [productId]="productId" [productName]="productName"></app-input-products>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Certification</mat-label>
    <mat-select formControlName="certifications" multiple>
      <mat-option [value]="''"> No certificate </mat-option>
      <mat-option [value]="certificacion.id" *ngFor="let certificacion of certifications">
        {{ certificacion.certification.certification}} {{certificacion.observations}}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Product
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>
</form>

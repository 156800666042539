import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CreditNoteFilter } from '../models/credit-filter';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class CreditNoteService {

  constructor(private http: HttpClient) { }


  searchCreditNotes(search: CreditNoteFilter, page: number = 1): Observable<any>{
    const params = new HttpParams({
      fromObject: {
        'page': page.toString(),
        'page_size': 20,
        'search': search.search || '',
      }
    })

    return this.http.get<any>(`${baseUrl}/credit-note/search`, {params})
  }

  getCreditNote(id: number): Observable<any>{
    return this.http.get<any>(`${baseUrl}/credit-note/${id}`)
  }

  updateCreditNote(id: number, data: any): Observable<any>{
    return this.http.put<any>(`${baseUrl}/credit-note/${id}/`, data)
  }

  createCreditNote(data: any): Observable<any>{
    return this.http.post<any>(`${baseUrl}/credit-note/`, data)
  }

  deleteCreditNote(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/credit-note/${id}/`)
  }


  generatePdf(id: number): Observable<boolean>{
    return this.http.get<boolean>(`${baseUrl}/credit-note/pdf/${id}`)
  }

}




import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ListPoProfitRes, PoProfitRes } from '../../models/list-po-profit';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ReportsService } from '../../services/reports.service';
import { IntrastatSearch } from '../../models/intrastat-reports';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-po-profits',
  templateUrl: './table-po-profits.component.html',
  styleUrls: ['./table-po-profits.component.css']
})
export class TablePoProfitsComponent implements AfterViewInit {

  displayedColumns: string[] = ['icon', 'po', 'departureDate', 'client', 'profit', 'actions'];
  orders?: ListPoProfitRes | null;
  data: PoProfitRes[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: IntrastatSearch = {}
  @Output() results = new EventEmitter<ListPoProfitRes>()

  constructor(
    private rt: Router,
    private rs: ReportsService,
  ) { }

  ngAfterViewInit() {
    this._getOrders()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrders()
    }
  }

  private _getOrders(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.rs.listPoProfit(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }
          this.results.emit(data)
          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  showProfitPo(po: string): void{
    this.rt.navigate(['/dash/reports/detail-sale', po])
  }


}

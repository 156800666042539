import { environment } from "src/environments/environment";

const baseUrl = environment.baseUrl;

export class Usuario {

    constructor(
        private _name: string,
        private _token: string,
        private _is_adm: boolean,
        public img?: string,
    ){
    }

    set token(token: string){
        this._token = token
    }

    get token() {
      return this._token
    }

    get isAdm() {
      return this._is_adm || localStorage.getItem('is_adm');
    }

    get name() {
      return this._name || localStorage.getItem('jcv_n');
    }

    get imagenUrl(): string{
        // /uploads/usuario/asdads
        if ( !this.img ){
            return `${ baseUrl }/uploads/usuarios/noimage`;
        } else{
            return `${ baseUrl }/uploads/usuarios/${ this.img }`;
        }
    }

}

<ul role="list" class="grid grid-cols-1 gap-x-4 gap-y-8 xl:gap-x-4 bg-white mb-3">
  <li class="overflow-hidden group rounded-xl border border-gray-200 hover:cursor-pointer">

    <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
      <div class="flex justify-between gap-x-4 py-2">
        <dt class="text-gray-800">Total</dt>
        <dd class="flex items-start gap-x-2">
        <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="totals.total|currency:'EUR'"></app-badget>
        <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="totals.totalPound|currency:'GBP'"></app-badget>
        </dd>
      </div>
      <div class="flex justify-between gap-x-4 py-2">
        <dt class="text-gray-800">Commission</dt>
        <dd class="flex items-start gap-x-2">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="totals.commission|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="totals.commissionPound|currency:'GBP'"></app-badget>
        </dd>
      </div>
    </dl>
  </li>
</ul>

<ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 xl:gap-x-8 bg-white">
  <li class="overflow-hidden group rounded-xl border border-gray-200 hover:cursor-pointer" *ngFor="let order of orders" (click)="selectOrder(order)">
    <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 px-6 py-3 group-hover:bg-sky-100 transition-colors duration-300"
      [ngClass]="{'bg-sky-300 hover:bg-sky-300': orderActive === order.id}"
    >

      <div class="h-12 w-12 flex items-center justify-center rounded-lg bg-white object-cover ring-1 ring-gray-900/10">
        <app-logistic-icon *ngIf="order.hasLogistic; else noLogistic"></app-logistic-icon>
        <ng-template #noLogistic>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round"
          d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
      </ng-template>

      </div>


      <div class="text-sm font-medium leading-6 text-gray-900">{{order.departureDate|date:'dd/MM/yyyy'}}</div>

    </div>
    <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
      <div class="flex justify-between gap-x-4 py-2">
        <dt class="text-gray-500 flex text-xs leading-5">
          <span class="relative truncate">{{order.supplier.name}}</span>
        </dt>

      </div>
      <div class="flex justify-between gap-x-4 py-2">
        <dt class="text-gray-500">Total</dt>
        <dd class="flex items-start gap-x-2">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.total|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="order.total|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
        </dd>
      </div>
      <div class="flex justify-between gap-x-4 py-2">
        <dt class="text-gray-500">Commission</dt>
        <dd class="flex items-start gap-x-2">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.totalCommission|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="order.totalCommission|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
        </dd>
      </div>
    </dl>
  </li>
</ul>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <span class="w-full text-lg font-semibold text-slate-700">
    Detection
  </span>

  <mat-form-field appearance="outline" class="w-8/12">
    <mat-label>Detection</mat-label>
    <input matInput type="text" formControlName="detection">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-1/12">
    <mat-label>PO</mat-label>
    <input matInput type="text" formControlName="po">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Lot / Delivery Note</mat-label>
    <input matInput type="text" formControlName="lot">
  </mat-form-field>

    <mat-form-field appearance="outline" class="w-7/12">
      <mat-label>Type</mat-label>
      <mat-select formControlName="typeOfDectection">
        <mat-option value="SUPPLIER">Supplier</mat-option>
        <mat-option value="CLAIM">Claim</mat-option>
        <mat-option value="MALFUNCTION">Mal Function</mat-option>
        <mat-option value="CLIENT">Client</mat-option>
        <mat-option value="PRODUCT">Product</mat-option>
        <mat-option value="AUDIT">Audit</mat-option>
      </mat-select>
    </mat-form-field>


  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Detection Date</mat-label>
    <input matInput [matDatepicker]="detectionDate" formControlName="detectionDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="detectionDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #detectionDate></mat-datepicker>
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="detectionDescription" placeholder="It has happened...."></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-7/12">
    <mat-label>Responsible of Detection</mat-label>
    <input matInput type="text" formControlName="responsibleDetection">
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Deadline</mat-label>
    <input matInput type="text" formControlName="deadline">
  </mat-form-field>

  <span class="w-full text-lg font-semibold text-slate-700">
    Correction
  </span>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Description of correction</mat-label>
    <textarea matInput formControlName="correctionDescription" placeholder="The solution was...."></textarea>
  </mat-form-field>

    <mat-form-field appearance="outline" class="w-5/12">
      <mat-label>Responsible of correction</mat-label>
      <input matInput type="text" formControlName="responsibleCorrection">
    </mat-form-field>


  <div class="self-center bg-slate-100 p-4 w-2/12 rounded-sm -mt-3">
    <mat-checkbox formControlName="isChecked" >Checked</mat-checkbox>
  </div>

  <div class="self-center bg-slate-100 p-4 w-2/12 rounded-sm -mt-3">
    <mat-checkbox formControlName="isRevised" >Revised</mat-checkbox>
  </div>


  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Closing Date</mat-label>
    <input matInput [matDatepicker]="closingDate" formControlName="closingDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="closingDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #closingDate></mat-datepicker>
  </mat-form-field>

  <span class="w-full text-lg font-semibold text-slate-700">
    Corrective Action
  </span>

  <mat-form-field appearance="outline" class="w-5/12">
    <mat-label>Responsible of Corrective Action</mat-label>
    <input matInput type="text" formControlName="responsibleCorrectiveAction">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Deadline</mat-label>
    <input matInput type="text" formControlName="correctiveActionDeadline">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Solution</mat-label>
    <textarea matInput formControlName="solution" placeholder="The corrective actions was...."></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-5/12">
    <mat-label>Responsible of checking Corrective Action</mat-label>
    <input matInput type="text" formControlName="responsibleFinalCorrectiveAction">
  </mat-form-field>

  <div class="self-center bg-slate-100 p-4 w-2/12 rounded-sm -mt-3">
    <mat-checkbox formControlName="isEffective">Effective</mat-checkbox>
  </div>


  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Closing Date</mat-label>
    <input matInput [matDatepicker]="closingDateCorrectiveAction" formControlName="closingDateCorrectiveAction">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="closingDateCorrectiveAction"></mat-datepicker-toggle>
    <mat-datepicker touchUi #closingDateCorrectiveAction></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Observations</mat-label>
    <textarea matInput formControlName="observationEffectiveness" placeholder="The efficiency was...."></textarea>
  </mat-form-field>




  <div class="actions-frm text-end my-8 w-full">

    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white">
      {{ nonConformity ? 'Update' : 'Create' }} Non Conformity </button>
  </div>
</form>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Size, Sizes } from '../models/sizes';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class SizesService {

  constructor( private http: HttpClient) { }

  getAll(): Observable<Sizes> {
    return this.http.get<Sizes>(`${baseUrl}/sizes`);
  }

  getSize(id: number): Observable<Size>{
    return this.http.get<Size>(`${baseUrl}/sizes/${id}/`);
  }

  addSize(size: Size): Observable<Size>{
    return this.http.post<Size>(`${baseUrl}/sizes/`, size);
  }

  updateSize(id: number, sizes: Size): Observable<Size>{
    return this.http.put<Size>(`${baseUrl}/sizes/${id}/`, sizes);
  }

  deleteSize(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/sizes/${id}/`);
  }

  searchSizes(search?: string, page=1): Observable<Sizes>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<Sizes>(`${baseUrl}/search-sizes`, {params})
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Product } from '../../models/products';
import { PackagingService } from '../../services/packaging.service';
import { Packaging } from '../../models/packaging';

@Component({
  selector: 'app-frm-product',
  templateUrl: './frm-product.component.html',
  styleUrls: ['./frm-product.component.css']
})
export class FrmProductComponent implements OnInit {

  public frm?: FormGroup;

  @Input() product?:Product
  @Output() sendData = new EventEmitter<Product>()

  public intrastatId?: number;
  public intrastatName?: string;
  public sizeId?: number;
  public sizeName?: string;
  public temperatureId?: number;
  public packaging: Packaging[] = [];
  partners_selected: number[] = [];

  constructor(
    private fb: FormBuilder,
    private ps: PackagingService
  ) { }

  ngOnInit(): void {
    this._createForm();
    this._loadPackaging();
    if (this.product){
      this._setProduct(this.product)
    }
  }

  _loadPackaging(): void{
    this.ps.getPackagings().subscribe(data => {
      this.packaging = data.results;
    })
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      intrastat_id: ['', Validators.required],
      category: ['', Validators.required],
      size_id: ['', Validators.required],
      temperature: ['', Validators.required],
      icon: [''],
      description: [''],
      kilosBox: ['', Validators.required],
      kilosNet: [''],
      boxPallet: ['', Validators.required],
      price: ['', Validators.required],
      packaging: [''],
      origin: ['Spain', Validators.required],
      ean13: [''],
      variety: [''],
      default_net: [''],
      default_gross: [''],
      partner: ['']
    });
  }

  _setProduct(product: Product): void{
      this.frm!.patchValue({
        ...product,
        intrastat_id: product.intrastat?.id,
        size_id: product.size?.id
      })

      this.partners_selected = product.partner;
      this.intrastatId = product.intrastat?.id;
      this.intrastatName = product.intrastat?.code + ' ' + product.intrastat?.description;
      this.sizeId = product.size?.id;
      this.sizeName = product.size?.size;
      this.temperatureId = product.temperature;
  }

  setIntrastat = (intrastat: string): void => this.frm!.get('intrastat_id')!.setValue(intrastat)
  setSize = (size: string): void => this.frm!.get('size_id')!.setValue(size)
  setTemperature = (temperature: number): void => this.frm!.get('temperature')!.setValue(temperature)
  changePartner = (partners: number[]): void => this.frm!.get('partner')!.setValue(partners)

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

import { Component, OnInit } from '@angular/core';
import { NonConformity } from '../../models/non-conformities';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Router } from '@angular/router';
import { TraceabilityService } from '../../services/traceability.service';

@Component({
  selector: 'app-non-conformities-create',
  templateUrl: './non-conformities-create.component.html',
  styleUrls: ['./non-conformities-create.component.css']
})
export class NonConformitiesCreateComponent implements OnInit {
  public breadcrumb = [
    {name: 'Non Conformities', url: '/dash/traceability/non-conformities'},
    {name: 'New Non Conformitiy'},
  ]

  constructor(
    private ts: TraceabilityService,
    private ss: SnackbarsService,
    private rt: Router,
  ) { }

  ngOnInit(): void {
  }

  createNonConformity(data: NonConformity): void {
    this.ts.addNonConformity(data).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Non Conformity created successfully`,'snack-success')
        this.rt.navigate(['/dash/traceability/non-conformities/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating non conformity','snack-error')
      }
    })

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-duplicate-order',
  templateUrl: './modal-duplicate-order.component.html',
  styleUrls: ['./modal-duplicate-order.component.css']
})
export class ModalDuplicateOrderComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    orderDate: ['', Validators.required],
    client: [this.data.clientId, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ModalDuplicateOrderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {clientId: number, clientName: string }
  ) { }

  ngOnInit(): void {
  }

  changeClient = (c: any) => this.frm.controls['client'].setValue(c);

  send(){
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      orderDate: moment(this.frm!.value.orderDate).format('YYYY-MM-DD'),
    }

    this.dialogRef.close(data);
  }

}

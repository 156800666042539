import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-generate-sale',
  templateUrl: './modal-generate-sale.component.html',
  styleUrls: ['./modal-generate-sale.component.css']
})
export class ModalGenerateSaleComponent implements OnInit {

  frm?: FormGroup

  constructor(
    private dialogref: MatDialogRef<ModalGenerateSaleComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: {preorder: number}
  ) { }

  ngOnInit(): void {
    this._createForm()
  }


  private _createForm(){
    this.frm = this.fb.group({
      client: [],
      departureDate: ['', Validators.required],
      arrivalDate: ['', Validators.required],
    })
  }

  setArrivalDate = (event: any): void => {

    if (event.value){
      const arrival = moment(event.value).add(4, 'days')
      const arrivalDate = new Date( arrival.year(), arrival.month(), arrival.date())
      this.frm!.patchValue({ arrivalDate}, {emitEvent: false});
    }
  }

  setClient = (client: string): void => this.frm!.get('client')!.setValue(client)

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      departureDate: moment(this.frm!.value.departureDate).format('YYYY-MM-DD'),
      arrivalDate: moment(this.frm!.value.arrivalDate).format('YYYY-MM-DD'),
    }

    this.dialogref.close(data);
  }

}

<div class="transform overflow-hidden bg-white text-left p-4 transition-all" >


  <div *ngIf="!isLoading">
    <div *ngIf="!paid">
    <form [formGroup]="frm" (ngSubmit)="send()">
    <div class="sm:flex sm:items-start">
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Partners payment information </h3>
        <div class="mt-2">
          <app-input-bank-account (accountChange)="selectAccount($event)"></app-input-bank-account>

          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Partner</mat-label>
            <mat-select formControlName="partners" multiple>
              <mat-option *ngFor="let partner of partners_list" [value]="partner.id">{{partner.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="partnersNotes" placeholder="Notes"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

      <button type="button"
        class="mt-3 inline-flex mx-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        (click)="close()">Close</button>
      <button type="submit"
        [disabled]="frm.invalid || frm.pristine || frm.pending"
        class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
        >Paid partners</button>
    </div>

    </form>
    </div>
    <div *ngIf="paid">
      <div class=" flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <h3 class="text-lg font-bold text-green-800 leading-6" id="modal-title">Certification:  #{{paid.certification}} Payments to partners </h3>

      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">Attention needed</h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>These amounts include the partner's outstanding debts</p>
            </div>
          </div>
        </div>
      </div>

      <table class="mt-8 min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Certification</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Partner</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total Paid by Person</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr class="even:bg-gray-50" *ngFor="let p of paid.partners">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">#{{p.certification}}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{p.partner}}</td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <span
                [ngClass]="{'bg-green-50 text-green-700 ring-green-600/10': p.total > 0, 'bg-red-50 text-red-700 ring-red-600/10': p.total < 0}"
                class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ">
                {{p.total|currency:'EUR'}}
              </span>


            </td>
          </tr>

        </tbody>
      </table>

      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

        <button type="button"
          class="mt-3 inline-flex mx-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          (click)="close()">Close</button>
      </div>

      </div>
        </div>
        </div>
    </div>


  </div>

  <div *ngIf="isLoading">
    <div class="sm:flex sm:items-start">
      <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-center">
        <h3 class="text-lg font-bold text-green-800 leading-6" id="modal-title">Generating payment certification </h3>
        <div class="mt-2">
        <mat-spinner class="mx-auto"></mat-spinner>
        </div>
      </div>
    </div>
  </div>

</div>




import { Component, OnInit } from '@angular/core';
import { PoTrace } from 'src/app/features/traceability/models/search-po';
import { TraceabilityService } from 'src/app/features/traceability/services/traceability.service';

@Component({
  selector: 'app-profit-by-po',
  templateUrl: './profit-by-po.component.html',
  styleUrls: ['./profit-by-po.component.css']
})
export class ProfitByPoComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Profits Partners By Po'}
  ]

  public searched?: string;
  listPo: PoTrace[] = [];
  selectedPo?: string;
  selectedPos: string[] = [];

  constructor(
    private ts: TraceabilityService,
  ) { }

  ngOnInit(): void {
  }

  search = (txt: string) => {
    this.searched = txt
    this.ts.searchPo(this.searched).subscribe({
      next: (r) => {
        this.listPo = r.results
      }
    });
  };

  private _addPoToList(po: string){
    if (!this.selectedPos.includes(po)){
      this.selectedPos = [...this.selectedPos, po]
    }

  }

  addPoToReport = (po: string) => this._addPoToList(po)


  refresh = (data: string[]) => {
    this.selectedPos = data
  }



}

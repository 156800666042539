import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Supplier, Suppliers } from '../../models/suppliers';
import { SupplierService } from '../../services/supplier.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';

@Component({
  selector: 'app-supplier-table',
  templateUrl: './supplier-table.component.html',
  styleUrls: ['./supplier-table.component.css']
})
export class SupplierTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['name', 'vatNumber', 'commission'];
  suppliers?: Suppliers | null;
  data: Supplier[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched = { text: ''}


  constructor(
    private rt: Router,
    private sps: SupplierService
  ) { }

  ngAfterViewInit() {
    this._getSuppliers()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['searched'].firstChange){
      this._getSuppliers()
    }
  }

  private _getSuppliers(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sps.searchSuppliers(
            this.searched.text,
            this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.rt.navigateByUrl(`/dash/admin/suppliers/edit/${id}`)

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from 'src/app/features/order/services/order.service';
import { ResumenByIntrastat, ResumenOrder } from '../../models/resumen-order';

@Component({
  selector: 'app-modal-resumen-pedidos',
  templateUrl: './modal-resumen-pedidos.component.html',
  styleUrls: ['./modal-resumen-pedidos.component.css']
})
export class ModalResumenPedidosComponent implements OnInit {

  orders: ResumenOrder[] = []
  totalByFamily?: ResumenByIntrastat[]

  constructor(
    public dialogRef: MatDialogRef<ModalResumenPedidosComponent>,
    private os: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: {preorder: number}
  ) { }

  ngOnInit(): void {
    this._loadResumen()
  }

  _loadResumen(){
    this.os.getResumenOrderByPreoder(this.data.preorder).subscribe({
      next: (resp) => this.orders = resp.results,
      error: (err) => console.log(err)
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { TraceabilityService } from '../../services/traceability.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { NonConformity } from '../../models/non-conformities';

@Component({
  selector: 'app-non-conformities-edit',
  templateUrl: './non-conformities-edit.component.html',
  styleUrls: ['./non-conformities-edit.component.css']
})
export class NonConformitiesEditComponent implements OnInit {

  idNonConformity = this.ar.snapshot.params['id']
  nonConformity?: NonConformity

  public breadcrumb = [
    {name: 'Non Conformities', url: '/dash/traceability/non-conformities'},
    {name: 'Update Non Conformitiy'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ts: TraceabilityService,
    private ss: SnackbarsService,

  ) { }

  ngOnInit(): void {
    this._getNonConformity()
  }


  private _getNonConformity(): void {
    this.ts.getNonConformity(this.idNonConformity!).subscribe({
      next: (resp) => {
        this.nonConformity = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting Non Conformity','snack-error')
        this.rt.navigate(['/dash/traceability/non-conformities'])
      }
    })
  }


  updateNonConformity(data: NonConformity): void {
    this.ts.updateNonConformity(this.idNonConformity, data).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Non Conformity updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating Non Conformity','snack-error')
      }
    })
  }
}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Orders Product
      <small class="block font-light text-xs text-slate-500 w-full"> Fiter product order to report.</small>
    </h2>

    <!-- Actions -->


  </div>

  <!-- Filter-->
  <div class="my-6">
    <app-filter-products (onSearch)="search($event)"></app-filter-products>
  </div>
  <div class="text-end w-full">
    <button mat-raised-button class="bg-green-700 text-white" (click)="exportExcel()">Excel Export</button>

  </div>
  <!-- List -->
  <app-table-products [searched]="searched"></app-table-products>
</main>

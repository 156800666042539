import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { PaymentMinimal, Payments } from '../../models/payments';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-payments-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.css']
})
export class PaymentsTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['date', 'client', 'pos', 'total'];
  payments?: Payments | null;
  data: PaymentMinimal[] = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;


  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  @Input('searched') searched: any = { search: ''}


  constructor(
    private rt: Router,
    private rs: ReportsService
  ) { }

  ngAfterViewInit() {
    this._getPayments()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getPayments()
    }
  }

  private _getPayments(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.rs.getPaymentsList(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }



  edit = (id: number) => this.rt.navigateByUrl(`/dash/reports/payments/${id}`)

}

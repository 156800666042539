import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Group, User } from '../../models/users-response';
import { UsersService } from '../../services/users.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-frm',
  templateUrl: './user-frm.component.html',
  styleUrls: ['./user-frm.component.css']
})
export class UserFrmComponent implements OnInit {
  public frm?: FormGroup;

  groups: Group[] = []

  @Input() user?:any
  @Output() sendData = new EventEmitter<any>()

  constructor(
    private fb: FormBuilder,
    private rt: Router,
    private us: UsersService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm()
    this._listGroups()
    if (this.user){
      this._setUser(this.user)
    }
  }

  _listGroups(){
    this.us.getGroups().subscribe({
      next:(g) => this.groups = g.results,
      error: (e) => {
        this.ss.nuevaSnack('Error loading groups', 'snack-error')
        this.rt.navigateByUrl('/dash/admin/users')
      }
    })
  }

  _createForm(): void{
    this.frm = this.fb.group({
      username: ['', Validators.required],
      first_name: ['', Validators.required],
      group: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  _setUser(user: User): void{
      this.frm!.patchValue({
        ...user,
      })
      this.frm?.patchValue({group: user.groups_list[0].id})
      this.frm?.get('password')?.clearValidators()
  }

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = this.frm!.value

    if (data.password === '') delete data.password

    this.sendData.emit(data);
  }


}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LogisticOrderBase } from '../../models/logistic-list-baseres';

@Component({
  selector: 'app-logistic-list',
  templateUrl: './logistic-list.component.html',
  styleUrls: ['./logistic-list.component.css']
})
export class LogisticListComponent implements OnInit {

  @Input() listOrder: LogisticOrderBase[] = [];
  @Output() onSelect = new EventEmitter<number>();
  selectedId?: number;
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listOrder']) {
      this.listOrder = changes['listOrder'].currentValue;
    }
  }

  ngOnInit(): void {
  }

  select = (id: number) => {
    this.selectedId = id;
    this.onSelect.emit(id);
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-modal-send-file-logistic',
  templateUrl: './modal-send-file-logistic.component.html',
  styleUrls: ['./modal-send-file-logistic.component.css']
})
export class ModalSendFileLogisticComponent implements OnInit {

  frm?: FormGroup;

  isSending = false

  sent:boolean = false

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
    'undo',
    'redo',
    'strikeThrough',
    'subscript',
    'superscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertUnorderedList',
    'insertOrderedList',
    'heading',
    'fontName'
  ],
  [
    'fontSize',
    'textColor',
    'customClasses',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'insertHorizontalRule',
    'removeFormat',
    'toggleEditorMode'
  ]
    ],
    customClasses: []
  };

  constructor(
    public dialogRef: MatDialogRef<ModalSendFileLogisticComponent>,
    private fb: FormBuilder,
    private ss: SnackbarsService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data:{id:number, title:string, sendClient: boolean},
  ) { }

  ngOnInit(): void {
    this._createForm()
  }


  private _createForm(): void {
    this.frm  = this.fb.group({
      title: [this.data.title, Validators.required],
      cc: [''],
      cco: [''],
      body: ['Buenas, <p>Please see attached logistic order.</p>Gracias', Validators.required],
    })
  }


  send(){
    if(this.frm?.invalid) return;
    this.isSending = true
    this.sent = false

    this.ns.sendSingleLogistic(this.data.id, this.frm!.value).subscribe({
      next: (resp) => {
        this.isSending = false
        this.sent = true
      },
      error: (e) => {
        this.isSending = false
        this.ss.nuevaSnack('Error to send email', 'snack-error')
      }
    })

  }


}

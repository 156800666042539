<div class="flex">

<button mat-button (click)="showResumen()"
matTooltip="Show Resumen"
          matTooltipClass="bg-purple-700 text-xs"
  class="!h-12 !w-12 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-purple-600 object-cover ring-1 ring-purple-950/10 hover:bg-purple-400 transition-colors duration-500">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
    class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>

</button>

<button
  mat-button
  matTooltip="Generate Orders PDF"
          matTooltipClass="bg-red-700 text-xs"
  class="!h-12 !w-12 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-red-600 object-cover ring-1 ring-red-950/10 hover:bg-red-400 transition-colors duration-500"
  [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }"
  (click)="generateAllPdf()"
  [disabled]="isGeneratingPdf"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" *ngIf="!isGeneratingPdf"
    class="stroke-white w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
    </svg>
    <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
    <app-tick-alert [flag]="follow.hasPdfGenerated"></app-tick-alert>
</button>

<button
  mat-button
  matTooltip="Generate / Assign to Logistic Order"
  matTooltipClass="bg-sky-700 text-xs"
  (click)="openLogistic()"
  class="!h-12 !w-12 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-sky-600 object-cover ring-1 ring-sky-950/10 hover:bg-sky-400 transition-colors duration-500"
  >
  <app-logistic-icon color="stroke-white"></app-logistic-icon>
  <app-tick-alert [flag]="follow.hasLogistic"></app-tick-alert>
</button>

<button
  mat-button
  matTooltip="Put in Other Logistic Order"
  matTooltipClass="bg-stone-700 text-xs"
  (click)="moveOtherLogisticOrder()"
  class="!h-12 !w-12 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-stone-600 object-cover ring-1 ring-stone-950/10 hover:bg-stone-400 transition-colors duration-500"
  >
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
    class="w-6 h-6">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
  </svg>
  <app-tick-alert [flag]="follow.hasLogistic"></app-tick-alert>
</button>
<button
  mat-button
  matTooltip="Generate fixed cost"
  matTooltipClass="bg-orange-700 text-xs"
  (click)="generateFixedCosts()"
  *ngIf="typeOfPreorder === 'SELL'"
  class="!h-12 !w-12 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-orange-600 object-cover ring-1 ring-orange-950/10 hover:bg-orange-400 transition-colors duration-500"
  >
  <mat-icon svgIcon="banknotes" class="text-white"></mat-icon>

  <app-tick-alert [flag]="follow.hasFixedCost"></app-tick-alert>

</button>





</div>



<div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 rounded">
  <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
    <div class="ml-4 mt-2">
      <h3 class="text-base !font-semibold leading-6 text-gray-900">Products Order</h3>
    </div>
    <div class="ml-4 mt-2 flex-shrink-0">
      <button type="button"
        (click)="openProductModal()"
        class="relative inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
        Add Product</button>


      <button mat-icon-button aria-label="Delete order" (click)="deleteOrder()" class="!ms-4">
        <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
      </button>


    </div>
  </div>
</div>

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Intrastat</mat-label>
    <input type="text" placeholder="Search intrastat" matInput [formControl]="intrastat" [matAutocomplete]="autoIntrastat">
    <mat-autocomplete #autoIntrastat="matAutocomplete" [displayWith]="showIntrastat"
      (optionSelected)="changeIntrastat($event)">
      <mat-option *ngFor="let option of intrastatFilter" [value]="option.id">
        {{option.code}} {{option.description}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

<div class="transform overflow-hidden bg-white text-left p-4 transition-all">
    <form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col flex-wrap gap-4 mt-6">
  <div>
    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Create Blank Order Logistic</h3>
      <div class="mt-2">

        <mat-form-field appearance="outline">
          <mat-label>Logistic Supplier</mat-label>
          <mat-select formControlName="logisticSupplier">
            <mat-option [value]="supplier.id" *ngFor="let supplier of suppliers"> {{supplier.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(order) ? 'Updated' :
      'Create'}}
      Order </button>
  </div>
    </form>
</div>


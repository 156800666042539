<div class="p-3 flex flex-col bg-white">

  <div class="w-full flex justify-end items-center gap-4">
    <button mat-button
      class="!h-8 !w-6 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-sky-700 object-cover ring-1 ring-sky-950/10 hover:bg-sky-400 transition-colors duration-500"
      (click)="loadResumen()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
        class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    </button>

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-6 h-6 text-slate-600">
      <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
    </svg>


    <button mat-button type="button"
      class="!h-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-yellow-500 object-cover ring-1 ring-yellow-950/10 hover:bg-yellow-400 transition-colors duration-500 text-white"
      (click)="payByClient()">
      Pay By Client
    </button>

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-6 h-6 text-slate-600">
      <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
    </svg>


    <button mat-button type="button"
      class="!h-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-orange-700 object-cover ring-1 ring-orange-950/10 hover:bg-orange-400 transition-colors duration-500 text-white"
      (click)="payComercial()">
      Pay Comercial
    </button>

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-6 h-6 text-slate-600">
      <path stroke-linecap="round" stroke-linejoin="round" d="m5.25 4.5 7.5 7.5-7.5 7.5m6-15 7.5 7.5-7.5 7.5" />
    </svg>


    <button mat-button type="button"
      class="!h-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-green-700 object-cover ring-1 ring-green-950/10 hover:bg-green-400 transition-colors duration-500 text-white"
      (click)="payPartners()">
      Pay Partners
    </button>
  </div>

  <div class="flex w-12/12 flex-wrap justify-start gap-4 bg-gray-50 border-dashed p-4 rounded-md ">

    <span
      *ngFor="let p of listSelectedPos"
      class="inline-flex items-center gap-x-0.5 rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-600 ring-1 ring-inset ring-purple-500/10">
      {{p}}
      <button type="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-purple-500/20" (click)="removePo(p)">
        <span class="sr-only">Remove</span>
        <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-purple-600/50 group-hover:stroke-purple-600/75">
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span class="absolute -inset-1"></span>
      </button>
    </span>



    <span class="font-medium text-gray-600" *ngIf="listSelectedPos.length === 0"> No Pos selected, search and add to view partners' profit report</span>

  </div>





    <div class="p-4" >

      <div class="flex flex-col w-full justify-center items-center" *ngIf="isLoading; else dataLoad">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-16 h-16 animate-spin text-sky-600 duration-700">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>

        <span class="text-gray-500 font-bold text-lg">
          Calculating profit...
        </span>

      </div>


      <ng-template #dataLoad>

        <div *ngIf="economicInfo">
        <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
          <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div class="ml-4 mt-4">
              <h3 class="text-base font-semibold leading-6 text-gray-900">Total Juan Antonio</h3>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">

              <span
                [ngClass]="{'bg-green-50 text-green-700 ring-green-600/10': saleTotal > 0, 'bg-red-50 text-red-700 ring-red-600/10': saleTotal < 0}"
                class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ">
                {{saleTotal|currency:'EUR'}}
              </span>



            </div>
          </div>


        </div>

        <div class="rounded-md bg-yellow-50 p-4 mt-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">Attention needed</h3>
              <div class="mt-2 text-sm text-yellow-700">
                <p>These amounts <b>NOT</b> include the partner's outstanding debts</p>
              </div>
            </div>
          </div>
        </div>


        <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6" *ngFor="let pos of economicInfo">
        <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div class="ml-4 mt-4">
            <h3 class="text-base font-semibold leading-6 text-gray-900">{{pos.partner_name}}</h3>
            <p class="mt-1 text-sm text-gray-500">{{pos.client}}</p>
          </div>
          <div class="ml-4 mt-4 flex-shrink-0">

            <span
              [ngClass]="{'bg-green-50 text-green-700 ring-green-600/10': pos.total > 0, 'bg-red-50 text-red-700 ring-red-600/10': pos.total < 0}"
              class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ">
              {{pos.total|currency:'EUR'}}
            </span>

            <span
              class="ms-2 inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-gray-50 text-gray-700 ring-gray-600/10">
              By person
            </span>



          </div>
        </div>

        <!-- TABLE -->

          <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                      <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Product</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Profit</th>
                      </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                      <tr *ngFor="let p of pos.product">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{p.product}}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{p.profit|currency:'EUR'}}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        <!-- END TABLE -->
      </div>




      </div>
      </ng-template>
    </div>

</div>

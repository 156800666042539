<div class="mt-8 flex flex-row flex-wrap justify-end gap-4 w-full items-start">
  <button mat-flat-button (click)="createCost()" class="bg-green-700 text-white w-2/12">Add New Cost</button>
</div>


<div class="w-full mt-4">
  <app-order-costs-table [orderId]="orderId" (editCost)="editCost($event)"></app-order-costs-table>
</div>



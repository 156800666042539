import { Component, Inject, OnInit } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-frm-product-translate',
  templateUrl: './frm-product-translate.component.html',
  styleUrls: ['./frm-product-translate.component.css']
})
export class FrmProductTranslateComponent implements OnInit {

  public frm?: FormGroup;
  languageId?: number;


  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<FrmProductTranslateComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id: string, product: number},
    private ps: ProductsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.data.id) this._getTranlations()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      product: [this.data.product, Validators.required],
      name: ['', Validators.required],
      description: [''],
      variety: [''],
      language_id: [false],
    });
  }

  private _getTranlations(): void{
    this.ps.getTranslateProduct(this.data.id).subscribe({
      next: (data) => {
        this.frm!.patchValue({
          ...data,
          language_id: data.language.id,
        });

        this.languageId = data.language.id;
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting translation', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _create(): void{
    this.ps.addTranslateProduct(this.frm!.value).subscribe({
      next: ({name}) => {
        this.ss.nuevaSnack(`The translation ${name} has been created`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating contact', 'snack-error');
      }
    }
    );
  }

  private _update(): void{
    this.ps.updateTranslateProduct(this.data.id, this.frm!.value).subscribe({
      next: ({name}) => {
        this.ss.nuevaSnack(`The translation ${name} has been updated`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating contact', 'snack-error');
      }
    })
  }

  setLanguage = (id:string) => this.frm?.patchValue({language_id: id});


  send(): void{
    if (this.frm!.invalid) { return; }
    if (this.data.id){
      this._update()
    }else{
      this._create();
    }
  }


  close(){
    this.bsheet.dismiss();
  }


}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NonConformitiesHomeComponent } from './pages/non-conformities-home/non-conformities-home.component';
import { NonConformitiesCreateComponent } from './pages/non-conformities-create/non-conformities-create.component';
import { NonConformitiesEditComponent } from './pages/non-conformities-edit/non-conformities-edit.component';
import { NonConformitiesFrmComponent } from './components/non-conformities-frm/non-conformities-frm.component';
import { NonConformitiesTableComponent } from './components/non-conformities-table/non-conformities-table.component';
import { TraceSearchComponent } from './pages/trace-search/trace-search.component';
import { TraceDetailPoComponent } from './components/trace-detail-po/trace-detail-po.component';



@NgModule({
  declarations: [
    NonConformitiesHomeComponent,
    NonConformitiesCreateComponent,
    NonConformitiesEditComponent,
    NonConformitiesFrmComponent,
    NonConformitiesTableComponent,
    TraceSearchComponent,
    TraceDetailPoComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class TraceabilityModule { }

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Supplier</mat-label>
    <input type="text" placeholder="Search supplier" matInput [formControl]="supplier" [matAutocomplete]="autoSupplier">
    <mat-autocomplete #autoSupplier="matAutocomplete" [displayWith]="showSupplier"
      (optionSelected)="changeSupplier($event)">

<!--
      <mat-select-trigger>
        {{supplier.value?.[0] || ''}}
        <span *ngIf="(supplier.value?.length || 0) > 1" class="">
          (+{{(supplier.value?.length || 0) - 1}} {{supplier.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger> -->


      <mat-option *ngFor="let option of suppliersFilter" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

<!-- Compare this snippet from src/app/shared/components/input-suppliers/input-suppliers.component.ts: -->

// Generated by https://quicktype.io

import { BaseCode } from "src/app/shared/models/base-code";
import { SupplierMinimal } from "../../admin/models/suppliers";
import { Size } from "../../admin/models/sizes";
import { ClientMinimal } from "../../admin/models/clients-response";
import { LogisticSupplierMinimal } from "../../admin/models/logistic-suppliers";
import { NonConformity } from "./non-conformities";

export interface TraceDetail {
  count:    number;
  next:     null;
  previous: null;
  results:  Trace[];
}

export interface Trace {
  client:          Client;
  typeOfPreOrder:  string;
  po:              string;
  poClient:        string;
  departureDate:   string;
  arrivalDate:     string;
  orders_buy:      OrdersBuy[];
  preorder_sales:  OrdersBuy[];
  info_base_sale:  InfoBaseSale;
  logistic_buy:    LogisticBuy[];
  logistic_sale:   LogisticBuy[];
  non_conformities: NonConformity[]
}

export interface Client {
  id:          number;
  name:        string;
  coin:        BaseCode;
  payLogistic: boolean;
}


interface OrdersBuy {
  supplier:        SupplierMinimal;
  arrivalDate:     string;
  departureDate:   string;
  total:           string;
  myInvoice:       string;
  totalCommission: string;
  updated:         string;
  hasLogistic:     boolean;
  carrierInvoice:  null;
  supplierInvoice: null;
  products:        ProductElement[];
}

interface ProductElement {
  id:          number;
  product:     ProductProduct;
  coin:        BaseCode;
  netWeight:   string;
  grossWeight: string;
  lot:         string;
  pallets:     number;
  total:       string;
  totalBoxes:  number;
  certifications: Certifications[];
}

interface ProductProduct {
  id:          number;
  name:        string;
  size:        Size;
  category:    string;
  description: string;
}


interface InfoBaseSale {
  id:               number;
  po:               string;
  poClient:         string;
  departureDate:    Date;
  arrivalDate:      Date;
  client:           ClientMinimal;
  logistic:         LogisticBuy[];
}

interface Certifications {
  certification: Certification;
}

interface Certification {
  id:            number;
  certification: string;
}


export interface LogisticBuy {
  id:               number;
  logisticSupplier: LogisticSupplierMinimal;
  code:             string;
  totalPallets:     number;
  totalCost:        string;
  plate:            null;
  preorder:         number;
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CountriesResponse } from 'src/app/shared/models/countries-response';
import { environment } from 'src/environments/environment';
import { Certification, Certifications } from '../models/certifications';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class CertificationsService {

  constructor(
    private http: HttpClient
  ) { }


  searchCertifications(search?: string, page=1): Observable<Certifications> {
    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())

    return this.http.get<Certifications>(`${baseUrl}/search-certifications`, { params })
  }

  getCertification(id: number): Observable<Certification>{
    return this.http.get<Certification>(`${baseUrl}/certifications/${id}/`);
  }

  addCertification(certification: Certification): Observable<Certification>{
    return this.http.post<Certification>(`${baseUrl}/certifications/`, certification);
  }

  updateCertification(id: number, certification: Certification): Observable<Certification>{
    return this.http.put<Certification>(`${baseUrl}/certifications/${id}/`, certification);
  }

  deleteCertification(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/certifications/${id}/`);
  }


}

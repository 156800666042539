import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { CardComponent } from './components/card/card.component';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ModalQuestionComponent } from './components/modal-question/modal-question.component';
import { SimpleSearchComponent } from './components/simple-search/simple-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputCoinsComponent } from './components/input-coins/input-coins.component';
import { InputCountriesComponent } from './components/input-countries/input-countries.component';
import { InputSuppliersComponent } from './components/input-suppliers/input-suppliers.component';
import { InputLogisticSupplierComponent } from './components/input-logistic-supplier/input-logistic-supplier.component';
import { InputProductsComponent } from './components/input-products/input-products.component';
import { InputBankAccountComponent } from './components/input-bank-account/input-bank-account.component';
import { InputLanguageComponent } from './components/input-language/input-language.component';
import { InputIntrastatComponent } from './components/input-intrastat/input-intrastat.component';
import { InputSizeComponent } from './components/input-size/input-size.component';
import { InputTemperatureComponent } from './components/input-temperature/input-temperature.component';
import { InputClientsComponent } from './components/input-clients/input-clients.component';
import { InputSupplierByProductComponent } from './components/input-supplier-by-product/input-supplier-by-product.component';
import { EmptyComponent } from './components/empty/empty.component';
import { OrderCardListComponent } from './components/order-card-list/order-card-list.component';
import { BadgetComponent } from './components/ui/badget/badget.component';
import { ChangeToEuroPipe } from './pipes/change-to-euro.pipe';
import { ChangeToPoundPipe } from './pipes/change-to-pound.pipe';
import { LogisticIconComponent } from './components/ui/logistic-icon/logistic-icon.component';
import { EditIconComponent } from './components/ui/edit-icon/edit-icon.component';
import { InputProductBySupplierComponent } from './components/input-product-by-supplier/input-product-by-supplier.component';
import { OrdersActionsComponent } from './components/orders-actions/orders-actions.component';
import { ProductsLogisticModalComponent } from './components/products-logistic-modal/products-logistic-modal.component';
import { InputSupplierMultipleComponent } from './components/input-supplier-multiple/input-supplier-multiple.component';
import { EmailModalComponent } from './components/email-modal/email-modal.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EmailLogisticModalComponent } from './components/email-logistic-modal/email-logistic-modal.component';
import { SelectBankModalComponent } from './components/select-bank-modal/select-bank-modal.component';
import { InputInvoiceComponent } from './components/input-invoice/input-invoice.component';
import { InputLoadplacesComponent } from './components/input-loadplaces/input-loadplaces.component';
import { InputUnloadplacesComponent } from './components/input-unloadplaces/input-unloadplaces.component';
import { InputLogisticOrderComponent } from './components/input-logistic-order/input-logistic-order.component';
import { ModalLogisticOtherOrderComponent } from './components/modal-logistic-other-order/modal-logistic-other-order.component';
import { PathBeautyPipe } from './pipes/path-beauty.pipe';
import { InputSelectPartnerComponent } from './components/input-select-partner/input-select-partner.component';
import { TickAlertComponent } from './components/tick-alert/tick-alert.component';
import { LogisticOrderFrmComponent } from './components/logistic-order-frm/logistic-order-frm.component';
import { ModalPayPartnersComponent } from './components/modal-pay-partners/modal-pay-partners.component';
import { ModaPayComercialComponent } from './components/moda-pay-comercial/moda-pay-comercial.component';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';


@NgModule({
  declarations: [
    CardComponent,
    BreadcrumbComponent,
    ModalQuestionComponent,
    SimpleSearchComponent,
    InputCoinsComponent,
    InputCountriesComponent,
    InputSuppliersComponent,
    InputLogisticSupplierComponent,
    InputProductsComponent,
    InputBankAccountComponent,
    InputLanguageComponent,
    InputIntrastatComponent,
    InputSizeComponent,
    InputTemperatureComponent,
    InputClientsComponent,
    InputSupplierByProductComponent,
    EmptyComponent,
    OrderCardListComponent,
    BadgetComponent,
    ChangeToEuroPipe,
    ChangeToPoundPipe,
    LogisticIconComponent,
    EditIconComponent,
    InputProductBySupplierComponent,
    OrdersActionsComponent,
    ProductsLogisticModalComponent,
    InputSupplierMultipleComponent,
    EmailModalComponent,
    EmailLogisticModalComponent,
    SelectBankModalComponent,
    InputInvoiceComponent,
    InputLoadplacesComponent,
    InputUnloadplacesComponent,
    InputLogisticOrderComponent,
    ModalLogisticOtherOrderComponent,
    PathBeautyPipe,
    InputSelectPartnerComponent,
    TickAlertComponent,
    LogisticOrderFrmComponent,
    ModalPayPartnersComponent,
    ModaPayComercialComponent,
    ModalDeleteComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule

  ],
  exports: [
    CardComponent,
    BreadcrumbComponent,
    ModalQuestionComponent,
    SimpleSearchComponent,
    InputCoinsComponent,
    InputCountriesComponent,
    InputSuppliersComponent,
    InputLogisticSupplierComponent,
    InputProductsComponent,
    InputBankAccountComponent,
    InputLanguageComponent,
    InputIntrastatComponent,
    InputSizeComponent,
    InputTemperatureComponent,
    InputClientsComponent,
    InputSupplierByProductComponent,
    EmptyComponent,
    OrderCardListComponent,
    BadgetComponent,
    ChangeToEuroPipe,
    ChangeToPoundPipe,
    LogisticIconComponent,
    EditIconComponent,
    InputProductBySupplierComponent,
    OrdersActionsComponent,
    InputSupplierMultipleComponent,
    SelectBankModalComponent,
    InputInvoiceComponent,
    InputLoadplacesComponent,
    InputUnloadplacesComponent,
    PathBeautyPipe,
    InputSelectPartnerComponent,
    TickAlertComponent,
    InputLogisticOrderComponent,
    LogisticOrderFrmComponent,
    ModalPayPartnersComponent,
    ModaPayComercialComponent,
    ModalDeleteComponent,
  ]
})
export class SharedModule { }

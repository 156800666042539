import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Trace } from '../../models/trace-detail';
import { SharedInfoService } from 'src/app/shared/services/shared-info.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-trace-detail-po',
  templateUrl: './trace-detail-po.component.html',
  styleUrls: ['./trace-detail-po.component.css']
})
export class TraceDetailPoComponent implements OnInit {

  @Input() trace?: Trace
  @Input() loading? = false
  files:any[] = []
  constructor(
    private shs: SharedInfoService
  ) { }


  ngOnChanges(changes: SimpleChanges): void {

    if(changes['trace'] && changes['trace'].currentValue){
      this.files = this._files_non_conformities()
    }

    if (changes['loading'].currentValue) {
      this.loading = changes['loading'].currentValue
    }
  }


  ngOnInit(): void {
  }

  private _files_non_conformities() {
    if (this.trace!.non_conformities){
      return this.trace!.non_conformities.map( nc => nc.files).flat()
    }

    return []
  }

  download(path:string){

    this.shs.downloadFile(path).subscribe( (data:any) => saveAs(data, path.split('/').pop() || 'file'))

  }


}

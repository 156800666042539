import { Component, Inject, OnInit } from '@angular/core';
import { SupplierService } from '../../services/supplier.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { CertificationSupplier } from '../../models/certifications-supplier';

@Component({
  selector: 'app-supplier-products-frm',
  templateUrl: './supplier-products-frm.component.html',
  styleUrls: ['./supplier-products-frm.component.css']
})
export class SupplierProductsFrmComponent implements OnInit {

  public frm?: FormGroup;

  public certifications: CertificationSupplier[] = [];

  public productId?: number;
  public productName?: string;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<SupplierProductsFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id: number, supplier: number},
    private sps: SupplierService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    this._getSupplierCertifications();
    if (this.data.id) this._getProductBySupplier()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      supplier_id: [this.data.supplier, Validators.required],
      product_id: ['', Validators.required],
      certifications: [''],
    });
  }

  private _getSupplierCertifications(): void{
    this.sps.searchCertificationsSupplier({supplier: this.data.supplier}).subscribe({
      next: (data) => {
        this.certifications = data.results;
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting certifications', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _getProductBySupplier(): void{
    this.sps.getProductSupplier(this.data.id).subscribe({
      next: (data) => {
        this.productId = data.product.id
        this.productName = `${data.product.name} ${data.product.description} ${data.product.size.size} | Cat.: ${data.product.category}`
        this.frm!.patchValue({
          certifications: data.certifications.map((c) => c.id),
          supplier_id: data.supplier.id,
          product_id: data.product.id
        });
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting product supplier data', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _create(): void{
    this.sps.addProductSupplier(this.frm!.value).subscribe({
      next: ({product}) => {
        this.ss.nuevaSnack(`${product.name} has been created`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating product', 'snack-error');
      }
    }
    );
  }

  private _update(): void{
    this.sps.updateProductSupplier(this.data.id, this.frm!.value).subscribe({
      next: ({product}) => {
        this.ss.nuevaSnack(`The ${product.name} has been updated`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating product', 'snack-error');
      }
    })
  }

  setProduct = (product: string): void => this.frm!.get('product_id')!.setValue(product)

  send(): void{
    if (this.frm!.invalid) { return; }
    if (this.data.id){
      this._update()
    }else{
      this._create();
    }
  }


  close(){
    this.bsheet.dismiss();
  }


}

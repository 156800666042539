import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Groups, User, UserRequest, UsersResponse } from '../models/users-response';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor( private http: HttpClient ) { }


  searchUser(search?: string, page=1): Observable<UsersResponse>{
    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())

    return this.http.get<UsersResponse>(`${baseUrl}/users`, {params})
  }

  getGroups(): Observable<Groups>{
    return this.http.get<Groups>(`${baseUrl}/groups`);
  }

  getUser(id: number ): Observable<User>{
    return this.http.get<User>(`${baseUrl}/users/${id}`)
  }

  deleteUser(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/users/${id}`)
  }

  createUser(data: UserRequest): Observable<User>{
    return this.http.post<User>(`${baseUrl}/users/`, data)
  }

  updateUser(id: number, data: UserRequest): Observable<User>{
    return this.http.patch<User>(`${baseUrl}/users/${id}/`, data)
  }


}

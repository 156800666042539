<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <div class="w-full">
    <app-input-clients [clientId]="clientId" [clientName]="clientName"
      (onSelect)="setClient($event)"></app-input-clients>
  </div>


    <span class="w-full text-lg font-semibold text-sky-700">
      Buy Information
    </span>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Departure Date</mat-label>
    <input matInput [matDatepicker]="departureDate" formControlName="departureDate"
      (dateInput)="setArrivalDate($event, 'arrivalDate');">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #departureDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Arrival Date</mat-label>
    <input matInput [matDatepicker]="arrivalDate" formControlName="arrivalDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="arrivalDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #arrivalDate></mat-datepicker>
  </mat-form-field>


    <mat-form-field appearance="outline" class="w-2/12">
      <mat-label>Buy Palet Cost</mat-label>
      <input matInput formControlName="palletCost" type="number" placeholder="190">
    </mat-form-field>



  <span class="w-full text-lg font-semibold text-green-700">
    Sale Information
  </span>


  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Sale Departure Date</mat-label>
    <input matInput [matDatepicker]="sale_departureDate" formControlName="sale_departureDate" (dateInput)="setArrivalDate($event, 'sale_arrivalDate');">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="sale_departureDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #sale_departureDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Sale Arrival Date</mat-label>
    <input matInput [matDatepicker]="sale_arrivalDate" formControlName="sale_arrivalDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="sale_arrivalDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #sale_arrivalDate></mat-datepicker>
  </mat-form-field>

    <mat-form-field appearance="outline" class="w-2/12">
      <mat-label>Sale Palet Cost</mat-label>
      <input matInput formControlName="sale_palletCost" type="number" placeholder="190">
    </mat-form-field>


  <span class="w-full text-lg font-semibold mt-8 text-slate-700">
    Economic Information
  </span>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Payment Method</mat-label>
    <mat-select formControlName="paymentMethod">
      <mat-option value="TRANSFER">Bank Transfer</mat-option>
      <mat-option value="CHECK">Check</mat-option>
    </mat-select>
  </mat-form-field>




  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Currency Exchange</mat-label>
    <input matInput type="number" step="0.001" formControlName="currencyChange" placeholder="1.18">
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes" placeholder="Ex. This preorder has..."></textarea>
  </mat-form-field>



  <div class="flex justify-end gap-8 my-8 w-full">


    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white">
      Create Preorder </button>

  </div>
</form>

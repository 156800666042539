import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TraceabilityService } from '../../services/traceability.service';
import { PoTrace } from '../../models/search-po';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Trace, TraceDetail } from '../../models/trace-detail';

@Component({
  selector: 'app-trace-search',
  templateUrl: './trace-search.component.html',
  styleUrls: ['./trace-search.component.css']
})
export class TraceSearchComponent implements OnInit {


  search = new FormControl('');
  listPO: PoTrace[] = []
  selectedPo?: Trace
  loading = false

  constructor(
    private ss: SnackbarsService,
    private ts: TraceabilityService
  ) { }

  ngOnInit(): void {
  }

  onSearch() {
    if (this.search.value) {
      this.ts.searchPo(this.search.value).subscribe({
        next: (r) => this.listPO = r.results,
        error: (e) => this.ss.nuevaSnack('Error to load PO List', 'snack-error')
      })
    }
  }

  getDataTraceability(po: string) {
    this.loading = true
    this.ts.tracePo(po).subscribe({
      next: (r) => {
        this.loading = false
        this.selectedPo = r.results[0]
      },
      error: (e) => {
        this.loading = false
        this.ss.nuevaSnack('Error to load PO Traceability', 'snack-error')
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { SecondLineService } from '../../services/second-line.service';
import { SecondLineFrm } from '../../models/second-line-frm';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-second-line-create',
  templateUrl: './second-line-create.component.html',
  styleUrls: ['./second-line-create.component.css']
})
export class SecondLineCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Second Lines', url: '/dash/second-line'},
    {name: 'New Second Line'},
  ]

  constructor(
    private rt: Router,
    private sls: SecondLineService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createOrder(preorder: SecondLineFrm): void {
    this.sls.createSecondLine(preorder).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Second Line order created successfully`,'snack-success')
        this.rt.navigate(['/dash/second-line/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating preorder','snack-error')
      }
    })

  }

}

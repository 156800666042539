import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogisticSupplier } from 'src/app/features/admin/models/logistic-suppliers';
import { LogisticSupplierService } from 'src/app/features/admin/services/logistic-supplier.service';
import { ProductElement } from 'src/app/features/order/models/porducts_order_by_preorder_res';
import { OrderService } from 'src/app/features/order/services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

import * as lodash from 'lodash'
import { LogisticService } from '../../../features/logistic/services/logistic.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-products-logistic-modal',
  templateUrl: './products-logistic-modal.component.html',
  styleUrls: ['./products-logistic-modal.component.css']
})
export class ProductsLogisticModalComponent implements OnInit {

  suppliers: LogisticSupplier[] = []

  logisticForm: FormGroup = this.fb.group({
    loads: this.fb.array([])
  })

  constructor(
    private dialogref: MatDialogRef<ProductsLogisticModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {preorder: number},
    private os: OrderService,
    private ss: SnackbarsService,
    private sls: LogisticSupplierService,
    private ls: LogisticService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._loadProductsToLogisstic()
    this._loadSuppliers()


  }

  private _loadProductsToLogisstic(): void {
    this.os.getProductsOrderByPreoder(this.data.preorder).subscribe({
      next: (resp) => {
        const products:ProductElement[] = []

        resp.results.forEach((p) => {
          // products.push(...p.products)

          p.products.forEach( (product) => {

            this.loads.push(this.fb.group({
              product: [product.id],
              product_name: [product.product.name],
              pallets: [product.pallets],
              isMixed: [product.isMixed],
              supplier: [product.supplier.id],
              supplier_name: [product.supplier.name],
              mixedGroup: [],
              logisticSupplier: [''],
            }))

          })
        })
      },
      error: (e) => {
        this.ss.nuevaSnack('Error loading products', 'snack-error')
        this.dialogref.close()
      }
    })
  }

  private _loadSuppliers(): void {
    this.sls.searchLogisticSuppliers().subscribe({
      next: (resp) => this.suppliers = resp.results,
      error: (e) => {
        this.ss.nuevaSnack('Error loading suppliers', 'snack-error')
        this.dialogref.close()
      }
    })
  }


  private _createLogistic(supplier:string, data: any){

    if (data.length === 0) {
      return
    }

    this.ls.generateInitialLogistic(supplier, data, this.data.preorder!).subscribe({
      next: (resp) => {
        this.dialogref.close(resp)
        this.ss.nuevaSnack('Logistic orders created', 'snack-success')
      },
      error: (e) => {
        this.ss.nuevaSnack('Error creating logistic orders', 'snack-error')
      }
    })
  }


  updateAllLogisitic(e:MatSelectChange){
    this.loads.controls.forEach( (control) => {
      control.get('logisticSupplier')?.setValue(e.value)
    })

  }



  loadCharges(){
    if (this.logisticForm.invalid) return

    const loadsBySupplier = lodash.groupBy(this.logisticForm.value.loads, 'logisticSupplier');

    let noSupplier = false
    let noGroup = false

    Object.keys(loadsBySupplier).forEach( key => {

      if (key !== ''){
        const data = loadsBySupplier[key].map( (p: any) => {
          if (p.isMixed && p.mixedGroup === null) {
            noGroup = true
            return
          }
          return {
            product: p.product,
            logisticSupplier: key,
            mixedGroup: p.mixedGroup,
          }
        }).filter( (p: any) => p !== undefined)

        this._createLogistic(key, data)
      }else{
        noSupplier = true
      }

    })

    if (noSupplier) this.ss.nuevaSnack('There are products without supplier, check logistic again', 'snack-error')
    if (noGroup) this.ss.nuevaSnack('There are products without mixed group, check logistic again', 'snack-error')

  }


  get loads(): FormArray {
    return this.logisticForm.get('loads') as FormArray;
  }


}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Client, ClientsResponse } from '../models/clients-response';
import { ContactClient, ContactsClients } from '../models/contacts-clients';
import { DownloadPlace, LogisticClients } from '../models/logistic-clients';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpClient
  ) { }


  searchClients(search?: string, page=1): Observable<ClientsResponse>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<ClientsResponse>(`${baseUrl}/clients/search`, {params})
  }

  getClient(id: number): Observable<Client>{
    return this.http.get<Client>(`${baseUrl}/clients/${id}`)
  }

  addClient(data:Client): Observable<Client>{
    return this.http.post<Client>(`${baseUrl}/clients/`, data)
  }

  updateClient(id: number, data: Client): Observable<Client>{
    return this.http.put<Client>(`${baseUrl}/clients/${id}/`, data)
  }


  getContactsByClient(id: number): Observable<ContactsClients>{
    return this.http.get<ContactsClients>(`${baseUrl}/clients/${id}/contacts`)
  }

  getContact(id: number): Observable<ContactClient>{
    return this.http.get<ContactClient>(`${baseUrl}/clients-contacts/${id}`)
  }

  addContact(data:ContactClient): Observable<ContactClient>{
    return this.http.post<ContactClient>(`${baseUrl}/clients-contacts/`, data)
  }

  updateContact(id: number, data: ContactClient): Observable<ContactClient>{
    return this.http.put<ContactClient>(`${baseUrl}/clients-contacts/${id}/`, data)
  }

  deleteContact(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/clients-contacts/${id}/`)
  }

  getDownloadsPlacesByClient(id: number): Observable<LogisticClients>{
    return this.http.get<LogisticClients>(`${baseUrl}/clients/${id}/logistics`)
  }

  getLogistic(id: number): Observable<DownloadPlace>{
    return this.http.get<DownloadPlace>(`${baseUrl}/clients-logistics/${id}`)
  }

  addLogistic(data:DownloadPlace): Observable<DownloadPlace>{
    return this.http.post<DownloadPlace>(`${baseUrl}/clients-logistics/`, data)
  }

  updateLogistic(id: number, data: DownloadPlace): Observable<DownloadPlace>{
    return this.http.put<DownloadPlace>(`${baseUrl}/clients-logistics/${id}/`, data)
  }

  deleteLogistic(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/clients-logistics/${id}/`)
  }

  searchUnloadPlaces(search: {client?: number, text?: string }, pageSize: number = 30): Observable<LogisticClients>{


    const params = new HttpParams()
      .set('search', search.text || '')
      .set('client', (search.client) ? search.client!.toString() : '')
      .set('page_size', pageSize.toString())

    return this.http.get<LogisticClients>(`${baseUrl}/clients/unload-place`, {params})
  }



}

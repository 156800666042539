import { Component, Input, OnInit } from '@angular/core';
import { MenuAdmin } from 'src/app/features/admin/models/menu-admin';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input('item') item?: MenuAdmin

  constructor(
    public is: CustomIconsService
  ) { }

  ngOnInit(): void {
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ContactSupplier, ContactsSupplier } from '../../models/contacts-supplier';
import { SupplierService } from '../../services/supplier.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SupplierContactFrmComponent } from '../supplier-contact-frm/supplier-contact-frm.component';

@Component({
  selector: 'app-supplier-contacts',
  templateUrl: './supplier-contacts.component.html',
  styleUrls: ['./supplier-contacts.component.css']
})
export class SupplierContactsComponent implements AfterViewInit {

  idSupplier = this.ar.snapshot.params['id']

  displayedColumns: string[] = ['name', 'email', 'phone', 'toSend', 'isHidden', 'actions'];
  contacts?: ContactsSupplier | null;
  data: ContactSupplier[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private ar: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private sps: SupplierService,
    private is: CustomIconsService,
  ) {
  }

  ngAfterViewInit() {
    this._getContacts()
  }


  private _getContacts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sps.getContactsBySupplier(
            this.idSupplier
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  openContactFrm(id = null): void {
    const accountSheet = this._bottomSheet.open(SupplierContactFrmComponent, {
      disableClose: true,
      data: {
        id,
        supplier: this.idSupplier,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getContacts()
    }
    );

  }

  deleteContact(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete contact',
        message: 'Are you sure you want to delete this supplier contact?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.sps.deleteContact(id).subscribe(() => this._getContacts())
      }
    })
  }

}


export function roundToTwo(num: number) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
}


export const poundToEuro = (value:number, change: number ) => roundToTwo(value * change)
export const euroToPound = (value:number, change: number ) => roundToTwo(value / change)



import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../../../services/partner.service';
import { Partner } from '../../../models/partner';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.css']
})
export class CreatePartnerComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Partners', url: '/dash/admin/partners'},
    {name: 'New Partner'},
  ]

  constructor(
    private rt: Router,
    private pts: PartnerService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createPartner(size: Partner): void {
    this.pts.addPartner(size).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/partners'])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating partner','snack-error')
      }
    })
  }
}

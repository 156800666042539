import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DeliveryFilter } from '../../models/delivery-filter';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';

@Component({
  selector: 'app-delivery-filter',
  templateUrl: './delivery-filter.component.html',
  styleUrls: ['./delivery-filter.component.css']
})
export class DeliveryFilterComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    search: [''],
  })

  @Output() onSearch: EventEmitter<DeliveryFilter> = new EventEmitter<DeliveryFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }



  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

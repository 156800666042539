import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LogisticSupplier } from 'src/app/features/admin/models/logistic-suppliers';
import { LogisticSupplierService } from 'src/app/features/admin/services/logistic-supplier.service';
import { LogisticService } from 'src/app/features/logistic/services/logistic.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-logistic-order-frm',
  templateUrl: './logistic-order-frm.component.html',
  styleUrls: ['./logistic-order-frm.component.css']
})
export class LogisticOrderFrmComponent implements OnInit {
  public frm?: FormGroup;
  suppliers: LogisticSupplier[] = []

  @Input() order?:any

  constructor(
    private dialogRef: MatDialogRef<LogisticOrderFrmComponent>,
    private fb: FormBuilder,
    private rt: Router,
    private ls: LogisticService,
    private sls: LogisticSupplierService,
    private ss: SnackbarsService,
    @Inject(MAT_DIALOG_DATA) public data: {preorder:string}
  ) { }

  ngOnInit(): void {
    this._createForm()
    if (this.order){
      this._setLogisticOrder(this.order)
    }
    this._loadSuppliers()
  }


  _createForm(): void{
    this.frm = this.fb.group({
      code: [''],
      logisticSupplier: ['', Validators.required],
      preorder: [this.data.preorder, Validators.required]
    });
  }

  private _loadSuppliers(): void {
    this.sls.searchLogisticSuppliers().subscribe({
      next: (resp) => this.suppliers = resp.results,
      error: (e) => {
        this.ss.nuevaSnack('Error loading suppliers', 'snack-error')
        this.dialogRef.close()
      }
    })
  }

  private _setLogisticOrder(order: any): void{
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    const data = this.frm!.value
    this.dialogRef.close(data);
  }


}

<form [formGroup]="frm" (ngSubmit)="submit()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Type Costs</mat-label>
    <mat-select formControlName="costType_id">
      <mat-option [value]="t.id"
        *ngFor="let t of costsType">{{t.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Product</mat-label>
    <mat-select formControlName="product">
      <mat-option [value]="p.id"
        *ngFor="let p of products">{{p.product.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Cost Date</mat-label>
    <input matInput [matDatepicker]="startDate" formControlName="date">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #startDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Typed</mat-label>
    <mat-select formControlName="typed">
      <mat-option value="UNIT" >Units</mat-option>
      <mat-option value="PERCENT" >Percent</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Quantity</mat-label>
    <input matInput formControlName="quantity" type="number" placeholder="5">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Cost</mat-label>
    <input matInput formControlName="cost" type="number" placeholder="0,00">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Total</mat-label>
    <input matInput formControlName="total" type="number" placeholder="0,00">
  </mat-form-field>


  <mat-form-field appearance="outline">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" placeholder="Ex. This cost is..."></textarea>
  </mat-form-field>



  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data) ? 'Create' : 'Update'}}
    Order Cost
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductOrder } from '../../models/products-order';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { OrderService } from '../../services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { ProductOrderFrmComponent } from '../product-order-frm/product-order-frm.component';
import { ProductOrderFrm } from '../../models/product-order-frm';
import { ModalDeleteComponent } from 'src/app/shared/components/modal-delete/modal-delete.component';


@Component({
  selector: 'app-product-order-table',
  templateUrl: './product-order-table.component.html',
  styleUrls: ['./product-order-table.component.css']
})
export class ProductOrderTableComponent implements OnInit {

  @Input() products: ProductOrder[] = []
  @Input() currencyExchange: number = 1.18
  @Input() typePreorder: string = ''
  @Output() reload = new EventEmitter<boolean>()

  constructor(
    private dialog: MatDialog,
    private is: CustomIconsService,
    private os: OrderService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  editProductOrder(product: ProductOrder): void {

    this.dialog.open(ProductOrderFrmComponent, {
      height: '98vh',
      data: {
        idProductOrder: product.id,
        order: product.order,
        typePreorder: this.typePreorder,
      }
    }).afterClosed().subscribe( (r:ProductOrderFrm) => {
        if (!r) return

        this.os.updateProductOrder(product.id, r).subscribe({
          next: () => {
            this.ss.nuevaSnack('Product updated, Don´t forget to generate the logistical order!', 'snack-success')
            this.reload.emit(true)
          },
          error: () => this.ss.nuevaSnack('Error to update product', 'snack-error')
        })
    });

  }



  deleteProductOrder(id: number, name:string): void {
    const modal = this.dialog.open(ModalDeleteComponent, {
      data: {
        title: `Delete product ${name}`,
        message: 'Deleting the product will delete all associated data, logistics, costs, sales.',
        phrase: `delete-product-${id}`,
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.os.deleteProductOrder(id).subscribe({
          next: () => {
            this.ss.nuevaSnack('Product deleted. Don´t forget to generate the logistical order!', 'snack-success')
            this.reload.emit(true)
          },
          error: () => {
            this.ss.nuevaSnack('Error while delete product', 'snack-error')
          }
        })
      }
    })
  }


}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Pre-orders
      <small class="block font-light text-xs text-slate-500 w-full"> Manage preorders .</small>
    </h2>
    <!-- Actions -->
    <app-preorders-actions class="w-4/12"></app-preorders-actions>

  </div>

  <!-- Filter-->
  <div class="my-6">
    <app-preorders-filter (onSearch)="search($event)"></app-preorders-filter>
  </div>

  <!-- List -->
  <app-preorders-table [searched]="searched"></app-preorders-table>
</main>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pathBeauty'
})
export class PathBeautyPipe implements PipeTransform {

  transform(value: string, ): string {
    return value.replace(/_/g, ' > ').replace(/-/g, ' > ').replace(/\//g, ' > ').replace(/\\/g, ' > ')
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductsService } from '../../../services/products.service';
import { ActivatedRoute } from '@angular/router';
import { ClientPriceProduct, PricesByClient } from '../../../models/products';
import { catchError, map, merge, of, startWith, switchMap } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ExceptionPriceFrmComponent } from '../exception-price-frm/exception-price-frm.component';

@Component({
  selector: 'app-exception-price-table',
  templateUrl: './exception-price-table.component.html',
  styleUrls: ['./exception-price-table.component.css']
})
export class ExceptionPriceTableComponent implements AfterViewInit {

  idProduct = this.ar.snapshot.params['id'];

  displayedColumns: string[] = ['client', 'buy_price', 'sale_price', 'actions'];
  prices?: PricesByClient | null;
  data: ClientPriceProduct[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;

  constructor(
    private ar: ActivatedRoute,
    private dialog: MatDialog,
    private bs: MatBottomSheet,
    private ps: ProductsService,

  ) { }

  ngAfterViewInit() {
    this._getPricesList()
  }

  private _getPricesList(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.ps.listPricesByClient(this.idProduct).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  deletePrice(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete this price rule?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.ps.deleteRulePriceByClient(id).subscribe(() => this._getPricesList())
      }
    })
  }

  openRule(id = null): void{
    this.bs.open(ExceptionPriceFrmComponent, {
      disableClose: true,
      data: {
        id,
        idProduct: this.idProduct,
      }
    }).afterDismissed().subscribe((data) => {
      if (data) this._getPricesList()
    });
  }

}

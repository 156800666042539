<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Contact</mat-label>
    <input matInput formControlName="name" placeholder="Juan">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" type="email" placeholder="newcontact@email.com">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Phone</mat-label>
    <input matInput formControlName="phone" placeholder="000 000 000">
  </mat-form-field>


  <div class="p-2">
    <mat-checkbox formControlName="toSend">Notificate</mat-checkbox>
  </div>

  <div class="p-2">
    <mat-checkbox formControlName="isHidden">Hidden Email</mat-checkbox>
  </div>

  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Contact
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

<div class="">
  <div class="">
    <form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col gap-4 mt-6">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full">
      <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              class="w-6 h-6 text-blue-600">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
            </svg>

          </div>
          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Update Mixed Group</h3>
            <div class="mt-2">
              <mat-form-field appearance="outline" class="w-full">
                <mat-label>Mixed Group</mat-label>
                <mat-select formControlName="mixedGroup">
                  <mat-option></mat-option>
                    <mat-option [value]="1"> Group 1 </mat-option>
                    <mat-option [value]="2"> Group 2 </mat-option>
                    <mat-option [value]="3"> Group 3 </mat-option>
                    <mat-option [value]="4"> Group 4 </mat-option>
                    <mat-option [value]="5"> Group 5 </mat-option>
                    <mat-option [value]="6"> Group 6 </mat-option>
                    <mat-option [value]="7"> Group 7 </mat-option>
                    <mat-option [value]="8"> Group 8 </mat-option>
                    <mat-option [value]="9"> Group 9 </mat-option>
                    <mat-option [value]="10"> Group 10 </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-4">

      <button
          mat-button
          type="submit"
          class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 mx-3 sm:w-auto">Change</button>
        <button
          mat-button
          (click)="close()"
          type="button"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
      </div>
    </div>
    </form>
  </div>
</div>



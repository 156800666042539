<div class="p-4">
<div>
  <mat-form-field appearance="outline">
    <mat-label>Update all Logistic Supplier</mat-label>
    <mat-select (selectionChange)="updateAllLogisitic($event)">
      <mat-option [value]="supplier.id" *ngFor="let supplier of suppliers"> {{supplier.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<form [formGroup]="logisticForm" (ngSubmit)="loadCharges()" *ngIf="loads.length > 0; else emptyLoads">
  <h3 class="!text-base !font-bold text-green-900"> Products to load</h3>

  <div formArrayName="loads">
    <div class="my-4 w-full text-end">
      <button mat-button class="bg-sky-600 !text-white" type="submit" [disabled]="loads.length === 0">
        Generate Logistic Orders
      </button>
    </div>
    <div *ngFor="let load of loads.controls; let i = index" [formGroupName]="i" class="flex gap-4 pt-4 px-4" [ngClass]="{'bg-slate-100': i%2}">
      <div class="flex gap-2 w-10/12">
        <mat-form-field appearance="outline">
          <mat-label> Product</mat-label>
          <input matInput formControlName="product_name" placeholder="Product" [readonly]="true">
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-1/12">
          <mat-label> Pallets </mat-label>
          <input matInput formControlName="pallets" placeholder="3" [readonly]="true">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Supplier </mat-label>
          <input matInput formControlName="supplier_name" placeholder="Juan CampoVerde" [readonly]="true">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Logistic Supplier</mat-label>
          <mat-select formControlName="logisticSupplier">
            <mat-option [value]="supplier.id" *ngFor="let supplier of suppliers"> {{supplier.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-form-field appearance="outline" *ngIf="load.get('isMixed')?.value; else noGroup" class="w-2/12">
        <mat-label>Group</mat-label>
        <mat-select formControlName="mixedGroup">
          <mat-option></mat-option>
          <mat-option [value]="1"> Group 1 </mat-option>
          <mat-option [value]="2"> Group 2 </mat-option>
          <mat-option [value]="3"> Group 3 </mat-option>
          <mat-option [value]="4"> Group 4 </mat-option>
          <mat-option [value]="5"> Group 5 </mat-option>
          <mat-option [value]="6"> Group 6 </mat-option>
          <mat-option [value]="7"> Group 7 </mat-option>
          <mat-option [value]="8"> Group 8 </mat-option>
          <mat-option [value]="9"> Group 9 </mat-option>
          <mat-option [value]="10"> Group 10 </mat-option>
        </mat-select>
      </mat-form-field>


      <ng-template #noGroup class="w-2/12"></ng-template>

    </div>
  </div>
</form>

<ng-template #emptyLoads>
  <div
    class="relative block w-full rounded-lg border-2 mt-4 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
    <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 24 24"
      aria-hidden="true">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
    </svg>
    <span class="mt-2 block text-sm font-semibold text-gray-900">No products to add at logistic</span>
  </div>
</ng-template>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">


      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-12" (click)="edit(row.id)">

          <app-badget [class]="'bg-purple-50 text-purple-700 ring-purple-700/10'" text="Sale" *ngIf="row.preorder.typeOfPreOrder === 'SELL'"></app-badget>
          <app-badget [class]="'bg-green-50 text-green-700 ring-green-700/10'" text="Buy" *ngIf="row.preorder.typeOfPreOrder === 'BUY'"></app-badget>
          <app-badget [class]="'bg-blue-50 text-blue-700 ring-blue-700/10'" text="Commission" *ngIf="row.preorder.typeOfPreOrder === 'COMMISSION'"></app-badget>

        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer text-xs" (click)="edit(row.id)">
          #{{ row.id}}

        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer">
          <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{id: row.id}">
          <span [ngClass]="{
            'bg-slate-50 text-slate-700 ring-slate-700/10': row.status === 'DRAFT',
            'bg-sky-50 text-sky-700 ring-sky-700/10': row.status === 'LOGISTIC',
            'bg-orange-50 text-orange-700 ring-orange-700/10': row.status === 'SEND',
            'bg-red-50 text-red-700 ring-red-600/20': row.status === 'CUSTOMS',
            'bg-indigo-50 text-indigo-700 ring-indigo-600/20': row.status === 'SUPPLIER',
            'bg-blue-50 text-blue-700 ring-blue-600/20': row.status === 'TRANSPORT',
            'bg-purple-50 text-purple-700 ring-purple-600/20': row.status === 'INVOICED_SUPPLIER',
            'bg-teal-50 text-teal-700 ring-teal-600/20': row.status === 'INVOICED_LOGISTIC',
            'bg-green-50 text-green-700 ring-green-600/20': row.status === 'PAID',
            'bg-zinc-50 text-zinc-700 ring-zinc-600/20': row.status === 'CANCELED'
            }" class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset">
            {{row.status|titlecase}}
          </span>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2 w-40" (click)="edit(row.id)">{{row.client.name}}</td>
      </ng-container>

      <ng-container matColumnDef="po">
        <th mat-header-cell *matHeaderCellDef>PO</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2 w-20" (click)="edit(row.id)">{{row.preorder.po}}</td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" (click)="edit(row.id)">{{row.supplier.name}}</td>
      </ng-container>

      <ng-container matColumnDef="departure">
        <th mat-header-cell *matHeaderCellDef>Departure</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28 px-2" (click)="edit(row.id)">
          {{row.departureDate|date}}</td>
      </ng-container>

      <ng-container matColumnDef="commision">
        <th mat-header-cell *matHeaderCellDef>Commission</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <div class="flex flex-col gap-2 justify-between">
            <app-badget [class]="'bg-green-50 text-green-700 ring-green-600/20'" [text]="row.totalCommission|currency:'EUR'"></app-badget>
            <app-badget [class]="'bg-purple-50 text-purple-700 ring-purle-600/20'" [text]="row.totalCommission|changeToPound:row.currencyChange|currency:'GBP'"> </app-badget>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <div class="flex flex-col gap-2">
            <app-badget [class]="'bg-green-50 text-green-700 ring-green-600/20'" [text]="row.total|currency:'EUR'"></app-badget>
            <app-badget [class]="'bg-purple-50 text-purple-700 ring-purle-600/20'" [text]="row.total|changeToPound:row.currencyChange|currency:'GBP'"> </app-badget>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-24"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Send Email" (click)="sendEmail(row)">
            <mat-icon
              svgIcon="email"
              [ngClass]="{
                'text-sky-700': row.isNotified,
                'text-gray-300': !row.isNotified
              }"
            ></mat-icon>
          </button>

          <button mat-icon-button aria-label="Generate Delivery Note" (click)="generateDelivery(row.id)" *ngIf="row.preorder.typeOfPreOrder === 'SELL'">
            <mat-icon svgIcon="delivery" class="text-indigo-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Order found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="60"
    aria-label="Select pre-order search results"></mat-paginator>
</div>



<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-id="id">
    <button mat-menu-item (click)="changeStatusOrder(id, status.code)" *ngFor="let status of status_order">
      <span>{{status.name}}</span>
    </button>
  </ng-template>

</mat-menu>

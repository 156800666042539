import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvoiceFilter } from '../models/invoice-filter';
import { InvoiceRes, InvoicesList } from '../models/invoices-list';
import { InvoiceOtherFrm } from '../models/invoice-other-frm';
import { ItemInvoiceFrm } from '../models/item-invoice-frm';
import { InvoiceDetail } from '../models/invoice-detail';

interface InvoicesResp{
  invoices: string[]
}

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpClient
  ) { }

  searchInvoices(search: InvoiceFilter, page: number = 1): Observable<InvoicesList>{
    const params = new HttpParams({
      fromObject: {
        'page': page.toString(),
        'page_size': 20,
        'search': search.search || '',
        'payerClient': search.payerClient || '',
        'payerSupplier': search.payerSupplier || '',
        'date_after': search.date_after || '',
        'date_before': search.date_before || '',
        'payDate_after': search.payDate_after || '',
        'payDate_before': search.payDate_before || '',
        'bankAccount': search.bankAccount || '',
        'typeInvoice': search.typeInvoice || ['ORDER', 'SECONDLINE', 'OTHER', 'SALES'],
        'isPayed': search.isPayed || ['True', 'False']
      }
    })

    return this.http.get<InvoicesList>(`${baseUrl}/invoices/search`, {params})
  }

  generateInvoiceSecondLine(data: number[], info: {bank: number, coin: string, currencyExchange: number}): Observable<InvoicesResp>{
    return this.http.post<InvoicesResp>(`${baseUrl}/invoice/second-line`, {orders:data, ba: info.bank, coin: info.coin, currencyExchange: info.currencyExchange})
  }

  generateInvoiceOrders(data: number[], info: {bank: number, payer: string, coin: string, currencyExchange: number, invoice_type:string}): Observable<InvoicesResp>{
    return this.http.post<InvoicesResp>(`${baseUrl}/invoice/order`, {orders:data, ba: info.bank, payer: info.payer, coin: info.coin, currencyExchange: info.currencyExchange, invoice_type: info.invoice_type})
  }

  generateInvoiceOther(info: InvoiceRes): Observable<InvoiceRes>{
    return this.http.post<InvoiceRes>(`${baseUrl}/invoice/other`, info)
  }


  getInvoice(id: number): Observable<InvoiceRes>{
    return this.http.get<InvoiceRes>(`${baseUrl}/invoices/${id}`)
  }

  updateInvoice(id: number, data: InvoiceRes): Observable<InvoiceRes>{
    return this.http.put<InvoiceRes>(`${baseUrl}/invoices/${id}/`, data)
  }

  // Products Invoice

  getDetailInvoice(invoice: string): Observable<InvoiceDetail>{
    return this.http.get<InvoiceDetail>(`${baseUrl}/detail-invoice/${invoice}`, {params: {invoice}})
  }

  createItemInvoice(data: ItemInvoiceFrm): Observable<ItemInvoiceFrm>{
    return this.http.post<ItemInvoiceFrm>(`${baseUrl}/invoice-item/`, data)
  }

  updateItemInvoice(id: number, data: ItemInvoiceFrm): Observable<ItemInvoiceFrm>{
    return this.http.put<ItemInvoiceFrm>(`${baseUrl}/invoice-item/${id}/`, data)
  }

  deleteItemInvoice(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/invoice-item/${id}/`)
  }


  generatePdf(id: number): Observable<boolean>{
    return this.http.get<boolean>(`${baseUrl}/invoice/pdf/${id}`)
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarsService } from '../../services/snackbars.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SupplierService } from 'src/app/features/admin/services/supplier.service';
import { ClientsService } from 'src/app/features/admin/services/clients.service';
import { ContactClient } from 'src/app/features/admin/models/contacts-clients';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit {

  frm?: FormGroup;

  contacts: {email: string, toSend: boolean }[] = []
  toSend: {email: string, toSend: boolean }[] = []

  contactClient: ContactClient[] = []
  isSending = false

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
    'undo',
    'redo',
    'strikeThrough',
    'subscript',
    'superscript',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    'indent',
    'outdent',
    'insertUnorderedList',
    'insertOrderedList',
    'heading',
    'fontName'
  ],
  [
    'fontSize',
    'textColor',
    'customClasses',
    'link',
    'unlink',
    'insertImage',
    'insertVideo',
    'insertHorizontalRule',
    'removeFormat',
    'toggleEditorMode'
  ]
    ],
    customClasses: []
  };


  constructor(
    public dialogRef: MatDialogRef<EmailModalComponent>,
    private fb: FormBuilder,
    private ss: SnackbarsService,
    private sps: SupplierService,
    private cs: ClientsService,
    private ns: NotificationsService,
    @Inject(MAT_DIALOG_DATA) public data:{id:number, title: string, type_preorder:string, supplier?: number, client?: number, hasFile?: boolean},
  ) { }



  ngOnInit(): void {
    this._createForm();
    if (this.data.supplier)  this._getContactSupplier()
    if (this.data.client)  this._getContactClient()


  }

  private _getContactSupplier(): void {
    this.sps.getContactsBySupplier(this.data.supplier!).subscribe({
      next: (r) => {
        const toSend = r.results.filter( c =>  (c.toSend === true && c.isHidden === false))
        this.toSend.push(...toSend.map((c) => ({email: c.email, toSend: c.toSend})))

        const hiddenToSend = r.results.filter( c =>  (c.toSend === true && c.isHidden === true))
        this.contacts.push(...toSend.map((c) => ({email: c.email, toSend: c.toSend})))
        this.frm?.patchValue({cco: hiddenToSend.map((c) => c.email).join(',')})

        this.to?.setValue(this.toSend.map((c) => c.email))

      },
      error: (e) => this.ss.nuevaSnack('Error getting supplier contact', 'snack-error')
    })
  }

  private _getContactClient(): void {
    this.cs.getContactsByClient(this.data.client!).subscribe({
      next: (r) => {
        const toSend = r.results.filter( c =>  (c.toSend === true))
        this.toSend.push(...toSend.map((c) => ({email: c.email, toSend: c.toSend})))

        this.contacts.push(...r.results.map((c) => ({email: c.email, toSend: c.toSend})))

        this.to?.setValue(this.toSend.map((c) => c.email))
      },
      error: (e) => this.ss.nuevaSnack('Error getting supplier contact', 'snack-error')
    })
  }


  private _createForm(): void {

    this.frm  = this.fb.group({
      title: [this.data.title, Validators.required],
      to: ['', Validators.required],
      cc: [''],
      cco: [''],
      body: [`Buenas, <p>Please could you confirm the attached order?</p> <br /> ${(this.data.type_preorder === 'BUY') ? '<p><strong>Nota proveedores: Por favor anotar en los albaranes de salida y facturas los PO de compras, para poder procesarlas para pago</strong></p><br />' : ''}<br />Gracias`, Validators.required],
    })
  }


  send(){
    if(this.frm?.invalid) return;
    this.isSending = true
    this.ns.sendOrder(this.data.id, this.frm?.value).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('Email sent', 'snack-success')
        this.isSending = false
        this.dialogRef.close(true)
      },
      error: (e) => {
        this.ss.nuevaSnack('Error sending email', 'snack-error')
        this.isSending = false
      }
    })
  }

  get to() {
    return this.frm?.get('to')
  }
}

import { Component, OnInit } from '@angular/core';
import { User, UserRequest } from '../../../models/users-response';
import { UsersService } from '../../../services/users.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Size } from '../../../models/sizes';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  idUser = this.ar.snapshot.params['id']
  user?: User

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Users', url: '/dash/admin/users'},
    {name: 'Update User'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private us: UsersService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getUser(this.idUser)
  }

  private _getUser(userId: number): void {
    this.us.getUser(userId).subscribe({
      next: (resp) => {
        this.user = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting user','snack-error')
        this.rt.navigate(['/dash/admin/users'])
      }
    })
  }


  updateUser(user: UserRequest): void {
    this.us.updateUser(this.idUser, user).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.first_name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating user','snack-error')
      }
    })
  }

}

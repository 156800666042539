import { DownloadPlaceBase } from "../../admin/models/logistic-clients";
import { Product } from "../../admin/models/products";
import { SupplierLoadPlaceBase } from "../../admin/models/supplier-load-places";
import { euroToPound, poundToEuro, roundToTwo } from "../utils/utils";

interface InitialDataProductPreorder {
  palletCost:            number
  changeCoin:            number
  payLogistic:           string
  commisionPercentage:   number
  coin:                  number
  coinName:              string
  isMixed?:              boolean;
  isExtra?:              boolean;
  product?:              Product;
  productName?:          string;
  supplier?:             number;
  price?:                number;
  kgsBox?:               number;
  palletBox?:            number;
  pallets?:              number;
  totalBoxes?:           number;
  boxCost?:              number;
  payCommission?:        string;
  deliveryPrice?:        number;
  logisticCost?:         number;
  invoicedPrice?:        number;
  boxCommission?:        number;
  totalCommission?:      number;
  myInvoicedPrice?:      number;
  total?:                number;
  isMe?:                 boolean;
  sale_price?:           number;
  sale_palletCost?:      number;
  sale_payLogistic?:     string;
  partners?:             number[];
  netWeight?:            number;
  grossWeight?:          number;
}

export class ProductPreorder {

  product?:                 Product;
  productName?:             string;
  supplier?:                number;
  isMe?:                    boolean;
  supplierName?:            string;
  coin:                     number;
  coinName:                 string;
  loadPlace? :              SupplierLoadPlaceBase;
  unloadPlace?:             DownloadPlaceBase;
  isMixed:                  boolean;
  isExtra:                  boolean;
  payLogistic:              string;
  payCommission?:           string;
  price:                    number;
  kgsBox:                   number;
  palletBox:                number;
  palletCost:               number;
  pallets:                  number;
  totalBoxes:               number;
  boxCost:                  number;
  deliveryPrice:            number;
  logisticCost:             number;
  commisionPercentage:      number;
  invoicedPrice:            number;
  boxCommission:            number;
  totalCommission:          number;
  myInvoicedPrice:          number;
  total:                    number;
  changeCoin:               number;
  netWeight:                number;
  grossWeight:              number;

  constructor(initialData: InitialDataProductPreorder){
    this.palletCost = initialData.palletCost;
    this.changeCoin = initialData.changeCoin;
    this.payLogistic = initialData.payLogistic;
    this.coin = initialData.coin;
    this.isMe = initialData.isMe ?? false;
    this.payCommission = initialData.payCommission ?? 'CLIENT';
    this.coinName = initialData.coinName;
    this.commisionPercentage = initialData.commisionPercentage;
    this.isMixed = initialData.isMixed ?? false
    this.isExtra = initialData.isExtra ?? false
    this.price = initialData.price ?? 0
    this.kgsBox = initialData.kgsBox ?? 0
    this.palletBox = initialData.palletBox ?? 0
    this.pallets = initialData.pallets ?? 0
    this.totalBoxes = initialData.totalBoxes ?? 0
    this.boxCost = initialData.boxCost ?? 0
    this.deliveryPrice = initialData.deliveryPrice ?? 0
    this.logisticCost = initialData.logisticCost ?? 0
    this.invoicedPrice = initialData.invoicedPrice ?? 0
    this.boxCommission = initialData.boxCommission ?? 0
    this.totalCommission = initialData.totalCommission ?? 0
    this.myInvoicedPrice = initialData.myInvoicedPrice ?? 0
    this.total = initialData.total ?? 0
    this.netWeight = initialData.netWeight ?? 0
    this.grossWeight = initialData.grossWeight ?? 0
  }


  getBoxCost(){
    this.boxCost =  this.kgsBox * this.price;
  }

  getTotalBoxes(){
    this.totalBoxes = (this.isMixed) ? this.totalBoxes : this.palletBox * this.pallets;
  }

  getLogisticPaleCost(){
    return (this.coinName === 'EUR') ? this.palletCost : euroToPound(this.palletCost, this.changeCoin);
  }

  getLogisticCost(){

    // if (!this.isMe){
      this.logisticCost = (this.palletBox > 0) ? this.getLogisticPaleCost() / this.palletBox : 0
    // }else{
    //   this.logisticCost = (this.payLogistic === 'CLIENT') ? (this.palletBox > 0) ? this.getLogisticPaleCost() / this.palletBox : 0 : 0
    // }
  }

  getDeliveryPrice() {
    this.deliveryPrice = this.logisticCost + this.boxCost;

  }

  getPriceToBeInvoiced(){
    let price = 0;

    (this.payCommission === 'CLIENT') ? price = this.boxCost - (this.boxCost * this.commisionPercentage / 100) : price = this.boxCost;

    // if (!this.isMe && this.payLogistic === 'SUPPLIER'){
    if (this.payLogistic === 'SUPPLIER'){
      price += this.logisticCost;
    }

    this.invoicedPrice = price;

  }

  getTotalCommission(){
    if (this.payCommission === 'ZERO') this.totalCommission = 0


    this.totalCommission = (this.payLogistic === 'SUPPLIER')
      ? (this.boxCost - (this.invoicedPrice - this.logisticCost)) * this.totalBoxes
      : (this.boxCost - this.invoicedPrice) * this.totalBoxes;
  }

  getBoxCommission(){
    if (this.payCommission === 'ZERO') this.boxCommission = 0

    if (this.payLogistic === 'SUPPLIER'){
      this.boxCommission = (this.boxCost - (this.invoicedPrice - this.logisticCost))
    }else{
      this.boxCommission = (this.boxCost - this.invoicedPrice)
    }


  }

  getMyInvoicedPrice(){
    if (this.payCommission === 'ZERO') {
      this.myInvoicedPrice = 0
    } else {
      this.myInvoicedPrice = (this.boxCost - (this.boxCost - (this.boxCost * this.commisionPercentage / 100))) * this.totalBoxes;
    }
  }

  getNetWeight() {
    this.netWeight = (this.product?.default_net ?? 0) * this.pallets ?? 0
  }

  getGrossWeight() {
    this.grossWeight = (this.product?.default_gross ?? 0)  * this.pallets ?? 0
  }

  getTotal(){
    this.total =  this.totalBoxes * this.invoicedPrice;
  }

  setPrice(price: number){
    this.price = (this.coinName === 'EUR') ? poundToEuro(price, this.changeCoin) : euroToPound(price, this.changeCoin);
  }




  calculateProductPreorder(){
    this.getBoxCost();
    this.getTotalBoxes();
    this.getLogisticCost();
    this.getDeliveryPrice()
    this.getPriceToBeInvoiced();
    this.getTotalCommission();
    this.getBoxCommission();
    this.getMyInvoicedPrice();
    this.getNetWeight()
    this.getGrossWeight()
    this.getTotal();

    return {
      boxCost:            roundToTwo(this.boxCost),
      totalBoxes:         roundToTwo(this.totalBoxes),
      logisticCost:       roundToTwo(this.logisticCost),
      deliveryPrice:      roundToTwo(this.deliveryPrice),
      invoicedPrice:      roundToTwo(this.invoicedPrice),
      totalCommission:    roundToTwo(this.totalCommission),
      boxCommission:      roundToTwo(this.boxCommission),
      myInvoicedPrice:    roundToTwo(this.myInvoicedPrice),
      total:              roundToTwo(this.total),
      netWeight:          roundToTwo(this.netWeight),
      grossWeight:        roundToTwo(this.grossWeight),
    }

  }


  setBaseProductoInfo(product: Product){
    this.product = product;
    this.productName = product.name;
    // if (!this.price) this.price = (this.coinName === 'EUR') ? product.price : euroToPound(product.price, this.changeCoin);
    this.price = (this.coinName === 'EUR') ? product.price : euroToPound(product.price, this.changeCoin);

    this.kgsBox = product.kilosBox;
    this.palletBox = product.boxPallet;

    return {
      price:      this.price,
      kgsBox:     this.kgsBox,
      palletBox:  this.palletBox,
    }
  }










}

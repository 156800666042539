import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../models/clients-response';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-frm-client',
  templateUrl: './frm-client.component.html',
  styleUrls: ['./frm-client.component.css']
})
export class FrmClientComponent implements OnInit {

  public frm?: FormGroup;

  @Input() client?:Client
  @Output() sendData = new EventEmitter<Client>()

  public coinId?: number;
  public languageId?: number;
  public countryId?: number;
  public countryName?: string;
  public accountId?: number;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();

    if (this.client) this._setClient(this.client)
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      vatNumber: ['', Validators.required],
      companyCode: [''],
      eori: [''],
      coin_id: ['', Validators.required],
      language_id: ['', Validators.required],
      payLogistic: [false, Validators.required],
      dayDeparture: [3, Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: ['', Validators.required],
      country_id: ['', Validators.required],
      province: ['', Validators.required],
      billingAccount_id: ['', Validators.required],
      notes: [''],
      comercialDiscount: ['', Validators.required],
      saleComission: [''],
      hasPercentage: [false],
    });
  }

  _setClient(client: Client): void{
      this.frm!.patchValue({
        ...client,
        coin_id: client.coin.id,
        language_id: client.language.id,
        country_id: client.country.id,
        billingAccount_id: client.billingAccount.id,
      })

      this.coinId = client.coin.id
      this.languageId = client.language.id
      this.countryId = client.country.id
      this.countryName = client.country.name
      this.accountId = client.billingAccount.id
  }


  setLanguage = (language: string): void => this.frm!.get('language_id')!.setValue(language)
  setCoins = (coin: {id: string, code: string}): void => this.frm!.get('coin_id')!.setValue(coin.id)
  setCountry = (country: string): void => this.frm!.get('country_id')!.setValue(country)
  setBillingAccount = (billingAccount: string): void => this.frm!.get('billingAccount_id')!.setValue(billingAccount)

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Client } from '../../models/clients-response';
import { Intrastat } from '../../models/intrastat';

@Component({
  selector: 'app-frm-intrastat',
  templateUrl: './frm-intrastat.component.html',
  styleUrls: ['./frm-intrastat.component.css']
})
export class FrmIntrastatComponent implements OnInit {

  public frm?: FormGroup;

  @Input() intrastat?:Intrastat
  @Output() sendData = new EventEmitter<Intrastat>()

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.intrastat){
      this._setIntrastat(this.intrastat)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      code: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  _setIntrastat(intrastat: Intrastat): void{
      this.frm!.patchValue({
        ...intrastat,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

<div class="p-4">
<h4 class="text-base text-green-700 !font-bold"> Move Load </h4>
<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col gap-4 mt-6">

  <app-input-logistic-order (onSelect)="changeOrder($event)"></app-input-logistic-order>

  <div class="actions-frm text-end w-full">
    <button mat-raised-button type="submit" class="w-full bg-green-700 text-white"> Move Load </button>
  </div>

</form>
</div>

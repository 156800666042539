<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Product</mat-label>
    <input type="text" placeholder="Search product" matInput [formControl]="product" [matAutocomplete]="autoProduct">
    <mat-autocomplete #autoProduct="matAutocomplete" [displayWith]="showProduct"
      (optionSelected)="changeProduct($event)">
      <mat-option *ngFor="let option of productsFilter" [value]="option.id">
        {{option.name}} {{option.description}} {{option.size.size}} | Cat.: {{option.category}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SecondLineHomeComponent } from './pages/second-line-home/second-line-home.component';
import { SecondLineEditComponent } from './pages/second-line-edit/second-line-edit.component';
import { SecondLineCreateComponent } from './pages/second-line-create/second-line-create.component';
import { SecondLineFilterComponent } from './components/second-line-filter/second-line-filter.component';
import { SecondLineTableComponent } from './components/second-line-table/second-line-table.component';
import { SecondLineFrmComponent } from './components/second-line-frm/second-line-frm.component';
import { SecondLineProductComponent } from './components/second-line-product/second-line-product.component';
import { SecondLineProductsComponent } from './components/second-line-products/second-line-products.component';
import { SecondLineProductsTableComponent } from './components/second-line-products-table/second-line-products-table.component';
import { ModelInvoiceSecondlineComponent } from './components/model-invoice-secondline/model-invoice-secondline.component';
import { ModalDuplicateOrderComponent } from './components/modal-duplicate-order/modal-duplicate-order.component';



@NgModule({
  declarations: [
    SecondLineHomeComponent,
    SecondLineEditComponent,
    SecondLineCreateComponent,
    SecondLineFilterComponent,
    SecondLineTableComponent,
    SecondLineFrmComponent,
    SecondLineProductComponent,
    SecondLineProductsComponent,
    SecondLineProductsTableComponent,
    ModelInvoiceSecondlineComponent,
    ModalDuplicateOrderComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class SecondLineModule { }

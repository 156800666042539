import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderCost } from '../../models/order-costs';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CostTypeService } from 'src/app/features/admin/services/cost-type.service';
import { CostType } from 'src/app/features/admin/models/cost-types';
import * as moment from 'moment';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-costs-frm',
  templateUrl: './order-costs-frm.component.html',
  styleUrls: ['./order-costs-frm.component.css']
})
export class OrderCostsFrmComponent implements OnInit {

  costsType: CostType[] = [];
  products: any[] = [];

  frm?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bsheetRef: MatBottomSheetRef<OrderCostsFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: OrderCost,
    private cts: CostTypeService,
    private os: OrderService,
  ) { }

  ngOnInit(): void {
    this._getOrderCostType()
    this._getOrderProducts()
    this._formBuilder()
    if (this.data.id) {
      this.frm?.patchValue({
        ...this.data,
        costType_id: this.data.costType.id,
      })
    }
    this._listener()
  }

  private _getOrderCostType():void{
    this.cts.searchCostTypes().subscribe( resp => this.costsType = resp.results)
  }

  private _getOrderProducts():void {
    this.os.searchProductsOrder({order: this.data.order, limit: 100}).subscribe({
      next: (products) => {
        this.products = products.results
      },
      error: (e) => console.log(e)

    })
  }

  private _formBuilder(): void {
    this.frm = this.fb.group({
      costType_id: ['', Validators.required],
      cost: [0, Validators.required],
      quantity: ['', Validators.required],
      typed: ['', Validators.required],
      description: [''],
      total: [0, Validators.required],
      date: [moment().format('YYYY-MM-DD'), Validators.required],
      product: ['', Validators.required]
    })
  }

  private _listener(){
    this.cost.valueChanges.subscribe( () => {
      if (this.typed.value === 'UNIT') this.total.setValue((this.cost.value * this.quantity.value).toFixed(2))
      })

    this.quantity.valueChanges.subscribe( () => {
      if (this.typed.value === 'UNIT')  this.total.setValue((this.cost.value * this.quantity.value).toFixed(2))
    })

    this.typed.valueChanges.subscribe( () => {
      if (this.typed.value === 'PERCENT') {
        this.cost.setValue(0)
        this.total.setValue(0)
      }
    })
  }

  submit(): void {
    if (this.frm?.valid) {

      const sendData = {
        ...this.frm?.value,
        date: moment(this.frm?.value.date).format('YYYY-MM-DD')
      }

      this.bsheetRef.dismiss(sendData)
    }
  }

  close = () => this.bsheetRef.dismiss()



  get cost(): AbstractControl {
    return this.frm?.get('cost') as AbstractControl
  }

  get quantity(): AbstractControl {
    return this.frm?.get('quantity') as AbstractControl
  }

  get typed(): AbstractControl {
    return this.frm?.get('typed') as AbstractControl
  }

  get total(): AbstractControl {
    return this.frm?.get('total') as AbstractControl
  }

}

import { Component, OnInit } from '@angular/core';
import { SupplierService } from '../../../services/supplier.service';
import { Supplier } from '../../../models/suppliers';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-supplier-create',
  templateUrl: './supplier-create.component.html',
  styleUrls: ['./supplier-create.component.css']
})
export class SupplierCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Suppliers', url: '/dash/admin/suppliers'},
    {name: 'New Client'},
  ]

  constructor(
    private rt: Router,
    private sps: SupplierService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createSupplier(supplier: Supplier): void {
    this.sps.addSupplier(supplier).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Supplier ${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/suppliers/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating supplier','snack-error')
      }
    })

  }

}

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Created At</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-36">
          <app-badget class="bg-sky-50 text-sky-700 ring-sky-600/20" [text]="row.paidDate|date"></app-badget>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer">
          {{row.client}}
        </td>
      </ng-container>

      <ng-container matColumnDef="pos">
        <th mat-header-cell *matHeaderCellDef>Pos</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">
          {{row.pos ? row.pos[0] : ''}}
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-36">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|currency:'EUR'"></app-badget>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Order found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"  (click)="edit(row.id)"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="60"
    aria-label="Select pre-order search results"></mat-paginator>
</div>


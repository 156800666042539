import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Order } from '../../models/list-orders';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { OrderService } from '../../services/order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-frm',
  templateUrl: './order-frm.component.html',
  styleUrls: ['./order-frm.component.css']
})
export class OrderFrmComponent implements OnInit {

  public frm?: FormGroup;
  @Input() order?:Order
  @Output() sendData = new EventEmitter<Order>()

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private rt: Router,
    private os: OrderService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    this._setOrder(this.order!)
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      departureDate: ['', Validators.required],
      arrivalDate: ['', Validators.required],
      supplierInvoice: [''],
      carrierInvoice: [''],
      status: [''],
      notes: ['']
    })
  }

  private _setOrder(order: Order): void{
    this.frm!.patchValue({
        ...order,
      })
  }

  setArrivalDate = (event: any): void => {

    if (event.value){
      const arrival = moment(event.value).add(4, 'days')
      const arrivalDate = new Date( arrival.year(), arrival.month(), arrival.date())
      this.frm!.patchValue({ arrivalDate}, {emitEvent: false});
    }
  }

  deleteOrder(): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete Order',
        message: 'Are you sure you want to delete this order?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) this.os.deleteOrder(this.order!.id).subscribe(() => this.rt.navigateByUrl('/dash/orders'))
    })
  }

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      departureDate: moment(this.frm!.value.departureDate).format('YYYY-MM-DD'),
      arrivalDate: moment(this.frm!.value.arrivalDate).format('YYYY-MM-DD'),
    }

    this.sendData.emit(data);
  }
}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BankAccount } from 'src/app/features/admin/models/bank-accounts';
import { JcvService } from 'src/app/features/admin/services/jcv.service';

@Component({
  selector: 'app-input-bank-account',
  templateUrl: './input-bank-account.component.html',
  styleUrls: ['./input-bank-account.component.css']
})
export class InputBankAccountComponent implements OnInit {

  account = new FormControl('', Validators.required)

  @Input() accountId?: number
  @Input() label: string = 'Bank Accounts for Invoice'
  @Output() accountChange = new EventEmitter<string>()

  accounts:BankAccount[] = []

  constructor(
    private jcvs: JcvService
  ) { }

  ngOnInit(): void {
    this._loadAccounts()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['accountId']?.currentValue) this.account.setValue(changes['accountId']?.currentValue)
  }


  private _loadAccounts() {
    this.jcvs.getBankAccounts().subscribe( resp => this.accounts = resp.results)
  }

  onAccountChange() {
    this.accountChange.emit(this.account.value || '')
  }

}

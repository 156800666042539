<div *ngIf="trace; else noTrace" class="mt-8">


<div class="bg-white">
  <div class="pt-4 sm:pt-4 px-4">
    <h3 class="!text-xl font-semibold leading-7 text-gray-900">Buy Preorder</h3>
    <p class="mt-1 max-w-2xl text-xs leading-6 text-gray-500">PO: {{trace.po}} <span *ngIf="trace.poClient">| Client: {{trace.poClient}}</span></p>
  </div>
  <div class="mt-6">
    <dl class="grid grid-cols-1 sm:grid-cols-2">
      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-4">
        <dt class="text-sm font-medium leading-6 text-gray-900">Client</dt>
        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{trace.client.name}}</dd>
      </div>
      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-4">
        <dt class="text-sm font-medium leading-6 text-gray-900">Dates</dt>
        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{trace.departureDate|date}} - {{trace.arrivalDate|date}}</dd>
      </div>

      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-4">
        <dt class="text-lg font-medium leading-6 text-gray-900 ps-4 ">Buy Orders</dt>
        <dd class="mt-2 text-sm text-gray-900">

          <div *ngFor="let orderBuy of trace.orders_buy" class="my-6 border-b-2 border-slate-600">

            <div class="border-b border-gray-200 bg-white px-4 py-2 sm:px-6">
              <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-4">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">{{orderBuy.supplier.name}}</h3>
                  <p class="mt-1 text-sm text-gray-500">
                    {{orderBuy.departureDate|date}} - {{orderBuy.arrivalDate|date}}
                  </p>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 font-semibold">
                  Supplier Invoice: <span class="text-red-700">{{orderBuy.supplierInvoice}}</span>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 font-semibold">
                  Carrier Invoice: <span class="text-blue-700">{{orderBuy.carrierInvoice}}</span>
                </div>
              </div>
            </div>
            <div class="bg-white px-4 pt-5 pb-0 sm:px-6">
              <div class="inline-block min-w-full py-2 align-middle">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Product</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Lot</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Net Weight</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Gross Weight</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Pallets</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Boxes</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white">
                    <tr class="even:bg-gray-50" *ngFor="let product of orderBuy.products">
                      <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {{product.product.name}} {{ product.product.description }}
                        <small>Size: {{product.product.size.size}} | Cat.: {{product.product.category}}</small>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.lot}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.netWeight}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.grossWeight}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.pallets}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.totalBoxes}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.total}}
                      </td>

                    </tr>

                  </tbody>

                </table>


                <ul role="list" class="divide-y divide-gray-100">
                  <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
                    <div class="w-full">
                      <p class="text-sm font-semibold leading-6 text-gray-900">
                        Certifications
                      </p>

                      <div class="mt-1 flex items-center justify-between gap-x-2 text-xs leading-5 text-gray-500" *ngFor="let product of orderBuy.products">
                        <div *ngIf="product.certifications.length > 0">
                        <p>
                          {{product.product.name}} {{ product.product.description }} Size: {{product.product.size.size}} | Cat.: {{product.product.category}}
                        </p>
                        <div class="flex gap-2">
                          <app-badget [text]="cert.certification.certification" *ngFor="let cert of product.certifications"></app-badget>
                        </div>
                        </div>
                      </div>

                    </div>

                  </li>

                </ul>



              </div>

            </div>

          </div>

        </dd>
      </div>
    </dl>
  </div>

  <div class="border-y-2 border-sky-700 p-4">
  <p class="text-sm font-semibold leading-6 text-sky-950 ">
    Logistic
  </p>

  <ul role="list" class="divide-y divide-gray-100 px-4" v-if="trace.logistic_buy.length > 0">
    <li class="flex justify-between gap-x-6 py-5" *ngFor="let logistic of trace.logistic_buy">
      <div class="flex min-w-0 gap-x-4">
        <app-logistic-icon></app-logistic-icon>
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-6 text-gray-900">{{logistic.code}}</p>
          <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{logistic.logisticSupplier.name}}</p>
        </div>
      </div>
      <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
        <p class="text-sm leading-6 text-gray-900">{{logistic.totalPallets}} Pallet/s</p>
        <p class="mt-1 text-xs leading-5 text-gray-500">{{logistic.plate}}</p>
      </div>
    </li>
  </ul>

  <span class="text-sm font-semibold leading-6 text-sky-950" v-else> No logistic in buy </span>
  </div>


</div>



<div class="bg-slate-100 border-t-2">
  <div class="pt-4 sm:pt-4 px-4">
    <h3 class="!text-xl font-semibold leading-7 text-gray-900">Sale Preorder</h3>
    <p class="mt-1 max-w-2xl text-xs leading-6 text-gray-500">PO: {{trace.info_base_sale.po}} <span *ngIf="trace.info_base_sale.poClient">| Client:
        {{trace.info_base_sale.poClient}}</span></p>
  </div>
  <div class="mt-6">
    <dl class="grid grid-cols-1 sm:grid-cols-2">
      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-4">
        <dt class="text-sm font-medium leading-6 text-gray-900">Client</dt>
        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{trace.info_base_sale.client.name}}</dd>
      </div>
      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-4">
        <dt class="text-sm font-medium leading-6 text-gray-900">Dates</dt>
        <dd class="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{{trace.info_base_sale.departureDate|date}} -
          {{trace.info_base_sale.arrivalDate|date}}</dd>
      </div>

      <div class="border-t border-gray-100 px-4 py-6 sm:col-span-2 sm:px-4">
        <dt class="text-lg font-medium leading-6 text-gray-900 ps-4 ">Sale Orders</dt>
        <dd class="mt-2 text-sm text-gray-900">

          <div *ngFor="let orderSale of trace.preorder_sales" class="my-6 border-b-2 border-slate-600">

            <div class="border-b border-gray-200 bg-slate-100 px-4 py-2 sm:px-6">
              <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
                <div class="ml-4 mt-4">
                  <h3 class="text-base font-semibold leading-6 text-gray-900">{{orderSale.supplier.name}}</h3>
                  <p class="mt-1 text-sm text-gray-500">
                    {{orderSale.departureDate|date}} - {{orderSale.arrivalDate|date}}
                  </p>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 font-semibold">
                  Supplier Invoice: <span class="text-red-700">{{orderSale.supplierInvoice}}</span>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 font-semibold">
                  Carrier Invoice: <span class="text-blue-700">{{orderSale.carrierInvoice}}</span>
                </div>
              </div>
            </div>
            <div class="bg-slate-100 px-4 py-5 sm:px-6">
              <div class="inline-block min-w-full py-2 align-middle">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                        Product</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Lot
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Net Weight</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Gross Weight
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Pallets</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Boxes</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                    </tr>
                  </thead>
                  <tbody class="bg-slate-100">
                    <tr class="even:bg-gray-50" *ngFor="let product of orderSale.products">
                      <td class="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {{product.product.name}} {{ product.product.description }}
                        <small>Size: {{product.product.size.size}} | Cat.: {{product.product.category}}</small>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.lot}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.netWeight}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.grossWeight}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.pallets}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.totalBoxes}}
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{product.total}}
                      </td>

                    </tr>

                  </tbody>
                </table>
              </div>

            </div>

          </div>

        </dd>
      </div>
    </dl>
  </div>




  <div class="border-y-2 border-sky-700 p-4">
    <p class="text-sm font-semibold leading-6 text-sky-950 ">
      Logistic Sale
    </p>

    <ul role="list" class="divide-y divide-gray-100 px-4">
      <li class="flex justify-between gap-x-6 py-5" *ngFor="let logistic of trace.logistic_sale">
        <div class="flex min-w-0 gap-x-4">
          <app-logistic-icon></app-logistic-icon>
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">{{logistic.code}}</p>
            <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{logistic.logisticSupplier.name}}</p>
          </div>
        </div>
        <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
          <p class="text-sm leading-6 text-gray-900">{{logistic.totalPallets}} Pallet/s</p>
          <p class="mt-1 text-xs leading-5 text-gray-500">{{logistic.plate}}</p>
        </div>
      </li>
    </ul>
  </div>


</div>



<div *ngIf="trace.non_conformities && trace.non_conformities.length > 0" class="bg-white p-5 rounded-sm transition-all duration-300 ani">
  <div class="flex flex-col gap-4">

    <h3 class="!text-xl font-semibold leading-7 text-gray-900">Non Conformities</h3>

    <div class="mt-4 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col"
                  class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0">Detection</th>
                <th scope="col" class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Detection Date</th>
                <th scope="col" class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Close</th>
                <th scope="col" class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">Close Date</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr *ngFor="let nc of trace.non_conformities">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{nc.detection}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{nc.detectionDate|date:'dd/MM/yyyy'}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <app-badget [text]="(nc.isEffective) ? 'Closed' : 'Non Closed'" [class]="(nc.isEffective) ? 'bg-green-50 text-green-700 ring-green-600/20' : 'bg-red-50 text-red-700 ring-red-600/20'"></app-badget>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{nc.closingDateCorrectiveAction|date:'dd/MM/yyyy'}}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <h3 class="!text-xl font-semibold leading-7 text-gray-900">Files</h3>

    <div *ngIf="files">
      <table class="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"> File </th>
            <th scope="col" class="px-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr *ngFor="let f of files">
            <td class="whitespace-nowrap pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{f.name}}</td>
            <td class="whitespace-nowrap px-3 text-sm text-gray-500">

              <button mat-button
                class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-white object-cover ring-1 ring-white  transition-colors duration-500"
                (click)="download(f.path)">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="purple"
                  class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>


              </button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


</div>

<ng-template #noTrace>

  <div class="text-center">
    <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
    </svg>
    <h3 class="mt-2 text-sm font-semibold text-gray-900">No PO Selected</h3>
    <p class="mt-1 text-sm text-gray-500">
      {{loading ? 'Loading information' : 'Search and Select the PO to show traceability information'}}
    </p>
  </div>



</ng-template>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">

  <div class="w-3/12">
    <app-input-language [languageId]="languageId" (languageChange)="setLanguage($event)"></app-input-language>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>Product name</mat-label>
    <input matInput formControlName="name" placeholder="Pimiento mixto">
  </mat-form-field>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description" placeholder="This product has...."></textarea>
    </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Variety</mat-label>
    <input matInput formControlName="variety" type="text" placeholder="Mixin">
  </mat-form-field>

  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Translation
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

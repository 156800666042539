import { Component, OnInit } from '@angular/core';
import { Order } from '../../models/list-orders';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.css']
})
export class OrderEditComponent implements OnInit {

  idOrder = this.ar.snapshot.params['id']
  order?: Order

  public breadcrumb = [
    {name: 'Orders', url: '/dash/orders'},
    {name: 'Update Order'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ss: SnackbarsService,
    private os: OrderService,
  ) { }

  ngOnInit(): void {
    this._getOrder()
  }

  private _getOrder(): void {
    this.os.getOrder(this.idOrder).subscribe({
      next: (resp) => {
        this.order = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting order','snack-error')
        this.rt.navigate(['/dash/orders'])
      }
    })
  }

  changeIndex = (event: number) => (event === 0) ? this._getOrder() : null


  updateOrder(order: Order): void {
    this.os.updateOrder(this.idOrder, order).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Order updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating preOrder','snack-error')
      }
    })
  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


    <mat-form-field appearance="outline" class="">
      <mat-label>Account Name</mat-label>
      <input matInput formControlName="name" placeholder="Account Name">
    </mat-form-field>

    <mat-form-field appearance="outline" class="">
      <mat-label>IBAN</mat-label>
      <input matInput formControlName="iban" placeholder="IBAN">
    </mat-form-field>
    <mat-form-field appearance="outline" class="">
      <mat-label>BIC</mat-label>
      <input matInput formControlName="bic" placeholder="BIC">
    </mat-form-field>
    <mat-form-field appearance="outline" class="">
      <mat-label>SWIFT</mat-label>
      <input matInput formControlName="swift" placeholder="SWIFT">
    </mat-form-field>

    <div class="p-2">
      <mat-checkbox formControlName="default">Account by default</mat-checkbox>
    </div>

    <mat-form-field appearance="outline" class="">
      <mat-label>Address</mat-label>
      <input matInput formControlName="direction" placeholder="Adrress">
    </mat-form-field>

    <mat-form-field appearance="outline" class="">
      <mat-label>Sort Code UK</mat-label>
      <input matInput formControlName="sortCode" placeholder="Sort Code UK">
    </mat-form-field>

    <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Account </button>
    <div class="my-4">
      <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
    </div>

</form>

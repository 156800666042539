import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { EuroPool } from 'src/app/features/admin/models/euro-pool';
import { Packaging } from 'src/app/features/admin/models/packaging';
import { PackagingService } from 'src/app/features/admin/services/packaging.service';

@Component({
  selector: 'app-modal-properties-load',
  templateUrl: './modal-properties-load.component.html',
  styleUrls: ['./modal-properties-load.component.css']
})
export class ModalPropertiesLoadComponent implements OnInit {

  europool_list: EuroPool[] = []
  packaging_list: Packaging[] = []

  frm: FormGroup = this.fb.group({
    load: [this.data.load],
    lot: [this.data.lot],
    origin: [this.data.origin],
    grossWeight: [this.data.grossWeight],
    netWeight: [this.data.netWeight],
    packaging: [this.data.packaging],
  })

  constructor(
    private fb: FormBuilder,
    private bsRef: MatBottomSheetRef<ModalPropertiesLoadComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {load:number, lot: string, origin: string, grossWeight: number, netWeight: number, product: string, packaging: number, europool: number },
    private ps: PackagingService,
  ) { }

  ngOnInit(): void {
    this._loadPackaging()
  }

  private _loadPackaging(): void{
    this.ps.getPackagings().subscribe( r => this.packaging_list = r.results )
  }

  send(){
    if (this.frm.invalid) return

    this.bsRef.dismiss(this.frm.value)
  }

}

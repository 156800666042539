<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Users
      <small class="block font-light text-xs text-slate-500 w-full">
        Manage users.
      </small>
    </h2>

    <button mat-raised-button class="bg-green-700 text-white w-2/12" (click)="goto('/dash/admin/users/add')">Add
      User</button>

  </div>

  <!-- Search -->
  <div class="my-8">
    <app-simple-search (searched)="search($event)"></app-simple-search>
  </div>

  <!-- List -->

  <app-user-table [searched]="searched"></app-user-table>
</main>

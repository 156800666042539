import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Certification } from '../../models/certifications';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-frm-certification',
  templateUrl: './frm-certification.component.html',
  styleUrls: ['./frm-certification.component.css']
})
export class FrmCertificationComponent implements OnInit {

  public frm?: FormGroup;

  @Input() certification?:Certification
  @Output() sendData = new EventEmitter<Certification>()

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.certification){
      this._setSize(this.certification)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      certification: ['', Validators.required],
    });
  }

  _setSize(certification: Certification): void{
      this.frm!.patchValue({
        ...certification,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

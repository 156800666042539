import { Component, OnInit } from '@angular/core';
import { Packaging } from '../../../models/packaging';
import { PackagingService } from '../../../services/packaging.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-packaging-edit',
  templateUrl: './packaging-edit.component.html',
  styleUrls: ['./packaging-edit.component.css']
})
export class PackagingEditComponent implements OnInit {

  idPackaging = this.ar.snapshot.params['id']
  packaging?: Packaging

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Packaging', url: '/dash/admin/packaging'},
    {name: 'Update Packaging'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ps: PackagingService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getPackaging(this.idPackaging)
  }

  private _getPackaging(packagingId: number): void {
    this.ps.getPackaging(packagingId).subscribe({
      next: (resp) => {
        this.packaging = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting packaging','snack-error')
        this.rt.navigate(['/dash/admin/packaging'])
      }
    })
  }


  updatePackaging(packaging: Packaging): void {
    this.ps.updatePackaging(this.idPackaging, packaging).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Packaging ${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating packaging','snack-error')
      }
    })
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit {
  @Input() title: string = 'No data'
  @Input() text: string = 'No data to show'

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { Size } from '../../../models/sizes';
import { ActivatedRoute, Router } from '@angular/router';
import { SizesService } from '../../../services/sizes.service';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-sizes-edit',
  templateUrl: './sizes-edit.component.html',
  styleUrls: ['./sizes-edit.component.css']
})
export class SizesEditComponent implements OnInit {

  idSize = this.ar.snapshot.params['id']
  size?: Size

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Sizes', url: '/dash/admin/sizes'},
    {name: 'Update Size'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private szs: SizesService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getSize(this.idSize)
  }

  private _getSize(sizeId: number): void {
    this.szs.getSize(sizeId).subscribe({
      next: (resp) => {
        this.size = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting size','snack-error')
        this.rt.navigate(['/dash/admin/sizes'])
      }
    })
  }


  updateSize(size: Size): void {
    this.szs.updateSize(this.idSize, size).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Size ${resp.size} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating size','snack-error')
      }
    })
  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">
  <div class="mt-6 border-t border-gray-100 w-full" *ngIf="delivery">
    <dl class="divide-y divide-gray-100">

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Code</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <span class="flex-grow font-bold">{{delivery.code}}</span>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Client</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{delivery.order.client.name}}</span>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Supplier</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <span class="flex-grow">{{delivery.order.supplier.name}}</span>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Certified Phytosanitary</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Certified Phytosanitary</mat-label>
            <input matInput type="text" formControlName="certifiedPhyto">
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Incoterm</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-full">
            <mat-label>Incoterm</mat-label>
            <mat-select formControlName="incoterms_id">
              <mat-option [value]="incoterm.id" *ngFor="let incoterm of incoterms">{{incoterm.incoterm}}</mat-option>
            </mat-select>
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" placeholder="Ex. This deliviry note has..."></textarea>
        </mat-form-field>
        </dd>
      </div>

    </dl>

  </div>

  <div class="w-full" *ngIf="delivery">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-base font-semibold leading-6 text-gray-900">Products</h1>
        </div>
      </div>
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Product</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Origin</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Pallets</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Packaging</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Lot</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Boxes</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Net Weight</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Gross Weight</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr class="even:bg-gray-50 hover:bg-gray-100 hover:cursor-pointer" *ngFor="let product of delivery.order.products" (click)="editPropertiesLoad(product)">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                    {{product.product.name}} {{product.product.description}}
                    <br>
                    <small>Size {{product.product.size.size}} | Cat.: {{product.product.category}}</small>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {{product.origin}} </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{product.pallets}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{(product.packaging) ? product.packaging.name : ''}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {{product.lot}} </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{product.totalBoxes}}</td>
                  <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500">
                    {{product.netWeight}}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500">
                    {{product.grossWeight}}
                  </td>
                  <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500">

                    <div *ngIf="product.coin.code === 'EUR'; else deliveryPound" class="flex gap-2 justify-start">
                      <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="product.total|currency:'EUR'"></app-badget>
                      <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="product.total|changeToPound:delivery.order.currencyChange|currency:'GBP'"> </app-badget>
                    </div>
                    <ng-template #deliveryPound>
                      <div class="flex gap-2 justify-start">
                        <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="product.total|changeToEuro:delivery.order.currencyChange|currency:'EUR'"> </app-badget>
                        <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="product.total|currency:'GBP'"></app-badget>
                      </div>
                    </ng-template>



                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
  </div>




  <div class="w-full text-end">
    <button type="submit"
      class="inline-flex justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 w-3/12">Save</button>
  </div>
</form>


<div *ngIf="frm" class="px-3">

  <mat-accordion class="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="flex flex-row flex-wrap gap-2 justify-start items-center">

          <mat-form-field appearance="outline" class="w-full">
            <mat-label> Search </mat-label>
            <input matInput type="text" formControlName="search">
          </mat-form-field>

          <app-input-clients (onSelect)="updateClient($event)" class="w-7/12"></app-input-clients>

          <mat-form-field appearance="outline" class="w-3/12">
            <mat-date-range-input [formGroup]="frm" [rangePicker]="picker">
              <input matStartDate formControlName="departureDate_after" placeholder="After">
              <input matEndDate formControlName="departureDate_before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>


          <div class="w-5/12">
            <mat-button-toggle-group formControlName="typeOfPreOrder" aria-label="" multiple>
              <mat-button-toggle value="COMMISSION"> Commission</mat-button-toggle>
              <mat-button-toggle value="BUY"> Buy </mat-button-toggle>
              <mat-button-toggle value="SELL"> Sell </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="w-5/12">
            <mat-button-toggle-group formControlName="status" aria-label="" multiple>
              <mat-button-toggle value="DRAFT"> Draft </mat-button-toggle>
              <mat-button-toggle value="GENERATED"> Generated </mat-button-toggle>
              <mat-button-toggle value="TEMPLATE"> Template </mat-button-toggle>
              <mat-button-toggle value="CANCELED"> Canceled </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <button mat-raised-button class="bg-red-600 text-white w-1/12" (click)="clean()">Clean</button>
        </div>

      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

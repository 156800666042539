import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-simple-search',
  templateUrl: './simple-search.component.html',
  styleUrls: ['./simple-search.component.css']
})
export class SimpleSearchComponent implements OnInit {

  @Output() searched = new EventEmitter<any>()

  searchText = new FormControl('')

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService
  ) { }

  ngOnInit(): void {
    this.searchText.valueChanges.subscribe( resp => {
      if (resp!.length >= 2 || resp!.length === 0) this.search()
    })
  }

  search(){
    this.searched.emit({text: this.searchText.value})
  }

}

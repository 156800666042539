<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Certification</mat-label>
    <mat-select formControlName="certification_id">
      <mat-option [value]="certification.id" *ngFor="let certification of certifications">{{certification.certification}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Identification</mat-label>
    <input matInput formControlName="identification" type="text" placeholder="00000000000">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Type</mat-label>
    <mat-select formControlName="typeCertification">
      <mat-option value="APPROVED">Approved</mat-option>
      <mat-option value="TEST">Test</mat-option>
      <mat-option value="REJECTED">Rejected</mat-option>
      <mat-option value="DEHOMOLOGIZED">Dehomologized</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Risk</mat-label>
    <mat-select formControlName="risk">
      <mat-option value="LOW">Low</mat-option>
      <mat-option value="MEDIUM">Medium</mat-option>
      <mat-option value="HIGH">High</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="dates flex flex-row gap-4">

    <mat-form-field appearance="outline" class="w-5/12">
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startDate" formControlName="startDate">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi #startDate></mat-datepicker>

    </mat-form-field>

    <mat-form-field appearance="outline" class="w-5/12">
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endDate" formControlName="endDate">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker touchUi #endDate></mat-datepicker>

    </mat-form-field>
  </div>


  <mat-form-field appearance="outline">
    <mat-label>Observations</mat-label>
    <textarea matInput formControlName="observations" placeholder="Ex. This certification is..."></textarea>
  </mat-form-field>



  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Certification
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>
</form>


<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Costs
      <small class="block font-light text-xs text-slate-500 w-full"> Manage JCV S4U costs</small>
    </h2>


    <button mat-raised-button (click)="createCost()" class="bg-green-700 text-white w-2/12">Add New Cost</button>


  </div>

  <!-- Filter-->
  <div class="my-6">
    <app-costs-filter></app-costs-filter>
  </div>

  <!-- List -->
  <app-costs-table [searched]="searched" (editCost)="editCost($event)"></app-costs-table>
</main>

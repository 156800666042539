import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../models/usuario';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login-form';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public usuario?: Usuario;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }


  get headers(): any{
    return {
      headers: {
        'x-token': this.token
      }
    };
  }

  get token(): string | null{
    return localStorage.getItem('token');
  }

  guardarStorage( token: string ): void{
    localStorage.setItem('token', token);
  }

  saveName(name: string): void {
    localStorage.setItem('jcv_n', name);
  }

  isAdmin(name: string): void {
    localStorage.setItem('is_adm', name);
  }

  logout(): void{
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  validarToken(): Observable<boolean>{

    return this.http.post(`${ baseUrl }/check/`, {token: this.token}, {
      headers: {
        'Authorization': `token ${ this.token }`
      }
    }).pipe(
      map( (resp: any) => {
        this.guardarStorage(resp.token)
        return true;
      }),
      catchError( error => of(false))
      );
  }


  login( formData: LoginForm): Observable<{token:string,  nombre: string}>{
    return this.http.post(`${ baseUrl }/login/`, formData)
      .pipe(
        tap( (resp: any) => {
          this.guardarStorage(resp.token)
          this.saveName(resp.nombre)
          this.isAdmin(resp.admin)
          this.usuario = new Usuario(resp.nombre, resp.token, resp.admin);
        })
      );
  }



}

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { SupplierService } from '../../services/supplier.service';
import { Certification } from '../../models/certifications';
import { CertificationsService } from '../../services/certifications.service';
import * as moment from 'moment';
import { CertificationSupplier } from '../../models/certifications-supplier';



@Component({
  selector: 'app-supplier-certifications-frm',
  templateUrl: './supplier-certifications-frm.component.html',
  styleUrls: ['./supplier-certifications-frm.component.css']
})
export class SupplierCertificationsFrmComponent implements OnInit {

  public frm?: FormGroup;
  public certifications: Certification[] = []
  customFirstDayOfWeek: number = 1;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<SupplierCertificationsFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id: number, supplier: number},
    private sps: SupplierService,
    private cs: CertificationsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    this._loadCertifications();
    if (this.data.id) this._getCertification()

  }

  private _createForm(): void{
    this.frm = this.fb.group({
      supplier_id: [this.data.supplier, Validators.required],
      certification_id: ['', Validators.required],
      identification: ['', Validators.required],
      typeCertification: ['', Validators.required],
      risk: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      observations: [''],
    });
  }

  private _loadCertifications(): void{
    this.cs.searchCertifications().subscribe({
      next: (data) => {
        this.certifications = data.results;
      },error: (error) => {
        this.ss.nuevaSnack('Error loading certifications', 'snack-error');
      }
    })
  }

  private _getCertification(): void{
    this.sps.getCertification(this.data.id).subscribe({
      next: (data) => {
        this.frm!.patchValue({...data, certification_id: data.certification.id});
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting certification data', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _create(data: CertificationSupplier): void{
    this.sps.addCertification(data).subscribe({
      next: ({certification}) => {
        this.ss.nuevaSnack(`The certification ${certification.certification} has been created`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating certification', 'snack-error');
      }
    }
    );
  }

  private _update(data: CertificationSupplier): void{
    this.sps.updateCertification(this.data.id, data).subscribe({
      next: ({certification}) => {
        this.ss.nuevaSnack(`The certification ${certification.certification} has been updated`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating certification', 'snack-error');
      }
    })
  }


  send(): void{
    if (this.frm!.invalid) { return; }

    const dataCertification = {
      ...this.frm!.value,
      startDate: moment(this.frm!.value.startDate).format('YYYY-MM-DD'),
      endDate: moment(this.frm!.value.endDate).format('YYYY-MM-DD'),
    }

    if (this.data.id){
      this._update(dataCertification)
    }else{
      this._create(dataCertification);
    }
  }


  close(){
    this.bsheet.dismiss();
  }


}

import { Component, OnInit } from '@angular/core';
import { LogisticSupplierService } from '../../../services/logistic-supplier.service';
import { LogisticSupplier } from '../../../models/logistic-suppliers';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logistic-create',
  templateUrl: './logistic-create.component.html',
  styleUrls: ['./logistic-create.component.css']
})
export class LogisticCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Logistic Suppliers', url: '/dash/admin/logistic'},
    {name: 'New Logistic Supplier'},
  ]

  constructor(
    private rt: Router,
    private lss: LogisticSupplierService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createLogistic(logistic: LogisticSupplier): void {
    this.lss.addLogisticSupplier(logistic).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Logistic Supplier ${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/logistic/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating logistic supplier','snack-error')
      }
    })

  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-costs-filter',
  templateUrl: './costs-filter.component.html',
  styleUrls: ['./costs-filter.component.css']
})
export class CostsFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Router } from '@angular/router';
import { UserRequest } from '../../../models/users-response';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Users', url: '/dash/admin/users'},
    {name: 'New user'},
  ]

  constructor(
    private rt: Router,
    private us: UsersService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createUser(user: UserRequest): void {
    this.us.createUser(user).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.first_name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/users'])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating users','snack-error')
      }
    })
  }
}

import { Component, Input, OnInit } from '@angular/core';

interface Breadcrumb {
  name: string;
  url?: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input('routes') routes: Breadcrumb[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseCode } from '../models/base-code';
import { CoinsResponse } from '../models/coins-response';
import { CountriesResponse } from '../models/countries-response';
import { Languages } from '../models/languages-response';
import { Users } from '../models/users';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class SharedInfoService {

  constructor(private http: HttpClient) { }

  getCoins(): Observable<CoinsResponse> {
    return this.http.get<CoinsResponse>(`${baseUrl}/coins`)
  }

  getLanguages(): Observable<Languages> {
    return this.http.get<Languages>(`${baseUrl}/languages`)
  }

  searchCountries(search?: string): Observable<CountriesResponse> {
    const params = new HttpParams()
      .set('search', search || '')

    return this.http.get<CountriesResponse>(`${baseUrl}/search-countries`, { params })
  }

  getUsersPartners(): Observable<Users> {
    return this.http.get<Users>(`${baseUrl}/user-partners`)
  }

  downloadFile(path: string) {
    return this.http.post(`${baseUrl}/download-dbx/`, {path: path}, { responseType: 'blob' })
  }
}

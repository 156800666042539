import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingService } from '../../services/billing.service';

import { InvoiceItemFrmComponent } from '../invoice-item-frm/invoice-item-frm.component';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-items-table',
  templateUrl: './invoice-items-table.component.html',
  styleUrls: ['./invoice-items-table.component.css']
})
export class InvoiceItemsTableComponent implements OnInit {

  @Input('data') data?: any
  @Input('invoice') invoice = ''
  @Output('refresh') refresh = new EventEmitter<boolean>()


  constructor(
    private bs: BillingService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }


  openInvoiceItem(item = null){
    this.bottomSheet.open(InvoiceItemFrmComponent, {
      data: {
        invoice: this.invoice,
        invoice_item: item
      }
    }).afterDismissed().subscribe( resp => {
      if (resp){
        this.refresh.emit(true)
      }

    })

  }

  delete(id: number){

    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete contact',
        message: 'Are you sure you want to delete this concept?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.bs.deleteItemInvoice(id).subscribe( resp => this.refresh.emit(true))
      }
    })



  }

}


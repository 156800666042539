import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LogisticMailFrm, OrderMailFrm } from '../models/emails-frm';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }


  sendOrder(order:number, data: OrderMailFrm): Observable<any>{
    return this.http.post(`${baseUrl}/send-order/${order}`, data)
  }

  sendLogistic(logistic:number, data: LogisticMailFrm): Observable<any>{
    return this.http.post(`${baseUrl}/send-logistic/${logistic}`, data)
  }

  sendSingleLogistic(logistic:number, data: LogisticMailFrm): Observable<any>{
    return this.http.post(`${baseUrl}/send-single-logistic/${logistic}`, data)
  }
}

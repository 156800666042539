<table class="min-w-full divide-y divide-gray-300" *ngIf="data">
  <thead>
    <tr>
      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Concept</th>
      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>

    </tr>
  </thead>
  <tbody class="bg-white">
    <tr class="even:bg-gray-50" *ngFor="let c of data.resumen">
      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
        Commision Load: {{c.concept}}
      </td>

      <td class="whitespace-nowrap py-4 pl-3 text-sm text-gray-500">
        <div *ngIf="data.coin === 'EUR'; else deliveryPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
            [text]="c.total|currency:'EUR'"></app-badget>
        </div>

        <ng-template #deliveryPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="c.total|changeToEuro:data.exchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
              [text]="c.total|currency:'GBP'"></app-badget>
          </div>
        </ng-template>

      </td>
    </tr>

    <!-- More people... -->
  </tbody>
</table>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Packaging, PackagingFrm } from '../../models/packaging';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-frm-packaging',
  templateUrl: './frm-packaging.component.html',
  styleUrls: ['./frm-packaging.component.css']
})
export class FrmPackagingComponent implements OnInit {

  public frm?: FormGroup;

  @Input() packging?:Packaging
  @Output() sendData = new EventEmitter<Packaging>()

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.packging){
      this._setPacakgin(this.packging)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      price: ['', Validators.required],
    });
  }

  _setPacakgin(packging: Packaging): void{
      this.frm!.patchValue({
        ...packging,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BillingService } from '../../services/billing.service';
import { InvoiceDetail, ProductsOrderInvoice } from '../../models/invoice-detail';

@Component({
  selector: 'app-invoice-products',
  templateUrl: './invoice-products.component.html',
  styleUrls: ['./invoice-products.component.css']
})
export class InvoiceProductsComponent implements OnInit {

  secondLine?: {resumen: any[], coin: string, exchange: number}
  invoiceItems ?: any
  ordersItems ?: ProductsOrderInvoice[]
  typeInvoice?: string
  @Input('invoice') invoice?: string
  constructor(
    private bs: BillingService
  ) { }

  ngOnInit(): void {
    if (this.invoice) this._detailInvoice()
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  private _detailInvoice() {
    this.bs.getDetailInvoice(this.invoice!).subscribe({
      next: (res: InvoiceDetail) => {
        this.typeInvoice = res.typeInvoice
        if (res.resumen_secondline.length > 0){
          this.secondLine = {
            resumen: res.resumen_secondline,
            coin: res.coin.code,
            exchange: res.currencyExchange
          }
        }


        if (res.invoice_items.length > 0) this.invoiceItems = {items: res.invoice_items, coin: res.coin.code, exchange: res.currencyExchange}
        if (res.products_orders.length > 0) this.ordersItems = res.products_orders
      },
      error: (err: any) => console.log(err)
    })
  }


  refresh = (e:boolean) => (e) ? this._detailInvoice() : null
}

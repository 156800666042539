import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SecondLineFilter } from '../../models/second-line-filter';
import { SecondLineService } from '../../services/second-line.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';

@Component({
  selector: 'app-second-line-filter',
  templateUrl: './second-line-filter.component.html',
  styleUrls: ['./second-line-filter.component.css']
})
export class SecondLineFilterComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    search: [''],
    client: [],
    isInvoiced: [],
    supplier: [],
    orderDate_after: [],
    orderDate_before: [],
  })

  @Output() onSearch: EventEmitter<SecondLineFilter> = new EventEmitter<SecondLineFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,
    private sls: SecondLineService
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  updateClient = (client: string) => this.frm?.patchValue({ client })
  updateSupplier = (supplier: string) => this.frm?.patchValue({ supplier })

  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
      orderDate_after: (this.frm.value.orderDate_after) ? moment(this.frm.value.orderDate_after).format('YYYY-MM-DD') : '',
      orderDate_before: (this.frm.value.orderDate_before) ? moment(this.frm.value.orderDate_before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">
<div class="mt-6 border-t border-gray-100 w-full" *ngIf="order">
  <dl class="divide-y divide-gray-100">
    <div>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 justify-end">
        <button mat-raised-button aria-label="Delete Pre-order" type="button" (click)="deleteOrder()" class="bg-red-600">
          <mat-icon svgIcon="delete" class="text-white"></mat-icon>
        </button>

      </dd>
    </div>
  </dl>
  <dl class="divide-y divide-gray-100">
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Client</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <span class="flex-grow">{{order.client.name}}</span>

      </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Supplier</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <span class="flex-grow">{{order.supplier.name}}</span>
      </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Departure Data</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-6/12">
          <mat-label>Departure Date</mat-label>
          <input matInput [matDatepicker]="departureDate" formControlName="departureDate" (dateInput)="setArrivalDate($event);">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
          <mat-datepicker touchUi #departureDate></mat-datepicker>
        </mat-form-field>
      </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Arrival Date</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-6/12">
          <mat-label>Arrival Date</mat-label>
          <input matInput [matDatepicker]="arrivalDate" formControlName="arrivalDate">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="arrivalDate"></mat-datepicker-toggle>
          <mat-datepicker touchUi #arrivalDate></mat-datepicker>
        </mat-form-field>
      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Status</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option value="DRAFT">Draft</mat-option>
              <mat-option value="LOGISTIC">Logistic Generated</mat-option>
              <mat-option value="SEND">Send</mat-option>
              <mat-option value="CUSTOMS">Received Customs</mat-option>
              <mat-option value="SUPPLIER">Supplier Paid</mat-option>
              <mat-option value="TRANSPORT">Transport Paid</mat-option>
              <mat-option value="INVOICED_SUPPLIER">Supplier Invoiced</mat-option>
              <mat-option value="INVOICED_LOGISTIC">Logistic Invoiced</mat-option>
              <mat-option value="PAID">Paid</mat-option>
            </mat-select>
        </mat-form-field>
      </dd>
    </div>
    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Supplier Invoice</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-5/12">
          <mat-label>Supplier Invoice</mat-label>
          <input matInput type="text" formControlName="supplierInvoice">
        </mat-form-field>
      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Carrier Invoice</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-5/12">
          <mat-label>Carrier Invoice</mat-label>
          <input matInput type="text" formControlName="carrierInvoice">
        </mat-form-field>
      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Total</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

        <div class="flex flex-grow gap-4">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.total|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="order.total|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
        </div>

      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Commission</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <div class="flex flex-grow gap-4">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.totalCommission|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="order.totalCommission|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
        </div>
      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">JCV Invoice</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <div class="flex flex-grow gap-4">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.myInvoice|currency:'EUR'"></app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="order.myInvoice|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
        </div>
      </dd>
    </div>

    <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
      <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
        <mat-form-field appearance="outline" class="w-12/12">
          <mat-label>Notes</mat-label>
          <textarea matInput formControlName="notes" placeholder="Ex. This order is..."></textarea>
        </mat-form-field>
      </dd>
    </div>
  </dl>

</div>
<div class="w-full text-end">
  <button type="submit"
  class="inline-flex justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 w-3/12">Save</button>
</div>
</form>

import { Component, OnInit } from '@angular/core';
import { Certification } from '../../../models/certifications';
import { CertificationsService } from '../../../services/certifications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-certification-edit',
  templateUrl: './certification-edit.component.html',
  styleUrls: ['./certification-edit.component.css']
})
export class CertificationEditComponent implements OnInit {

  idCertification = this.ar.snapshot.params['id']
  certification?: Certification

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Certifications', url: '/dash/admin/certifications'},
    {name: 'Update Certification'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private cs: CertificationsService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getCertification(this.idCertification)
  }

  private _getCertification(certificationId: number): void {
    this.cs.getCertification(certificationId).subscribe({
      next: (resp) => {
        this.certification = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting certification','snack-error')
        this.rt.navigate(['/dash/admin/certifications'])
      }
    })
  }


  updateCertification(certification: Certification): void {
    this.cs.updateCertification(this.idCertification, certification).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Certification ${resp.certification} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating certification','snack-error')
      }
    })
  }

}

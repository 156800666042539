import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProductSupplier } from 'src/app/features/admin/models/products-supplier';
import { SupplierService } from 'src/app/features/admin/services/supplier.service';

@Component({
  selector: 'app-input-product-by-supplier',
  templateUrl: './input-product-by-supplier.component.html',
  styleUrls: ['./input-product-by-supplier.component.css']
})
export class InputProductBySupplierComponent implements OnInit {

  product = new FormControl('', Validators.required)

  @Input() idSupplier: number = 0
  @Input() productId?: number
  @Input() productName?: string
  @Input() order?: number
  @Output() productChange = new EventEmitter<number>()

  products:ProductSupplier[] = []

  constructor(
    private ss: SupplierService
  ) { }

  ngOnInit(): void {
    this._loadProduct()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['idSupplier']?.currentValue === 0) {
      this.product.setValue('')
      this.products = []
    }

    if (changes['productId']?.currentValue) this.product.setValue(changes['productId']?.currentValue)


    if (changes['idSupplier']?.currentValue && !changes['idSupplier']?.firstChange) {
      this._loadProduct()
    }


  }


  private _loadProduct() {
    this.ss.searchProductsBySupplier(this.idSupplier, this.order ?? '').subscribe( resp => this.products = resp.results)
  }

  onProductChange() {
    const product = this.products.find( p => p.product.id.toString() === this.product.value?.toString())?.product.id || null
    if (product) this.productChange.emit(product)
  }

}

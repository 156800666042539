<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>Product</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" >{{row.product.name}} {{row.product.description}} - Size: {{row.product.size.size}} - Cat: {{row.product.category}}</td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">{{row.order.client.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">
          {{row.supplier.name}}</td>
      </ng-container>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Order Products found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select pre-order search results"></mat-paginator>
</div>

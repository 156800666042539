<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Credit Note
      <small class="block font-light text-xs text-slate-500 w-full"> Search and Manage my credit notes .</small>
    </h2>

    <!-- Actions -->
    <div class="flex flex-row justify-between">
      <button mat-raised-button class="bg-green-700 text-white" (click)="goto('/dash/credit-notes/create')">New Credit Note
      </button>

    </div>

  </div>

  <!-- Filter-->
  <div class="my-6">
    <app-credit-filter (onSearch)="search($event)"></app-credit-filter>
  </div>

  <!-- List -->
  <app-credit-table [searched]="searched"></app-credit-table>
</main>

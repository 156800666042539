import { Component, OnInit } from '@angular/core';
import { CreditNoteService } from '../../services/credit-note.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-create',
  templateUrl: './credit-create.component.html',
  styleUrls: ['./credit-create.component.css']
})
export class CreditCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Credit Notes', url: '/dash/credit-notes'},
    {name: 'New Invoice'},
  ]

  constructor(
    private cs: CreditNoteService,
    private rt: Router,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }

  sendData(creditNote: any){

    this.cs.createCreditNote(creditNote).subscribe({
      next: resp => {
        this.ss.nuevaSnack(`Credit Note ${resp.code} created successfully`, 'snack-success')
        this.rt.navigate(['/dash/credit-notes/edit',resp.id])
      },
      error: err => console.log(err)
    })
  }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-input-temperature',
  templateUrl: './input-temperature.component.html',
  styleUrls: ['./input-temperature.component.css']
})
export class InputTemperatureComponent implements OnInit {

  temperature = new FormControl('', Validators.required)

  @Input() temperatureId?: number
  @Output() temperatureChange = new EventEmitter<number>()

  temperatures: string[] = ['-7','-6','-5','-4','-3','-2','-1','0','1','2','3','4','5','6','7','8','9','10','11','12','13','14']

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {


    if (changes['temperatureId']?.currentValue) this.temperature.setValue(changes['temperatureId']?.currentValue)
  }

  onTemperatureChange() {
    if (this.temperature.value){
      this.temperatureChange.emit(parseInt(this.temperature.value))

    }
  }

}

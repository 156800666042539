<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Intrastat
      <small class="block font-light text-xs text-slate-500 w-full"> Show and export Intrastat data.</small>
    </h2>

  </div>

  <!-- Filter-->
  <app-intrastat-filter (onSearch)="search($event)"></app-intrastat-filter>

  <!-- Actions -->

  <div class="flex flex-row flex-wrap gap-4 justify-end items-center w-full mt-4">
    <button mat-raised-button class="bg-green-600 text-white !h-12" (click)="export()">Export Excel</button>
  </div>

  <!-- List -->

  <div class="w-full">
    <app-intrastat-graph [searched]="searched"></app-intrastat-graph>
  </div>

</main>

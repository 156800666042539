<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">
  <div class="mt-6 border-t border-gray-100 w-full">
    <dl class="divide-y divide-gray-100">

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" *ngIf="creditNote">
        <dt class="text-sm font-medium leading-6 text-gray-900">Credit Note number</dt>
        <dd class="mt-1 flex text-base leading-6 font-bold text-gray-700 sm:col-span-2 sm:mt-0">
          {{creditNote.code}}
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Invoice</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 gap-2">

          <div class="w-full">
            <app-input-invoice [invoiceId]="invoiceId" [invoiceName]="invoiceName"
              (onSelect)="setInvoice($event)"></app-input-invoice>
          </div>

        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Coin</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            <div class="w-full">
              <app-input-coins [coinId]="coinId" (coinChange)="setCoin($event)"></app-input-coins>
            </div>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Currency Exchange</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>Currency Exchange</mat-label>
            <input matInput type="number" formControlName="currencyExchange">
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">IVA</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>IVA</mat-label>
            <input matInput type="number" formControlName="iva">
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Base</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>Base</mat-label>
            <input matInput type="number" formControlName="base">
          </mat-form-field>
        </dd>
      </div>


      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-12/12">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes" placeholder="Ex. This credt note is..."></textarea>
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" *ngIf="total">
        <dt class="text-sm font-medium leading-6 text-gray-900">Total</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <div class="flex flex-grow gap-4">


            <div *ngIf="coinCode === 'EUR'; else totalPound" class="flex gap-2 justify-start">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
                [text]="total.value|currency:'EUR'"></app-badget>
            </div>

            <ng-template #totalPound>
              <div class="flex gap-2 justify-start">
                <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="(total.value * currencyExchange?.value)|currency:'EUR'"></app-badget>
                <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="total.value|currency:'GBP'"></app-badget>
              </div>
            </ng-template>

          </div>
        </dd>
      </div>


    </dl>

  </div>
  <div class="w-full text-end">
    <button type="submit"
      class="inline-flex justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 w-3/12">
      {{ (creditNote) ? 'Update' : 'Generate' }} Credit Note
    </button>
  </div>

</form>

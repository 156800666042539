<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="po">
        <th mat-header-cell *matHeaderCellDef>Po</th>
        <td mat-cell *matCellDef="let row" class="px-2">{{row.po}}</td>
      </ng-container>

      <ng-container matColumnDef="departureDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row" class="px-2">{{row.departureDate|date}}</td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="px-2">{{row.client}}
        </td>
      </ng-container>

      <ng-container matColumnDef="profit">
        <th mat-header-cell *matHeaderCellDef>Profit</th>
        <td
          mat-cell
          *matCellDef="let row"
          class="px-2 font-bold"
          [ngClass]="{'text-green-600': row.profit > 0, 'text-red-700': row.profit < 0}">
          {{row.profit|currency:'EUR'}}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef>Profit</th>
        <td mat-cell *matCellDef="let row" class="px-2">
          <div *ngIf="row.profit > 0; else down">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green"
              class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941" />
            </svg>
          </div>
          <ng-template #down>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red"
              class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181" />
            </svg>

          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="px-2 w-12">
          <button mat-button (click)="showProfitPo(row.po)"
            class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-green-600 object-cover ring-1 ring-green-950/10 hover:bg-green-400 transition-colors duration-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
              class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

          </button>
        </td>
      </ng-container>


      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Sales found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select sales po search results"></mat-paginator>
</div>

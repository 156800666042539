import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jcv',
  templateUrl: './jcv.component.html',
  styleUrls: ['./jcv.component.css']
})
export class JcvComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'JCV'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Update Client
      <small class="block font-light text-xs text-slate-500 w-full"> Update general info of client.</small>
    </h2>


  </div>

  <!-- Form -->

  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 lg:px-8">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="General">
          <ng-template matTabContent>
            <app-frm-client (sendData)="updateClient($event)" [client]="client" *ngIf="client"></app-frm-client>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label><mat-icon svgIcon="contacts"></mat-icon></ng-template>
          <ng-template matTabContent>
            <app-contacts-clients></app-contacts-clients>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label><mat-icon svgIcon="logistic"></mat-icon></ng-template>
          <ng-template matTabContent>
            <app-logistic-clients></app-logistic-clients>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </div>
  </div>


</main>

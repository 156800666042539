import { Component, OnInit } from '@angular/core';
import { OrderFilter } from '../../models/order-filter';

@Component({
  selector: 'app-order-home',
  templateUrl: './order-home.component.html',
  styleUrls: ['./order-home.component.css']
})
export class OrderHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Orders'},
  ]

  public searched: OrderFilter = { search: '' }


  constructor() { }

  ngOnInit(): void {
  }

  search = (data: OrderFilter) => this.searched = data

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceHomeComponent } from './pages/invoice-home/invoice-home.component';
import { InvoiceCreateComponent } from './pages/invoice-create/invoice-create.component';
import { InvoiceEditComponent } from './pages/invoice-edit/invoice-edit.component';
import { InvoiceTableComponent } from './components/invoice-table/invoice-table.component';
import { InvoiceFrmComponent } from './components/invoice-frm/invoice-frm.component';
import { InvoiceFilterComponent } from './components/invoice-filter/invoice-filter.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { OrderItemsTableComponent } from './components/order-items-table/order-items-table.component';
import { SecondlineItemsTableComponent } from './components/secondline-items-table/secondline-items-table.component';
import { InvoiceItemsTableComponent } from './components/invoice-items-table/invoice-items-table.component';
import { InvoiceProductsComponent } from './components/invoice-products/invoice-products.component';
import { InvoiceItemFrmComponent } from './components/invoice-item-frm/invoice-item-frm.component';
import { OrderInvoicesComponent } from './pages/order-invoices/order-invoices.component';
import { OrdersTableInvoiceComponent } from './components/orders-table-invoice/orders-table-invoice.component';
import { ModalInvoiceOrdersComponent } from './components/modal-invoice-orders/modal-invoice-orders.component';
import { OrderInvoiceFilterComponent } from './components/order-invoice-filter/order-invoice-filter.component';




@NgModule({
  declarations: [
    InvoiceHomeComponent,
    InvoiceCreateComponent,
    InvoiceEditComponent,
    InvoiceTableComponent,
    InvoiceFrmComponent,
    InvoiceFilterComponent,
    OrderItemsTableComponent,
    SecondlineItemsTableComponent,
    InvoiceItemsTableComponent,
    InvoiceProductsComponent,
    InvoiceItemFrmComponent,
    OrderInvoicesComponent,
    OrdersTableInvoiceComponent,
    ModalInvoiceOrdersComponent,
    OrderInvoiceFilterComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class BillingModule { }

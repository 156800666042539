import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Product, Products } from 'src/app/features/admin/models/products';
import { ProductsService } from 'src/app/features/admin/services/products.service';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-input-products',
  templateUrl: './input-products.component.html',
  styleUrls: ['./input-products.component.css']
})
export class InputProductsComponent implements OnInit {

  product = new FormControl('', Validators.required)

  products: Products[] = []
  productsFilter?: Product[]
  productSelected?: any

  @Input() productId?: number
  @Input() productName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private ps: ProductsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['productId']?.currentValue){
      this.product.setValue(changes['productId']?.currentValue)
      this.productSelected = changes['productName']?.currentValue
    }

  }

  private _listener():void{
    this.product.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.ps.searchProduct(resp).subscribe({
            next: (r) => {
              this.productsFilter = r.results
            }
          })
        }
      }
    })
  }


  showProduct = (valor: any): string => {
    this.productsFilter?.filter( (m) => {
      if(m.id === valor){
        this.productSelected = `${m.name} ${m.description} ${m.size.size} | Cat.: ${m.category}`
      }
    })
    return this.productSelected;
  }

  clean():void{
    this.product.reset()
    this.productSelected = null
    this.productsFilter = []
    this.onSearch.emit('')
  }

  changeProduct = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)



}

<div class="p-4">
<h3 class="!my-2 !text-base !font-semibold !text-green-900"> Generate Invoices</h3>

<form [formGroup]="frm" (ngSubmit)="invoice()">

  <app-input-bank-account (accountChange)="selectAccount($event)"></app-input-bank-account>

  <app-input-coins (coinChange)="selectCoin($event)" ></app-input-coins>

  <mat-form-field appearance="outline">
    <mat-label>Exchange</mat-label>
    <input matInput type="number" formControlName="currencyExchange">

  </mat-form-field>

  <h3 class="!my-4 !text-sm !font-semibold !text-green-900"> Payer</h3>

  <mat-radio-group aria-label="Select an option" formControlName="payer" class="flex flex-col gap-7 text-lg">
    <mat-radio-button value="C" class="bg-slate-50 rounded-sm p-2">Client</mat-radio-button>
    <mat-radio-button value="S" class="bg-slate-50 rounded-sm p-2">Supplier</mat-radio-button>
  </mat-radio-group>

  <h3 class="!my-4 !text-sm !font-semibold !text-green-900"> Type of Invoice</h3>

  <mat-radio-group aria-label="Select an option" formControlName="invoice_type" class="flex flex-col gap-7 mb-4 text-lg">
    <mat-radio-button value="ORDER" class="bg-zinc-50 rounded-sm p-2">Commission</mat-radio-button>
    <mat-radio-button value="SALES" class="bg-zinc-50 rounded-sm p-2">Sale</mat-radio-button>
  </mat-radio-group>


<button mat-flat-button class="bg-green-700 text-white w-full" type="submit" [disabled]="frm.invalid"> Invoice </button>

</form>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from '../../../services/products.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Product } from '../../../models/products';

@Component({
  selector: 'app-products-create',
  templateUrl: './products-create.component.html',
  styleUrls: ['./products-create.component.css']
})
export class ProductsCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Products', url: '/dash/admin/products'},
    {name: 'New Product'},
  ]

  constructor(
    private rt: Router,
    private ps: ProductsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createProduct(product: Product): void {
    this.ps.addProduct(product).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/products'])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating product','snack-error')
      }
    })
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListOrders, Order } from 'src/app/features/order/models/list-orders';
import { ProductOrder } from 'src/app/features/order/models/products-order';
import { OrderService } from 'src/app/features/order/services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';


interface OrderSelected {
  id: number
  currencyExchange: number
}

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css']
})
export class OrderListComponent implements OnInit {

  orders: Order[] = []
  selectedOrder?: Order
  currencyExchangeSelected?: number
  products: ProductOrder[] = []

  isOpenResumen = false

  followOptions = {
    hasPdfGenerated: false,
    hasFixedCost: false,
    hasLogistic: false
  }

  isReload = false

  @Input() preorder?: number
  @Input() typePreorder?: string


  constructor(
    private ar: ActivatedRoute,
    private os: OrderService,
    private ss: SnackbarsService
  ) {
  }

  ngOnInit(): void {
    this.getOrders()
  }

  getOrders(){
    this.os.searchOrders({preorder: this.preorder! }).subscribe({
      next: (resp) => {
        this.orders  = resp.results
        this._updateFollowOptions(resp)
      },
      error: (err) => this.ss.nuevaSnack('Error loading orders', 'snack-error')
    })
  }

  reload = (e: boolean) => {
    if(e){
      this.getOrders()
      if (this.selectedOrder) this.loadProductsOrder(this.selectedOrder!)
      this.isReload =  true
    }

    this.isReload =  false
    return
  }

  _updateFollowOptions(orders: ListOrders): void{
    this.followOptions = {
      hasPdfGenerated: !orders.results.map((order) => order.preorder.hasPdfGenerated).includes(false),
      hasFixedCost: !orders.results.map((order) => order.preorder.hasFixedCosts).includes(false),
      hasLogistic: !orders.results.map((order) => order.hasLogistic).includes(false)
    }

  }


  loadProductsOrder(order: Order){
    this.selectedOrder = order
    this.currencyExchangeSelected = order.currencyChange



    this.os.searchProductsOrder({order: order.id, limit: 100}).subscribe({
      next: (products) => this.products = products.results,
      error: (e) => this.ss.nuevaSnack('Error loading products', 'snack-error')
    })

  }

}

import { AfterViewInit, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CreditNoteFilter } from '../../models/credit-filter';
import { CreditNoteService } from '../../services/credit-note.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-credit-table',
  templateUrl: './credit-table.component.html',
  styleUrls: ['./credit-table.component.css']
})
export class CreditTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['code', 'total', 'actions'];
  secondLines?: any | null;
  data: any[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  isGeneratingPdf = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: CreditNoteFilter = { search: ''}

  constructor(
    private rt: Router,
    private cs: CreditNoteService,
    private ss: SnackbarsService,
  ) { }

  ngAfterViewInit() {
    this._getOrder()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrder()
    }
  }

  private _getOrder(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cs.searchCreditNotes(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  edit = (id: number) => this.rt.navigateByUrl(`/dash/credit-notes/edit/${id}`)


  generatePdf(invoice: number){
    this.isGeneratingPdf = true
    this.cs.generatePdf(invoice).subscribe({
      next: (data) => {
        this.ss.nuevaSnack('Credit Note generate successfully', 'snack-success')
        this.isGeneratingPdf = false
      },
      error: (e) => {
        this.ss.nuevaSnack('Error to generate Credit Note PDF', 'snack-error')
        this.isGeneratingPdf = false
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-home',
  templateUrl: './reports-home.component.html',
  styleUrls: ['./reports-home.component.css']
})
export class ReportsHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports'},
  ]

  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  goto = (url:string) => this.rt.navigateByUrl(url)

}

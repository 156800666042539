<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      New Intrastat code
      <small class="block font-light text-xs text-slate-500 w-full"> Add info to create a new intrastat code.</small>
    </h2>


  </div>

  <!-- Form -->
  <app-frm-intrastat (sendData)="createIntrastat($event)"></app-frm-intrastat>
</main>

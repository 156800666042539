import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { OrderFilter } from '../../models/order-filter';

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.css']
})
export class OrderFilterComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    search: [''],
    client: [],
    isInvoiced: [],
    status: [],
    supplier: [],
    hasLogistic: [],
    departureDate_after: [],
    departureDate_before: [],
    preType: [],
    plate: [],
    invoice: [],
  })

  @Output() onSearch: EventEmitter<OrderFilter> = new EventEmitter<OrderFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  updateClient = (client: string) => this.frm?.patchValue({ client })
  updateSupplier = (supplier: string) => this.frm?.patchValue({ supplier })

  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
      departureDate_after: (this.frm.value.departureDate_after) ? moment(this.frm.value.departureDate_after).format('YYYY-MM-DD') : '',
      departureDate_before: (this.frm.value.departureDate_before) ? moment(this.frm.value.departureDate_before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Partner } from 'src/app/features/admin/models/partner';
import { PartnerService } from 'src/app/features/admin/services/partner.service';

@Component({
  selector: 'app-input-select-partner',
  templateUrl: './input-select-partner.component.html',
  styleUrls: ['./input-select-partner.component.css']
})
export class InputSelectPartnerComponent implements OnInit {

  partners = new FormControl('')

  @Input() partnerIds?: any
  @Output() partnerChange = new EventEmitter<any>()

  partnerList:Partner[] = []

  constructor(
    private ps: PartnerService
  ) { }

  ngOnInit(): void {
    this._loadPartners()

    if (this.partnerIds) this.partners.setValue(this.partnerIds)

    this.partners.valueChanges.subscribe( resp => (resp) ? this.partnerChange.emit(resp) : null)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['partnerIds']?.currentValue) this.partners.setValue(changes['partnerIds']?.currentValue)
  }


  private _loadPartners() {
    this.ps.getPartners().subscribe( resp => this.partnerList = resp.results)
  }

  onCoinChange() {

    this.partnerChange.emit()
  }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Order } from 'src/app/features/order/models/list-orders';

@Component({
  selector: 'app-order-card-list',
  templateUrl: './order-card-list.component.html',
  styleUrls: ['./order-card-list.component.css']
})
export class OrderCardListComponent implements OnInit {

  totals = {
    total: 0,
    commission: 0,
    totalPound: 0,
    commissionPound: 0,
  }


  @Input() orders: Order[] = []
  @Input() orderActive?: number
  @Input() isReload = false
  @Output() selectedOrder = new EventEmitter<Order>()
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['orders'] && changes['orders'].currentValue.length > 0) {
      this.calculateTotals(changes['orders'].currentValue)
    }


    // if (changes['isReload'].currentValue) {
    //   this.calculateTotals(this.orders)
    // }

  }

  ngOnInit(): void {
  }

  calculateTotals(orders: Order[]){
    this.totals = {
      total: 0,
      commission: 0,
      totalPound: 0,
      commissionPound: 0,
    }

    orders.forEach(order => {
      this.totals.total += parseFloat(order.total)
      this.totals.commission += parseFloat(order.totalCommission)
      this.totals.totalPound += parseFloat(order.total) / order.currencyChange
      this.totals.commissionPound += parseFloat(order.totalCommission) / order.currencyChange
    })
  }

  selectOrder = (order: Order) => this.selectedOrder.emit(order)


}

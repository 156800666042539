import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientsService } from 'src/app/features/admin/services/clients.service';
import * as moment from 'moment';
import { type BuySalePreorder } from '../../models/pre-order';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { Router } from '@angular/router';
import { PreorderService } from '../../services/preorder.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-preorder-buy-frm',
  templateUrl: './preorder-buy-frm.component.html',
  styleUrls: ['./preorder-buy-frm.component.css']
})
export class PreorderBuyFrmComponent implements OnInit {

  public frm: FormGroup = this.fb.group({
    status: ['DRAFT', Validators.required],
    client_id: ['', Validators.required],
    notes: [''],
    departureDate: ['', Validators.required],
    arrivalDate: ['', Validators.required],
    sale_departureDate: ['', Validators.required],
    sale_arrivalDate: ['', Validators.required],
    paymentMethod: ['TRANSFER', Validators.required],
    palletCost: ['0', Validators.required],
    sale_palletCost: ['', Validators.required],
    currencyChange: ['1.18', Validators.required],
  })

  arrivalDays = 3
  @Output() sendData = new EventEmitter<BuySalePreorder>()
  public clientId?: number;
  public clientName?: string;

  constructor(
    private rt: Router,
    private fb: FormBuilder,
    private cs: ClientsService,
    private dialog: MatDialog,
    private pos: PreorderService,
    private ss: SnackbarsService
  ) { }

  ngOnInit(): void {
  }

  setClient = (client: string): void => {
    if (client) this._infoClient(parseInt(client))
    this.frm!.get('client_id')!.setValue(client)
  }

  private _infoClient(client:number) {
    this.cs.getClient(client).subscribe( ({dayDeparture}) => {
      this.arrivalDays = dayDeparture
      if (this.frm?.get('departureDate')?.value){
        this.setArrivalDate(this.frm?.get('departureDate')?.value, 'arrivalDate')
      }

      if (this.frm?.get('sale_departureDate')?.value){
        this.setArrivalDate(this.frm?.get('sale_departureDate')?.value,'sale_arrivalDate')
      }
    })
  }

  setArrivalDate = (event: any, field: string): void => {

    if (event.value){
      const arrival = moment(event.value).add(this.arrivalDays, 'days')
      const arrivalDate = new Date( arrival.year(), arrival.month(), arrival.date())
      this.frm!.patchValue({ [field]:arrivalDate}, {emitEvent: false});
    }
  }


  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      departureDate: moment(this.frm!.value.departureDate).format('YYYY-MM-DD'),
      arrivalDate: moment(this.frm!.value.arrivalDate).format('YYYY-MM-DD'),
      sale_arrivalDate: moment(this.frm!.value.sale_arrivalDate).format('YYYY-MM-DD'),
      sale_departureDate: moment(this.frm!.value.sale_departureDate).format('YYYY-MM-DD'),
    }

    this.sendData.emit(data);
  }
}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Intrastat Code</mat-label>
    <input matInput formControlName="code" placeholder="0000000000">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-8/12">
    <mat-label>Family </mat-label>
    <input matInput formControlName="description" placeholder="Cucumbers">
  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(intrastat) ? 'Updated' : 'Create'}}
      intrastat </button>
  </div>
</form>

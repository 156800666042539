
<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="vatNumber">
        <th mat-header-cell *matHeaderCellDef>VAT</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.vatNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="companyCode">
        <th mat-header-cell *matHeaderCellDef>Company Code</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.companyCode}}</td>
      </ng-container>

      <ng-container matColumnDef="coin">
        <th mat-header-cell *matHeaderCellDef class="w-32">Coin</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <span
            class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-600/20"
            *ngIf="row.coin">{{row.coin.name}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="payLogistic">
        <th mat-header-cell *matHeaderCellDef class="w-48">Pay Logistic</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <span
            class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-red-600/20"
            *ngIf="row.payLogistic">Pay Logistic</span>
          <span
            class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20"
            *ngIf="!row.payLogistic">Don't Pay Logistic</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          ---
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No clients found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select Clients search results"></mat-paginator>
</div>

import { ProductPreorderTableComponent } from './../product-preorder-table/product-preorder-table.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PreorderProductFrmComponent } from '../preorder-product-frm/preorder-product-frm.component';
import { PreorderService } from '../../services/preorder.service';
import { PreorderBaseDetail, ProductPreorderFrm } from '../../models/pre-order';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-preorder-products-list',
  templateUrl: './preorder-products-list.component.html',
  styleUrls: ['./preorder-products-list.component.css']
})
export class PreorderProductsListComponent implements OnInit {

  idPreorder: number = this.ar.snapshot.params['id'];
  preorderBaseDetail?: PreorderBaseDetail;
  filter = {preorder: this.ar.snapshot.params['id']}
  disableGenerate: boolean = false
  disableAddProduct: boolean = false
  textGenerateButton: string = 'Generate Orders'

  @ViewChild(ProductPreorderTableComponent, {static : true}) child?: ProductPreorderTableComponent;

  constructor(
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private pos: PreorderService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getPreorder();
  }


  private _getPreorder() {
    this.pos.getPreoder(this.idPreorder).subscribe({
      next: (resp) => {
        this.disableGenerate = (resp.status === 'GENERATED' || resp.status === 'TEMPLATE') ? true : false;
        this.disableAddProduct = (resp.status === 'GENERATED') ? true : false;

        this.preorderBaseDetail =  {
          id: resp.id,
          client: resp.client,
          palletCost: resp.palletCost,
          currencyChange: resp.currencyChange,
          status: resp.status,
          typeOfPreOrder: resp.typeOfPreOrder
        };
      }
    })
  }

  private _updateProduct(id: number, data:ProductPreorderFrm) {
    this.pos.updateProductToPreorder(id, data).subscribe( r => this.child!.getProducts() )
  }

  private createProduct(data: ProductPreorderFrm) {
    this.pos.addProductToPreorder(data).subscribe( r => this.child!.getProducts() )

  }

  openProductModal(idProductPreorder:any = null) {
    this.dialog.open(PreorderProductFrmComponent, {
      height: '97vh',
      data: {
        idProductPreorder,
        preorder: this.preorderBaseDetail
      }
    }).afterClosed().subscribe( (r:ProductPreorderFrm) => {
      if (r){
        (idProductPreorder) ? this._updateProduct(idProductPreorder, r) : this.createProduct(r)
      }
    });
  }

  generateInitialOrders(){
    this.disableGenerate = true;
    this.disableAddProduct = true;
    this.textGenerateButton = 'Generating...'
    this.pos.generateInitialOrder(this.idPreorder).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack('Orders generated', 'snack-success')
        this.textGenerateButton = 'Orders Generated'
      },
      error: (err) => {
        this.ss.nuevaSnack('Error to generate Orders', 'snack-error')
        this.disableGenerate = false;
        this.disableAddProduct = false;
        this.textGenerateButton = 'Generate Orders'
      }
    });
  }

}

<div class="mx-auto sm:px-6 lg:px-8">
  <div class="space-y-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>

    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Payments List</h3>
      <p class="mt-2 max-w-4xl text-sm text-gray-500">
        Click on each payment to see the details of the payments made.
      </p>
    </div>

    <div>
      <app-payments-table></app-payments-table>
    </div>
  </div>
</div>

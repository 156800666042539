import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../models/list-orders';
import { ProductOrder } from '../../models/products-order';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-products',
  templateUrl: './order-products.component.html',
  styleUrls: ['./order-products.component.css']
})
export class OrderProductsComponent implements OnInit {

  @Input() selectedOrder?: Order;
  currencyExchangeSelected?: number
  products: ProductOrder[] = []
  typePreorder?: string

  constructor(
    private os: OrderService,
    private ss: SnackbarsService
  ) { }

  ngOnInit(): void {
    this.loadProductsOrder()
  }


  reload(e: boolean){
    if(e){
      this.loadProductsOrder()
    }
  }

  loadProductsOrder(){
    this.selectedOrder = this.selectedOrder!
    this.currencyExchangeSelected = this.selectedOrder!.currencyChange
    this.typePreorder = this.selectedOrder!.preorder.typeOfPreOrder


    this.os.searchProductsOrder({order: this.selectedOrder!.id, limit: 100}).subscribe({
      next: (products) => this.products = products.results,
      error: (e) => this.ss.nuevaSnack('Error loading products', 'snack-error')
    })

  }
}

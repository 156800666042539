
<div class="border-b border-white/5 mb-12">
  <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
</div>
<div>
  <h2 class="text-base font-semibold leading-6 text-gray-900">Report's</h2>
  <p class="mt-1 text-sm text-gray-500">You will be able to consult different information, payments to partners, follow-up of pos, certifications of payments. </p>
  <ul role="list" class="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
    <li class="flow-root">
      <div
        class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
        <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-pink-500">
          <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
          </svg>
        </div>
        <div>
          <h3 class="text-sm font-medium text-gray-900">
            <a routerLink="/dash/reports/intrastat" class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <span>Intrastat</span>
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </h3>
          <p class="mt-1 text-sm text-gray-500">View the number of kilos loaded grouped by Intrastat code</p>
        </div>
      </div>
    </li>
    <li class="flow-root">
      <div
        class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
        <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-yellow-500">
          <svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
          </svg>
        </div>
        <div>
          <h3 class="text-sm font-medium text-gray-900">
            <a routerLink="/dash/reports/sale" class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <span>Pos Detail</span>
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </h3>
          <p class="mt-1 text-sm text-gray-500">Detail of costs and benefits per PO.</p>
        </div>
      </div>
    </li>
    <li class="flow-root">
      <div
        class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
        <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-green-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6 text-white">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
          </svg>
        </div>
        <div>
          <h3 class="text-sm font-medium text-gray-900">
            <a routerLink="/dash/reports/profit-pos" class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <span>Pay Partners</span>
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </h3>
          <p class="mt-1 text-sm text-gray-500">From here you can pay POs.</p>
        </div>
      </div>
    </li>
    <li class="flow-root">
      <div
        class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
        <div class="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg bg-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6 text-white">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
          </svg>

        </div>
        <div>
          <h3 class="text-sm font-medium text-gray-900">
            <a routerLink="/dash/reports/payments" class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              <span>Payments</span>
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </h3>
          <p class="mt-1 text-sm text-gray-500">List of payments made to partners.</p>
        </div>
      </div>
    </li>

  </ul>
</div>

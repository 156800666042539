<div class="container mx-auto">

  <div class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
    <div class="sm:col-span-4 bg-white p-2 rounded-md">

      <div class="flex items-start space-x-5">
        <div class="flex-shrink-0">
          <div class="relative">
            <img class="h-16 w-16 rounded-full" [src]="(is_admin) ? 'assets/img/jcv.jpeg' : 'assets/img/avatar.png'" alt="">
            <span class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></span>
          </div>
        </div>

        <div class="pt-1.5">
          <h1 class="!text-3xl !font-extrabold text-gray-900">HI! {{userName}}</h1>
          <p class="text-sm font-medium text-gray-500"><small>{{now}}</small></p>
        </div>
      </div>

      <div class="mt-8">
        <h2 class="text-base font-semibold leading-6 text-gray-900">Quick Access</h2>
        <ul role="list" class="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
          <li class="flow-root" *ngFor="let l of links">
            <div
              class="relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50">
              <div class="flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-lg " [ngClass]="l.color">
                <mat-icon [svgIcon]="l.icon" class="text-white h-6 w-6"></mat-icon>
              </div>
              <div>
                <h3 class="text-sm font-medium text-gray-900">
                  <a [routerLink]="[l.url]" [queryParams]="l.params" class="focus:outline-none">
                    <span class="absolute inset-0" aria-hidden="true"></span>
                    <span>{{l.name}}</span>
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </h3>
                <p class="mt-1 text-sm text-gray-500">{{l.description}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>



    </div>

    <div class="sm:col-span-2 p-4 bg-white">
      <div class="mt-4" *ngIf="is_admin">
        <h3 class="!text-base !font-semibold !leading-6 !text-gray-900">Last 90 days</h3>
        <dl class="mt-5 grid grid-cols-1 gap-5">

          <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt class="truncate text-sm font-medium text-gray-500">Commission Invoiced</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{homeReport.commission|currency:'EUR'}}</dd>
          </div>

          <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt class="truncate text-sm font-medium text-gray-500">Sales Invoiced</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{homeReport.sales|currency:'EUR'}}</dd>
          </div>

          <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt class="truncate text-sm font-medium text-gray-500">Purchases</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{homeReport.purchases|currency:'EUR'}}</dd>
          </div>

          <div class="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt class="truncate text-sm font-medium text-gray-500">Second Line Invoiced</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{homeReport.second_line|currency:'EUR'}}
            </dd>
          </div>
        </dl>

      </div>

    </div>
  </div>


</div>

import { Component, Input, SimpleChanges, ViewChild, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Cost, CostResponse } from '../../models/cost-response';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CostsService } from '../../services/costs.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { Order } from 'src/app/features/order/models/list-orders';
import { OrderFilter } from 'src/app/features/order/models/order-filter';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-costs-table',
  templateUrl: './costs-table.component.html',
  styleUrls: ['./costs-table.component.css']
})
export class CostsTableComponent implements AfterViewInit {

  displayColumns: string[] = ['date', 'concept', 'quantity', 'price', 'total', 'actions'];
  orders?: CostResponse | null;
  data: Cost[] = [];

  resultsLength = 0;
  isLoading = true;
  isRateLimitReached = false;



  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: OrderFilter = { search: ''}
  @Output() editCost = new EventEmitter<Cost>();

  selection = new SelectionModel<Order>(true, [])

  constructor(
    private dialog: MatDialog,
    private cs: CostsService,
  ) { }

  ngAfterViewInit() {
    this.getCosts()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this.getCosts()
    }
  }

  getCosts(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          return this.cs.searchCosts(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoading = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }



  edit = (cost: Cost) => this.editCost.emit(cost)

  deleteCost(id: number): void {
    this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete Order Cost',
        messge: 'Are you sure to delete this cost?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    }).afterClosed().subscribe( res => {
      if (res) {
        this.cs.deleteCost(id).subscribe( () => this.getCosts() )
      }
    })
  }

}

<div class="p-4">
<form [formGroup]="frm!" (ngSubmit)="send()">

  <mat-form-field appearance="outline">
    <mat-label>Title</mat-label>
    <input matInput type="text" formControlName="title" placeholder="Title">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>To: </mat-label>
    <mat-select formControlName="to">
      <mat-option value="CLIENT"> <span [ngClass]="{'text-sky-500': isClientNotified}">Send to client</span></mat-option>
      <mat-option value="DELIVERY"> <span [ngClass]="{'text-sky-500': isDeliveredNotified}">Send to Logistic delivery</span></mat-option>
      <mat-option value="SUPPLIERS"> <span [ngClass]="{'text-sky-500': isSuppliersNotified}">Send to Suppliers</span></mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field style="width: 100%;" appearance="outline">
    <mat-label>CC:</mat-label>
    <input matInput type="text" formControlName="cc" placeholder="CC">
    <mat-hint> Separate by commas the different emails</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>CCO</mat-label>
    <input matInput type="text" formControlName="cco" placeholder="CCO">
  </mat-form-field>

  <div>
    <angular-editor formControlName="body" [config]="config"></angular-editor>
  </div>

  <div class="rounded-md bg-green-50 p-2 mt-5" *ngIf="sent">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
            clip-rule="evenodd" />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium text-green-800 !m-0">Email sent successfully </p>
      </div>

    </div>
  </div>

  <div class="ta-end mt-3">
    <button mat-raised-button color="primary" *ngIf="!isSending; else sending">Send Emails</button>
    <ng-template #sending>
      <mat-spinner diameter="30" color="primary"></mat-spinner>
    </ng-template>
  </div>
</form>
</div>

import { Component, OnInit } from '@angular/core';
import { Intrastat } from '../../../models/intrastat';
import { IntrastatService } from '../../../services/intrastat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Client } from '../../../models/clients-response';

@Component({
  selector: 'app-instrastat-edit',
  templateUrl: './instrastat-edit.component.html',
  styleUrls: ['./instrastat-edit.component.css']
})
export class InstrastatEditComponent implements OnInit {

  idIntrastat = this.ar.snapshot.params['id']
  intrastat?: Intrastat

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Intrastats', url: '/dash/admin/intrastat'},
    {name: 'Update Intrastat'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private its: IntrastatService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getIntrastat(this.idIntrastat)
  }

  private _getIntrastat(intrastatId: number): void {
    this.its.getIntrastat(intrastatId).subscribe({
      next: (resp) => {
        this.intrastat = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting intrastat code','snack-error')
        this.rt.navigate(['/dash/admin/intrastat'])
      }
    })
  }


  updateIntrastat(intrastat: Intrastat): void {
    this.its.updateIntrastat(this.idIntrastat, intrastat).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Instrastat code ${resp.code} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating intrastat','snack-error')
      }
    })
  }

}

<!-- <mat-form-field appearance="outline">
  <mat-label>Product</mat-label>
  <mat-select formControlName="product">
    <mat-option [value]="p.id" *ngFor="let p of products">{{p.product.name}}</mat-option>
  </mat-select>
</mat-form-field> -->


<div class="transform overflow-hidden bg-white text-left p-4 transition-all">
  <div class="sm:flex sm:items-start">
    <div
      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
      </svg>
    </div>
    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
      <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">{{data.title}}</h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">{{data.message}}</p>
      </div>
      <div class="mt-2">
        <p> Write this <span class="text-red-700 font-semibold">{{data.phrase}}</span> to delete product</p>
      </div>
      <div class="mt-2">
        <mat-form-field appearance="outline">
          <mat-label>Phrase</mat-label>
          <input matInput [formControl]="phrase" type="text" placeholder="Write the correct phrese to delete product">
        </mat-form-field>
      </div>
    </div>


  </div>
  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

    <button type="button"
      class="mt-3 inline-flex mx-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      (click)="modal.close()">{{data.cancel}}</button>
    <button type="button"
      mat-raised-button
      [disabled]="phrase.value !== data.phrase"
      class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
      (click)="modal.close(true)">{{data.accept}}</button>
  </div>
</div>

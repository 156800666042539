<div>

<ul role="list" class="divide-y divide-gray-100">

  <li class="flex flex-wrap flex-col my-3 bg-white p-4" *ngFor="let product of products">
    <div class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap">
    <div>
      <p class="text-sm font-semibold leading-6 text-gray-900" (click)="editProductOrder(product)">
        <span class="hover:underline hover:cursor-pointer">{{product.product.name}} {{product.product.size.size}} {{product.product.description}}</span>
      </p>
      <div class="mt-1 flex items-center gap-x-2 text-sm leading-5 text-slate-900">
        <p>
          <span class="font-bold me-4 capitalize">
            {{product.payLogistic }} Pay Logistic
          </span>
        </p>
      </div>

      <div class="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">

            <app-badget
              [text]="product.pallets.toString() + ' Pallets'"
              [subtitle]="(product.isMixed) ? 'Mixed' : ''"
              class="bg-teal-50 text-teal-800 ring-teal-600/10 !text-sm"
              >
            </app-badget>
            <app-badget
              [text]="product.totalBoxes.toString()"
              subtitle="Boxes"
              class="bg-sky-50 text-sky-800 ring-sky-600/10 !text-sm"
            ></app-badget>

      </div>
    </div>

    <dd>
      <button mat-icon-button aria-label="Delete product" (click)="deleteProductOrder(product.id, product.product.name)" [disabled]="typePreorder === 'SELL'">
        <mat-icon
          svgIcon="delete"
          [ngClass]="{'text-slate-700': typePreorder === 'SELL', 'text-red-700': typePreorder !== 'SELL'}">
        </mat-icon>
      </button>
    </dd>
    </div>
    <div class="relative my-4">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <div class="flex flex-row justify-between text-sm">
      <div>
        <span class="font-bold me-4">Delivery Price</span>


        <div *ngIf="product.coin.code === 'EUR'; else deliveryPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="product.deliveryPrice|currency:'EUR'"> </app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="product.deliveryPrice|changeToPound:currencyExchange|currency:'GBP'"> </app-badget>
        </div>
        <ng-template #deliveryPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="product.deliveryPrice|changeToEuro:currencyExchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="product.deliveryPrice|currency:'GBP'"> </app-badget>
          </div>
        </ng-template>



      </div>
      <div>
        <span class="font-bold me-4">Box Price</span>

        <div *ngIf="product.coin.code === 'EUR'; else deliveryPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="product.boxCost|currency:'EUR'">
          </app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="product.boxCost|changeToPound:currencyExchange|currency:'GBP'"> </app-badget>
        </div>
        <ng-template #deliveryPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="product.boxCost|changeToEuro:currencyExchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="product.boxCost|currency:'GBP'">
            </app-badget>
          </div>
        </ng-template>


      </div>
      <div>
        <span class="font-bold me-4">Total</span>

        <div *ngIf="product.coin.code === 'EUR'; else totalPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="product.total|currency:'EUR'">
          </app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="product.total|changeToPound:currencyExchange|currency:'GBP'"> </app-badget>
        </div>
        <ng-template #totalPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="product.total|changeToEuro:currencyExchange|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="product.total|currency:'GBP'">
            </app-badget>
          </div>
        </ng-template>


      </div>
    </div>
  </li>


</ul>

</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.first_name}}</td>
      </ng-container>

      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef>Group</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <span class="inline-flex items-center gap-x-1.5 rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-700" *ngFor="let g of row.groups_list">
            {{g.name}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Partner" (click)="deleteUser(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No users found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select users search results"></mat-paginator>
</div>

import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { DeliveryNoteRes, DeliveryNotesRes } from '../../models/delivery-notes-res';
import { DeliveryService } from '../../services/delivery.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { OrderFilter } from 'src/app/features/order/models/order-filter';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-delivery-table',
  templateUrl: './delivery-table.component.html',
  styleUrls: ['./delivery-table.component.css']
})
export class DeliveryTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['code', 'po', 'client', 'supplier', 'actions'];
  orders?: DeliveryNotesRes | null;
  data: DeliveryNoteRes[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  isGeneratingPdf = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: OrderFilter = { search: ''}


  constructor(
    private rt: Router,
    private ds: DeliveryService,
    private ss: SnackbarsService,
  ) { }

  ngAfterViewInit() {
    this._getOrder()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrder()
    }
  }

  private _getOrder(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.ds.searchDeliveryNotes(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.rt.navigateByUrl(`/dash/delivery/edit/${id}`)


  generatePdf(id: number, type_order: string){
    this.isGeneratingPdf = true;
    this.ds.generatePdf(id, type_order).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('PDF generated successfully','snack-success')
        this.isGeneratingPdf = false
      },
      error: (err) => {
        this.ss.nuevaSnack('Error to generate PDF, check Incoterms and delivery information','snack-error')
        this.isGeneratingPdf = false
      }
    })
  }

  generateTransportRoadPdf(id: number, ){
    this.isGeneratingPdf = true;
    this.ds.generateTransportRoadPdf(id).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('PDF generated successfully','snack-success')
        this.isGeneratingPdf = false
      },
      error: (err) => {
        this.ss.nuevaSnack('Error to generate PDF','snack-error')
        this.isGeneratingPdf = false
      }
    })
  }


}

import { Injectable } from '@angular/core';
import { DeliveryNotesRes } from '../models/delivery-notes-res';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DeliveryFilter } from '../models/delivery-filter';
import { DeliveryDetail } from '../models/delivery-detail';
import { DeliveryFrm } from '../models/delivery-frm';
import { DataLoadDeliveryFrm } from '../models/data-load-delivery-frm';


const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private http: HttpClient
  ) { }


  searchDeliveryNotes(filters: DeliveryFilter, page:number = 1): Observable<DeliveryNotesRes>{
    const params = new HttpParams({
      fromObject: {
      'page': page.toString(),
      'search':  filters.search || '',
      'page_size':  filters.page_size || 20
      }
    })

    return this.http.get<DeliveryNotesRes>(`${baseUrl}/delivery/search`, {params});
  }

  getDeliveryNote(id: number): Observable<DeliveryDetail>{
    return this.http.get<DeliveryDetail>(`${baseUrl}/delivery/${id}`);
  }

  generateDeliveryNote(order: number): Observable<any>{
    return this.http.get<any>(`${baseUrl}/delivery/generate/${order}`);
  }

  updateDelivery(id: number, data:DeliveryFrm): Observable<DeliveryDetail> {
    return this.http.patch<DeliveryDetail>(`${baseUrl}/delivery/${id}/`, data)
  }

  generatePdf(id: number, type_doc: string): Observable<boolean> {
    return this.http.get<boolean>(`${baseUrl}/delivery/pdf/${id}?t=${type_doc}`)
  }

  generateTransportRoadPdf(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${baseUrl}/control-transport-road/pdf/${id}`)
  }


  changeDataLoads(id: number, data:DataLoadDeliveryFrm): Observable<boolean> {
    return this.http.post<boolean>(`${baseUrl}/delivery/data-load/${id}`, data)
  }

}

import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ModalUpdatePlateComponent } from 'src/app/features/logistic/components/modal-update-plate/modal-update-plate.component';
import { LogisticOrder } from 'src/app/features/logistic/models/logistic-list-response';
import { LogisticService } from 'src/app/features/logistic/services/logistic.service';
import { EmailLogisticModalComponent } from 'src/app/shared/components/email-logistic-modal/email-logistic-modal.component';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-order-logistic',
  templateUrl: './order-logistic.component.html',
  styleUrls: ['./order-logistic.component.css']
})
export class OrderLogisticComponent implements OnInit {

  order = this.ar.snapshot.params['id']
  logisticOrders: LogisticOrder[] = []

  constructor(
    private ar: ActivatedRoute,
    private dp: DatePipe,
    private dialog: MatDialog,
    private ls: LogisticService,
    private ss: SnackbarsService
  ) { }

  ngOnInit(): void {
    this._loadLogisticOrders()
  }

  private _loadLogisticOrders(): void {

    this.ls.logisticOrderByOrder(this.order).subscribe({
      next: (resp) => this.logisticOrders = resp,
      error: (e) => this.ss.nuevaSnack('Error to load logistic information', 'snack-error')
    })
  }


  updatePlate(id: number){
    this.dialog.open(ModalUpdatePlateComponent).afterClosed().subscribe({
      next: (r) => {
        this.ls.updateLogistic(id, r).subscribe({
          next: (resp) => this._loadLogisticOrders(),
          error: (e) => this.ss.nuevaSnack('Error to assign plate', 'snack-error')
        })

      }
    })
  }

  sendEmails(logistic: LogisticOrder){
    this.dialog.open(EmailLogisticModalComponent, {
      data: {
        id: logistic.id,
        title: `🚛 Logistic Order: ${logistic.code} - ${this.dp.transform(logistic.preorder.departureDate,'dd-MM-yyyy')} - ${logistic.preorder.client.name}`,
        isClientNotified: logistic.isClientNotified,
        isSuppliersNotified: logistic.isSuppliersNotified,
        isDeliveredNotified: logistic.isDeliveredNotified
      }
    }).afterClosed().subscribe( r => {
      console.log(r);
    })
  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">
  <div class="mt-6 border-t border-gray-100 w-full">
    <dl class="divide-y divide-gray-100">

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" *ngIf="invoice">
        <dt class="text-sm font-medium leading-6 text-gray-900">Invoice number</dt>
        <dd class="mt-1 flex text-base leading-6 font-bold text-gray-700 sm:col-span-2 sm:mt-0">
          {{invoice.invoiceNumber}}
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Payer</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 gap-2">

          <div class="w-full">
            <app-input-suppliers [supplierId]="supplierId" [supplierName]="supplierName" (onSelect)="setSupplierPayer($event)"></app-input-suppliers>

            <app-input-clients [clientId]="clientId" [clientName]="clientName" (onSelect)="setClientPayer($event)"></app-input-clients>
          </div>

        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Invoice Date</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-6/12">
            <mat-label>Invoice Date</mat-label>
            <input matInput [matDatepicker]="dateInvoice" formControlName="date">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dateInvoice"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dateInvoice></mat-datepicker>
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Pay Date</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-6/12">
            <mat-label>Pay Date</mat-label>
            <input matInput [matDatepicker]="payDateInvoice" formControlName="payDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="payDateInvoice"></mat-datepicker-toggle>
            <mat-datepicker touchUi #payDateInvoice></mat-datepicker>
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Coins</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <app-input-coins [coinId]="coinId" (coinChange)="setCoin($event)"></app-input-coins>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Currency Exchange</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>Currency Exchange</mat-label>
            <input matInput type="number" formControlName="currencyExchange">
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Status</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-5/12">
            <mat-label>Is Payed</mat-label>
            <mat-select formControlName="isPayed">
              <mat-option value="True">Payed</mat-option>
              <mat-option value="False">No Payed</mat-option>
            </mat-select>
          </mat-form-field>
        </dd>
      </div>


      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Bank Account</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <app-input-bank-account [accountId]="bankId" (accountChange)="setBanckAccount($event)" class="w-full"></app-input-bank-account>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <mat-form-field appearance="outline" class="w-12/12">
            <mat-label>Notes</mat-label>
            <textarea matInput formControlName="notes" placeholder="Ex. This invoice is..."></textarea>
          </mat-form-field>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" *ngIf="invoice">
        <dt class="text-sm font-medium leading-6 text-gray-900">Total</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <div class="flex flex-grow gap-4">


            <div *ngIf="coinCode === 'EUR'; else totalPound" class="flex gap-2 justify-start">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="invoice.total|currency:'EUR'"></app-badget>
            </div>

            <ng-template #totalPound>
              <div class="flex gap-2 justify-start">
                <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
                  [text]="invoice.total|currency:'EUR'"> </app-badget>
                <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="invoice.totalPound|currency:'GBP'"></app-badget>
              </div>
            </ng-template>

          </div>
        </dd>
      </div>

      <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0" *ngIf="invoice">
        <dt class="text-sm font-medium leading-6 text-gray-900">Discount</dt>
        <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
          <div class="flex flex-grow gap-4">
            <div class="flex gap-2 justify-start">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="invoice.discount|currency:'EUR'"></app-badget>
            </div>
          </div>
        </dd>
      </div>

    </dl>

  </div>
  <div class="w-full text-end">
    <button type="submit"
      class="inline-flex justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 w-3/12">
      {{ (invoice) ? 'Update' : 'Generate' }} Invoice
    </button>
  </div>

</form>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogisticTracking } from '../../models/logistic-tracking';
import { LogisticService } from '../../services/logistic.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { MatDialog } from '@angular/material/dialog';
import { UpdateLogisticLoadFrmComponent } from '../update-logistic-load-frm/update-logistic-load-frm.component';
import { ModalMoveLoadComponent } from '../modal-move-load/modal-move-load.component';
@Component({
  selector: 'app-logistic-track-detail',
  templateUrl: './logistic-track-detail.component.html',
  styleUrls: ['./logistic-track-detail.component.css']
})
export class LogisticTrackDetailComponent implements OnInit {

  @Input() trackDetail?: LogisticTracking
  @Output() onRefresh = new EventEmitter<{refresh:boolean, id:number}>()

  public generating  = false
  constructor(
    private dialog: MatDialog,
    private ls: LogisticService,
    private ss: SnackbarsService
  ) { }


  ngOnInit(): void {
  }

  generatePDF(id: number){
    this.generating = true
    this.ls.generateSinglePdfLogistic(id).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('Logistic PDF have been generated', 'snack-success')
        this.generating = false
        this.onRefresh.emit({refresh: true, id: id})
      },
      error: (e) => {
        this.generating = false
        this.ss.nuevaSnack('Error to generate pdf', 'snack-error')
      }
    })
  }

  editLogisticLoad(id: number) {
    this.dialog.open(UpdateLogisticLoadFrmComponent, {
      width: '40%',
      data: {
        id: id
      }
    }).afterClosed().subscribe( (resp: {mixedGroup: number}) => {
      if (!resp) return

      this.ls.updateLogisticLoad(id, resp).subscribe({
        next: (r) => {
          this.onRefresh.emit({refresh: true, id: this.trackDetail!.id})
          this.ss.nuevaSnack('Logistic load updated. Don`t forget to generate PDF', 'snack-success')
        },
        error: (e) => console.log(e)

      });


    })
  }

  moveLoad(load: number, order: number) {
    this.dialog.open(ModalMoveLoadComponent, {
      data: {
        load: load,
        order: order,
      }
    }).afterClosed().subscribe( r => {
      if (r) {
        this.ls.moveLoad(r).subscribe({
          next: (r) => {
            this.ss.nuevaSnack('Load moved, Generate new Logistic PDF', 'snack-success')
            this.onRefresh.emit({refresh: true, id: this.trackDetail!.id})
          },
          error: (e) => console.log(e)
        })

      }
    })
  }

}

import { Injectable } from '@angular/core';
import { ExceptionClientPrice, ExceptionsPriceClient, PricesByClient, Product, ProductTranslateReq, ProductTranslation, Products, ProductsTranslations } from '../models/products';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor( private http: HttpClient) { }


  getAll(): Observable<Products> {
    return this.http.get<Products>(`${baseUrl}/products`);
  }

  getProduct(id: number, order: string = ''): Observable<Product>{
    return this.http.get<Product>(`${baseUrl}/products/${id}/`, { params: { order } });
  }

  addProduct(product: Product): Observable<Product>{
    return this.http.post<Product>(`${baseUrl}/products/`, product);
  }

  updateProduct(id: number, product: Product): Observable<Product>{
    return this.http.put<Product>(`${baseUrl}/products/${id}/`, product);
  }

  deleteProduct(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/products/${id}/`);
  }

  searchProduct(search?: string, page=1): Observable<Products>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<Products>(`${baseUrl}/search-products`, {params})
  }


  searchTranslationsProduct(product: string):Observable<ProductsTranslations>{
    const params = new HttpParams().set('product', product)
    return this.http.get<ProductsTranslations>(`${baseUrl}/product-translate/search`, {params})
  }



  getTranslateProduct(id: string): Observable<ProductTranslation>{
    return this.http.get<ProductTranslation>(`${baseUrl}/product-translation/${id}`);
  }

  addTranslateProduct(translate: ProductTranslateReq): Observable<ProductTranslation>{
    return this.http.post<ProductTranslation>(`${baseUrl}/product-translation/`, translate);
  }

  updateTranslateProduct(id: string, translate: ProductTranslateReq): Observable<ProductTranslation>{
    return this.http.put<ProductTranslation>(`${baseUrl}/product-translation/${id}/`, translate);
  }

  deleteTranslateProduct(id: string): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/product-translation/${id}`);
  }


  listPricesByClient(id: number): Observable<PricesByClient>{
    return this.http.get<PricesByClient>(`${baseUrl}/exception-price-product/?p=${id}`);
  }

  getRulePriceByClient(id: any): Observable<ExceptionClientPrice>{
    return this.http.get<ExceptionClientPrice>(`${baseUrl}/prices-by-client/${id}`);
  }

  addRulePriceByClient(rule: any): Observable<ExceptionClientPrice>{
    return this.http.post<ExceptionClientPrice>(`${baseUrl}/prices-by-client/`, rule);
  }

  updateRulePriceByClient(rule: any, id: any): Observable<ExceptionClientPrice>{
    return this.http.put<ExceptionClientPrice>(`${baseUrl}/prices-by-client/${id}/`, rule);
  }



  deleteRulePriceByClient(id: any): Observable<any>{
    return this.http.delete(`${baseUrl}/prices-by-client/${id}/`);
  }



}

<div class="grid grid-cols-1 gap-4" *ngIf="listPo.length > 0; else noLogistic">
  <div *ngFor="let item of listPo"
    class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white p-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-blue-50 transition-colors duration-500"
    [ngClass]="{'border-green-500 !bg-green-100': item.po === selectedPo}" (click)="select(item.po)">
    <div class="min-w-0 flex-1 hover:cursor-pointer">
      <span class="absolute inset-0" aria-hidden="true"></span>
      <p class="!m-0 text-sm font-medium text-gray-900">{{item.po}}</p>
      <p class="!m-0 truncate text-xs text-gray-500">Show report </p>
    </div>
  </div>
</div>

<ng-template #noLogistic>
  <div
    class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">

    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      data-slot="icon" class="w-12 h-12 mx-auto text-blue-700">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
    </svg>

    <span class="mt-2 block text-sm font-semibold text-gray-900">No PO Found</span>
  </div>
</ng-template>

    <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
      <div
        class="relative transform overflow-hidden bg-white pb-4 pt-5 text-left transition-all sm:my-8 h-5/6 w-full">
        <div>
          <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-green-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Preorder Resumen</h3>
            <div class="mt-2">
              <!-- CONTENT -->
              <div class="px-4 sm:px-6 lg:px-8">

                <div class="mt-8 flow-root">
                  <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table class="min-w-full text-left">
                        <thead class="bg-white">
                          <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Product</th>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Delivery Price</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Commission</th>
                          </tr>
                        </thead>
                        <tbody class="bg-white" *ngFor="let order of orders">

                          <tr class="border-t border-gray-200">
                            <th scope="colgroup" colspan="2" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                              {{order.supplier}}
                            </th>
                            <th scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.total|currency:'EUR'"> </app-badget>
                              <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20 ms-2" [text]="order.total|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
                            </th>
                            <th scope="colgroup" class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="order.totalCommission|currency:'EUR'"> </app-badget>
                              <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20 ms-2" [text]="order.totalCommission|changeToPound:order.currencyChange|currency:'GBP'"> </app-badget>
                            </th>
                          </tr>

                          <tr class="border-t border-gray-300" *ngFor="let p of order.order_products">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">{{p.product.name}} {{p.product.size.size}}</td>

                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">
                              <div *ngIf="p.coin.code === 'EUR'; else invoicePound">
                                {{p.deliveryPrice|currency:'EUR'}} / {{p.deliveryPrice|changeToPound:order.currencyChange|currency:'GBP'}}
                              </div>
                              <ng-template #invoicePound>
                                {{p.deliveryPrice|changeToEuro:order.currencyChange|currency:'EUR'}} / {{p.deliveryPrice|currency:'GBP'}}
                              </ng-template>

                            </td>

                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div *ngIf="p.coin.code === 'EUR'; else totalPound">
                                {{p.total|currency:'EUR'}} / {{p.total|changeToPound:order.currencyChange|currency:'GBP'}}
                              </div>
                              <ng-template #totalPound>
                                {{p.total|changeToEuro:order.currencyChange|currency:'EUR'}} / {{p.total|currency:'GBP'}}
                              </ng-template>

                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div *ngIf="p.coin.code === 'EUR'; else commissionPound">
                                {{p.totalCommission|currency:'EUR'}} / {{p.totalCommission|changeToPound:order.currencyChange|currency:'GBP'}}
                              </div>
                              <ng-template #commissionPound>
                                {{p.totalCommission|changeToEuro:order.currencyChange|currency:'EUR'}} / {{p.totalCommission|currency:'GBP'}}
                              </ng-template>
                            </td>

                          </tr>
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>


                <div class="mt-6 " *ngIf="totalByFamily">
                  <h3 class="text-base text-green-800 font-semibold leading-6">Resumen By Family</h3>
                  <table class="min-w-full text-left">
                    <thead class="bg-white">
                      <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Code</th>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Family</th>
                        <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Total</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white" *ngFor="let r of totalByFamily">

                      <tr class="border-t border-gray-200">
                        <th scope="colgroup" colspan="3"
                          class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                          {{r.supplier}}
                        </th>

                      </tr>

                      <tr class="border-t border-gray-300" *ngFor="let c of r.resumen">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">{{c.intrastat}}</td>
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">{{c.description}}</td>

                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-3">
                            {{c.total|currency:'EUR'}} / {{c.total_pound|currency:'GBP'}}
                        </td>


                      </tr>
                    </tbody>

                  </table>



                </div>



              </div>


            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3">
          <button type="button"
            (click)="dialogRef.close()"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">Close</button>
        </div>
      </div>
    </div>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { JcvService } from '../../services/jcv.service';

@Component({
  selector: 'app-jcv-general-form',
  templateUrl: './jcv-general-form.component.html',
  styleUrls: ['./jcv-general-form.component.css']
})
export class JcvGeneralFormComponent implements OnInit {

  public frm!: FormGroup;
  private idEnterprise?: number;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private jcv: JcvService,
    private ss: SnackbarsService,
  ) { }


  ngOnInit(): void {
    this.loadData()
    this._createForm();
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      emailGestor: ['', Validators.required],
      invoiceNumber: ['', Validators.required],
      poNumber: ['', Validators.required],
      credit_note: ['', Validators.required],
      invoiceProforma: ['', Validators.required],
      deliveryNotesNumber: ['', Validators.required],
      changePound: ['', Validators.required],
      jcvPercentage: ['', Validators.required],
    });
  }


  loadData(): void{
    this.jcv.getConfig().subscribe({
      next: (resp) => {
        this.idEnterprise = resp.id!;
        this.frm.patchValue({...resp})
      },
      error: (err) => {
        this.ss.nuevaSnack('An error occurred while loading the data', 'snack-error');
        this.router.navigateByUrl('/dash/admin');
      }
    })
  }

  enviar(): void{
    if (this.frm.invalid) return;

    this.jcv.updateConfig(this.idEnterprise!, this.frm.value).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack('Data updated', 'snack-success');
      },
      error: (err) => {
        this.ss.nuevaSnack('An error occurred while updating the data', 'snack-error');
      }
    })

  }

}

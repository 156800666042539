<div class="transform overflow-hidden bg-white text-left p-4 transition-all">



      <form [formGroup]="frm" (ngSubmit)="send()">
        <div class="sm:flex sm:items-start">
          <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Partners payment information
            </h3>
            <div class="mt-2">
              <app-input-bank-account (accountChange)="selectAccount($event)"></app-input-bank-account>

              <mat-form-field appearance="outline">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="notes" placeholder="Notes"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">

          <button type="button"
            class="mt-3 inline-flex mx-3 w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="close()">Close</button>
          <button type="submit" [disabled]="frm.invalid || frm.pristine || frm.pending"
            class="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto">Paid
            partners</button>
        </div>

      </form>


</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import * as lodash from 'lodash';
import { LogisticSupplierService } from 'src/app/features/admin/services/logistic-supplier.service';
import { LogisticService } from 'src/app/features/logistic/services/logistic.service';
import { OrderService } from 'src/app/features/order/services/order.service';
import { LogisticSupplier, ProductElement } from 'src/app/features/traceability/models/tests';
import { SnackbarsService } from '../../services/snackbars.service';
import { ProductsLogisticModalComponent } from '../products-logistic-modal/products-logistic-modal.component';

@Component({
  selector: 'app-modal-logistic-other-order',
  templateUrl: './modal-logistic-other-order.component.html',
  styleUrls: ['./modal-logistic-other-order.component.css']
})
export class ModalLogisticOtherOrderComponent implements OnInit {

  suppliers: LogisticSupplier[] = []

  logisticForm: FormGroup = this.fb.group({
    loads: this.fb.array([])
  })

  constructor(
    private dialogref: MatDialogRef<ProductsLogisticModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {preorder: number},
    private os: OrderService,
    private ss: SnackbarsService,
    private sls: LogisticSupplierService,
    private ls: LogisticService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._loadProductsToLogisstic()
    this._loadSuppliers()


  }

  private _loadProductsToLogisstic(): void {
    this.os.getProductsOrderByPreoder(this.data.preorder).subscribe({
      next: (resp) => {
        const products:ProductElement[] = []

        resp.results.forEach((p) => {
          // products.push(...p.products)

          p.products.forEach( (product) => {

            this.loads.push(this.fb.group({
              product: [product.id],
              product_name: [product.product.name],
              pallets: [product.pallets],
              isMixed: [product.isMixed],
              supplier: [product.supplier.id],
              supplier_name: [product.supplier.name],
              mixedGroup: [],
              logisticOrder: [],
            }))

          })
        })
      },
      error: (e) => {
        this.ss.nuevaSnack('Error loading products', 'snack-error')
        this.dialogref.close()
      }
    })
  }

  private _loadSuppliers(): void {
    this.sls.searchLogisticSuppliers().subscribe({
      next: (resp) => this.suppliers = resp.results,
      error: (e) => {
        this.ss.nuevaSnack('Error loading suppliers', 'snack-error')
        this.dialogref.close()
      }
    })
  }


  private _assignProductOrder(supplier:string, data: any){

    if (data.length === 0) {
      return
    }


    this.ls.assignProductsToLogistic(supplier, data).subscribe({
      next: (resp) => {
        this.dialogref.close(resp)
        this.ss.nuevaSnack('Product assign succesfully', 'snack-success')
      },
      error: (e) => {
        this.ss.nuevaSnack('Error to assign products to logistic orders', 'snack-error')
      }
    })
  }

  changeLogisticOrder = (order:string, index:  number) => {
    this.loads.controls[index].get('logisticOrder')?.setValue(order)

  }


  loadCharges(){
    if (this.logisticForm.invalid) return

    const loadsByOrder = lodash.groupBy(this.logisticForm.value.loads, 'logisticOrder');

    let noLogisticOrder = false
    let noGroup = false

    Object.keys(loadsByOrder).forEach( key => {

      if (key !== ''){
        const data = loadsByOrder[key].map( (p: any) => {
          if (p.isMixed && p.mixedGroup === null) {
            noGroup = true
            return
          }
          return {
            product: p.product,
            logisticSupplier: key,
            mixedGroup: p.mixedGroup,
          }
        }).filter( (p: any) => p !== undefined)

        this._assignProductOrder(key, data)
      }else{
        noLogisticOrder = true
      }

    })

    if (noLogisticOrder) this.ss.nuevaSnack('There are products without supplier, check logistic again', 'snack-error')
    if (noGroup) this.ss.nuevaSnack('There are products without mixed group, check logistic again', 'snack-error')

  }


  get loads(): FormArray {
    return this.logisticForm.get('loads') as FormArray;
  }


}

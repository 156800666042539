import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { InvoicesList, InvoiceRes } from 'src/app/features/billing/models/invoices-list';
import { BillingService } from 'src/app/features/billing/services/billing.service';
import { CustomIconsService } from '../../services/custom-icons.service';

@Component({
  selector: 'app-input-invoice',
  templateUrl: './input-invoice.component.html',
  styleUrls: ['./input-invoice.component.css']
})
export class InputInvoiceComponent implements OnInit {

  invoice = new FormControl('', Validators.required)

  invoices: InvoicesList[] = []
  invoicesFilter?: InvoiceRes[]
  invoiceSelected?: any

  @Input() invoiceId?: number
  @Input() invoiceName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private bs: BillingService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invoiceId']?.currentValue){
      this.invoice.setValue(changes['invoiceId']?.currentValue)
      this.invoiceSelected = changes['invoiceName']?.currentValue
    }

  }

  private _listener():void{
    this.invoice.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.bs.searchInvoices({search: resp}).subscribe({
            next: (r) => {
              this.invoicesFilter = r.results
            }
          })
        }
      }
    })
  }


  showInvoice = (valor: any): string => {
    this.invoicesFilter?.filter( (m) => {
      if(m.id === valor){
        this.invoiceSelected = m.invoiceNumber
      }
    })
    return this.invoiceSelected;
  }

  clean():void{
    this.invoice.reset()
    this.invoiceSelected = null
    this.invoicesFilter = []
    this.onSearch.emit('')
  }

  changeInvoice = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)



}

import { Component, OnInit } from '@angular/core';
import { CostType, CostTypeFrm } from '../../../models/cost-types';
import { CostTypeService } from '../../../services/cost-type.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-cost-type-edit',
  templateUrl: './cost-type-edit.component.html',
  styleUrls: ['./cost-type-edit.component.css']
})
export class CostTypeEditComponent implements OnInit {

  idCostType = this.ar.snapshot.params['id']
  costType?: CostType

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Cost Types', url: '/dash/admin/cost-type'},
    {name: 'Update Cost Type'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private cts: CostTypeService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getCostType(this.idCostType)
  }

  private _getCostType(id: number): void {
    this.cts.getCostType(id).subscribe({
      next: (resp) => {
        this.costType = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting cost type','snack-error')
        this.rt.navigate(['/dash/admin/cost-type'])
      }
    })
  }


  updateCostType(data: CostTypeFrm): void {
    this.cts.updateCostType(this.idCostType, data).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating cost type','snack-error')
      }
    })
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { PreorderService } from '../../services/preorder.service';
import { PreorderFilter } from '../../models/preorder-filter';
import * as moment from 'moment';

@Component({
  selector: 'app-preorders-filter',
  templateUrl: './preorders-filter.component.html',
  styleUrls: ['./preorders-filter.component.css']
})
export class PreordersFilterComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    search: [''],
    client: [],
    typeOfPreOrder: [],
    status: [],
    departureDate_after: [],
    departureDate_before: [],
  })

  @Output() onSearch: EventEmitter<PreorderFilter> = new EventEmitter<PreorderFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,
    private ps: PreorderService
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  updateClient = (client: string) => this.frm?.patchValue({ client })

  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
      departureDate_after: (this.frm.value.departureDate_after) ? moment(this.frm.value.departureDate_after).format('YYYY-MM-DD') : '',
      departureDate_before: (this.frm.value.departureDate_before) ? moment(this.frm.value.departureDate_before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

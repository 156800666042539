<div class="w-full mt-4 text-end">
  <button mat-raised-button type="button" class="bg-sky-800 text-white" (click)="invoice()" [disabled]="selection.selected.length === 0"> Generate Invoices </button>
</div>
<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">

          <mat-checkbox class="p-1" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)" [disabled]="row.isInvoiced">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" (click)="edit(row.id)">{{row.client.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" (click)="edit(row.id)">
          {{row.supplier.name}}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          {{row.orderDate|date}}</td>
      </ng-container>

      <ng-container matColumnDef="payer">
        <th mat-header-cell *matHeaderCellDef>Pay Commission</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          <app-badget class="bg-indigo-50 text-indigo-700 ring-indigo-600/20"
            [text]="row.payCommission" *ngIf="row.payCommission === 'CLIENT'"></app-badget>

            <app-badget class="bg-pink-50 text-pink-700 ring-pink-600/20"
            [text]="row.payCommission" *ngIf="row.payCommission === 'SUPPLIER'"></app-badget>

      </ng-container>

      <ng-container matColumnDef="myInvoice">
        <th mat-header-cell *matHeaderCellDef>My Invoice</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          {{row.invoice_number}}
      </ng-container>

      <ng-container matColumnDef="load">
        <th mat-header-cell *matHeaderCellDef>Load</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          {{row.po}}
      </ng-container>



      <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef>Commission</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
            <div *ngIf="row.coin.code === 'GBP'; else commissionEuro" class="flex gap-2 content-between">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
                [text]="row.totalCommission|changeToEuro:row.currencyExchange|currency:'EUR'"></app-badget>
              <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.totalCommission|currency:'GBP'"> </app-badget>
            </div>
            <ng-template #commissionEuro>
              <div class="flex gap-2 content-between">
                <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.totalCommission|currency:'EUR'"></app-badget>
                <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.totalCommission|changeToPound:row.currencyExchange|currency:'GBP'"> </app-badget>
              </div>
            </ng-template>

        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">

            <div *ngIf="row.coin.code === 'GBP'; else totalEuro" class="flex gap-2">
                <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|changeToEuro:row.currencyExchange|currency:'EUR'"></app-badget>
                <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.total|currency:'GBP'"> </app-badget>
            </div>
            <ng-template #totalEuro>
                <div class="flex gap-2">
                  <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|currency:'EUR'"></app-badget>
                  <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.total|changeToPound:row.currencyExchange|currency:'GBP'"> </app-badget>
                </div>
            </ng-template>

        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-28"></th>
        <td mat-cell *matCellDef="let row">

          <button mat-icon-button aria-label="Duplicate Order" (click)="openModalDuplicate(row)">
            <mat-icon svgIcon="duplicate" class="text-indigo-700"></mat-icon>
          </button>


          <button mat-icon-button aria-label="Delete Order" (click)="deleteSecondLine(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>

        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Second Line found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select pre-order search results"></mat-paginator>
</div>

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Unload Place</mat-label>
    <input type="text" placeholder="Search Unload Place" matInput [formControl]="unLoadPlace"
      [matAutocomplete]="autoUnoadPlaces">
    <mat-autocomplete #autoUnoadPlaces="matAutocomplete" [displayWith]="showUnloadPlace"
      (optionSelected)="changeUnloadPlace($event)">
      <mat-option *ngFor="let option of unLoadPlacesFilter" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

import { Component, OnInit } from '@angular/core';
import { LogisticSupplier } from '../../../models/logistic-suppliers';
import { LogisticSupplierService } from '../../../services/logistic-supplier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-logistic-edit',
  templateUrl: './logistic-edit.component.html',
  styleUrls: ['./logistic-edit.component.css']
})
export class LogisticEditComponent implements OnInit {

  idSupplier = this.ar.snapshot.params['id']
  supplier?: LogisticSupplier

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Logistic Supplier', url: '/dash/admin/logistic'},
    {name: 'Update Logistic Supplier'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private lss: LogisticSupplierService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getSupplier(this.idSupplier)
  }

  private _getSupplier(clientId: string): void {
    this.lss.getLogisticSupplier(this.idSupplier).subscribe({
      next: (resp) => {
        this.supplier = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting logistic supplier','snack-error')
        this.rt.navigate(['/dash/admin/logistic'])
      }
    })
  }


  updateLogisticSupplier(supplier: LogisticSupplier): void {
    this.lss.updateLogisticSupplier(this.idSupplier, supplier).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Logistic supplier ${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating logistic supplier','snack-error')
      }
    })
  }

}

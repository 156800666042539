import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { IntrastatSearch } from '../../models/intrastat-reports';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType, Color } from 'chart.js';


@Component({
  selector: 'app-intrastat-graph',
  templateUrl: './intrastat-graph.component.html',
  styleUrls: ['./intrastat-graph.component.css']
})
export class IntrastatGraphComponent implements OnInit {

  @Input('searched') searched: IntrastatSearch = {}
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public barChartOptions: ChartConfiguration['options'] = {
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 10,
      },
    },

    plugins: {
      legend: {
        display: true,
      },


      subtitle: {
        display: true,
        text: 'Net and gross kilograms ',
        font: {
          size: 14,
          weight: 'bold'
        }
      }

      //   align: 'end',
      // },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [];

  public barChartData?: ChartData<'bar'>;
  public barChartDataSale?: ChartData<'bar'>;

  colorScheme: any = {
    domain: ['#d81159', '#73d2de']
  };

  constructor( private rpts: ReportsService ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._loadData()
    }
  }


  private _loadData(){
    this.rpts.resumenIntrastat(this.searched).subscribe({
      next: (res) => {
        this.barChartData = res.commission
        this.barChartDataSale = res.sales
        this.chart?.update();
      },
      error: (err) => console.error(err)
    })
  }

}

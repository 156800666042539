<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">#</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer hidden sm:table-cell text-xs" (click)="edit(row.id, row.typeOfPreOrder)">#{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">Template Name</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer hidden sm:table-cell" (click)="edit(row.id, row.typeOfPreOrder)">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id, row.typeOfPreOrder)">
          <span
            [ngClass]="{
              'bg-purple-50 text-purple-700 ring-purple-700/10': row.typeOfPreOrder === 'SELL',
              'bg-green-50 text-green-700 ring-green-600/20': row.typeOfPreOrder === 'BUY',
              'bg-blue-50 text-blue-700 ring-blue-700/10': row.typeOfPreOrder === 'COMMISSION'
            }"
            class="inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium ring-1 ring-inset">
            {{row.typeOfPreOrder}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">Status</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer hidden sm:table-cell" (click)="edit(row.id, row.typeOfPreOrder)">
          <span
            [ngClass]="{
              'bg-green-50 text-green-700 ring-green-600/20': row.status === 'GENERATED',
              'bg-purple-50 text-purple-700 ring-purple-700/10': row.status === 'TEMPLATE',
              'bg-blue-50 text-blue-700 ring-blue-700/10': row.status === 'DRAFT',
              'bg-zinc-50 text-zinc-700 ring-zinc-600/20': row.status === 'CANCELED'
            }"
            class="inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset">
            {{row.status}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id, row.typeOfPreOrder)">{{row.client.name}}</td>
      </ng-container>

      <ng-container matColumnDef="po">
        <th mat-header-cell *matHeaderCellDef>PO</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id, row.typeOfPreOrder)">{{row.po}}</td>
      </ng-container>

      <ng-container matColumnDef="poClient">
        <th mat-header-cell *matHeaderCellDef>PO Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id, row.typeOfPreOrder)">{{row.poClient}}</td>
      </ng-container>

      <ng-container matColumnDef="departure">
        <th mat-header-cell *matHeaderCellDef>Departure</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id, row.typeOfPreOrder)">
          <ng-template [ngIf]="row.status !== 'TEMPLATE'">
            {{row.departureDate|date}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="arrival">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">Arrival</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer hidden sm:table-cell" (click)="edit(row.id, row.typeOfPreOrder)">
          <ng-template [ngIf]="row.status !== 'TEMPLATE'">
            {{row.arrivalDate|date}}
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="clientPaid">
        <th mat-header-cell *matHeaderCellDef class="hidden sm:table-cell">Paid by Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer hidden sm:table-cell" (click)="edit(row.id, row.typeOfPreOrder)">
            <span
              *ngIf="row.clientPaid && row.typeOfPreOrder === 'SELL'"
              [matTooltip]="row.partnersClientPaidDate"
              matTooltipClass="bg-zinc-700 text-sm"
              class="inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium bg-green-50 text-green-600 ring-green-500/10 ring-1 ring-inset">
              Paid by Client
            </span>
        </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class=""></th>
        <td mat-cell *matCellDef="let row" class="flex gap-2">

          <button
            matTooltip="Show Pre-order resumen"
            matTooltipClass="bg-purple-700 text-xs"
            mat-button (click)="showResumenPreorder(row.id)"
            class="!h-8 !w-6 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-purple-700 object-cover ring-1 ring-purple-950/10 hover:bg-purple-400 transition-colors duration-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
              class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </button>

          <button
            mat-button
            aria-label="Duplicate Pre-order"
            matTooltip="Duplicate Pre-order"
            matTooltipClass="bg-indigo-700 text-xs"
            class="bg-indigo-700 !h-8 !w-6 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg object-cover ring-1 ring-indigo-700/50 transition-colors duration-500"
            (click)="duplicatePreorder(row.id)">
            <mat-icon svgIcon="duplicate" class="text-white"></mat-icon>
          </button>

          <button
            mat-button
            aria-label="Generate Sell Pre-order"
            (click)="generateSellOrder(row.id)"
            matTooltip="Generate Sell Pre-order"
            matTooltipClass="bg-green-700 text-xs"
            *ngIf="row.typeOfPreOrder === 'BUY'"
            class="bg-green-700 !h-8 !w-6 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg object-cover ring-1 ring-green-700/50 transition-colors duration-500">
            <mat-icon svgIcon="buy-sell" class="text-white"></mat-icon>
          </button>


        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Pre-order found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select pre-order search results"></mat-paginator>
</div>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-8/12">
    <mat-label>Supplier Name</mat-label>
    <mat-icon matPrefix>supervised_user_circle</mat-icon>
    <input matInput formControlName="name" placeholder="EuroFresh">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>CIF / VAT </mat-label>
    <input matInput formControlName="vatNumber" placeholder="00000000X">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Commission</mat-label>
    <input matInput formControlName="commission" placeholder="00" type="number">
  </mat-form-field>

  <span class="w-full text-lg font-semibold text-slate-700"> Addres Info </span>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Address</mat-label>
    <input matInput formControlName="address" placeholder="New Street">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>City</mat-label>
    <input matInput formControlName="city" placeholder="Seville">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Zip Code</mat-label>
    <input matInput formControlName="zipCode" placeholder="00000">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Province</mat-label>
    <input matInput formControlName="province" placeholder="Seville">
  </mat-form-field>

  <div class="w-4/12">
    <app-input-countries [countryId]="countryId" [countryName]="countryName"
      (onSelect)="setCountry($event)"></app-input-countries>
  </div>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes" placeholder="Ex. This suppliers has..."></textarea>
  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(supplier) ? 'Updated' : 'Create'}}
      Supplier </button>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { InvoiceFilter } from '../../models/invoice-filter';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-home',
  templateUrl: './invoice-home.component.html',
  styleUrls: ['./invoice-home.component.css']
})
export class InvoiceHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Invoices'},
  ]

  public searched: InvoiceFilter = { search: '' }


  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  search = (data: InvoiceFilter) => this.searched = data

  goto = (url:string) => this.rt.navigateByUrl(url )
}

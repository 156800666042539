import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderService } from 'src/app/features/order/services/order.service';
import { SnackbarsService } from '../../services/snackbars.service';
import { MatDialog } from '@angular/material/dialog';
import { ProductsLogisticModalComponent } from 'src/app/shared/components/products-logistic-modal/products-logistic-modal.component';
import { ResumenOrdersComponent } from 'src/app/features/preorder/components/resumen-orders/resumen-orders.component';
import { ModalLogisticOtherOrderComponent } from '../modal-logistic-other-order/modal-logistic-other-order.component';
import { ModalQuestionComponent } from '../modal-question/modal-question.component';

@Component({
  selector: 'app-orders-actions',
  templateUrl: './orders-actions.component.html',
  styleUrls: ['./orders-actions.component.css']
})
export class OrdersActionsComponent implements OnInit {

  isGeneratingPdf = false

  @Input() follow: {hasPdfGenerated: boolean, hasFixedCost: boolean, hasLogistic: boolean} = { hasPdfGenerated: false, hasFixedCost: false, hasLogistic: false }
  @Input() preorder?: number
  @Input() typeOfPreorder?: string
  @Output() reload = new EventEmitter<boolean>()

  constructor(
    private dialog: MatDialog,
    private os: OrderService,
    private ss: SnackbarsService
  ) { }

  ngOnInit(): void {
  }


  generateAllPdf(){
    this.isGeneratingPdf = true
    this.os.generateOrdersPdfByPreorder(this.preorder!).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack('Orders PDFs Generated', 'snack-success')
        this.isGeneratingPdf = false
        this.reload.emit(true)
      },
      error: (err) => {
        this.ss.nuevaSnack('Error generating PDFs', 'snack-error')
        this.isGeneratingPdf = false
      }
    })
  }

  private _openModalLogistic(){
    this.dialog.open(ProductsLogisticModalComponent, {
      height: '70vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        preorder: this.preorder
      }
    }).afterClosed().subscribe( r => {
      if(r){
        this.reload.emit(true)
      }
    })
  }

  private _openModalOtherLogistic(){
    this.dialog.open(ModalLogisticOtherOrderComponent, {
      height: '70vh',
      width: '100vw',
      maxWidth: '100vw',
      data: {
        preorder: this.preorder
      }
    }).afterClosed().subscribe( r => {
      if(r){
        this.reload.emit(true)
      }
    })
  }


  openLogistic(){
    if (this.typeOfPreorder === 'BUY'){
      this.dialog.open(ModalQuestionComponent, {
        data: {
          title: 'Sure?',
          message: 'Is a BUY preorder, are you sure you want to generate the logistic?',
          cancel: 'Close',
          accept: 'Generate Logistic'
        }
      }).afterClosed().subscribe( r => {
        if (r) this._openModalLogistic()
      })
    }else{
      this._openModalLogistic()
    }
  }

  moveOtherLogisticOrder(){
    if (this.typeOfPreorder === 'BUY'){
      this.dialog.open(ModalQuestionComponent, {
        data: {
          title: 'Sure?',
          message: 'Is a BUY preorder, are you sure you want to move loads to logistic order?',
          cancel: 'Close',
          accept: 'Generate Logistic'
        }
      }).afterClosed().subscribe( r => {
        if (r) this._openModalOtherLogistic()
      })
    }else{
      this._openModalOtherLogistic()
    }
  }


  showResumen(){
    this.dialog.open(ResumenOrdersComponent, {
      data: {
        preorder: this.preorder,
      },
      width: '90vw',
      height: '80vh'

    })
  }

  generateFixedCosts(){
    this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Generate Fixed Costs',
        message: 'Are you sure you want to generate fixed costs?',
        cancel: 'Cancel',
        accept: 'Generate'
      }
    }).afterClosed().subscribe( r => {
      if(r){
        this.os.generateFixedCost(this.preorder!).subscribe({
          next: (resp) => {
            this.ss.nuevaSnack('Fixed Costs Generated', 'snack-success')
            this.reload.emit(true)
          },
          error: (err) => {
            this.ss.nuevaSnack('Error generating Fixed Costs', 'snack-error')
          }
        })
      }
    })
  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Download Place</mat-label>
    <input matInput formControlName="name" placeholder="Warehouse X">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Address</mat-label>
    <input matInput formControlName="address" type="text" placeholder="New Street">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>City</mat-label>
    <input matInput formControlName="city" placeholder="Seville">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Zip Code</mat-label>
    <input matInput formControlName="zipCode" placeholder="41010">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Province</mat-label>
    <input matInput formControlName="province" placeholder="Seville">
  </mat-form-field>

  <app-input-countries [countryId]="countryId" [countryName]="countryName"
    (onSelect)="setCountry($event)"></app-input-countries>

  <div class="bg-slate-100 p-4 rounded-sm my-3">
    <mat-checkbox formControlName="byDefault">Default place</mat-checkbox>
  </div>

  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Logistic
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CostTypeList, CostType } from '../models/cost-types';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class CostTypeService {

  constructor(
    private http: HttpClient
  ) { }


  searchCostTypes(search?: string, page=1): Observable<CostTypeList>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<CostTypeList>(`${baseUrl}/search-cost-type/`, {params})
  }

  getCostType(id: number): Observable<CostType>{
    return this.http.get<CostType>(`${baseUrl}/cost-type/${id}`)
  }

  addCostType(data:any): Observable<CostType>{
    return this.http.post<CostType>(`${baseUrl}/cost-type/`, data)
  }

  updateCostType(id: number, data: any): Observable<CostType>{
    return this.http.put<CostType>(`${baseUrl}/cost-type/${id}/`, data)
  }

  deleteCostType(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/cost-type/${id}/`)
  }





}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Intrastat, Intrastats } from '../models/intrastat';


const baseUrl = environment.baseUrl;


@Injectable({
  providedIn: 'root'
})
export class IntrastatService {

  constructor( private http: HttpClient) { }


  getAll(): Observable<Intrastats> {
    return this.http.get<Intrastats>(`${baseUrl}/intrastat`);
  }

  getIntrastat(id: number): Observable<Intrastat>{
    return this.http.get<Intrastat>(`${baseUrl}/intrastat/${id}/`);
  }

  addIntrastat(intrastat: Intrastat): Observable<Intrastat>{
    return this.http.post<Intrastat>(`${baseUrl}/intrastat/`, intrastat);
  }

  updateIntrastat(id: number, intrastat: Intrastat): Observable<Intrastat>{
    return this.http.put<Intrastat>(`${baseUrl}/intrastat/${id}/`, intrastat);
  }

  deleteIntrastat(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/intrastat/${id}/`);
  }

  searchInstrastat(search?: string, page=1): Observable<Intrastats>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<Intrastats>(`${baseUrl}/search-intrastat`, {params})
  }

}

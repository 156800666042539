import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CostsHomeComponent } from './pages/costs-home/costs-home.component';
import { CostsTableComponent } from './components/costs-table/costs-table.component';
import { CostsFilterComponent } from './components/costs-filter/costs-filter.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CostFrmComponent } from './components/cost-frm/cost-frm.component';



@NgModule({
  declarations: [
    CostsHomeComponent,
    CostsTableComponent,
    CostsFilterComponent,
    CostFrmComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class CostsModule { }

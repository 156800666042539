<form [formGroup]="frm" (ngSubmit)="enviar()" *ngIf="frm" class="w-full flex flex-row">

  <div class="mt-8 flex flex-row flex-wrap justify-start gap-4 w-9/12">

    <h2 class="w-full m-0 p-0">
      Company Profile
      <small class="block font-light text-xs text-slate-500 w-full"> Update yout company general data here.</small>

    </h2>

    <mat-form-field appearance="outline" class="w-5/12">
      <mat-label>Name</mat-label>
      <mat-icon matPrefix>supervised_user_circle</mat-icon>
      <input matInput formControlName="name" placeholder="Enterprise name">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-5/12">
      <mat-label>Email Gestor</mat-label>
      <mat-icon matPrefix>mail_outline</mat-icon>
      <input matInput formControlName="emailGestor" placeholder="Email Gestor">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Invoice Number</mat-label>
      <input matInput type="number" formControlName="invoiceNumber" placeholder="Invoice Number">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Proforma Number</mat-label>
      <input matInput type="number" formControlName="invoiceProforma" placeholder="Proforma Invoice">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Delivery Note Number</mat-label>
      <input matInput type="number" formControlName="deliveryNotesNumber" placeholder="Delivery Notes">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>PO Number</mat-label>
      <input matInput type="number" formControlName="poNumber" placeholder="PO Start">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Pound Change</mat-label>
      <input matInput type="number" formControlName="changePound" placeholder="Pound to Euro Change">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>JCV Sale %</mat-label>
      <input matInput type="number" formControlName="jcvPercentage" placeholder="0">
    </mat-form-field>

  </div>

    <div class="actions-frm text-end my-8 w-3/12">
      <button mat-raised-button type="submit" class="w-80 bg-green-700 text-white"> Update information </button>
    </div>

</form>

<div class="menu-container">

  <div>
    <div
      *ngFor="let item of menu"
      class="flex px-6 group font-semibold justify-start items-center text-slate-400 cursor-pointer my-4 transition-all duration-700"
      [routerLink]="[item.path]"
      [routerLinkActiveOptions]="{ exact: item.exact || false}"
      routerLinkActive="text-slate-900 border-r-4 border-green-900"
    >
      <mat-icon class="me-3 group-hover:text-slate-900 transition-colors duration-500" svgIcon="{{item.icon}}"></mat-icon>
      <span class="group-hover:text-slate-900 transition-colors duration-500">{{item.text}}</span>
    </div>
  </div>

  <div class="px-6">
    <div class=" text-red-400 cursor-pointer hover:text-red-900 transition-colors duration-500" (click)="logout()">
      <mat-icon svgIcon="logout"></mat-icon>
      <span> Logout </span>
    </div>

  </div>

</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FilterProductOrderReport } from '../../models/filter-product-order-report';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-products',
  templateUrl: './filter-products.component.html',
  styleUrls: ['./filter-products.component.css']
})
export class FilterProductsComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    isInvoiced: [],
    client: [],
    supplier: [],
    product: [],
    departureDate_after: [],
    departureDate_before: [],
    carrierInvoice: [],
    supplierInvoice: [],
  })

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>()


  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }

  updateClient = (client: string) => this.frm?.patchValue({ client })
  updateSupplier = (supplier: string) => this.frm?.patchValue({ supplier })
  updateProduct = (product: string) => this.frm?.patchValue({ product })

  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
      departureDate_after: (this.frm.value.departureDate_after) ? moment(this.frm.value.departureDate_after).format('YYYY-MM-DD') : '',
      departureDate_before: (this.frm.value.departureDate_before) ? moment(this.frm.value.departureDate_before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }
}

import { Component, OnInit } from '@angular/core';
import { DeliveryFilter } from '../../models/delivery-filter';

@Component({
  selector: 'app-delivery-home',
  templateUrl: './delivery-home.component.html',
  styleUrls: ['./delivery-home.component.css']
})
export class DeliveryHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Delivery Notes'},
  ]

  public searched: DeliveryFilter = { search: '' }


  constructor() { }

  ngOnInit(): void {
  }

  search = (data: DeliveryFilter) => this.searched = data

}

import { Component, OnInit } from '@angular/core';
import { InvoiceRes } from '../../models/invoices-list';
import { BillingService } from '../../services/billing.service';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.css']
})
export class InvoiceCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Invoices', url: '/dash/invoices'},
    {name: 'New Invoice'},
  ]

  constructor(
    private bs: BillingService,
    private rt: Router,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }

  sendData(invoice: InvoiceRes){

    this.bs.generateInvoiceOther(invoice).subscribe({
      next: resp => {
        this.ss.nuevaSnack(`Invoice ${resp.invoiceNumber} created successfully`, 'snack-success')
        this.rt.navigate(['/dash/invoices/edit',resp.id])
      },
      error: err => console.log(err)
    })
  }

}

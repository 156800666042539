import { Component, OnInit } from '@angular/core';
import { PreorderFilter } from '../../models/preorder-filter';

@Component({
  selector: 'app-preorder-home',
  templateUrl: './preorder-home.component.html',
  styleUrls: ['./preorder-home.component.css']
})
export class PreorderHomeComponent implements OnInit {
  public breadcrumb = [
    {name: 'Pre-Orders'},
  ]

  public searched:PreorderFilter = { search: '' }

  constructor(
  ) { }

  ngOnInit(): void {
  }

  search = (data: PreorderFilter) => this.searched = data

}

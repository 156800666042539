import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-data-invoice',
  templateUrl: './modal-data-invoice.component.html',
  styleUrls: ['./modal-data-invoice.component.css']
})
export class ModalDataInvoiceComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    bank: ['', Validators.required],
    payer: ['', Validators.required],
    coin: ['', Validators.required],
    invoice_type: ['', Validators.required],
    currencyExchange: [0,]
  })

  constructor(
    private dialogRef:MatDialogRef<ModalDataInvoiceComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  selectAccount = (bank: string) => this.frm.patchValue({bank})

  selectCoin = (coin: {id:string, code: string}) => {
    this.frm.patchValue({coin: coin.id})
    this.frm.controls['currencyExchange'].setValidators( (coin.code === 'GBP') ? Validators.required : null)
    this.frm.controls['currencyExchange'].updateValueAndValidity();
  }

  invoice(){
    if (this.frm.invalid) return

    this.dialogRef.close(this.frm.value)
  }


}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddSupplierOrderFrmComponent } from '../add-supplier-order-frm/add-supplier-order-frm.component';

@Component({
  selector: 'app-add-supplier-order',
  templateUrl: './add-supplier-order.component.html',
  styleUrls: ['./add-supplier-order.component.css']
})
export class AddSupplierOrderComponent implements OnInit {

  @Input() idPreorder?: number
  @Output() addSupplierOrder = new EventEmitter<boolean>()

  constructor(
    private _bottomSheet: MatBottomSheet,
  ) { }

  ngOnInit(): void {
  }


  addSupplier(){
    this.addSupplierOrder.emit(true)
  }


  openSupplierFrm(id = null): void {
    const sheetBottom = this._bottomSheet.open(AddSupplierOrderFrmComponent, {
      disableClose: true,
      data: {
        preorder: this.idPreorder,
      }
    });

    sheetBottom.afterDismissed().subscribe((data) => {
      if (data) this.addSupplierOrder.emit(data)
    }
    );

  }

}

// Generated by https://quicktype.io



export interface ReportByPoResumen {
  res:            PartnerProfitResumen[];
  comercial:      number;
}

export interface PartnerProfitResumen {
  partner:        number;
  partner_name:   string;
  client:         string;
  product:        ProductResumen[];
  total:          number;
}

export interface ProductResumen {
  product: string;
  profit:  number;
}


interface Profit{
  profit_by_person: number;
  profit_sale: number;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from 'src/app/material.module';
import { LogisticPreorderListComponent } from './components/logistic-preorder-list/logistic-preorder-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModalUpdatePlateComponent } from './components/modal-update-plate/modal-update-plate.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalMoveLoadComponent } from './components/modal-move-load/modal-move-load.component';
import { ModalSendFileLogisticComponent } from './components/modal-send-file-logistic/modal-send-file-logistic.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LogisticTrackingComponent } from './pages/logistic-tracking/logistic-tracking.component';
import { SearchLogisticFrmComponent } from './components/search-logistic-frm/search-logistic-frm.component';
import { LogisticListComponent } from './components/logistic-list/logistic-list.component';
import { LogisticTrackDetailComponent } from './components/logistic-track-detail/logistic-track-detail.component';
import { UpdateLogisticLoadFrmComponent } from './components/update-logistic-load-frm/update-logistic-load-frm.component';



@NgModule({
  declarations: [
    LogisticPreorderListComponent,
    ModalUpdatePlateComponent,
    ModalMoveLoadComponent,
    ModalSendFileLogisticComponent,
    LogisticTrackingComponent,
    SearchLogisticFrmComponent,
    LogisticListComponent,
    LogisticTrackDetailComponent,
    UpdateLogisticLoadFrmComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    AngularEditorModule,
  ],
  exports: [
    LogisticPreorderListComponent
  ]
})
export class LogisticModule { }

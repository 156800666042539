import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-non-conformities-home',
  templateUrl: './non-conformities-home.component.html',
  styleUrls: ['./non-conformities-home.component.css']
})
export class NonConformitiesHomeComponent implements OnInit {

  public breadcrumb = [
    {name: 'Non Conformities'},
  ]

  public searched = { text: ''}

  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  search = (data: {text:string}) => this.searched = data

  goto = (url:string) => this.rt.navigateByUrl(url)

}

import { Component, OnInit } from '@angular/core';
import { Supplier } from '../../../models/suppliers';
import { SupplierService } from '../../../services/supplier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.css']
})
export class SupplierEditComponent implements OnInit {

  idSupplier = this.ar.snapshot.params['id']
  supplier?: Supplier

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Suppliers', url: '/dash/admin/suppliers'},
    {name: 'Update Supplier'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private sps: SupplierService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getSupplier(this.idSupplier)
  }

  private _getSupplier(clientId: string): void {
    this.sps.getSupplier(this.idSupplier).subscribe({
      next: (resp) => {
        this.supplier = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting supplier','snack-error')
        this.rt.navigate(['/dash/admin/suppliers'])
      }
    })
  }


  updateSupplier(supplier: Supplier): void {
    this.sps.updateSupplier(this.idSupplier, supplier).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Supplier ${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating supplier','snack-error')
      }
    })
  }

}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SecondLineProduct, SecondLineProductsResp } from '../../models/second-line-products-res';
import { SecondLineService } from '../../services/second-line.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { BaseCode } from '../../../../shared/models/base-code';

@Component({
  selector: 'app-second-line-products-table',
  templateUrl: './second-line-products-table.component.html',
  styleUrls: ['./second-line-products-table.component.css']
})
export class SecondLineProductsTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['product', 'pallet', 'quantity', 'price', 'total', 'actions'];
  products?: SecondLineProductsResp | null;
  data: SecondLineProduct[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('filter') filter: {secondLine: string} = { secondLine: ''}
  @Input('extra') extra: {coin?: BaseCode, currencyExchange?: number} = {}
  @Output() editProduct = new EventEmitter<number>();


  constructor(
    private dialog: MatDialog,
    private sls: SecondLineService
  ) { }

  ngAfterViewInit() {
    this.getProducts()
  }


  getProducts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sls.searchProductsSecondLine(
            this.filter
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  edit = (id: number) => this.editProduct.emit(id)

  deleteProduct(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete this product?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.sls.deleteProductSecondLine(id).subscribe(() => this.getProducts())
      }
    })
  }

}


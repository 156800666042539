<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">



      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          {{row.code}}</td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Total</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">
          <div class="flex gap-2">
            <div *ngIf="row.coin.code === 'EUR'; else totalPound">
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="-row.total|currency:'EUR'"></app-badget>
            </div>
            <ng-template #totalPound>
              <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="-(row.total*row.currencyExchange)|currency:'EUR'"></app-badget>
              <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="-row.total|currency:'GBP'"></app-badget>
            </ng-template>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-20">

          <button mat-button
            class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-red-600 object-cover ring-1 ring-red-950/10 hover:bg-red-400 transition-colors duration-500"
            [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }" (click)="generatePdf(row.id)"
            [disabled]="isGeneratingPdf">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" *ngIf="!isGeneratingPdf" class="stroke-white w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>
            <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
          </button>

        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Credit Notes found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select pre-order search results"></mat-paginator>
</div>

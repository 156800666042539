import { Component, Input, OnInit } from '@angular/core';
import { ProductsOrderInvoice } from '../../models/invoice-detail';

@Component({
  selector: 'app-order-items-table',
  templateUrl: './order-items-table.component.html',
  styleUrls: ['./order-items-table.component.css']
})
export class OrderItemsTableComponent implements OnInit {

  @Input('data') data?: ProductsOrderInvoice[]

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { OrderFilter } from 'src/app/features/order/models/order-filter';

@Component({
  selector: 'app-order-invoices',
  templateUrl: './order-invoices.component.html',
  styleUrls: ['./order-invoices.component.css']
})
export class OrderInvoicesComponent implements OnInit {
  public breadcrumb = [
      {name: 'Orders to be Invoiced'},
    ]

  public searched: OrderFilter = { search: '' }


  constructor() { }

  ngOnInit(): void {
  }

  search = (data: OrderFilter) => this.searched = data

}

import { Component, OnInit } from '@angular/core';
import { InvoiceRes } from '../../models/invoices-list';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { BillingService } from '../../services/billing.service';

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.css']
})
export class InvoiceEditComponent implements OnInit {
  idInvoice = this.ar.snapshot.params['id']
  invoice?: InvoiceRes

  public breadcrumb = [
    {name: 'Invoices', url: '/dash/invoices'},
    {name: 'Update Invoice'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ss: SnackbarsService,
    private bs: BillingService,
  ) { }

  ngOnInit(): void {
    this._getInvoice()
  }


  private _getInvoice(): void {

    this.bs.getInvoice(this.idInvoice!).subscribe({
      next: (resp) => this.invoice = resp,
      error: (err) => {
        this.ss.nuevaSnack('Error getting invoice','snack-error')
        this.rt.navigate(['/dash/invoices'])
      }
    })
  }

  changeIndex = (event: number) => (event === 0) ? this._getInvoice() : null

  updateInvoice(invoice: InvoiceRes): void {
    this.bs.updateInvoice(this.idInvoice, invoice).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack('Invoice updated', 'snack-success')
        this.invoice = resp
      },
      error: (err) => this.ss.nuevaSnack('Error updating invoice', 'snack-error')
    })

  }

}

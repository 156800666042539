<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Orders
      <small class="block font-light text-xs text-slate-500 w-full"> Manage orders .</small>
    </h2>


  </div>

  <!-- Filter-->
  <div class="my-6">
    <app-order-filter (onSearch)="search($event)"></app-order-filter>
  </div>

  <!-- List -->
  <app-orders-table [searched]="searched"></app-orders-table>
</main>

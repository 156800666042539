<div class="grid grid-cols-1 gap-4" *ngIf="listOrder.length > 0; else noLogistic">
  <div
    *ngFor="let item of listOrder"
    class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white p-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 hover:bg-blue-50 transition-colors duration-500"
    [ngClass]="{'border-green-500 !bg-green-100': item.id === selectedId}"
    (click)="select(item.id)"
    >
    <div class="min-w-0 flex-1 hover:cursor-pointer">
        <span class="absolute inset-0" aria-hidden="true"></span>
        <p class="!m-0 text-sm font-medium text-gray-900">{{item.code}}</p>
        <p class="!m-0 truncate text-xs text-gray-500">Show details logistic order</p>
    </div>
  </div>
</div>

<ng-template #noLogistic>
<div
  class="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">

  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
    data-slot="icon" class="w-12 h-12 mx-auto text-blue-700">
    <path stroke-linecap="round" stroke-linejoin="round"
      d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
  </svg>

  <span class="mt-2 block text-sm font-semibold text-gray-900">No Logistic Found</span>
</div>
</ng-template>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Product</mat-label>
    <input matInput formControlName="product" placeholder="Tomato...">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Pallets</mat-label>
    <input matInput formControlName="pallet" type="number" placeholder="0">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Quantity</mat-label>
    <input matInput formControlName="quantity" placeholder="000">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Unit</mat-label>
    <mat-select formControlName="unit">
      <mat-option value="BOXES">Boxes</mat-option>
      <mat-option value="KGS">Kgs</mat-option>
      <mat-option value="UNITS">Units</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Price</mat-label>
    <input matInput formControlName="price" type="number" placeholder="0.00">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Total</mat-label>
    <input matInput formControlName="total" type="number" placeholder="0.00">
  </mat-form-field>



  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Add' : 'Update'}} Product
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

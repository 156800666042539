<div class="p-4">
<h4 class="text-base text-green-700 !font-bold"> Duplicate Preorder </h4>
<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Departure Date</mat-label>
    <input matInput [matDatepicker]="departureDate" formControlName="departureDate"
      (dateInput)="setArrivalDate($event);">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #departureDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Arrival Date</mat-label>
    <input matInput [matDatepicker]="arrivalDate" formControlName="arrivalDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="arrivalDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #arrivalDate></mat-datepicker>
  </mat-form-field>

  <div class="actions-frm text-end my-8 w-full">

    <button mat-raised-button type="submit" class="w-full bg-green-700 text-white"> Duplicate </button>
  </div>
</form>
</div>

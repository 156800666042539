import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-model-invoice-secondline',
  templateUrl: './model-invoice-secondline.component.html',
  styleUrls: ['./model-invoice-secondline.component.css']
})
export class ModelInvoiceSecondlineComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    bank: ['', Validators.required],
    coin: ['', Validators.required],
    currencyExchange: [0,]
  })

  constructor(
    private dialogRef:MatDialogRef<ModelInvoiceSecondlineComponent>,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  selectAccount = (bank: string) => this.frm.patchValue({bank})

  selectCoin = (coin: {id:string, code: string}) => {
    this.frm.patchValue({coin: coin.id})
    this.frm.controls['currencyExchange'].setValidators( (coin.code === 'GBP') ? Validators.required : null)
    this.frm.controls['currencyExchange'].updateValueAndValidity();
  }

  invoice(){
    if (this.frm.invalid) return

    this.dialogRef.close(this.frm.value)
  }


}

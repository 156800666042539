import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SupplierLoadPlace } from 'src/app/features/admin/models/supplier-load-places';
import { SupplierService } from 'src/app/features/admin/services/supplier.service';

@Component({
  selector: 'app-input-loadplaces',
  templateUrl: './input-loadplaces.component.html',
  styleUrls: ['./input-loadplaces.component.css']
})
export class InputLoadplacesComponent implements OnInit {

  @Input() supplierInfo?: {id: number, isMe: boolean};
  @Input() loadPlaceInfo?: {id:number, name: string};

  @Output('onSelect') onSearch = new EventEmitter<string>()

  loadPlace = new FormControl('', Validators.required)


  loadPlacesFilter?: SupplierLoadPlace[]
  loadPlaceselected?: any
  search: {text: string, supplier?: number} = {text: '', supplier: undefined}

  constructor(
    private sps: SupplierService,
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadPlaceInfo']?.currentValue){
      this.loadPlace.setValue(changes['loadPlaceInfo']?.currentValue.id)
      this.loadPlaceselected = changes['loadPlaceInfo']?.currentValue.name
    }



    if (!changes['loadPlaceInfo']){
      this.clean()
    }

  }

  private _listener():void{
    this.loadPlace.valueChanges.subscribe({
      next: (resp: any) => {
        (!this.supplierInfo!.isMe)
          ? this.search.supplier = this.supplierInfo!.id
          : this.search.supplier = undefined


        this.search.text = resp


        if (resp){
          this.sps.searchLoadPlacesSupplier(this.search).subscribe({
            next: (r) => {
              this.loadPlacesFilter = r.results
            }
          })
        }
      }
    })
  }


  showLoadPlace = (valor: any): string => {
    this.loadPlacesFilter?.filter( (m) => {
      if(m.id === valor){
        this.loadPlaceselected = m.name
      }
    })
    return this.loadPlaceselected;
  }

  clean():void{
    this.loadPlace.reset()
    this.loadPlaceselected = null
    this.loadPlacesFilter = []
    this.onSearch.emit('')
  }

  changeLoadPlace = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

import { Component, OnInit } from '@angular/core';
import { Partner } from '../../../models/partner';
import { PartnerService } from '../../../services/partner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { CostTypeFrm } from '../../../models/cost-types';

@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.css']
})
export class EditPartnerComponent implements OnInit {

  idPartner = this.ar.snapshot.params['id']
  partner?: Partner

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Partners', url: '/dash/admin/partners'},
    {name: 'Update Partner'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private pts: PartnerService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getPartner(this.idPartner)
  }

  private _getPartner(id: number): void {
    this.pts.getPartner(id).subscribe({
      next: (resp) => {
        this.partner = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting partner','snack-error')
        this.rt.navigate(['/dash/admin/partners'])
      }
    })
  }


  updatePartner(data: Partner): void {
    this.pts.updatePartner(this.idPartner, data).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating partner','snack-error')
      }
    })
  }

}

<div class="p-3" *ngIf="trackDetail; else noDetail">

  <div class="flex flex-col">

    <!-- Header -->
    <div class="my-2 flex flex-row gap-4">
      <h4 class="!text-lg !m-0 !p-0 !font-bold flex-auto"> Logistic Order: <span class="text-zinc-400">#{{trackDetail.code}}</span></h4>
      <div>
        <h4 class="!text-lg !m-0 !p-0 !font-bold">{{trackDetail.totalPallets}} Pallets </h4>
      </div>
      <div>
        <h4 class="!text-lg !m-0 !p-0 !font-bold">{{trackDetail.totalCost|currency:'EUR'}}</h4>
      </div>
      <div>
        <button
          mat-button
          (click)="generatePDF(trackDetail.id)"
          class="!h-6 !w-6 !flex items-center hover:cursor-pointer !min-w-0 !min-h-min !mx-2 justify-center rounded-lg bg-red-600 object-cover ring-1 ring-red-950/10 hover:bg-red-400 transition-colors duration-500"
        >
          <svg *ngIf="!generating" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="white" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <mat-spinner *ngIf="generating" diameter="15" color="white"></mat-spinner>

        </button>
      </div>
    </div>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <!-- Info -->
    <div class="flex flex-row mb-4">
      <div class="w-6/12">

        <h5 class="!my-4 !p-0 text-slate-600 !text-sm flex gap-2 !font-bold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            data-slot="icon" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
          </svg>
          Order Information
        </h5>

        <div>
          <span class="capitalize text-zinc-400">Load Date</span>
          <br>
          <span class=""> {{trackDetail.departureDate}}</span>
        </div>
        <div class="my-6">
          <span class="capitalize text-zinc-400">Unload Date</span>
          <br>
          <span class=""> {{trackDetail.arrivalDate}}</span>
        </div>
        <div>
          <span class="capitalize text-zinc-400">Drop Off Estimation</span>
          <br>
          <span class=""> {{trackDetail.dropoffEstimation}} days</span>
        </div>

      </div>

      <div class="w-6/12">

        <h5 class="!my-4 !p-0 text-slate-600 !text-sm flex gap-2 !font-bold">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            data-slot="icon" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
          </svg>


          Logistic Supplier Information
        </h5>

        <div>
          <span class="capitalize text-zinc-400">Supplier</span>
          <br>
          <span class=""> {{trackDetail.logisticSupplier.name}}</span>
        </div>
        <div class="my-6">
          <span class="capitalize text-zinc-400">email</span>
          <br>
          <span class=""> {{trackDetail.logisticSupplier.contacts.email}}</span>
        </div>
        <div>
          <span class="capitalize text-zinc-400">Phone</span>
          <br>
          <span class=""> {{trackDetail.logisticSupplier.contacts.phone}}</span>
        </div>

      </div>
    </div>

    <!-- Loads -->
    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <div class="my-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300 text-xs">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Product</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Pallet</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Mixed Group</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Supplier</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Load Place</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Unload Place</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Acions</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let l of trackDetail.loads"  class="hover:bg-slate-100">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">{{l.product.product}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.pallets}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <span (click)="editLogisticLoad(l.id)" class="inline-flex cursor-pointer items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700 hover:animate-pulse duration-500"
                      *ngIf="l.mixedGroup">
                      <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                      </svg>
                      {{ l.mixedGroup }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.supplier}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.loadPlace}}</td>
                  <td class="whitespace-nowrap py-4 pl-3 text-xs text-gray-500">{{l.product.unloadPlace}}</td>
                  <td class="whitespace-nowrap py-4 pl-3 text-xs text-gray-500">
                    <button matTooltip="Move Load to another Logistic Order" matTooltipClass="bg-sky-700 text-xs" mat-button
                      (click)="moveLoad(l.id, trackDetail.id)"
                      class="!h-6 !w-5 !flex items-center group hover:cursor-pointer !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-sky-600 object-cover ring-1 ring-red-950/10 hover:bg-sky-400 transition-colors duration-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
                        class="w-4 h-4 group-hover:animate-spin">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                    
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>


    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300 text-xs">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Files</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let f of trackDetail.files">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                    {{f.filePath|pathBeauty}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<ng-template #noDetail>
  <div class="flex flex-col justify-center items-center">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"
      data-slot="icon" class="w-36 h-36 mt-12">
      <path stroke-linecap="round" stroke-linejoin="round"
        d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3" />
    </svg>
    <h4 class="!text-lg !m-0 !p-0 !font-bold">Select a logistic order to see the details</h4>
  </div>
</ng-template>

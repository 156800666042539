import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreorderService } from 'src/app/features/preorder/services/preorder.service';
import { SnackbarsService } from '../../services/snackbars.service';
import { PartnerService } from 'src/app/features/admin/services/partner.service';
import { PartnerBase } from 'src/app/features/admin/models/partner';

@Component({
  selector: 'app-modal-pay-partners',
  templateUrl: './modal-pay-partners.component.html',
  styleUrls: ['./modal-pay-partners.component.css']
})
export class ModalPayPartnersComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    bankAccountPartnerPaid: ['', Validators.required],
    partnersNotes: [''],
    partners: [[], Validators.required]
  })

  isLoading = false;
  paid?:any;
  partners_list: PartnerBase[] = []

  constructor(
    private dialogRef: MatDialogRef<ModalPayPartnersComponent>,
    private fb: FormBuilder,
    private ps: PreorderService,
    private ss: SnackbarsService,
    private pts: PartnerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this._loadPartners()
  }

  _loadPartners(){
    this.pts.getPartnersByPo(this.data.pos).subscribe({
      next: (r) => this.partners_list = r,
      error: (e) => this.ss.nuevaSnack('Error loading partners', 'snack-error')
    })
  }

  close = () => this.dialogRef.close()

  selectAccount = (bank: string) => this.frm.patchValue({ bankAccountPartnerPaid: bank })


  _payPartners(data: any){
    this.isLoading = true
    this.ps.payPartnersByPo(data).subscribe({
      next: (r) => {
        this.isLoading = false
        this.paid = r
        this.ss.nuevaSnack('Pos paid correctly', 'snack-success')
      },
      error: (e) => {
        this.ss.nuevaSnack(e.error.message ?? 'Error when making payment', 'snack-error')
        this.isLoading = false
      }
    })
  }


  send(){
    if(this.frm.valid){
      // this.dialogRef.close(this.frm.value)
      const data = {
        ...this.frm.value,
        pos: this.data.pos
      }
      this._payPartners(data)
    }
  }


}

import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-bank-modal',
  templateUrl: './select-bank-modal.component.html',
  styleUrls: ['./select-bank-modal.component.css']
})
export class SelectBankModalComponent implements OnInit {

  bankAccount?: string

  constructor(
    private dialogRef:MatDialogRef<SelectBankModalComponent>
  ) { }

  ngOnInit(): void {
  }

  selectAccount = (bank: string) => this.bankAccount = bank


  invoice(){
    this.dialogRef.close({bank: this.bankAccount})
  }



}

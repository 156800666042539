<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <!-- Settings forms -->
  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 lg:px-8">

      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="General">
          <ng-template matTabContent>
            <app-jcv-general-form></app-jcv-general-form>
          </ng-template>
        </mat-tab>

        <mat-tab label="Banks Accounts">
          <ng-template matTabContent>
            <app-jcv-banks-account></app-jcv-banks-account>
          </ng-template>
        </mat-tab>

        <mat-tab label="Notifications">
          <ng-template matTabContent>
            Notificaciones
          </ng-template>
        </mat-tab>

        <mat-tab label="Signatures">
          <ng-template matTabContent>
            Firmas
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </div>
  </div>
</main>

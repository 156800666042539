import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { InvoiceRes, InvoicesList } from '../../models/invoices-list';
import { BillingService } from '../../services/billing.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { InvoiceFilter } from '../../models/invoice-filter';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.css']
})
export class InvoiceTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['isPayed', 'invoiceNumber', 'date', 'payDate', 'payer', 'typeInvoice', 'bank', 'total', 'actions'];
  secondLines?: InvoicesList | null;
  data: InvoiceRes[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  isGeneratingPdf = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: InvoiceFilter = { search: ''}

  constructor(
    private rt: Router,
    private bs: BillingService,
    private ss: SnackbarsService,
  ) { }

  ngAfterViewInit() {
    this._getOrder()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrder()
    }
  }

  private _getOrder(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.bs.searchInvoices(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  edit = (id: number) => this.rt.navigateByUrl(`/dash/invoices/edit/${id}`)


  generatePdf(invoice: number){
    this.isGeneratingPdf = true
    this.bs.generatePdf(invoice).subscribe({
      next: (data) => {
        this.ss.nuevaSnack('Invoice PDF generate successfully', 'snack-success')
        this.isGeneratingPdf = false
      },
      error: (e) => {
        this.ss.nuevaSnack('Error to generate Invoice PDF', 'snack-error')
        this.isGeneratingPdf = false
      }
    })
  }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { PartnerProfitResumen, ReportByPoResumen } from '../../models/report-bypo-resumen';
import { MatDialog } from '@angular/material/dialog';
import { ModalPayPartnersComponent } from 'src/app/shared/components/modal-pay-partners/modal-pay-partners.component';
import { PreorderService } from 'src/app/features/preorder/services/preorder.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ModaPayComercialComponent } from 'src/app/shared/components/moda-pay-comercial/moda-pay-comercial.component';

@Component({
  selector: 'app-resumen-profit-pos-partners',
  templateUrl: './resumen-profit-pos-partners.component.html',
  styleUrls: ['./resumen-profit-pos-partners.component.css']
})
export class ResumenProfitPosPartnersComponent implements OnInit {

  isLoading = false;

  economicInfo?: PartnerProfitResumen[]
  saleTotal = 0;

  @Input() listSelectedPos: string[] = [];
  @Output() remove = new EventEmitter<string[]>();
  constructor(
    private dialog: MatDialog,
    private rs: ReportsService,
    private ps: PreorderService,
    private ss: SnackbarsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if(changes['listSelectedPos'].currentValue.length == 0){
      this.economicInfo = undefined
    // }


  }


  removePo(po: string){
    this.listSelectedPos = this.listSelectedPos.filter(p => p !== po)
    this.remove.emit(this.listSelectedPos)
  }


  loadResumen(){
    this.isLoading = true
    this.rs.reportByPo(this.listSelectedPos).subscribe({
      next: (r) => {
        this.economicInfo = r.res.map( e => {
          return {
            ...e,
            total: e.product.reduce((a, b) => a + b.profit, 0),

          }
        })
        this.saleTotal = r.comercial
        this.isLoading = false
      },
      error: (e) => {
        console.log(e)
        this.isLoading = false
      }
    })
  }


  payPartners(){
    this.dialog.open(ModalPayPartnersComponent, {
      data: {
        pos: this.listSelectedPos
      }
    }).afterClosed().subscribe( r => {
      // TODO! Mensaje
    })
  }

  payComercial(){
    this.dialog.open(ModaPayComercialComponent, {
      data: {
        pos: this.listSelectedPos
      }
    }).afterClosed().subscribe( r => {
      if (r){
        this.ps.payComercial({pos: this.listSelectedPos, ...r}).subscribe({
          next: (resp) => this.ss.nuevaSnack('The payment certificate has been generated / updated and marked as paid to the sales representative. ', 'snack-success'),
          error: (e) => this.ss.nuevaSnack(e.error.message || 'Error to pay comercial', 'snack-error')
        })
      }
    })
  }

  payByClient(){
    this.ps.payPartnersByClient({pos: this.listSelectedPos}).subscribe({
      next: (r) => this.ss.nuevaSnack(`The Pos have been marked as paid by client`, 'snack-success'),
      error: (e) => this.ss.nuevaSnack(`Error to pay partners by client`, 'snack-error')
    })
  }



}

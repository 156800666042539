import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryHomeComponent } from './pages/delivery-home/delivery-home.component';
import { DeliveryEditComponent } from './pages/delivery-edit/delivery-edit.component';
import { DeliveryTableComponent } from './components/delivery-table/delivery-table.component';
import { DeliveryFrmComponent } from './components/delivery-frm/delivery-frm.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeliveryFilterComponent } from './components/delivery-filter/delivery-filter.component';
import { ModalPropertiesLoadComponent } from './components/modal-properties-load/modal-properties-load.component';



@NgModule({
  declarations: [
    DeliveryHomeComponent,
    DeliveryEditComponent,
    DeliveryTableComponent,
    DeliveryFrmComponent,
    DeliveryFilterComponent,
    ModalPropertiesLoadComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class DeliveryModule { }

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OrderCost, OrderCostList } from '../../models/order-costs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { OrderService } from '../../services/order.service';
import { catchError, map, merge, of, startWith, switchMap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-order-costs-table',
  templateUrl: './order-costs-table.component.html',
  styleUrls: ['./order-costs-table.component.css']
})
export class OrderCostsTableComponent implements AfterViewInit {

  displayColumns: string[] = ['date', 'typed', 'concept', 'product', 'quantity', 'price', 'total', 'actions'];
  concepts?: OrderCostList
  data: OrderCost[] = []

  resultsLength = 0
  isLoading = true
  isRateLimitReached = false

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Output() editCost = new EventEmitter<OrderCost>();
  @Input() orderId?: string = undefined

  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private os: OrderService
  ) { }

  ngAfterViewInit(): void {
    this.getConcepts()
  }

  getConcepts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true
          return this.os.getOrderCostByOrder(this.orderId!, this.paginator!.pageIndex + 1)
            .pipe(catchError(() => of(null)))
        }),
        map( data => {
          this.isLoading = false
          this.isRateLimitReached = data === null

          if (data === null) return []

          this.resultsLength = data.count
          return data.results
        })
      ).subscribe( data => this.data = data)
  }

  edit = (cost: OrderCost) => this.editCost.emit(cost)

  deleteCost(id: number): void {
    this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete Order Cost',
        messge: 'Are you sure to delete this order cost?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    }).afterClosed().subscribe( res => {
      if (res) {
        this.os.deleteOrderCost(id).subscribe( () => this.getConcepts() )
      }
    })
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Incoterms } from '../models/incoterms';
import { Observable } from 'rxjs';

const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class IncotermsService {

  constructor( private http: HttpClient) { }

  getAll(): Observable<Incoterms> {
    return this.http.get<Incoterms>(`${baseUrl}/incoterms`);
  }

}

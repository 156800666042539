<div class="mt-8">
  <div *ngIf="isLoading || isRateLimitReached">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <div *ngIf="isRateLimitReached">
      Order Costs
    </div>
  </div>

  <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="date" matSortDisableClear
    matSortDirection="desc">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row)" class="hover:cursor-pointer px-2">
        {{row.date | date: 'dd/MM/yyyy'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Qty.</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row)" class="hover:cursor-pointer">
        {{row.quantity}}
      </td>
    </ng-container>

    <ng-container matColumnDef="concept">
      <th mat-header-cell *matHeaderCellDef>Concept</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row)" class="hover:cursor-pointer">
        {{row.costType.name}}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row)" class="hover:cursor-pointer">
        <app-badget class="bg-purple-50 text-purple-700 ring-purple-600/20" [text]="row.price|currency:'EUR'"></app-badget>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row)" class="hover:cursor-pointer">
        <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|currency:'EUR'"></app-badget>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="w-20"></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button aria-label="Delete Product" (click)="deleteCost(row.id)">
          <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayColumns.length">
        No Cost found
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns;"
      class="hover:bg-slate-200 transition-colors duration-500"></tr>
  </table>
</div>

<mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
  aria-label="Select Order Cost search results"></mat-paginator>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SharedInfoService } from '../../services/shared-info.service';
import { Languages } from '../../models/languages-response';
import { BaseCode } from '../../models/base-code';

@Component({
  selector: 'app-input-language',
  templateUrl: './input-language.component.html',
  styleUrls: ['./input-language.component.css']
})
export class InputLanguageComponent implements OnInit {

  language = new FormControl('', Validators.required)

  @Input() languageId?: number
  @Output() languageChange = new EventEmitter<string>()

  languages:BaseCode[] = []

  constructor(
    private ss: SharedInfoService
  ) { }

  ngOnInit(): void {
    this._loadLanguage()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['languageId']?.currentValue) this.language.setValue(changes['languageId']?.currentValue)
  }

  private _loadLanguage() {
    this.ss.getLanguages().subscribe( resp => this.languages = resp.results)
  }

  onLanguageChange() {
    this.languageChange.emit(this.language.value || '')
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CreditNoteFilter } from '../../models/credit-filter';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';

@Component({
  selector: 'app-credit-filter',
  templateUrl: './credit-filter.component.html',
  styleUrls: ['./credit-filter.component.css']
})
export class CreditFilterComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    search: [''],
  })

  @Output() onSearch: EventEmitter<CreditNoteFilter> = new EventEmitter<CreditNoteFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }



  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Country</mat-label>
    <input type="text" placeholder="Search country" matInput [formControl]="country" [matAutocomplete]="autoCountry">
    <mat-autocomplete #autoCountry="matAutocomplete" [displayWith]="showCountry" (optionSelected)="changeCountry($event)">
      <mat-option *ngFor="let option of countriesFilter" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

<div *ngIf="frm" class="px-3">

  <mat-accordion class="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">

          <mat-form-field appearance="outline" class="sm:col-span-4">
            <mat-label> Search </mat-label>
            <input matInput type="text" formControlName="search">
            <mat-hint class="text-sky-700">You cand search by code, notes, product name</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" class="sm:col-span-2">
            <mat-label> Departure </mat-label>
            <mat-date-range-input [formGroup]="frm" [rangePicker]="picker">
              <input matStartDate formControlName="departureDate_after" placeholder="After">
              <input matEndDate formControlName="departureDate_before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <app-input-clients (onSelect)="updateClient($event)" class="sm:col-span-2"></app-input-clients>
          <app-input-suppliers (onSelect)="updateSupplier($event)" class="sm:col-span-2"></app-input-suppliers>

            <mat-form-field appearance="outline" class="sm:col-span-1">
              <mat-label>Type</mat-label>
              <mat-select formControlName="preType" multiple>
                <mat-option value="COMMISSION">Commission</mat-option>
                <mat-option value="BUY">Buy</mat-option>
                <mat-option value="SELL">Sell</mat-option>
              </mat-select>
            </mat-form-field>



            <mat-form-field appearance="outline" class="sm:col-span-1">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" multiple>
                <mat-option value="DRAFT">Draft</mat-option>
                <mat-option value="LOGISTIC">Logistic Generated</mat-option>
                <mat-option value="SEND">Sended</mat-option>
                <mat-option value="CUSTOMS">Received Customs</mat-option>
                <mat-option value="SUPPLIER">Supplier Paid</mat-option>
                <mat-option value="TRANSPORT">Transport Paid</mat-option>
                <mat-option value="INVOICED_SUPPLIER">Supplier Invoiced</mat-option>
                <mat-option value="INVOICED_LOGISTIC">Logistic Invoiced</mat-option>
                <mat-option value="PAID">Paid</mat-option>
              </mat-select>
            </mat-form-field>




            <mat-form-field appearance="outline" class="sm:col-span-1">
              <mat-label>Invoiced?</mat-label>
              <mat-select formControlName="isInvoiced">
                <mat-option value="True">It's Invoiced</mat-option>
                <mat-option value="False">Not Invoiced</mat-option>
              </mat-select>
            </mat-form-field>

          <mat-form-field appearance="outline" class="sm:col-span-1">
            <mat-label> Nº Invoice </mat-label>
            <input matInput type="text" formControlName="invoice">
          </mat-form-field>


            <mat-form-field appearance="outline" class="sm:col-span-1">
              <mat-label>Logistic</mat-label>
              <mat-select formControlName="hasLogistic">
                <mat-option value="True">It Has Logistic</mat-option>
                <mat-option value="False">No Logistics</mat-option>
              </mat-select>
            </mat-form-field>


          <mat-form-field appearance="outline" class="sm:col-span-1">
            <mat-label> Plate </mat-label>
            <input matInput type="text" formControlName="plate">
          </mat-form-field>

          <div class="actions w-2/12 text-end pb-2">
            <button mat-raised-button class="bg-red-600 text-white" (click)="clean()">Clean</button>
          </div>


        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarsService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }


  nuevaSnack(mensaje: string, color: string = 'snack-neutral'){
    this._snackBar.open(mensaje, 'Close', {
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 3500,
      panelClass: [color]
    });
  }

}

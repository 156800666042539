import { Component, Inject, OnInit } from '@angular/core';
import { OrderService } from '../../services/order.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductPreorder } from 'src/app/features/preorder/models/product-preorder';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductsService } from 'src/app/features/admin/services/products.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ProductOrderRes } from '../../models/product-order-res';
import { Order } from '../../models/list-orders';
import { Packaging } from 'src/app/features/admin/models/packaging';
import { PackagingService } from 'src/app/features/admin/services/packaging.service';

@Component({
  selector: 'app-product-order-frm',
  templateUrl: './product-order-frm.component.html',
  styleUrls: ['./product-order-frm.component.css']
})
export class ProductOrderFrmComponent implements OnInit {

  frm?: FormGroup = this.fb.group({});

  productOrder?: ProductPreorder;

  productName?: string;
  productId?: number;
  supplierName?: string;
  supplierId?: number;

  coinId?: number

  loadPlaceInfo?: {id: number, name: string};
  unLoadPlaceInfo?: {id: number, name: string};
  supplierInfo = {isMe: false, id: 0}
  clientInfo = {isMe: false, id: 0}

  packaging_list: Packaging[] = []
  partners_selected?: number[]

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ProductOrderFrmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {idProductOrder?: number, order: Order, typePreorder: string},
    private ps: ProductsService,
    private ss: SnackbarsService,
    private os: OrderService,
    private pcks: PackagingService,
  ) {
  }

  ngOnInit(): void {
    // Init Data
    this.clientInfo = {isMe: this.data.order.preorder.client.isMe, id: this.data.order.preorder.client.id}

    if (this.data.order.preorder.client.logistic_default) {
      this.unLoadPlaceInfo = {
        id: this.data.order.preorder.client.logistic_default.id,
        name: this.data.order.preorder.client.logistic_default.name
      }
    }

    if (this.data.order.supplier.logistic_default){
      this.loadPlaceInfo = {
        id: this.data.order.supplier.logistic_default.id,
        name: this.data.order.supplier.logistic_default.name
      }
    }






    this._loadPackaging()
    this._buildForm();
    if (this.data.idProductOrder) {
      this._getOrderProduct(this.data.idProductOrder)
    }else{
      this._init();
    }

    this._listener();
  }

  private _init() {

    this.productOrder = new ProductPreorder({
      palletCost: this.data.order.preorder.palletCost ?? 180,
      changeCoin: this.data.order.currencyChange,
      payLogistic: (this.data.order.client.payLogistic) ? 'CLIENT' : 'SUPPLIER',
      commisionPercentage: 4,
      coin: this.data.order.client.coin.id,
      coinName: this.data.order.client.coin.code,
    });

    this.coinId = this.data.order.client.coin.id
    this.changeSupplier(this.data.order.supplier)
    this.supplierName = this.data.order.supplier.name
    this.supplierId = this.data.order.supplier.id

  }

  private _loadPackaging(): void{
    this.pcks.getPackagings().subscribe( r => this.packaging_list = r.results )
  }

  private _getOrderProduct(id: number) {
    this.os.getProductOrder(id).subscribe({
      next: (resp) => this._initProductOrderEdition(resp),
      error: () => {
        this.ss.nuevaSnack('Error to load product info', 'snack-error')
        this.dialogRef.close()
      }
    })
  }

  private _initProductOrderEdition(product:ProductOrderRes){
    this.productOrder = new ProductPreorder({
      palletCost: product.palletCost,
      changeCoin: this.data.order.currencyChange,
      payLogistic: product.payLogistic,
      commisionPercentage: product.commisionPercentage,
      coin: product.coin.id,
      coinName: product.coin.code,
      payCommission: product.payCommission,
      isMixed: product.isMixed,
      isExtra: product.isExtra,
      product: product.product,
      isMe: product.supplier.isMe,
      productName: product.product.name,
      supplier: product.supplier.id,
      price: product.price,
      kgsBox: product.kgsBox,
      palletBox: product.palletBox,
      pallets: product.pallets,
      totalBoxes: product.totalBoxes,
      boxCost: product.boxCost,
      deliveryPrice: product.deliveryPrice,
      logisticCost: product.logisticCost,
      invoicedPrice: product.invoicedPrice,
      boxCommission: product.boxCommission,
      totalCommission: product.totalCommission,
      myInvoicedPrice: product.myInvoicedPrice,
      total: product.total,
    })


    this.frm?.patchValue({
      ...product,
      coin_id: product.coin.id,
      supplier_id: product.supplier.id,
      loadPlace_id: product.loadPlace!.id,
      unloadPlace_id: product.unloadPlace!.id,
      product_id: product.product.id,
      partners: product.partners,
    }, {emitEvent: false})

    this.loadPlaceInfo = {id: product.loadPlace!.id, name: product.loadPlace!.name}
    this.unLoadPlaceInfo = {id: product.unloadPlace!.id, name: product.unloadPlace!.name}
    this.supplierInfo = {id: product.supplier.id, isMe: product.supplier.isMe}

    this.partners_selected = product.partners

    this.coinId = product.coin.id
    this.productName = `${product.product.name} ${product.product.description} ${product.product.size.size} | Cat.: ${product.product.category}`
    this.productId = product.product.id
    this.supplierName = product.supplier.name
    this.supplierId = product.supplier.id
  }


  private _buildForm() {

    this.frm = this.fb.group({
      order_id: [this.data.order.id, Validators.required],
      product_id: ['', Validators.required],
      supplier_id: ['', Validators.required],
      coin_id: [this.data.order.client.coin.id ?? '', Validators.required],
      loadPlace_id: [(this.data.order.supplier.logistic_default) ? this.data.order.supplier.logistic_default.id : '', Validators.required],
      unloadPlace_id: [(this.data.order.preorder.client.logistic_default) ? this.data.order.preorder.client.logistic_default.id : '', Validators.required],
      isMixed: [false, Validators.required],
      isExtra: [false, Validators.required],
      netWeight: [0],
      grossWeight: [0],
      lot: [''],
      origin: [''],
      payLogistic: [(this.data.order.client.payLogistic) ? 'CLIENT' : 'SUPPLIER', Validators.required],
      payCommission: ['', Validators.required],
      price: ['',  Validators.required],
      kgsBox: ['', Validators.required],
      palletBox: ['', Validators.required],
      palletCost: [180, Validators.required],
      pallets: ['', Validators.required],
      totalBoxes: ['', Validators.required],
      boxCost: ['', Validators.required],
      deliveryPrice: ['', Validators.required],
      logisticCost: ['', Validators.required],
      commisionPercentage: [4, Validators.required],
      invoicedPrice: ['', Validators.required],
      boxCommission: ['', Validators.required],
      totalCommission: ['', Validators.required],
      myInvoicedPrice: ['', Validators.required],
      total: ['', Validators.required],
      sale_price: [''],
      sale_palletCost: [''],
      sale_payLogistic: [''],
      packaging: [''],
      partners: [],
    });
  }

  private _getInfoProduct(id: number) {
    this.ps.getProduct(id, this.data.order.id.toString()).subscribe({
      next: (resp) => {
        let buy_price = 0;
        let sale_price = 0
        const productInfo = this.productOrder!.setBaseProductoInfo(resp);
        this.partners_selected = resp.partner;

        if(resp.exception_price){
          buy_price = resp.exception_price.buy_price;
          sale_price = resp.exception_price.sale_price;
        }else{
          buy_price = productInfo.price
          sale_price = 0
        }

        this.frm?.patchValue({
          ...productInfo,
          price: buy_price,
          sale_price: sale_price,
        }, {emitEvent: true});
        this.frm?.patchValue({
          origin: resp.origin,
          packaging: resp.packaging,
          partners: (this.data.order.preorder.typeOfPreOrder === 'BUY') ? resp.partner : null,
        },{emitEvent: false})
      },
      error: () => {
        this.ss.nuevaSnack('Error to load product info', 'snack-error' );
      }
    });
  }



  private _listener() {
    this.price?.valueChanges.subscribe((r) => {
      this.productOrder!.price = r
      this._updateCalculations()
    })


    this.payCommission?.valueChanges.subscribe((r) => {
      this.productOrder!.payCommission = r
      if (r === 'ZERO') {
        this.commisionPercentage?.setValue(0, {emitEvent: false})
      }
      this._updateCalculations()
    })
    this.palletCost?.valueChanges.subscribe((r) => {
      this.productOrder!.palletCost = r
      this._updateCalculations()
    })
    this.payLogistic?.valueChanges.subscribe((r) => {
      this.productOrder!.payLogistic = r
      this._updateCalculations()
    })
    this.pallets?.valueChanges.subscribe((r) => {
      this.productOrder!.pallets = r
      this._updateCalculations()
    })
    this.isMixed?.valueChanges.subscribe((r) => {
      this.productOrder!.isMixed = r
      this._updateCalculations()
    })
    this.palletBox?.valueChanges.subscribe((r) => {
      this.productOrder!.palletBox = r
      this._updateCalculations()
    })
    this.kgsBox?.valueChanges.subscribe((r) => {
      this.productOrder!.kgsBox = r
      this._updateCalculations()
    })
    this.totalBoxes?.valueChanges.subscribe((r) => {
      this.productOrder!.totalBoxes = r
      this._updateCalculations()
    })
    this.commisionPercentage?.valueChanges.subscribe((r) => {
      this.productOrder!.commisionPercentage = r
      this._updateCalculations()
    })

  }


  private _updateCalculations() {
    const calculos = this.productOrder!.calculateProductPreorder()
      this.frm?.patchValue({...calculos}, {emitEvent: false});
  }

  changePartner = (partners: any) => this.frm?.patchValue({partners: partners})

  changeProduct = (product: any) => {
    this.frm?.patchValue({product_id: product})

    if (product) {
      this._getInfoProduct(product);
    }else{
      this.frm?.patchValue({product: ''})
    }

  };

  changeSupplier = (supplier: any) => {
    this.frm?.patchValue({supplier_id: supplier.id, commisionPercentage: supplier.commission})
    this.productOrder!.isMe = supplier.isMe;
    this.supplierInfo = {id: supplier.id, isMe: supplier.isMe}
  }

  changeCoin = (coin: any) => {
    this.frm?.patchValue({coin_id: coin.id})
    this.productOrder!.coin = coin.id
    this.productOrder!.coinName = coin.code

    this.productOrder!.setPrice(this.price?.value)
    this.frm?.patchValue({price: this.productOrder?.price}, {emitEvent: false})

    this._updateCalculations()
  }


  changeLoadPlace = (loadPlace: any) => this.frm?.patchValue({loadPlace_id:loadPlace})
  changeUnloadPlace = (unloadPlace: any) => this.frm?.patchValue({unloadPlace_id: unloadPlace})

  sendData(){
    if (this.frm?.invalid) return;

    this.dialogRef.close(this.frm?.value);
  }




  get product() : AbstractControl | null {
    return this.frm!.get('product_id');
  }

  get coin(): AbstractControl | null {
    return this.frm!.get('coin');
  }

  get isMixed(): AbstractControl | null {
    return this.frm!.get('isMixed');
  }

  get payLogistic() : AbstractControl | null {
    return this.frm!.get('payLogistic')
  }

  get payCommission() : AbstractControl | null {
    return this.frm!.get('payCommission')
  }

  get price() : AbstractControl | null {
    return this.frm!.get('price')
  }

  get kgsBox() : AbstractControl | null {
    return this.frm!.get('kgsBox');
  }

  get palletBox() : AbstractControl | null {
    return this.frm!.get('palletBox')
  }

  get palletCost() : AbstractControl | null {
    return this.frm!.get('palletCost');
  }

  get pallets() : AbstractControl | null {
    return this.frm!.get('pallets');
  }

  get totalBoxes() : AbstractControl | null {
    return this.frm!.get('totalBoxes');
  }

  get boxCost() : AbstractControl | null {
    return this.frm!.get('boxCost')
  }

  get deliveryPrice() : AbstractControl | null {
    return this.frm!.get('deliveryPrice');
  }

  get logisticCost() : AbstractControl | null {
    return this.frm!.get('logisticCost');
  }

  get commisionPercentage() : AbstractControl | null {
    return this.frm!.get('commisionPercentage');
  }

  get invoicedPrice() : AbstractControl | null {
    return this.frm!.get('invoicedPrice');
  }

  get boxCommission() : AbstractControl | null {
    return this.frm!.get('boxCommission');
  }

  get totalCommission() : AbstractControl | null {
    return this.frm!.get('totalCommission');
  }

  get myInvoicedPrice() : AbstractControl | null {
    return this.frm!.get('myInvoicedPrice');
  }

  get total() : AbstractControl | null {
    return this.frm!.get('total');
  }

}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Update Delivery Note
      <small class="block font-light text-xs text-slate-500 w-full"> Update general of Order.</small>
    </h2>


  </div>

  <!-- Form -->
  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 lg:px-8 bg-white mt-4">

      <app-delivery-frm (sendData)="updateDelivery($event)" [delivery]="delivery" *ngIf="delivery" (refresh)="refresh($event)"></app-delivery-frm>

    </div>
  </div>


</main>

import { Component, OnInit } from '@angular/core';
import { Product } from '../../../models/products';
import { ProductsService } from '../../../services/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-products-edit',
  templateUrl: './products-edit.component.html',
  styleUrls: ['./products-edit.component.css']
})
export class ProductsEditComponent implements OnInit {

  idProduct = this.ar.snapshot.params['id']
  product?: Product

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Products', url: '/dash/admin/products'},
    {name: 'Update Product'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ps: ProductsService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getProduct(this.idProduct)
  }

  private _getProduct(productId: number): void {
    this.ps.getProduct(productId).subscribe({
      next: (resp) => {
        this.product = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting product','snack-error')
        this.rt.navigate(['/dash/admin/sizes'])
      }
    })
  }


  updateProduct(product: Product): void {
    this.ps.updateProduct(this.idProduct, product).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`${resp.name} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating product','snack-error')
      }
    })
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { InvoiceRes } from '../../models/invoices-list';

@Component({
  selector: 'app-invoice-frm',
  templateUrl: './invoice-frm.component.html',
  styleUrls: ['./invoice-frm.component.css']
})
export class InvoiceFrmComponent implements OnInit {

  public frm?: FormGroup;
  @Input() invoice?:InvoiceRes
  @Output() sendData = new EventEmitter<InvoiceRes>()

  supplierId?:     number;
  supplierName?:   string;
  clientId?:       number;
  clientName?:     string;
  coinId?:         number;
  bankId?:         number;
  coinCode?:       string;
  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.invoice) this._setInvoice(this.invoice!)
  }


  private _createForm(): void{
    this.frm = this.fb.group({
      date: ['', Validators.required],
      payDate: [''],
      isPayed: ['False'],
      notes: [''],
      banckAccount: ['', Validators.required],
      payerClient_id: [''],
      payerSupplier_id: [''],
      coin_id: ['', Validators.required],
      currencyExchange: ['', Validators.required],
    })
  }

  private _setInvoice(invoice: InvoiceRes): void{


    this.frm!.patchValue({
        ...invoice,
        isPayed: invoice.isPayed ? 'True' : 'False',
        payerClient_id: invoice.payerClient?.id || null,
        payerSupplier_id: invoice.payerSupplier?.id || null,
        coin_id: invoice.coin!.id
    })
    this.supplierId = invoice.payerSupplier?.id
    this.supplierName = invoice.payerSupplier?.name
    this.clientId = invoice.payerClient?.id
    this.clientName = invoice.payerClient?.name
    this.coinCode = invoice.coin?.code
    this.coinId = invoice.coin.id
    this.bankId = invoice.banckAccount
  }


  setClientPayer = (client: string): void => this.frm!.get('payerClient_id')!.setValue(client)
  setSupplierPayer = (supplier: string): void => this.frm!.get('payerSupplier_id')!.setValue(supplier)
  setBanckAccount = (bank: string): void => this.frm!.get('banckAccount')!.setValue(bank)
  setCoin = (coin: {id: string, code: string}): void => this.frm!.get('coin_id')!.setValue(coin.id)

  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      payerClient_id: this.frm?.value.payerClient_id || null,
      payerSupplier_id: this.frm?.value.payerSupplier_id || null,
      invoiceNumber: this.invoice?.invoiceNumber || null,
      date: moment(this.frm!.value.date).format('YYYY-MM-DD'),
      payDate: (this.frm!.value.payDate) ? moment(this.frm!.value.payDate).format('YYYY-MM-DD') : null,
    }

    this.sendData.emit(data);
  }
}

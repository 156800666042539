import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ListOrders, Order } from '../../models/list-orders';
import { OrderFilter } from '../../models/order-filter';
import { OrderService } from '../../services/order.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { DeliveryService } from 'src/app/features/delivery/services/delivery.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { EmailModalComponent } from 'src/app/shared/components/email-modal/email-modal.component';
import { DatePipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { BillingService } from 'src/app/features/billing/services/billing.service';
import { ModalDataInvoiceComponent } from '../modal-data-invoice/modal-data-invoice.component';

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.css']
})
export class OrdersTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['id', 'type', 'po', 'status', 'client', 'supplier', 'departure', 'commision', 'total', 'actions'];
  orders?: ListOrders | null;
  data: Order[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  status_order = [
    {
      code: 'DRAFT',
      name: 'Draft'
    },
    {
      code: 'LOGISTIC',
      name: 'Logistic'
    },
    {
      code: 'SEND',
      name: 'Send'
    },
    {
      code: 'CUSTOMS',
      name: 'Customs'
    },
    {
      code: 'SUPPLIER',
      name: 'Supplier'
    },
    {
      code: 'TRANSPORT',
      name: 'Transport'
    },
    {
      code: 'INVOICED_SUPPLIER',
      name: 'Invoiced Supplier'
    },
    {
      code: 'INVOICED_LOGISTIC',
      name: 'Invoiced Logistic'
    },
    {
      code: 'PAID',
      name: 'Paid'
    },
    {
      code: 'CANCELED',
      name: 'Canceled'
    },
]


  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: OrderFilter = { search: ''}

  constructor(
    private rt: Router,
    private dp: DatePipe,
    private dialog: MatDialog,
    private bs: BillingService,
    private os: OrderService,
    private ds: DeliveryService,
    private ss: SnackbarsService
  ) { }

  ngAfterViewInit() {
    this._getOrders()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrders()
    }
  }

  private _getOrders(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.os.searchOrders(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  edit = (id: number) => this.rt.navigateByUrl(`/dash/orders/edit/${id}`)

  generateDelivery(order:number){
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Generate Delivery Note',
        message: 'Do you want to generate the delivery note for this order?',
        cancel: 'Cancel',
        accept: 'Generate'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.ds.generateDeliveryNote(order).subscribe({
          next: (r) => this.ss.nuevaSnack('Delivery note correctly generated', 'snack-success'),
          error: (e) => this.ss.nuevaSnack('Error generating delivery note', 'snack-error')
        })
      }
    })
  }

  sendEmail(order:Order){

    this.dialog.open(EmailModalComponent, {
      data:{
        id: order.id,
        supplier: order.supplier.id,
        client: order.preorder.client.id,
        hasFile: order.hasFile,
        type_preorder: order.preorder.typeOfPreOrder,
        title: `🔔 ${order.client.name} ${this.dp.transform(order.departureDate,'dd-MM-yyyy')} - ${order.supplier.name} - ${order.preorder.po}`,
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this._getOrders()
      }
    })

  }


  changeStatusOrder = (id: number, status: string) => {
    this.os.updateOrder(id,{status: status}).subscribe({
      next: (r) =>  {
        this.data.filter( o => o.id === id)[0].status = status
      },
      error: (e) => this.ss.nuevaSnack('Error changing status', 'snack-error')
    })
  }


}

// Generated by https://quicktype.io

export interface PackagingResponse  {
  count:    number;
  next:     string;
  previous: string;
  results:  Packaging[];
}

export interface Packaging {
  id:      number;
  name:    string;
  price:   number;
  default: boolean;
}


export interface PackagingFrm {
  name:    string;
  price:   number;
  default: boolean;
}

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Product</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.name}} {{row.description}}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Cat.</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.category}}</td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.size.size}}</td>
      </ng-container>

      <ng-container matColumnDef="kilosBox">
        <th mat-header-cell *matHeaderCellDef>Kgs / Box</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.kilosBox}}</td>
      </ng-container>


      <ng-container matColumnDef="boxPallet">
        <th mat-header-cell *matHeaderCellDef>Box / Pallet </th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.boxPallet}}</td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef>Price</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer" (click)="edit(row.id)">{{row.price|currency:'EUR'}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Bank Account" (click)="deleteProduct(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Products found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select product search results"></mat-paginator>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NonConformity } from '../../models/non-conformities';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-non-conformities-frm',
  templateUrl: './non-conformities-frm.component.html',
  styleUrls: ['./non-conformities-frm.component.css']
})
export class NonConformitiesFrmComponent implements OnInit {

  public frm?: FormGroup;

  @Input() nonConformity?:NonConformity
  @Output() sendData = new EventEmitter<NonConformity>()


  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.nonConformity){
      this._setNonConformity(this.nonConformity)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      po: [''],
      lot: [''],
      detection: ['', Validators.required],
      detectionDate: ['', Validators.required],
      detectionDescription: [''],
      typeOfDectection: ['', Validators.required],
      responsibleDetection: [''],
      deadline: [''],
      correctionDescription: [''],
      isChecked: [false],
      isRevised: [false],
      closingDate: [''],
      responsibleCorrection: [''],
      responsibleCorrectiveAction: [''],
      correctiveActionDeadline: [''],
      solution: [''],
      isEffective: [false],
      observationEffectiveness: [''],
      responsibleFinalCorrectiveAction: [''],
      closingDateCorrectiveAction: [''],

    });
  }

  _setNonConformity(nonConformity: NonConformity): void{
      this.frm!.patchValue({
        ...nonConformity,
      })
  }


  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      detectionDate: (this.frm!.value.detectionDate) ? moment(this.frm!.value.detectionDate).format('YYYY-MM-DD') : null,
      closingDate: (this.frm!.value.closingDate) ? moment(this.frm!.value.closingDate).format('YYYY-MM-DD') : null,
      closingDateCorrectiveAction: (this.frm!.value.closingDateCorrectiveAction) ? moment(this.frm!.value.closingDateCorrectiveAction).format('YYYY-MM-DD') : null,
      departureDate: (this.frm!.value.departureDate) ? moment(this.frm!.value.departureDate).format('YYYY-MM-DD') : null,
    }

    this.sendData.emit(data);
  }


}

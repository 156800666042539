import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ProductsService } from '../../../services/products.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { ClientsService } from '../../../services/clients.service';

@Component({
  selector: 'app-exception-price-frm',
  templateUrl: './exception-price-frm.component.html',
  styleUrls: ['./exception-price-frm.component.css']
})
export class ExceptionPriceFrmComponent implements OnInit {

  public frm = this.fb.group({
    client_id: ['', Validators.required],
    buy_price: ['', Validators.required],
    sale_price: ['', Validators.required],
    product_id: [this.data.idProduct]
  })

  public clientId?: number;
  public clientName?: string;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<ExceptionPriceFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id?: number, idProduct: number},
    private ps: ProductsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    if (this.data.id){
      this._getExceptionPrice()
    }
  }

  setClient = (client: string): void => {
    this.frm!.get('client_id')!.setValue(client)
  }

  private _getExceptionPrice(): void{
      this.ps.getRulePriceByClient(this.data.id!).subscribe({
        next: (data) => {
          this.frm.patchValue({
            client_id: data.client.id.toString(),
            buy_price: data.buy_price ?? 0,
            sale_price: data.sale_price ?? 0
          });
          this.clientId = data.client.id;
          this.clientName = data.client.name;
        },
        error: (error) => {
          this.ss.nuevaSnack('Error getting account data', 'snack-error');
            this.bsheet.dismiss();
        }
      })
  }


  _create(){
    this.ps.addRulePriceByClient(this.frm.value).subscribe({
      next: () => {
        this.ss.nuevaSnack('The exception price has been created', 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating the exception price', 'snack-error');
      }
    })
  }

  _update(){
    this.ps.updateRulePriceByClient(this.frm.value, this.data.id!).subscribe({
      next: () => {
        this.ss.nuevaSnack('The exception price has been updated', 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating the exception price', 'snack-error');
      }
    })
  }

  send(): void{
    if (this.frm.invalid) return;

    if (this.data.id){
      this._update()
    }else{
      this._create()
    }
  }

  close(): void{
    this.bsheet.dismiss();
  }


}

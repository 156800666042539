import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SizesService } from '../../../services/sizes.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { Size } from '../../../models/sizes';

@Component({
  selector: 'app-sizes-create',
  templateUrl: './sizes-create.component.html',
  styleUrls: ['./sizes-create.component.css']
})
export class SizesCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Sizes', url: '/dash/admin/sizes'},
    {name: 'New intrastat'},
  ]

  constructor(
    private rt: Router,
    private szs: SizesService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createSize(size: Size): void {
    this.szs.addSize(size).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Size ${resp.size} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/sizes'])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating size','snack-error')
      }
    })
  }
}

// Generated by https://quicktype.io

import { BaseCode } from "src/app/shared/models/base-code";

export interface LogisticSuppliers {
  count:    number;
  next:     null;
  previous: null;
  results:  LogisticSupplier[];
}

export interface LogisticSupplier {
  id:         number;
  name:       string;
  vatNumber:  string;
  address:    string;
  city:       string;
  zipCode:    string;
  province:   string;
  loadsCount: number;
  country:    BaseCode;
  byDefault:  boolean;
}

export interface LogisticSupplierContacts {
  count:      number;
  next?:      string;
  previous?:  string;
  results:    LogisticSupplierContact[];
}

export interface LogisticSupplierContact {
  id?:                number;
  name:               string;
  email:              string;
  phone:              string;
  toSend:             boolean;
  logisticSupplier:   number;
}


export interface LogisticSupplierMinimal {
  id:         number;
  name:       string;
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-update-plate',
  templateUrl: './modal-update-plate.component.html',
  styleUrls: ['./modal-update-plate.component.css']
})
export class ModalUpdatePlateComponent implements OnInit {

  frm: FormGroup = new FormGroup({
    plate: new FormControl('', Validators.required)
  })

  constructor(
    private dialogRef: MatDialogRef<ModalUpdatePlateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {order: number}
    ) { }

  ngOnInit(): void {
  }


  send(){
    if(this.frm.invalid) return

    this.dialogRef.close(this.frm.value)

  }

}

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { ProductsSuppliers, ProductSupplier } from '../../models/products-supplier';
import { SupplierService } from '../../services/supplier.service';
import { SupplierContactFrmComponent } from '../supplier-contact-frm/supplier-contact-frm.component';
import { SupplierProductsFrmComponent } from '../supplier-products-frm/supplier-products-frm.component';

@Component({
  selector: 'app-supplier-products',
  templateUrl: './supplier-products.component.html',
  styleUrls: ['./supplier-products.component.css']
})
export class SupplierProductsComponent implements AfterViewInit {

  idSupplier = this.ar.snapshot.params['id']

  displayedColumns: string[] = ['product', 'boxes', 'price', 'certifications', 'actions'];
  products?: ProductsSuppliers | null;
  data: ProductSupplier[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  searched = { supplier: this.idSupplier, text: ''}

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private ar: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private sps: SupplierService,
    private is: CustomIconsService,
  ) {
  }

  ngAfterViewInit() {
    this._getProducts()
  }

  private _getProducts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sps.searchProductsSupplier(
            this.searched
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  search = (data: {text:string}) => {
    this.searched.text = data.text
    this._getProducts()
  }

  openProductFrm(id = null): void {
    const accountSheet = this._bottomSheet.open(SupplierProductsFrmComponent, {
      disableClose: true,
      data: {
        id,
        supplier: this.idSupplier,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getProducts()
    }
    );

  }

  deleteProductFrm(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete product',
        message: 'Are you sure you want to delete this product?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.sps.deleteProductSupplier(id).subscribe(() => this._getProducts())
      }
    })
  }

}


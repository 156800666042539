<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <div class="w-full">
    <app-input-clients [clientId]="clientId" [clientName]="clientName" (onSelect)="setClient($event)"></app-input-clients>
  </div>



  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Departure Date</mat-label>
    <input matInput [matDatepicker]="departureDate" formControlName="departureDate" (dateInput)="setArrivalDate($event);">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="departureDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #departureDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Arrival Date</mat-label>
    <input matInput [matDatepicker]="arrivalDate" formControlName="arrivalDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="arrivalDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #arrivalDate></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>PO</mat-label>
    <input matInput type="text" formControlName="po">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>PO Client</mat-label>
    <input matInput type="text" formControlName="poClient">
  </mat-form-field>

  <div class="self-center bg-slate-100 p-4 w-4/12 rounded-sm -mt-3">
    <mat-checkbox [formControl]="asTemplate" (change)="setTemplate()" >Save as Template</mat-checkbox>
  </div>

  <mat-form-field appearance="outline" class="w-4/12" *ngIf="asTemplate.value === true">
    <mat-label>Template Name</mat-label>
    <input matInput type="text" formControlName="name">
  </mat-form-field>



  <span class="w-full text-lg font-semibold text-slate-700">
    Economic Information
  </span>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Payment Method</mat-label>
    <mat-select formControlName="paymentMethod">
      <mat-option value="TRANSFER">Bank Transfer</mat-option>
      <mat-option value="CHECK">Check</mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Palet Cost</mat-label>
    <input matInput formControlName="palletCost" type="number" placeholder="190">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Currency Exchange</mat-label>
    <input matInput type="number" step="0.001" formControlName="currencyChange" placeholder="1.18">
  </mat-form-field>

  <div *ngIf="preorder && preorder.typeOfPreOrder === 'SELL'" class="w-full flex flex-row flex-wrap gap-4">
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>JCV % Applied</mat-label>
      <input matInput formControlName="jcvPercentage" placeholder="0" type="number">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Commercial % Applied</mat-label>
      <input matInput formControlName="comercialDiscount" placeholder="0" type="number">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Sale % Applied</mat-label>
      <input matInput formControlName="saleComission" placeholder="0" type="number">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes" placeholder="Ex. This preorder has..."></textarea>
  </mat-form-field>



  <div class="flex justify-end gap-8 my-8 w-full">

  <button
    *ngIf="preorder"
    mat-button type="button" matTooltip="Generate / Assign to Logistic Order" matTooltipClass="bg-sky-700 text-xs"
    (click)="createBlankLogisticOrder()"
    class="!flex !items-center bg-sky-600 overflow-hidden ring-1 ring-sky-950/10 hover:bg-sky-400">
    <app-logistic-icon color="stroke-white"></app-logistic-icon>
  </button>

    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(preorder) ? 'Updated' : 'Create'}} Preorder </button>

    <button
      matTooltip="Delete PreOrder" matTooltipClass="bg-red-700 text-xs"
      mat-flat-button
      aria-label="Delete Pre-order" class="bg-red-600" type="button" (click)="deletePreorder()" *ngIf="preorder">
      <mat-icon svgIcon="delete" class="text-white"></mat-icon>
    </button>


  </div>
</form>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <mat-form-field appearance="outline">
    <mat-label>Description</mat-label>
    <input matInput formControlName="description" placeholder="Concept One">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Quantity</mat-label>
    <input matInput formControlName="quantity" type="number" placeholder="200">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Unit Price</mat-label>
    <input matInput formControlName="unit_price" type="number" placeholder="32,12">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Total</mat-label>
    <input matInput formControlName="total" type="number" placeholder="6424">
  </mat-form-field>


  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.invoice_item) ? 'Create' : 'Update'}} Concept
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

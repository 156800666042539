<div class="p-4">
<h4 class="text-base text-green-700 !font-bold"> Assign Plate to Order Logistic </h4>
<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-col gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Plate</mat-label>
    <input matInput type="text" formControlName="plate">
  </mat-form-field>

  <div class="actions-frm text-end w-full">
    <button mat-raised-button type="submit" class="w-full bg-green-700 text-white"> Assign Plate </button>
  </div>
</form>
</div>

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Invoice</mat-label>
    <input type="text" placeholder="Search invoices" matInput [formControl]="invoice" [matAutocomplete]="autoClient">
    <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="showInvoice" (optionSelected)="changeInvoice($event)">
      <mat-option *ngFor="let option of invoicesFilter" [value]="option.id">
        {{option.invoiceNumber}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

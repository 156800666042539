import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SecondLine } from '../../models/second-lines';
import { SecondLineFrm } from '../../models/second-line-frm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { roundToTwo } from 'src/app/features/preorder/utils/utils';

@Component({
  selector: 'app-second-line-frm',
  templateUrl: './second-line-frm.component.html',
  styleUrls: ['./second-line-frm.component.css']
})
export class SecondLineFrmComponent implements OnInit {

  public frm?: FormGroup;

  @Input() order?:SecondLine
  @Output() sendData = new EventEmitter<SecondLineFrm>()

  public clientId?: number;
  public clientName?: string;
  public supplierId?: number;
  public supplierName?: string;
  public coinId?: number;

  constructor(
    private fb: FormBuilder,
    private ar: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.order){
      this._setOrder(this.order)
    }

    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes['order'].firstChange) {
      this._setOrder(changes['order'].currentValue)
    }


  }

  _createForm(): void{
    this.frm = this.fb.group({
      client_id: ['', Validators.required],
      supplier_id: ['', Validators.required],
      supplierInvoice: [''],
      payCommission: ['SUPPLIER', Validators.required],
      orderDate: ['', Validators.required],
      carrier: [''],
      carrierInvoice: [''],
      po: [''],
      plate: [''],
      coin_id: ['', Validators.required],
      currencyExchange: ['1.18'],
      commissionType: ['PERCENT', Validators.required],
      commisionPercentage: ['', Validators.required],
      discount: [0],
      totalCommission: [0],
      total: [0],
      totalKilos: [0, ],

    });
  }

  _setOrder(order: SecondLine): void{
      this.frm!.patchValue({
        ...order,
        client_id: order.client.id,
        supplier_id: order.supplier.id,
        coin_id: order.coin.id,
      })

      this.coinId = order.coin.id
      this.clientId = order.client.id
      this.clientName = order.client.name
      this.supplierId = order.supplier.id
      this.supplierName = order.supplier.name
  }


  setClient = (client: string): void => this.frm!.get('client_id')!.setValue(client)
  setSupplier = (supplier: string): void => this.frm!.get('supplier_id')!.setValue(supplier)
  changeCoin = (coin: any) => this.frm?.patchValue({coin_id: coin.id})


  private _listener(){

    this.commisionPercentage!.valueChanges.subscribe( (value: number) => {
      const quantityCommission = (this.commissionType!.value === 'KGS') ? this.commisionPercentage?.value : this.commisionPercentage?.value / 100
      this.frm?.patchValue({
        totalCommission: roundToTwo((this.total?.value) * quantityCommission - this.discount?.value)
      }, {emitEvent: false})
    })

    this.discount!.valueChanges.subscribe( (value: number) => {
      const quantityCommission = (this.commissionType!.value === 'KGS') ? this.commisionPercentage?.value : this.commisionPercentage?.value / 100
      this.frm?.patchValue({
        totalCommission: roundToTwo((this.total?.value) * quantityCommission - value)
      })
    })

    this.commissionType!.valueChanges.subscribe( (value: string) => {
      if (value === 'KGS'){
        this.frm?.patchValue({
          totalCommission: roundToTwo((this.totalKilos?.value) * this.commisionPercentage?.value - this.discount?.value)
        })
      }else{
        this.frm?.patchValue({
          totalCommission: roundToTwo((this.total?.value) * (this.commisionPercentage?.value / 100) - this.discount?.value)
        })
      }
    })

    this.totalKilos!.valueChanges.subscribe( (value: number) => {

    })


  }


  send(): void{
    if (this.frm!.invalid) { return; }

    const data = {
      ...this.frm!.value,
      orderDate: moment(this.frm!.value.orderDate).format('YYYY-MM-DD'),
    }

    this.sendData.emit(data);
  }


  get commissionType() {
    return this.frm?.get('commissionType')
  }

  get commisionPercentage() {
    return this.frm?.get('commisionPercentage')
  }

  get discount() {
    return this.frm?.get('discount')
  }

  get totalCommission() {
    return this.frm?.get('totalCommission')
  }

  get totalKilos() {
    return this.frm?.get('totalKilos')
  }

  get total() {
    return this.frm?.get('total')
  }


}

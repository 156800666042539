<div *ngIf="frm" class="px-3">

  <mat-accordion class="">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="flex flex-row flex-wrap gap-0 gap-x-2 justify-start items-center">

          <mat-form-field appearance="outline" class="w-4/12">
            <mat-label> Search </mat-label>
            <input matInput type="text" formControlName="search">
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-3/12">
            <mat-label> Date Invoice </mat-label>
            <mat-date-range-input [formGroup]="frm" [rangePicker]="picker">
              <input matStartDate formControlName="date_after" placeholder="After">
              <input matEndDate formControlName="date_before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-3/12">
            <mat-label> Due Date </mat-label>
            <mat-date-range-input [formGroup]="frm" [rangePicker]="duepicker">
              <input matStartDate formControlName="payDate_after" placeholder="After">
              <input matEndDate formControlName="payDate_before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
            <mat-date-range-picker #duepicker></mat-date-range-picker>
          </mat-form-field>

          <div class="w-1/12">
            <mat-form-field appearance="outline">
              <mat-label>Payed?</mat-label>
              <mat-select formControlName="isPayed">
                <mat-option value="True">Payed</mat-option>
                <mat-option value="False">Pending payment</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <app-input-clients (onSelect)="updateClient($event)" class="w-5/12"></app-input-clients>
          <app-input-suppliers (onSelect)="updateSupplier($event)" class="w-5/12"></app-input-suppliers>
          <app-input-bank-account (accountChange)="updateAccount($event)" class="w-5/12"></app-input-bank-account>



          <div class="w-5/12 -mt-5">

            <mat-button-toggle-group formControlName="typeInvoice" aria-label="" multiple>
              <mat-button-toggle value="ORDER"> Order </mat-button-toggle>
              <mat-button-toggle value="SECONDLINE"> Second Line </mat-button-toggle>
              <mat-button-toggle value="OTHER"> Others </mat-button-toggle>
              <mat-button-toggle value="SALES"> Sales </mat-button-toggle>
            </mat-button-toggle-group>

          </div>

          <div class="actions w-1/12 text-end pb-2">
            <button mat-raised-button class="bg-red-600 text-white" (click)="clean()">Clean</button>
          </div>


        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { SecondLineService } from '../../services/second-line.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { roundToTwo } from 'src/app/features/preorder/utils/utils';

@Component({
  selector: 'app-second-line-product',
  templateUrl: './second-line-product.component.html',
  styleUrls: ['./second-line-product.component.css']
})
export class SecondLineProductComponent implements OnInit {

  public frm?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<SecondLineProductComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { id: number, idOrder: number },
    private sls: SecondLineService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.data.id) this._getProduct()
    this._listeners()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      secondLine: [this.data.idOrder, Validators.required],
      product: ['', Validators.required],
      pallet: [''],
      quantity: [''],
      unit: [''],
      price: ['', Validators.required],
      total: ['', Validators.required],
    });
  }

  private _getProduct(): void{
    this.sls.getSecondLineProduct(this.data.id).subscribe({
      next: (data) => {
        this.frm!.patchValue(data);
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting product', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _listeners(): void{
    this.quantity.valueChanges.subscribe( (q) => this.frm?.patchValue({ total: roundToTwo(q * this.price.value) }))
    this.price.valueChanges.subscribe( (p) => this.frm?.patchValue({ total: roundToTwo(p * this.quantity.value) }))
  }


  send(): void{
    if (this.frm!.invalid) { return; }
    this.bsheet.dismiss(this.frm!.value);
  }


  close(){
    this.bsheet.dismiss();
  }

  get quantity() {
    return this.frm!.get('quantity')!
  }

  get price() {
    return this.frm!.get('price')!
  }

}

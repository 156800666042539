import { Component, OnInit } from '@angular/core';
import { DeliveryDetail } from '../../models/delivery-detail';
import { DeliveryService } from '../../services/delivery.service';
import { DeliveryFrm } from '../../models/delivery-frm';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-delivery-edit',
  templateUrl: './delivery-edit.component.html',
  styleUrls: ['./delivery-edit.component.css']
})
export class DeliveryEditComponent implements OnInit {

  idDelivery = this.ar.snapshot.params['id']
  delivery?: DeliveryDetail

  public breadcrumb = [
    {name: 'Deliveries', url: '/dash/delivery'},
    {name: 'Delivery'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private ss: SnackbarsService,
    private ds: DeliveryService,
  ) { }

  ngOnInit(): void {
    this._getDeliveryNote()
  }

  refresh(e: boolean){
    this._getDeliveryNote()
  }

  private _getDeliveryNote(): void {
    this.ds.getDeliveryNote(this.idDelivery).subscribe({
      next: (resp) => {
        this.delivery = resp
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting delivery note','snack-error')
        this.rt.navigate(['/dash/delivery'])
      }
    })
  }


  updateDelivery(delivery: DeliveryFrm): void {
    this.ds.updateDelivery(this.idDelivery, delivery).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Delivery ${resp.code} updated successfully`,'snack-success')
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating Delivery Note','snack-error')
      }
    })
  }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Intrastat } from 'src/app/features/admin/models/intrastat';
import { CustomIconsService } from '../../services/custom-icons.service';
import { IntrastatService } from 'src/app/features/admin/services/intrastat.service';

@Component({
  selector: 'app-input-intrastat',
  templateUrl: './input-intrastat.component.html',
  styleUrls: ['./input-intrastat.component.css']
})
export class InputIntrastatComponent implements OnInit {

  intrastat = new FormControl('', Validators.required)

  intrastats: Intrastat[] = []
  intrastatFilter?: any[]
  intrastatSelected?: any

  @Input() intrastatId?: number
  @Input() intrastatName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private its: IntrastatService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['intrastatId']?.currentValue){
      this.intrastat.setValue(changes['intrastatId']?.currentValue)
      this.intrastatSelected = changes['intrastatName']?.currentValue
    }

  }

  private _listener():void{
    this.intrastat.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.its.searchInstrastat(resp).subscribe({
            next: (r) => {
              this.intrastatFilter = r.results
            }
          })
        }
      }
    })
  }


  showIntrastat = (valor: any): string => {
    this.intrastatFilter?.filter( (m) => {
      if(m.id === valor){
        this.intrastatSelected = m.code + ' ' + m.description
      }
    })
    return this.intrastatSelected;
  }

  clean():void{
    this.intrastat.reset()
    this.intrastatSelected = null
    this.intrastatFilter = []
    this.onSearch.emit('')
  }

  changeIntrastat = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

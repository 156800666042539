<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">

  <mat-form-field appearance="outline">
    <mat-label>Currency Exchange</mat-label>
    <input matInput formControlName="currencyChange" placeholder="1.18">
  </mat-form-field>

  <app-input-suppliers (onSelect)="setSupplier($event)"></app-input-suppliers>

  <mat-form-field appearance="outline">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes" placeholder="Ex. This order supplier has..."></textarea>
  </mat-form-field>


  <button mat-flat-button class="bg-green-700 text-white" type="submit"> Add Supplier
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>

</form>

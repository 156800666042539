import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalDataInvoiceComponent } from 'src/app/features/order/components/modal-data-invoice/modal-data-invoice.component';
import { OrderFilter } from 'src/app/features/order/models/order-filter';
import { OrderService } from 'src/app/features/order/services/order.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { BillingService } from '../../services/billing.service';
import { OrdersToInvoice, OrdersToInvoiceResponse } from 'src/app/features/order/models/orders-invoice';

@Component({
  selector: 'app-orders-table-invoice',
  templateUrl: './orders-table-invoice.component.html',
  styleUrls: ['./orders-table-invoice.component.css']
})
export class OrdersTableInvoiceComponent implements AfterViewInit {

  displayedColumns: string[] = ['select', 'id', 'po', 'typeOfPreOrder', 'client', 'supplier', 'supplierInvoice', 'departureDate'];
  orders?: OrdersToInvoiceResponse | null;
  data: OrdersToInvoice[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;




  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @Input('searched') searched: OrderFilter = { search: ''}

  selection = new SelectionModel<OrdersToInvoice>(true, [])

  constructor(
    private rt: Router,
    private dialog: MatDialog,
    private bs: BillingService,
    private os: OrderService,
    private ss: SnackbarsService
  ) { }

  ngAfterViewInit() {
    this._getOrders()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(!changes['searched'].firstChange){
      this._getOrders()
    }
  }

  private _getOrders(): void {

    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.os.searchOrdersToInvoice(
            this.searched, this.paginator!.pageIndex + 1,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }

  private _invoiceSelection(r:{bank: number, payer: string, coin: string, currencyExchange: number, invoice_type: string}){
    const orders_invoice = this.selection.selected.map(s => s.id)
    this.bs.generateInvoiceOrders(orders_invoice, r).subscribe({
      next: (r) => {
        this.ss.nuevaSnack(`Invoices ${r.invoices.join(',')}  generated successfully`, 'snack-success')
        this._getOrders()
      },
      error: (e) => {
        console.log(e);
      }
    })

  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.orders!.results.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  edit = (id: number) => this.rt.navigateByUrl(`/dash/orders/edit/${id}`)

  invoice = () => {
    this.dialog.open(ModalDataInvoiceComponent).afterClosed().subscribe( (r) => {
      if (r) this._invoiceSelection(r)
    })
  }

  updateInvoice = (invoice: any, id: number) => {
    this.os.updateOrder(id,{supplierInvoice: invoice.target.value}).subscribe({
      next: (r) =>  {
        this.ss.nuevaSnack('Supplier Invoiced has been added', 'snack-success')
        this.data.filter( o => o.id === id)[0].supplierInvoice = invoice.target.value
      },
      error: (e) => this.ss.nuevaSnack('Error updating invoice', 'snack-error')
    })
  }

}

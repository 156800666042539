<div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">
  <button mat-raised-button (click)="openLogisticFrm()" class="bg-green-700 text-white w-2/12">Add Download
    Place</button>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div *ngIf="isRateLimitReached">
      Download places to supplier...
    </div>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="name" matSortDisableClear
      matSortDirection="desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row" (click)="openLogisticFrm(row.id)" class="hover:cursor-pointer">{{row.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef>City</th>
        <td mat-cell *matCellDef="let row" (click)="openLogisticFrm(row.id)" class="hover:cursor-pointer">{{row.city}}
        </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef>Country</th>
        <td mat-cell *matCellDef="let row" (click)="openLogisticFrm(row.id)" class="hover:cursor-pointer">
          {{row.country.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="default">
        <th mat-header-cell *matHeaderCellDef>Is Default?</th>
        <td mat-cell *matCellDef="let row" (click)="openLogisticFrm(row.id)" class="hover:cursor-pointer text-center">
          <svg *ngIf="row.byDefault" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6 text-green-700">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>

        </td>
      </ng-container>



      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button aria-label="Delete Contact" (click)="deleteLogistic(row.id)">
            <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No downloads places found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select download places search results"></mat-paginator>
</div>

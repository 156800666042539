import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderService } from 'src/app/features/order/services/order.service';
import { ResumenByIntrastat, ResumenOrder } from '../../models/resumen-order';

@Component({
  selector: 'app-resumen-orders',
  templateUrl: './resumen-orders.component.html',
  styleUrls: ['./resumen-orders.component.css']
})
export class ResumenOrdersComponent implements OnInit {

  orders: ResumenOrder[] = []
  totalByFamily?: ResumenByIntrastat[]

  constructor(
    public dialogRef: MatDialogRef<ResumenOrdersComponent>,
    private os: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: {preorder: number}
  ) { }

  ngOnInit(): void {
    this._loadResumen()
    this._loadResumenByFamily()
  }

  _loadResumen(){
    this.os.getResumenOrderByPreoder(this.data.preorder).subscribe({
      next: (resp) => this.orders = resp.results,
      error: (err) => console.log(err)
    })
  }


  _loadResumenByFamily() {
    this.os.getResumenByIntrastat(this.data.preorder).subscribe({
      next: (resp) => this.totalByFamily = resp,
      error: (err) => console.log(err)
    })
  }


}

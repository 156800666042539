<div class="w-full flex h-fit gap-3">

  <div class="w-3/12 p-3 bg-white">
    <h4 class="!my-3 !text-base !font-bold !text-center !p-0 text-green-900"> Logistics Loads</h4>

    <app-search-logistic-frm (onSearch)="search($event)"></app-search-logistic-frm>

    <div class="relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300"></div>
      </div>
    </div>

    <div class="mt-3">
      <app-logistic-list [listOrder]="listOrder" (onSelect)="loadDetails($event)"></app-logistic-list>
    </div>
  </div>

  <div class="w-9/12 p-3 bg-white">
    <app-logistic-track-detail [trackDetail]="logisticTrack" (onRefresh)="refresh($event)"></app-logistic-track-detail>
  </div>

</div>

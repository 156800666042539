<div class="w-full mt-4 text-end">
  <button mat-raised-button type="button" class="bg-sky-800 text-white" (click)="invoice()"
    [disabled]="selection.selected.length === 0"> Generate Invoices </button>
</div>
<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">

          <mat-checkbox class="p-1" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"
            [disabled]="row.isInvoiced">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="typeOfPreOrder">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer">

          <app-badget [class]="'bg-purple-50 text-purple-700 ring-purple-700/10'" text="Sale" *ngIf="row.typeOfPreOrder === 'SELL'"></app-badget>
          <app-badget [class]="'bg-green-50 text-green-700 ring-green-700/10'" text="Buy" *ngIf="row.typeOfPreOrder === 'BUY'"></app-badget>
          <app-badget [class]="'bg-blue-50 text-blue-700 ring-blue-700/10'" text="Commission" *ngIf="row.typeOfPreOrder === 'COMMISSION'"></app-badget>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">
          {{row.client}}</td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2 text-xs">
          #{{row.id}}</td>
      </ng-container>

      <ng-container matColumnDef="po">
        <th mat-header-cell *matHeaderCellDef>PO</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">{{row.po}}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">
          {{row.supplier}}</td>
      </ng-container>

      <ng-container matColumnDef="supplierInvoice">
        <th mat-header-cell *matHeaderCellDef>Supplier invoice</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer">
          <mat-form-field appearance="outline" class="w-40" *ngIf="row.typeOfPreOrder !== 'SELL'">
            <mat-label>Supplier Invoice</mat-label>
            <input matInput type="text" [value]="row.supplierInvoice" (change)="updateInvoice($event, row.id)">
          </mat-form-field>

        </td>
      </ng-container>

      <ng-container matColumnDef="departureDate">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2">
          {{ row.departureDate|date:'dd/MM/yyyy' }}
        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Order found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="60"
    aria-label="Select pre-order search results"></mat-paginator>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div *ngIf="isRateLimitReached">
      Products Second Line
    </div>
  </div>

  <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="product" matSortDisableClear
    matSortDirection="desc">

    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef>Product</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer w-3/12">
        {{row.product}}
      </td>
    </ng-container>

    <ng-container matColumnDef="pallet">
      <th mat-header-cell *matHeaderCellDef>Pallets</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer">
        <span
          class="inline-flex items-center rounded-md bg-blue-400/10 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-400/30">
          {{row.pallet}}
        </span>

      </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer">
        {{row.quantity}} {{row.unit}}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Price</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer w-3/12">
      <div *ngIf="extra.coin?.code; else pricePound" class="flex gap-2 justify-start">
        <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.price|currency:'EUR'"></app-badget>
        <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.price|changeToPound:extra!.currencyExchange!|currency:'GBP'"> </app-badget>
      </div>
      <ng-template #pricePound>
        <div class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.price|changeToEuro:extra!.currencyExchange!|currency:'EUR'"> </app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.price|currency:'GBP'"> </app-badget>
        </div>
      </ng-template>
      </td>
    </ng-container>



    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total</th>
      <td mat-cell *matCellDef="let row" (click)="edit(row.id)" class="hover:cursor-pointer w-3/12">

        <div *ngIf="extra.coin?.code; else totalPound" class="flex gap-2 justify-start">
          <app-badget class="bg-green-50 text-green-700 ring-green-600/20" [text]="row.total|currency:'EUR'">
          </app-badget>
          <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20"
            [text]="row.total|changeToPound:extra!.currencyExchange!|currency:'GBP'"> </app-badget>
        </div>
        <ng-template #totalPound>
          <div class="flex gap-2 justify-start">
            <app-badget class="bg-green-50 text-green-700 ring-green-600/20"
              [text]="row.total|changeToEuro:extra!.currencyExchange!|currency:'EUR'"> </app-badget>
            <app-badget class="bg-purple-50 text-purple-700 ring-purle-600/20" [text]="row.total|currency:'GBP'">
            </app-badget>
          </div>
        </ng-template>




      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="w-20"></th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button aria-label="Delete Product" (click)="deleteProduct(row.id)">
          <mat-icon svgIcon="delete" class="text-red-700"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        No Products found
      </td>
    </tr>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      class="hover:bg-slate-200 transition-colors duration-500"></tr>
  </table>
</div>

<mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
  aria-label="Select Contacts search results"></mat-paginator>

import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { SupplierLoadPlace, SupplierLoadPlaces } from '../../models/supplier-load-places';
import { SupplierService } from '../../services/supplier.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { merge, startWith, switchMap, catchError, of, map } from 'rxjs';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SupplierLogisticFrmComponent } from '../supplier-logistic-frm/supplier-logistic-frm.component';

@Component({
  selector: 'app-supplier-logistic',
  templateUrl: './supplier-logistic.component.html',
  styleUrls: ['./supplier-logistic.component.css']
})
export class SupplierLogisticComponent implements AfterViewInit {

  idSupplier = this.ar.snapshot.params['id']

  displayedColumns: string[] = ['name', 'city', 'country', 'default', 'actions'];
  logistics?: SupplierLoadPlaces | null;
  data: SupplierLoadPlace[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private ar: ActivatedRoute,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private sps: SupplierService,
    private is: CustomIconsService,
  ) {
  }

  ngAfterViewInit() {
    this._getLogisticsPlaces()
  }


  private _getLogisticsPlaces(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.sps.getDownloadsPlacesBySupplier(
            this.idSupplier
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  openLogisticFrm(id = null): void {
    const accountSheet = this._bottomSheet.open(SupplierLogisticFrmComponent, {
      disableClose: true,
      data: {
        id,
        supplier: this.idSupplier,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getLogisticsPlaces()
    }
    );

  }

  deleteLogistic(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete download place',
        message: 'Are you sure you want to delete this download place?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.sps.deleteLogistic(id).subscribe(() => this._getLogisticsPlaces())
      }
    })
  }

}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Users'},
  ]

  public searched = { text: ''}

  constructor(
    private rt: Router
  ) { }

  ngOnInit(): void {
  }

  search = (data: {text:string}) => this.searched = data

  goto = (url:string) => this.rt.navigateByUrl(url)

}

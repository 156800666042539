<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-full">
      Edit Partner
      <small class="block font-light text-xs text-slate-500 w-full"> Edit info to updated the partner.</small>
    </h2>


  </div>

  <!-- Form -->
  <app-partner-frm [partner]="partner" *ngIf="partner" (sendData)="updatePartner($event)"></app-partner-frm>
</main>

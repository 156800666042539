import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Packaging, PackagingFrm, PackagingResponse } from '../models/packaging';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class PackagingService {

  constructor(private http: HttpClient) { }

  searchPackagings(search?: string, page=1): Observable<PackagingResponse>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<PackagingResponse>(`${baseUrl}/search-packaging`, {params})
  }

  getPackagings(): Observable<PackagingResponse>{
    return this.http.get<PackagingResponse>(`${baseUrl}/packaging`)
  }

  getPackaging(id: number): Observable<Packaging> {
    return this.http.get<Packaging>(`${baseUrl}/packaging/${id}`)
  }

  createPackaging(packaging: PackagingFrm): Observable<Packaging> {
    return this.http.post<Packaging>(`${baseUrl}/packaging/`, packaging)
  }

  updatePackaging(id: number, packaging: PackagingFrm): Observable<Packaging> {
    return this.http.patch<Packaging>(`${baseUrl}/packaging/${id}/`, packaging)
  }

  deletePackaging(id: number): Observable<Packaging> {
    return this.http.delete<Packaging>(`${baseUrl}/packaging/${id}/`)
  }

}

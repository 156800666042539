import { Injectable } from '@angular/core';
import { Partner, PartnerBase, PartnerResponse } from '../models/partner';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private http: HttpClient
  ) { }


  searchPartner(search?: string, page=1): Observable<PartnerResponse>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<PartnerResponse>(`${baseUrl}/search-partner/`, {params})
  }

  getPartners(): Observable<PartnerResponse>{
    return this.http.get<PartnerResponse>(`${baseUrl}/partner`)
  }

  getPartner(id: number): Observable<Partner>{
    return this.http.get<Partner>(`${baseUrl}/partner/${id}`)
  }

  addPartner(data:any): Observable<Partner>{
    return this.http.post<Partner>(`${baseUrl}/partner/`, data)
  }

  updatePartner(id: number, data: any): Observable<Partner>{
    return this.http.put<Partner>(`${baseUrl}/partner/${id}/`, data)
  }

  deletePartner(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/partner/${id}/`)
  }

  getPartnersByPo(pos: string): Observable<PartnerBase[]>{
    return this.http.get<PartnerBase[]>(`${baseUrl}/partners/by-pos?po=${pos}`)
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SecondLineFilter } from '../models/second-line-filter';
import { Observable } from 'rxjs';
import { SecondLineFrm } from '../models/second-line-frm';
import { SecondLine } from '../models/second-lines';
import { SecondLineProductFrm } from '../models/second-line-product-frm';
import { SecondLineProductsResp } from '../models/second-line-products-res';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class SecondLineService {

  constructor( private http: HttpClient) { }


  searchSecondLine(filters: SecondLineFilter, page:number = 1): Observable<any>{
    const params = new HttpParams({
      fromObject: {
      'page': page.toString(),
      'client':  filters.client || '',
      'orderDate_after':  filters.orderDate_after || '',
      'orderDate_before':  filters.orderDate_before || '',
      'isInvoiced':  filters.isInvoiced || '',
      'search':  filters.search || '',
      'supplier':  filters.supplier || '',
      'page_size':  filters.page_size || 20
      }
    })


    return this.http.get<any>(`${baseUrl}/second-line/search`, {params})
  }

  createSecondLine(data: SecondLineFrm): Observable<SecondLine>{
    return this.http.post<SecondLine>(`${baseUrl}/second-line/`, data)
  }

  updateSecondLine(id: number, data: SecondLineFrm): Observable<SecondLine>{
    return this.http.patch<SecondLine>(`${baseUrl}/second-line/${id}/`, data)
  }

  deleteSecondLine(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/second-line/${id}/`)
  }

  getSecondLine(id: number): Observable<SecondLine>{
    return this.http.get<SecondLine>(`${baseUrl}/second-line/${id}`)
  }


  duplicateSecondLine(id: number, data:{orderDate: string, client: number}): Observable<any>{
    return this.http.post<any>(`${baseUrl}/second-line/duplicate/${id}`, data)
  }

  searchProductsSecondLine(filter: {secondLine:string}): Observable<SecondLineProductsResp>{
    const params = new HttpParams({
      fromObject: {
        'secondLine': filter.secondLine
      }
    })
    return this.http.get<SecondLineProductsResp>(`${baseUrl}/second-line/products`, {params})
  }

  getSecondLineProduct(id:number): Observable<SecondLineProductFrm>{
    return this.http.get<SecondLineProductFrm>(`${baseUrl}/second-line-product/${id}`)
  }

  createProductSecondLine(data: SecondLineProductFrm): Observable<SecondLineProductFrm>{
    return this.http.post<SecondLineProductFrm>(`${baseUrl}/second-line-product/`, data)
  }

  updateProductSecondLine(id: number, data: SecondLineProductFrm): Observable<SecondLineProductFrm>{
    return this.http.patch<SecondLineProductFrm>(`${baseUrl}/second-line-product/${id}/`, data)
  }

  deleteProductSecondLine(id: number): Observable<any>{
    return this.http.delete(`${baseUrl}/second-line-product/${id}/`)
  }

}

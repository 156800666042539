import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-intrastat-filter',
  templateUrl: './intrastat-filter.component.html',
  styleUrls: ['./intrastat-filter.component.css']
})
export class IntrastatFilterComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    after: ['', Validators.required],
    before: ['', Validators.required],
  })

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>()


  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  private _emitDataSearch(){

    if (this.frm.invalid) return
    this.onSearch.emit({
      after: (this.frm.value.after) ? moment(this.frm.value.after).format('YYYY-MM-DD') : '',
      before: (this.frm.value.before) ? moment(this.frm.value.before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this.onSearch.emit({})
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Size } from '../../models/sizes';

@Component({
  selector: 'app-frm-sizes',
  templateUrl: './frm-sizes.component.html',
  styleUrls: ['./frm-sizes.component.css']
})
export class FrmSizesComponent implements OnInit {

  public frm?: FormGroup;

  @Input() size?:Size
  @Output() sendData = new EventEmitter<Size>()

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.size){
      this._setSize(this.size)
    }
  }

  _createForm(): void{
    this.frm = this.fb.group({
      size: ['', Validators.required],
    });
  }

  _setSize(size: Size): void{
      this.frm!.patchValue({
        ...size,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Supplier, Suppliers } from '../models/suppliers';
import { ContactSupplier, ContactsSupplier } from '../models/contacts-supplier';
import { SupplierLoadPlace, SupplierLoadPlaces } from '../models/supplier-load-places';
import { CertificationSupplier, CertificationsSuppliers } from '../models/certifications-supplier';
import { ProductSupplier, ProductsSuppliers } from '../models/products-supplier';


const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(
    private http: HttpClient
  ) { }

  searchSuppliers(search?: string, page=1): Observable<Suppliers>{
    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<Suppliers>(`${baseUrl}/suppliers/search`, {params})
  }

  addSupplier(data:Supplier): Observable<Supplier>{
    return this.http.post<Supplier>(`${baseUrl}/suppliers/`, data)
  }

  updateSupplier(id: number, data: Supplier): Observable<Supplier>{
    return this.http.put<Supplier>(`${baseUrl}/suppliers/${id}/`, data)
  }

  getSupplier(id: number): Observable<Supplier>{
    return this.http.get<Supplier>(`${baseUrl}/suppliers/${id}/`)
  }

  deleteSupplier(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/suppliers/${id}/`)
  }

  // Contacts

  getContactsBySupplier(id: number): Observable<ContactsSupplier>{
    return this.http.get<ContactsSupplier>(`${baseUrl}/suppliers/${id}/contacts`)
  }

  getContact(id: number): Observable<ContactSupplier>{
    return this.http.get<ContactSupplier>(`${baseUrl}/suppliers-contacts/${id}`)
  }

  addContact(data:ContactSupplier): Observable<ContactSupplier>{
    return this.http.post<ContactSupplier>(`${baseUrl}/suppliers-contacts/`, data)
  }

  updateContact(id: number, data: ContactSupplier): Observable<ContactSupplier>{
    return this.http.put<ContactSupplier>(`${baseUrl}/suppliers-contacts/${id}/`, data)
  }

  deleteContact(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/suppliers-contacts/${id}/`)
  }

  // Logistic

  getDownloadsPlacesBySupplier(id: number): Observable<SupplierLoadPlaces>{
    return this.http.get<SupplierLoadPlaces>(`${baseUrl}/suppliers/${id}/logistics`)
  }

  getLogistic(id: number): Observable<SupplierLoadPlace>{
    return this.http.get<SupplierLoadPlace>(`${baseUrl}/suppliers-logistics/${id}`)
  }

  getAllLogistic(): Observable<SupplierLoadPlaces>{
    return this.http.get<SupplierLoadPlaces>(`${baseUrl}/suppliers-logistics?page_size=100`)
  }

  addLogistic(data:SupplierLoadPlace): Observable<SupplierLoadPlace>{
    return this.http.post<SupplierLoadPlace>(`${baseUrl}/suppliers-logistics/`, data)
  }

  updateLogistic(id: number, data: SupplierLoadPlace): Observable<SupplierLoadPlace>{
    return this.http.put<SupplierLoadPlace>(`${baseUrl}/suppliers-logistics/${id}/`, data)
  }

  deleteLogistic(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/suppliers-logistics/${id}/`)
  }

  // Certification

  searchCertificationsSupplier(search: {supplier?: number, text?: string }, pageSize: number = 30): Observable<CertificationsSuppliers>{
    const params = new HttpParams()
      .set('search', search.text || '')
      .set('supplier', search.supplier || '')
      .set('page_size', pageSize.toString())

    return this.http.get<CertificationsSuppliers>(`${baseUrl}/suppliers/certifications`, {params})
  }

  getCertification(id: number): Observable<CertificationSupplier>{
    return this.http.get<CertificationSupplier>(`${baseUrl}/suppliers-certifications/${id}`)
  }

  addCertification(data:CertificationSupplier): Observable<CertificationSupplier>{
    return this.http.post<CertificationSupplier>(`${baseUrl}/suppliers-certifications/`, data)
  }

  updateCertification(id: number, data: CertificationSupplier): Observable<CertificationSupplier>{
    return this.http.put<CertificationSupplier>(`${baseUrl}/suppliers-certifications/${id}/`, data)
  }

  deleteCertification(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/suppliers-certifications/${id}/`)
  }

  // Products
  searchProductsSupplier(search: {supplier?: number, text?: string }, pageSize: number = 30): Observable<ProductsSuppliers>{
    const params = new HttpParams()
      .set('search', search.text || '')
      .set('supplier', search.supplier || '')
      .set('page_size', pageSize.toString())

    return this.http.get<ProductsSuppliers>(`${baseUrl}/suppliers/products`, {params})
  }

  getProductSupplier(id: number): Observable<ProductSupplier>{
    return this.http.get<ProductSupplier>(`${baseUrl}/suppliers-products/${id}`)
  }

  addProductSupplier(data:ProductSupplier): Observable<ProductSupplier>{
    return this.http.post<ProductSupplier>(`${baseUrl}/suppliers-products/`, data)
  }

  updateProductSupplier(id: number, data: ProductSupplier): Observable<ProductSupplier>{
    return this.http.put<ProductSupplier>(`${baseUrl}/suppliers-products/${id}/`, data)
  }

  deleteProductSupplier(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/suppliers-products/${id}/`)
  }


  searchSupplierByProduct(idProduct: number): Observable<ProductsSuppliers>{
      const params = new HttpParams()
        .set('product', idProduct.toString())
      return this.http.get<ProductsSuppliers>(`${baseUrl}/suppliers-byproducts`, {params})
  }

  searchProductsBySupplier(idSupplier: number, order: any): Observable<ProductsSuppliers>{
      const params = new HttpParams()
        .set('supplier', idSupplier.toString())
        .set('order', order ?? '')
        .set('page_size', '100')
      return this.http.get<ProductsSuppliers>(`${baseUrl}/suppliers-byproducts`, {params})
  }



  searchLoadPlacesSupplier(search: {supplier?: number, text?: string }, pageSize: number = 30): Observable<SupplierLoadPlaces>{


    const params = new HttpParams()
      .set('search', search.text || '')
      .set('supplier', (search.supplier) ? search.supplier!.toString() : '')
      .set('page_size', pageSize.toString())

    return this.http.get<SupplierLoadPlaces>(`${baseUrl}/suppliers/load-places`, {params})
  }



}

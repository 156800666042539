import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { OrderCost, OrderCostFrm } from '../../models/order-costs';
import { OrderCostsFrmComponent } from '../order-costs-frm/order-costs-frm.component';
import { OrderCostsTableComponent } from '../order-costs-table/order-costs-table.component';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'app-order-costs',
  templateUrl: './order-costs.component.html',
  styleUrls: ['./order-costs.component.css']
})
export class OrderCostsComponent implements OnInit {

  orderId = this.ar.snapshot.params['id']
  @ViewChild(OrderCostsTableComponent, {static : true}) child?: OrderCostsTableComponent;

  constructor(
    private ar: ActivatedRoute,
    private bsheet: MatBottomSheet,
    private os: OrderService,
  ) { }

  ngOnInit(): void {
  }


  editCost(cost: OrderCost){
    this.bsheet.open(OrderCostsFrmComponent, { data: cost }).afterDismissed().subscribe((data: OrderCostFrm) => {
      if (data) {
        this.os.updateOrderCost(cost.id, data).subscribe( () => this.child?.getConcepts() )
      }
    })
  }

  createCost(){
    this.bsheet.open(OrderCostsFrmComponent, {
      data: {
        order: this.orderId
      }
    }).afterDismissed().subscribe((data: OrderCostFrm) => {
      if (data) {
        data = {
          ...data,
          order: this.orderId
        }
        this.os.addOrderCost(data).subscribe( () => this.child?.getConcepts() )
      }
    })
  }

}

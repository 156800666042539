import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { CostType } from 'src/app/features/admin/models/cost-types';
import { CostTypeService } from 'src/app/features/admin/services/cost-type.service';
import { Cost } from '../../models/cost-response';

@Component({
  selector: 'app-cost-frm',
  templateUrl: './cost-frm.component.html',
  styleUrls: ['./cost-frm.component.css']
})
export class CostFrmComponent implements OnInit {

  costsType: CostType[] = [];

  frm?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bsheetRef: MatBottomSheetRef<CostFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: Cost,
    private cts: CostTypeService
  ) { }

  ngOnInit(): void {
    this._getOrderCostType()
    this._formBuilder()
    if (this.data) {
      this.frm?.patchValue({
        ...this.data,
        costType_id: this.data.costType.id,
      })
    }
    this._listener()
  }

  private _getOrderCostType():void{
    this.cts.searchCostTypes().subscribe( resp => this.costsType = resp.results)
  }

  private _formBuilder(): void {
    this.frm = this.fb.group({
      costType_id: ['', Validators.required],
      price: ['', Validators.required],
      quantity: ['', Validators.required],
      description: [''],
      total: ['', Validators.required],
      date: [moment().format('YYYY-MM-DD'), Validators.required]
    })
  }

  private _listener(){
    this.price.valueChanges.subscribe( () => this.total.setValue((this.price.value * this.quantity.value).toFixed(2)))
    this.quantity.valueChanges.subscribe( () => this.total.setValue((this.price.value * this.quantity.value).toFixed(2)))
  }

  submit(): void {
    if (this.frm?.valid) {

      const sendData = {
        ...this.frm?.value,
        date: moment(this.frm?.value.date).format('YYYY-MM-DD')
      }

      this.bsheetRef.dismiss(sendData)
    }
  }

  close = () => this.bsheetRef.dismiss()



  get price(): AbstractControl {
    return this.frm?.get('price') as AbstractControl
  }

  get quantity(): AbstractControl {
    return this.frm?.get('quantity') as AbstractControl
  }

  get total(): AbstractControl {
    return this.frm?.get('total') as AbstractControl
  }

}

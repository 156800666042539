import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { BillingService } from '../../services/billing.service';
import { roundToTwo } from 'src/app/features/preorder/utils/utils';

@Component({
  selector: 'app-invoice-item-frm',
  templateUrl: './invoice-item-frm.component.html',
  styleUrls: ['./invoice-item-frm.component.css']
})
export class InvoiceItemFrmComponent implements OnInit {

  public frm?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<InvoiceItemFrmComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {invoice: number, invoice_item: any},
    private bs: BillingService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.data.invoice_item) this._getItemInvoice()

    this._listener()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      invoice: [this.data.invoice, Validators.required],
      description: ['', Validators.required],
      quantity: [null,Validators.required],
      unit_price: [null, Validators.required],
      total: [0,Validators.required],
    });
  }

  private _getItemInvoice(): void{
    this.frm!.patchValue({...this.data.invoice_item})
  }

  private _listener(): void{
    this.quantity?.valueChanges.subscribe((value) => this.frm?.patchValue({total: roundToTwo(value * this.unit_price?.value)}))
    this.unit_price?.valueChanges.subscribe((value) => this.frm?.patchValue({total: roundToTwo(value * this.quantity?.value)}))
  }

  private _create(): void{
    this.bs.createItemInvoice(this.frm!.value).subscribe({
      next: ({description}) => {
        this.ss.nuevaSnack(`The concept ${description} has been created`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating concept', 'snack-error');
      }
    }
    );
  }

  private _update(): void{
    this.bs.updateItemInvoice(this.data.invoice_item.id, this.frm!.value).subscribe({
      next: ({description}) => {
        this.ss.nuevaSnack(`The concept ${description} has been updated`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating concept', 'snack-error');
      }
    }
    );
  }


  send(): void{
    if (this.frm!.invalid) { return; }
    if (this.data.invoice_item){
      this._update()
    }else{
      this._create();
    }
  }

  close(){
    this.bsheet.dismiss();
  }


  get quantity() {
    return this.frm?.get('quantity')
  }
  get unit_price() {
    return this.frm?.get('unit_price')
  }
  get total() {
    return this.frm?.get('total')
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Partner } from '../../models/partner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedInfoService } from 'src/app/shared/services/shared-info.service';
import { User } from 'src/app/shared/models/users';

@Component({
  selector: 'app-partner-frm',
  templateUrl: './partner-frm.component.html',
  styleUrls: ['./partner-frm.component.css']
})
export class PartnerFrmComponent implements OnInit {

  public frm?: FormGroup;
  public users: User[] = [];

  @Input() partner?:Partner
  @Output() sendData = new EventEmitter<Partner>()

  constructor(
    private fb: FormBuilder,
    private ss: SharedInfoService,
  ) { }

  ngOnInit(): void {
    this._getUsers();
    this._createForm();

    if (this.partner){
      this._setPartner(this.partner)
    }
  }


  _getUsers(){
    this.ss.getUsersPartners().subscribe( resp => this.users = resp.results)
  }

  _createForm(): void{
    this.frm = this.fb.group({
      name: ['', Validators.required],
      numberPerson: ['', Validators.required],
      user: [''],
      debt: [0],
    });
  }

  _setPartner(data: Partner): void{
      this.frm!.patchValue({
        ...data,
      })
  }

  send(): void{
    if (this.frm!.invalid) { return; }
    this.sendData.emit(this.frm!.value);
  }


}

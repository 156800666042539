import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogisticSupplier, LogisticSupplierContact, LogisticSupplierContacts, LogisticSuppliers } from '../models/logistic-suppliers';

const baseUrl = environment.baseUrl

@Injectable({
  providedIn: 'root'
})
export class LogisticSupplierService {

  constructor(
    private http: HttpClient
  ) { }


  searchLogisticSuppliers(search?: string, page=1): Observable<LogisticSuppliers>{

    const params = new HttpParams()
      .set('search', search || '')
      .set('page', page.toString())
    return this.http.get<LogisticSuppliers>(`${baseUrl}/logistic-suppliers/search`, {params})
  }

  getLogisticSupplier(id: number): Observable<LogisticSupplier>{
    return this.http.get<LogisticSupplier>(`${baseUrl}/logistic-suppliers/${id}`)
  }

  addLogisticSupplier(data:LogisticSupplier): Observable<LogisticSupplier>{
    return this.http.post<LogisticSupplier>(`${baseUrl}/logistic-suppliers/`, data)
  }

  updateLogisticSupplier(id: number, data: LogisticSupplier): Observable<LogisticSupplier>{
    return this.http.put<LogisticSupplier>(`${baseUrl}/logistic-suppliers/${id}/`, data)
  }


  getContactsByLogisticSupplier(id: number): Observable<LogisticSupplierContacts>{
    return this.http.get<LogisticSupplierContacts>(`${baseUrl}/logistic-suppliers/${id}/contacts`)
  }

  getContact(id: number): Observable<LogisticSupplierContact>{
    return this.http.get<LogisticSupplierContact>(`${baseUrl}/logistic-suppliers-contacts/${id}`)
  }

  addContact(data:LogisticSupplierContact): Observable<LogisticSupplierContact>{
    return this.http.post<LogisticSupplierContact>(`${baseUrl}/logistic-suppliers-contacts/`, data)
  }

  updateContact(id: number, data: LogisticSupplierContact): Observable<LogisticSupplierContact>{
    return this.http.put<LogisticSupplierContact>(`${baseUrl}/logistic-suppliers-contacts/${id}/`, data)
  }

  deleteContact(id: number): Observable<any>{
    return this.http.delete<any>(`${baseUrl}/logistic-suppliers-contacts/${id}/`)
  }



}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JcvService } from '../../services/jcv.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-jcv-bank-account-form',
  templateUrl: './jcv-bank-account-form.component.html',
  styleUrls: ['./jcv-bank-account-form.component.css']
})
export class JcvBankAccountFormComponent implements OnInit {

  public frm?: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bsheet: MatBottomSheetRef<JcvBankAccountFormComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: {id: number, enterprise: number},
    private jcv: JcvService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._createForm();
    if (this.data.id) this._getAccount()
  }

  private _createForm(): void{
    this.frm = this.fb.group({
      enterprise: [this.data.enterprise, Validators.required],
      name: ['', Validators.required],
      iban: ['', Validators.required],
      bic: [''],
      swift: [''],
      direction: [''],
      sortCode: [''],
      default: [false]
    });
  }

  private _getAccount(): void{
    this.jcv.getBankAccount(this.data.id).subscribe({
      next: (data) => {
        this.frm!.patchValue(data);
      },
      error: (error) => {
        this.ss.nuevaSnack('Error getting account data', 'snack-error');
        this.bsheet.dismiss();
      }
    })
  }

  private _create(): void{
    this.jcv.createBankAccount(this.frm!.value).subscribe({
      next: ({name}) => {
        this.ss.nuevaSnack(`The account ${name} has been created`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error creating account', 'snack-error');
      }
    }
    );
  }

  private _update(): void{
    this.jcv.updateBankAccount(this.data.id, this.frm!.value).subscribe({
      next: ({name}) => {
        this.ss.nuevaSnack(`The account ${name} has been updated`, 'snack-success');
        this.bsheet.dismiss(true)
      },
      error: (error) => {
        this.ss.nuevaSnack('Error updating account', 'snack-error');
      }
    })
  }


  send(): void{
    if (this.frm!.invalid) { return; }
    if (this.data.id){
      this._update()
    }else{
      this._create();
    }
  }


  close(){
    this.bsheet.dismiss();
  }


}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from '../core/layouts/main-layout/main-layout.component';
import { AuthGuard } from '../core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'dash',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./home/home-routing.module').then(m => m.HomeRoutingModule)
  },
  {
    path: 'dash/admin',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./admin/admin-routing.module').then(m => m.AdminRoutingModule)
  },
  {
    path: 'dash/pre-orders',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./preorder/preorder-routing.module').then(m => m.PreorderRoutingModule)
  },
  {
    path: 'dash/orders',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./order/order-routing.module').then(m => m.OrderRoutingModule)
  },
  {
    path: 'dash/second-line',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./second-line/second-line-routing.module').then(m => m.SecondLineRoutingModule)
  },
  {
    path: 'dash/traceability',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./traceability/traceability-routing.module').then(m => m.TraceabilityRoutingModule)
  },
  {
    path: 'dash/delivery',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./delivery/delivery-routing.module').then(m => m.DeliveryRoutingModule)
  },
  {
    path: 'dash/reports',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./reports/reports-routing.module').then(m => m.ReportsRoutingModule)
  },
  {
    path: 'dash/invoices',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./billing/billing-routing.module').then(m => m.BillingRoutingModule)
  },
  {
    path: 'dash/credit-notes',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./credit-note/credit-note-routing.module').then(m => m.CreditNoteRoutingModule)
  },
  {
    path: 'dash/costs',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./costs/costs-routing.module').then(m => m.CostsRoutingModule)
  },
  {
    path: 'dash/logistic',
    component: MainLayoutComponent,
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    loadChildren: () => import('./logistic/logistic-routing.module').then(m => m.LogisticRoutingModule)
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})

export class FeaturesRoutingModule { }

<div *ngIf="frm">

  <mat-accordion class="">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Formulario -->
      <form [formGroup]="frm">
        <div class="flex flex-row flex-wrap gap-0 gap-x-2 justify-start items-center">

          <mat-form-field appearance="outline" class="w-10/12">
            <mat-label> Date </mat-label>
            <mat-date-range-input [formGroup]="frm" [rangePicker]="picker">
              <input matStartDate formControlName="after" placeholder="After">
              <input matEndDate formControlName="before" placeholder="Before">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          <app-input-clients (onSelect)="changeClient($event)" class="w-10/12"></app-input-clients>

          <div class="pb-5">
            <button mat-raised-button class="bg-red-600 text-white !h-12" (click)="clean()">Clean</button>
          </div>

        </div>

      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<div class="mt-8">
  <div *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  </div>

  <div class="w-full mt-4">

    <table mat-table [dataSource]="data" class="w-full bg-transparent" matSort matSortActive="code" matSortDisableClear
      matSortDirection="desc">

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-36" (click)="edit(row.id)">{{row.code}}</td>
      </ng-container>



      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" (click)="edit(row.id)">{{row.order.client.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="supplier">
        <th mat-header-cell *matHeaderCellDef>Supplier</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer px-2" (click)="edit(row.id)">
          {{row.order.supplier.name}}</td>
      </ng-container>

      <ng-container matColumnDef="po">
        <th mat-header-cell *matHeaderCellDef>PO</th>
        <td mat-cell *matCellDef="let row" class="hover:cursor-pointer w-28" (click)="edit(row.id)">
          <app-badget class="bg-red-50 text-red-600 ring-red-500/10" [text]="row.order.preorder.po" *ngIf="row.order.preorder.po"></app-badget>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="w-32"></th>
        <td mat-cell *matCellDef="let row" class="flex">
        <button mat-button
          matTooltip="Generate Delivery"
          matTooltipClass="bg-sky-700 text-xs"
          class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-sky-600 object-cover ring-1 ring-sky-950/10 hover:bg-sky-400 transition-colors duration-500"
          [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }"
          (click)="generatePdf(row.id, 'delivery')" [disabled]="isGeneratingPdf">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            *ngIf="!isGeneratingPdf" class="stroke-white w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
        </button>

        <button mat-button
          matTooltip="Generate proforma"
          matTooltipClass="bg-red-700 text-xs"
          class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-red-600 object-cover ring-1 ring-red-950/10 hover:bg-red-400 transition-colors duration-500"
          [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }"
          (click)="generatePdf(row.id, 'proforma')" [disabled]="isGeneratingPdf">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            *ngIf="!isGeneratingPdf" class="stroke-white w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
          </svg>

          <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
        </button>

        <button mat-button
          matTooltip="Generate transport road"
          matTooltipClass="bg-purple-700 text-xs"
          class="!h-8 !w-8 !flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-purple-800 object-cover ring-1 ring-purple-950/10 hover:bg-purple-800 transition-colors duration-500"
          [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }"
          (click)="generateTransportRoadPdf(row.order.id)" [disabled]="isGeneratingPdf">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="stroke-white w-6 h-6" *ngIf="!isGeneratingPdf">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
          </svg>
          <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
        </button>

        <button mat-button matTooltip="Generate delivery without prices" matTooltipClass="bg-purple-700 text-xs"
          class="!flex items-center !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-zinc-400 object-cover ring-1 ring-zinc-950/10 hover:bg-zinc-400 transition-colors duration-500"
          [ngClass]="{ 'cursor-not-allowed bg-white hover:bg-white': isGeneratingPdf }"
          (click)="generatePdf(row.id, 'clean_delivery')" [disabled]="isGeneratingPdf">
          <span class="text-white">Delivery without prices</span>
          <mat-spinner *ngIf="isGeneratingPdf" diameter="35" color="warn"></mat-spinner>
        </button>

        </td>
      </ng-container>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">
          No Delivery Notes found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        class="hover:bg-slate-200 transition-colors duration-500"></tr>
    </table>
  </div>

  <mat-paginator class="bg-transparent" [length]="resultsLength" [pageSize]="20"
    aria-label="Select delivery notes search results"></mat-paginator>
</div>

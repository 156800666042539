import { Component, OnInit } from '@angular/core';
import { ReportsService } from '../../../services/reports.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentDetail } from '../../../models/payment-detail';

@Component({
  selector: 'app-detail-payment',
  templateUrl: './detail-payment.component.html',
  styleUrls: ['./detail-payment.component.css']
})
export class DetailPaymentComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Payments', url: '/dash/reports/payments'},
    {name: 'Detail Payment'}
  ]

  id = this.ar.snapshot.params['id']
  payment?: PaymentDetail



  constructor(
    private rs: ReportsService,
    private ar: ActivatedRoute,
    private rt: Router
  ) { }

  ngOnInit(): void {
    this.rs.getDetailPayment(this.id).subscribe({
      next: (res) => this.payment = res,
      error: (err) => this.rt.navigate(['/dash/reports/payments'])
    })
  }

}

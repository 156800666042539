import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalQuestionComponent } from '../modal-question/modal-question.component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.css']
})
export class ModalDeleteComponent implements OnInit {

  phrase = new FormControl('', [Validators.required]);

  constructor(
    public modal: MatDialogRef<ModalQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, cancel: string, accept: string, phrase?: string}
  ) { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {

  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Profits Partners By Po'}
  ]

  constructor() { }

  ngOnInit(): void {
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changeToEuro'
})
export class ChangeToEuroPipe implements PipeTransform {

  transform(value: any, changeCoin: any): number {
    return value * changeCoin
  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">



  <div class="w-4/12">
    <app-input-clients [clientId]="clientId" [clientName]="clientName"
      (onSelect)="setClient($event)"></app-input-clients>
  </div>

  <div class="w-5/12">
    <app-input-suppliers [supplierId]="supplierId" [supplierName]="supplierName"
      (onSelect)="setSupplier($event)"></app-input-suppliers>
  </div>



  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Order Date</mat-label>
    <input matInput [matDatepicker]="arrivalDate" formControlName="orderDate">
    <mat-hint>DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matSuffix [for]="arrivalDate"></mat-datepicker-toggle>
    <mat-datepicker touchUi #arrivalDate></mat-datepicker>
  </mat-form-field>



  <mat-form-field appearance="outline" class="w-4/12">
    <mat-label>Carrier</mat-label>
    <input matInput type="text" formControlName="carrier">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Plate</mat-label>
    <input matInput type="text" formControlName="plate">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>PO</mat-label>
    <input matInput type="text" formControlName="po">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Total Kilos</mat-label>
    <input matInput type="number" formControlName="totalKilos" placeholder="CI9212">
  </mat-form-field>


  <span class="w-full text-lg font-semibold text-slate-700">
    Invoices
  </span>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Supplier Invoice</mat-label>
    <input matInput formControlName="supplierInvoice" placeholder="IS9212">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Carrier Invoice</mat-label>
    <input matInput formControlName="carrierInvoice" placeholder="CI9212">
  </mat-form-field>


  <span class="w-full text-lg font-semibold text-slate-700">
    Economic Information
  </span>



  <div class="w-2/12">
    <app-input-coins [coinId]="coinId" (coinChange)="changeCoin($event)" class="w-2/12"></app-input-coins>
  </div>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Currency Exchange</mat-label>
    <input matInput formControlName="currencyExchange" placeholder="1.18">
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Pay Commission</mat-label>
    <mat-select formControlName="payCommission">
      <mat-option value="CLIENT">Client</mat-option>
      <mat-option value="SUPPLIER">Supplier</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Type Commission</mat-label>
    <mat-select formControlName="commissionType">
      <mat-option value="PERCENT">%</mat-option>
      <mat-option value="KGS">Kgs</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Commission {{(this.commissionType!.value === 'KGS') ? 'Cost' : 'Percentage'}}</mat-label>
    <input matInput type="number" formControlName="commisionPercentage" placeholder="2">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-2/12">
    <mat-label>Discount</mat-label>
    <input matInput type="number" formControlName="discount" placeholder="2">
    <mat-hint class="text-red-900">€ commission discount</mat-hint>
  </mat-form-field>


  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Total Commission</mat-label>
    <input matInput type="number" formControlName="totalCommission" placeholder="0,0">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>Total</mat-label>
    <input matInput type="number" formControlName="total" placeholder="0,0">
  </mat-form-field>





  <div class="actions-frm text-end my-8 w-full">

    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(order) ? 'Updated' :
      'Create'}}
      Order </button>
  </div>
</form>

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

  <mat-form-field appearance="outline" class="w-5/12">
    <mat-label>Product</mat-label>
    <input matInput formControlName="name" placeholder="Tomatoes">
  </mat-form-field>

  <app-input-intrastat [intrastatId]="intrastatId" [intrastatName]="intrastatName" (onSelect)="setIntrastat($event)" class="w-3/12"></app-input-intrastat>

  <mat-form-field appearance="outline" class="w-3/12">
    <mat-label>EAN13</mat-label>
    <input matInput formControlName="ean13" placeholder="XXXXXX">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-44">
    <mat-label>Category</mat-label>
    <mat-select formControlName="category">
      <mat-option value="F">First</mat-option>
      <mat-option value="S">Second</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-96">
    <mat-label>Variety</mat-label>
    <input matInput formControlName="variety" placeholder="Mandarina">
  </mat-form-field>

  <app-input-size [sizeId]="sizeId" [sizeName]="sizeName" (onSelect)="setSize($event)" class="w-48"></app-input-size>
  <app-input-temperature [temperatureId]="temperatureId" (temperatureChange)="setTemperature($event)" class="w-3/12"></app-input-temperature>


  <mat-form-field appearance="outline" class="w-72">
    <mat-label>Price</mat-label>
    <input matInput formControlName="price" type="number" placeholder="1.23">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Box/Pallet</mat-label>
    <input matInput formControlName="boxPallet" type="number" placeholder="123">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Kgr per box</mat-label>
    <input matInput formControlName="kilosBox" type="number" placeholder="1.43">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Net kgr.</mat-label>
    <input matInput formControlName="kilosNet" type="number" placeholder="1.43">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Description</mat-label>
    <textarea matInput formControlName="description" placeholder="This product has...."></textarea>
  </mat-form-field>

  <div class="border-b border-gray-200 pb-5 w-full">
    <h3 class="!text-base !font-semibold !leading-6 !text-gray-900">Default Configuration</h3>
  </div>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Default Net weight by pallet</mat-label>
    <input matInput formControlName="default_net" type="number" placeholder="300.3">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Default Gross weight by pallet</mat-label>
    <input matInput formControlName="default_gross" type="number" placeholder="400.4">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Origin</mat-label>
    <input matInput formControlName="origin" type="text" placeholder="Spain">
  </mat-form-field>

  <mat-form-field appearance="outline" class="w-64">
    <mat-label>Packaging</mat-label>
    <mat-select formControlName="packaging">
      <mat-option *ngFor="let pack of packaging" [value]="pack.id">{{pack.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="w-64">
    <app-input-select-partner [partnerIds]="partners_selected" (partnerChange)="changePartner($event)"></app-input-select-partner>
  </div>


  <div class="actions-frm text-end my-8 w-full">
    <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(product) ? 'Updated' :
      'Create'}} Product </button>
  </div>

</form>

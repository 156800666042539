import { Component, OnInit } from '@angular/core';
import { PoTrace } from 'src/app/features/traceability/models/search-po';
import { PoReport } from '../../models/report-po';
import { TraceabilityService } from 'src/app/features/traceability/services/traceability.service';
import { ReportsService } from '../../services/reports.service';

@Component({
  selector: 'app-buy-sale',
  templateUrl: './buy-sale.component.html',
  styleUrls: ['./buy-sale.component.css']
})
export class BuySaleComponent implements OnInit {

  public searched?: string;
  listPo: PoTrace[] = [];
  poReport?: PoReport[];
  selectedPo?: string;
  public breadcrumb = [
    {name: 'Reports', url: '/dash/reports'},
    {name: 'Profits Partners By Po'}
  ]
  constructor(
    private ts: TraceabilityService,
    private rs: ReportsService,
  ) { }

  ngOnInit(): void {
  }

  search = (txt: string) => {
    this.searched = txt
    this.ts.searchPo(this.searched).subscribe({
      next: (r) => {
        this.listPo = r.results
      }
    });
  };

  loadDetails(po: string) {
    this.rs.showEconomicPoReport(po).subscribe({
      next: (r) => {
        this.poReport = r
      },
      error: (e) => console.error(e)
    })
  }

  refresh(data: {refresh: boolean, po: string}){
    if (data.refresh) this.loadDetails(data.po)
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-frm-login',
  templateUrl: './frm-login.component.html',
  styleUrls: ['./frm-login.component.css']
})
export class FrmLoginComponent implements OnInit {
  public loginForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private as: AuthService,
    private ss: SnackbarsService
    ) { }

  ngOnInit(): void {
    this.creaFormulario();
  }

  creaFormulario():void{
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password:  ['', Validators.required],
    });
  }

  login(): void{

    if (this.loginForm.invalid){
      return;
    }

    this.as.login( this.loginForm.value ).subscribe( {
      next: (resp) => {
        this.router.navigateByUrl('/dash')
      },
      error: ({error}) => {
        this.ss.nuevaSnack(error.error || 'Error to access', 'snack-error');
      }
    } );
  }
}

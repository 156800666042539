<div class="mx-auto sm:px-6 lg:px-8" *ngIf="payment">
  <div class="space-y-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>

    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-base font-semibold leading-6 text-gray-900">Payment Detail</h3>
    </div>

    <div>
      <div class="mt-6 border-t border-gray-100">
        <dl class="divide-y divide-gray-100">
          <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Client</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{payment.general.client}}</dd>
          </div>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Created At</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{payment.general.paidDate|date}}</dd>
          </div>
          <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Is commercial Paid</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <app-badget
                [class]="(payment.general.comercialIsPaid) ? 'bg-green-50 text-green-700 ring-green-600/20' : 'bg-red-50 text-red-700 ring-red-600/20' "
                [text]="(payment.general.comercialIsPaid) ? 'Paid' : 'Not Paid'">
              </app-badget>
            </dd>
          </div>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Comemrcial Paid from</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{payment.general.comercialBankAccount}}</dd>
          </div>
          <div class="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">Notes</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{{payment.general.comercialNotes}}</dd>
          </div>
          <div class="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
            <dt class="text-sm font-medium leading-6 text-gray-900">POs</dt>
            <dd class="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <app-badget
                *ngFor="let po of payment.general.pos"
                class="bg-purple-50 text-purple-700 ring-purple-600/20 mr-1"
                [text]="po"
                ></app-badget>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div>
      <h3 class="my-4 text-lg !font-semibold text-green-700"> Paid-up Partners</h3>
    </div>

    <ul role="list" class="divide-y divide-gray-100" *ngIf="payment.partners.length > 0; else nopartner">
      <li class="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 py-5 sm:flex-nowrap" *ngFor="let p of payment.partners">
        <div>
          <p class="text-sm font-semibold leading-6 text-gray-900">
            <a href="#" class="hover:underline">{{p.partner}}</a>
          </p>
          <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
            <p>
              {{p.notes}}
            </p>
            <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
              <circle cx="1" cy="1" r="1" />
            </svg>
            <p>
              {{p.date|date}}
            </p>
          </div>
        </div>
        <dl class="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
          <div class="flex -space-x-0.5">
            <dt class="sr-only">Pays</dt>
            <dd>
              <app-badget
                class="bg-purple-50 text-purple-700 ring-purple-600/20"
                [text]="p.bankAccount">
              </app-badget>
            </dd>
          </div>
          <div class="flex w-16 gap-x-2.5">

            <app-badget
              [class]="(p.total > 0 ) ? 'bg-green-50 text-green-700 ring-green-600/20' : 'bg-red-50 text-red-700 ring-red-600/20' "
              [text]="p.total|currency:'EUR'">
            </app-badget>

          </div>
        </dl>
      </li>
    </ul>

    <ng-template #nopartner>
      <div class="text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12 text-red-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M15.182 16.318A4.486 4.486 0 0 0 12.016 15a4.486 4.486 0 0 0-3.198 1.318M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z" />
        </svg>
        <h3 class="mt-2 text-sm font-semibold text-gray-900">No payments made </h3>
        <p class="mt-1 text-sm text-gray-500">No payments have been made to any member</p>
      </div>
    </ng-template>

  </div>
</div>

import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsService {

  constructor(
    public iconRegistry: MatIconRegistry,
    private dom: DomSanitizer,
  ) {

    this.iconRegistry.addSvgIcon('home',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/home.svg') );
    this.iconRegistry.addSvgIcon('preorder',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/preorder.svg') );
    this.iconRegistry.addSvgIcon('intrastat',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/intrastat.svg') );
    this.iconRegistry.addSvgIcon('trace',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/trace.svg') );
    this.iconRegistry.addSvgIcon('invoices',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/invoices.svg') );
    this.iconRegistry.addSvgIcon('credit-note',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/credit-note.svg') );
    this.iconRegistry.addSvgIcon('delivery',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/delivery.svg') );
    this.iconRegistry.addSvgIcon('conformity',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/conformity.svg') );
    this.iconRegistry.addSvgIcon('admin',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/admin.svg') );
    this.iconRegistry.addSvgIcon('logout',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/logout.svg') );

    this.iconRegistry.addSvgIcon('clients',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/clients.svg') );
    this.iconRegistry.addSvgIcon('products',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/products.svg') );
    this.iconRegistry.addSvgIcon('suppliers',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/suppliers.svg') );
    this.iconRegistry.addSvgIcon('logistic',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/logistic.svg') );
    this.iconRegistry.addSvgIcon('certification',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/certification.svg') );
    this.iconRegistry.addSvgIcon('user',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/user.svg') );
    this.iconRegistry.addSvgIcon('contacts',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/contacts.svg') );

    this.iconRegistry.addSvgIcon('delete',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/delete.svg') );
    this.iconRegistry.addSvgIcon('email',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/email.svg') );
    this.iconRegistry.addSvgIcon('duplicate',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/duplicate.svg') );
    this.iconRegistry.addSvgIcon('buy-sell',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/buy-sell.svg') );
    this.iconRegistry.addSvgIcon('packaging',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/packaging.svg') );
    this.iconRegistry.addSvgIcon('banknotes',  this.dom.bypassSecurityTrustResourceUrl('assets/ui/banknotes.svg') );

  }



}

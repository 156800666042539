<div class="mx-auto flex w-full max-w-7xl items-start gap-x-8 py-4">
  <aside class="sticky top-8 hidden w-4/12 shrink-0 lg:block">

    <!-- Left column area: List Orders -->

    <!-- Actions Generals -->

    <app-orders-actions
      [follow]="followOptions"
      [preorder]="preorder"
      [typeOfPreorder]="typePreorder"
      (reload)="reload($event)"
      *ngIf="orders.length > 0">
    </app-orders-actions>

    <!-- List Orders -->
    <app-order-card-list [orders]="orders" [orderActive]="selectedOrder?.id" [isReload]="isReload"  (selectedOrder)="loadProductsOrder($event)"></app-order-card-list>

    <!-- Button Add order/supplier -->
    <app-add-supplier-order
      *ngIf="typePreorder !== 'SELL'"
      (addSupplierOrder)="reload($event)"
      [idPreorder]="preorder">
    </app-add-supplier-order>

  </aside>

  <main class="flex-1">
    <!-- Main area -->
    <app-empty [title]="'No Order Selected'" [text]="'Select an order from the list to view its details.'" *ngIf="!selectedOrder; else orderSel"></app-empty>

    <ng-template #orderSel>

      <!-- Add Product button -->

      <app-add-product-button *ngIf="typePreorder !== 'SELL'" [order]="selectedOrder" [typePreorder]="typePreorder" (refresh)="reload($event)"></app-add-product-button>

      <!-- Products -->

      <div class="mt-3" *ngIf="products.length === 0; else listadoProductos">
        <app-empty [title]="'No Products'" [text]="'There are no products in this order.'"></app-empty>
      </div>

      <ng-template #listadoProductos>
        <app-product-order-table app-product-order-table [typePreorder]="typePreorder!" [products]="products" (reload)="reload($event)" [currencyExchange]="currencyExchangeSelected!"></app-product-order-table>
      </ng-template>


    </ng-template>

  </main>

</div>


import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseCode } from '../../models/base-code';
import { CustomIconsService } from '../../services/custom-icons.service';
import { SharedInfoService } from '../../services/shared-info.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input-countries',
  templateUrl: './input-countries.component.html',
  styleUrls: ['./input-countries.component.css']
})
export class InputCountriesComponent implements OnInit {

  country = new FormControl('', Validators.required)

  countries: BaseCode[] = []
  countriesFilter?: any[]
  countrySelected?: any

  @Input() countryId?: number
  @Input() countryName?: string

  @Output('onSelect') onSearch = new EventEmitter<string>()

  constructor(
    private is: CustomIconsService,
    private ss: SharedInfoService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryId']?.currentValue){
      this.country.setValue(changes['countryId']?.currentValue)
      this.countrySelected = changes['countryName']?.currentValue
    }

  }

  private _listener():void{
    this.country.valueChanges.subscribe({
      next: (resp: any) => {
        if (resp){
          this.ss.searchCountries(resp).subscribe({
            next: (r) => {
              this.countriesFilter = r.results
            }
          })
        }
      }
    })
  }


  showCountry = (valor: any): string => {
    this.countriesFilter?.filter( (m) => {
      if(m.id === valor){
        this.countrySelected = m.name
      }
    })
    return this.countrySelected;
  }

  clean():void{
    this.country.reset()
    this.countrySelected = null
    this.countriesFilter = []
    this.onSearch.emit('')
  }

  changeCountry = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

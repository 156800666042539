
<h1> Settings </h1>
<div class="relative">
  <div class="absolute inset-0 flex items-center" aria-hidden="true">
    <div class="w-full border-t border-gray-300"></div>
  </div>
</div>


<div class="mt-10">
  <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-50 shadow sm:grid sm:grid-cols-3 md:grid-cols-4 sm:gap-px sm:divide-y-0">

    <app-card *ngFor="let item of items" [item]="item"></app-card>

  </div>
</div>

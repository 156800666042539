<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="w-full flex flex-col mb-5 mt-3">


  <div class="w-full">
    <app-input-clients [clientId]="clientId" [clientName]="clientName" (onSelect)="setClient($event)"></app-input-clients>
  </div>

  <mat-form-field appearance="outline" class="">
    <mat-label>Buy Price</mat-label>
    <input matInput type="number" step="0.0001" formControlName="buy_price" placeholder="">
  </mat-form-field>

  <mat-form-field appearance="outline" class="">
    <mat-label>Sale Price</mat-label>
    <input matInput type="number" step="0.0001" formControlName="sale_price" placeholder="">
  </mat-form-field>


  <button mat-flat-button class="bg-green-700 text-white" type="submit"> {{ (!data.id) ? 'Create' : 'Update'}} Rule
  </button>
  <div class="my-4">
    <button mat-flat-button class="w-full text-slate-700" type="button" (click)="close()"> Cancel </button>
  </div>
</form>

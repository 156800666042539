import { Component, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { SecondLineService } from '../../services/second-line.service';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { SecondLineProductComponent } from '../second-line-product/second-line-product.component';
import { SecondLineProductFrm } from '../../models/second-line-product-frm';
import { SecondLineProductsTableComponent } from '../second-line-products-table/second-line-products-table.component';

@Component({
  selector: 'app-second-line-products',
  templateUrl: './second-line-products.component.html',
  styleUrls: ['./second-line-products.component.css']
})
export class SecondLineProductsComponent implements OnInit {

  idOrder: number = this.ar.snapshot.params['id'];

  filter = {secondLine: this.ar.snapshot.params['id']}
  dataExtra = {}

  @ViewChild(SecondLineProductsTableComponent, {static : true}) child?: SecondLineProductsTableComponent;

  constructor(
    private bs: MatBottomSheet,
    private ar: ActivatedRoute,
    private sls: SecondLineService,
    private ss: SnackbarsService,
    private is: CustomIconsService,
  ) { }

  ngOnInit(): void {
    this._getSecondLine()
  }

  private _getSecondLine() {
    this.sls.getSecondLine(this.idOrder).subscribe( r => {
      this.dataExtra = {
        coin: r.coin,
        currencyExchange: r.currencyExchange
      }
    })
  }

  private _updateProduct(id: number, data:SecondLineProductFrm) {
    this.sls.updateProductSecondLine(id, data).subscribe( r => this.child!.getProducts() )
  }

  private createProduct(data: SecondLineProductFrm) {
    this.sls.createProductSecondLine(data).subscribe( r => this.child!.getProducts() )
  }

  openProductModal(idProductSecondLine:any = null) {
    this.bs.open(SecondLineProductComponent, {
      data: {
        id: idProductSecondLine,
        idOrder: this.idOrder,
      }
    }).afterDismissed().subscribe( (r: SecondLineProductFrm) => {
        if(r){
          (idProductSecondLine) ? this._updateProduct(idProductSecondLine, r) : this.createProduct(r)
        }
    });
  }



}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { LogisticService } from '../../services/logistic.service';
import { LogisticOrder } from '../../models/logistic-list-response';
import { ModalUpdatePlateComponent } from '../modal-update-plate/modal-update-plate.component';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { EmailLogisticModalComponent } from 'src/app/shared/components/email-logistic-modal/email-logistic-modal.component';
import { DatePipe } from '@angular/common';
import { ModalMoveLoadComponent } from '../modal-move-load/modal-move-load.component';
import { ModalSendFileLogisticComponent } from '../modal-send-file-logistic/modal-send-file-logistic.component';

@Component({
  selector: 'app-logistic-preorder-list',
  templateUrl: './logistic-preorder-list.component.html',
  styleUrls: ['./logistic-preorder-list.component.css']
})
export class LogisticPreorderListComponent implements OnInit {

  preorder = this.ar.snapshot.params['id']
  logisticOrders: LogisticOrder[] = []
  generateLogistic = false

  constructor(
    private ar: ActivatedRoute,
    private dp: DatePipe,
    private dialog: MatDialog,
    private ls: LogisticService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._loadLogisticOrders()
  }

  private _loadLogisticOrders(): void {


    this.ls.searchLogisticOrderByPreorder({preorder: this.preorder}, 1).subscribe({
      next: (resp) => this.logisticOrders = resp.results,
      error: (e) => console.log(e)
    })
  }

  updatePlate(id: number){
    this.dialog.open(ModalUpdatePlateComponent).afterClosed().subscribe({
      next: (r) => {
        this.ls.updateLogistic(id, r).subscribe({
          next: (resp) => this._loadLogisticOrders(),
          error: (e) => this.ss.nuevaSnack('Error to assign plate', 'snack-error')
        })

      }
    })
  }

  generateAllPdfsLogistic(){
    this.generateLogistic = true
    this.ls.generateAllPdfsLogistic(this.preorder).subscribe({
      next: (r) => {
        this.ss.nuevaSnack('All Logistic PDF have been generated', 'snack-success')
        this.generateLogistic = false
        this._loadLogisticOrders()
      },
      error: (e) => {
        this.generateLogistic = false
        this.ss.nuevaSnack('Error to generate pdfs', 'snack-error')
      }
    })
  }

  sendEmails(logistic: LogisticOrder){
    this.dialog.open(EmailLogisticModalComponent, {
      data: {
        id: logistic.id,
        title: `🚛 Logistic Order: ${logistic.code} - ${this.dp.transform(logistic.preorder.departureDate,'dd-MM-yyyy')} - ${logistic.preorder.client.name}`,
        isClientNotified: logistic.isClientNotified,
        isSuppliersNotified: logistic.isSuppliersNotified,
        isDeliveredNotified: logistic.isDeliveredNotified
      }
    }).afterClosed().subscribe( r => {
      this._loadLogisticOrders()
    })
  }

  sendEmailSingleFile(id: number, sendClient:boolean, logistic: LogisticOrder){
    this.dialog.open(ModalSendFileLogisticComponent, {
      data: {
        id,
        sendClient,
        title: `🚛 Logistic Order: ${logistic.code} - ${this.dp.transform(logistic.preorder.departureDate,'dd-MM-yyyy')} - ${logistic.preorder.client.name}`,
      }
    }).afterClosed().subscribe( r => {
      console.log(r);

    })
  }


  moveLoad(load: number, order: number) {
    this.dialog.open(ModalMoveLoadComponent, {
      data: {
        load: load,
        order: order,
        preorder: this.preorder
      }
    }).afterClosed().subscribe( r => {
      if (r) {
        this.ls.moveLoad(r).subscribe({
          next: (r) => {
            this.ss.nuevaSnack('Load moved, Generate new Logistic PDF', 'snack-success')
            this._loadLogisticOrders()
          },
          error: (e) => console.log(e)
        })

      }
    })
  }

}

import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BankAccount, BankAccounts } from '../../models/bank-accounts';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { JcvService } from '../../services/jcv.service';
import { catchError, map, merge, of, startWith, switchMap } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { JcvBankAccountFormComponent } from '../jcv-bank-account-form/jcv-bank-account-form.component';
import { Enterprise } from '../../models/jcv-response';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalQuestionComponent } from 'src/app/shared/components/modal-question/modal-question.component';

@Component({
  selector: 'app-jcv-banks-account',
  templateUrl: './jcv-banks-account.component.html',
  styleUrls: ['./jcv-banks-account.component.css']
})
export class JcvBanksAccountComponent implements AfterViewInit {

  idEnterprise?: number;
  displayedColumns: string[] = ['name', 'iban', 'default', 'actions'];
  bankAccounts?: BankAccounts | null;
  data: BankAccount[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private jcv: JcvService,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private is: CustomIconsService,
  ) {
    this.jcv.getConfig().subscribe(({id}) => this.idEnterprise = id);

  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this._getBankAccounts()
  }


  private _getBankAccounts(): void {
    merge(this.paginator!.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.jcv.getBankAccounts(
            // this.sort.active,
            // this.sort.direction,
            // this.paginator.pageIndex,
          ).pipe(catchError(() => of(null)));
        }),
        map( data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;
          return data.results;
        }),
      )
      .subscribe(data => (this.data = data));
  }


  openAccount(id = null): void {
    const accountSheet = this._bottomSheet.open(JcvBankAccountFormComponent, {
      disableClose: true,
      data: {
        id,
        enterprise: this.idEnterprise,
      }
    });

    accountSheet.afterDismissed().subscribe((data) => {
      if (data) this._getBankAccounts()
    }
    );

  }

  deleteAccount(id: number): void {
    const modal = this.dialog.open(ModalQuestionComponent, {
      data: {
        title: 'Delete bank account',
        message: 'Are you sure you want to delete this bank account?',
        cancel: 'Cancel',
        accept: 'Delete'
      }
    })

    modal.afterClosed().subscribe((result) => {
      if (result) {
        this.jcv.deleteBankAccount(id).subscribe(() => this._getBankAccounts())
      }
    })
  }

}


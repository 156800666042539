import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { InvoiceFilter } from '../../models/invoice-filter';
import { BillingService } from '../../services/billing.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CustomIconsService } from 'src/app/shared/services/custom-icons.service';

@Component({
  selector: 'app-invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.css']
})
export class InvoiceFilterComponent implements OnInit {


  frm: FormGroup = this.fb.group({
    banckAccount: [],
    date_after: [],
    date_before: [],
    payDate_after: [],
    payDate_before: [],
    isPayed: [],
    payerClient: [],
    bankAccount: [],
    payerSupplier: [],
    search: [''],
    typeInvoice: [],
  })

  @Output() onSearch: EventEmitter<InvoiceFilter> = new EventEmitter<InvoiceFilter>()

  constructor(
    private fb: FormBuilder,
    private is: CustomIconsService,

  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  updateAccount = (bank: string) => this.frm?.patchValue({ bankAccount: bank })
  updateClient = (client: string) => this.frm?.patchValue({ payerClient: client })
  updateSupplier = (supplier: string) => this.frm?.patchValue({ payerSupplier: supplier })

  private _emitDataSearch(){
    this.onSearch.emit({
      ...this.frm.value,
      date_after: (this.frm.value.date_after) ? moment(this.frm.value.date_after).format('YYYY-MM-DD') : '',
      date_before: (this.frm.value.date_before) ? moment(this.frm.value.date_before).format('YYYY-MM-DD') : '',
      payDate_after: (this.frm.value.payDate_after) ? moment(this.frm.value.payDate_after).format('YYYY-MM-DD') : '',
      payDate_before: (this.frm.value.payDate_before) ? moment(this.frm.value.payDate_before).format('YYYY-MM-DD') : '',
    })
  }

  clean(){
      this.frm?.reset()
      this._emitDataSearch()
    }

}

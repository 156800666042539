import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-po-frm',
  templateUrl: './search-po-frm.component.html',
  styleUrls: ['./search-po-frm.component.css']
})
export class SearchPoFrmComponent implements OnInit {


  @Output() onSearch = new EventEmitter<string>();
  search = new FormControl();

  constructor() { }

  ngOnInit(): void {
    let debouncedTimeout: any;
    this.search.valueChanges.subscribe(txt => {
      if (debouncedTimeout) clearTimeout(debouncedTimeout)
        debouncedTimeout = setTimeout(() => {
          this.onSearch.emit(txt);
        }, 500);
    });
  }

}

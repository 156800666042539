<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-8/12">
      Update Credit Note
      <small class="block font-light text-xs text-slate-500 w-full"> Update info of Credit Note.</small>
    </h2>


  </div>

  <!-- Form -->
  <div class="divide-y divide-white/5">
    <div class="grid grid-cols-1 gap-x-8 gap-y-10 px-4 py-4 sm:px-6 lg:px-8">
      <app-credit-frm (sendData)="updateCreditNote($event)" [creditNote]="creditNote" *ngIf="creditNote"></app-credit-frm>
    </div>
  </div>


</main>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BaseCode } from '../../models/base-code';
import { SharedInfoService } from '../../services/shared-info.service';

@Component({
  selector: 'app-input-coins',
  templateUrl: './input-coins.component.html',
  styleUrls: ['./input-coins.component.css']
})
export class InputCoinsComponent implements OnInit {

  coin = new FormControl('', Validators.required)

  @Input() coinId?: number
  @Output() coinChange = new EventEmitter<{id: string, code: string}>()

  coins:BaseCode[] = []

  constructor(
    private ss: SharedInfoService
  ) { }

  ngOnInit(): void {
    this._loadCoins()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coinId']?.currentValue) this.coin.setValue(changes['coinId']?.currentValue)
  }


  private _loadCoins() {
    this.ss.getCoins().subscribe( resp => this.coins = resp.results)
  }

  onCoinChange() {

    this.coinChange.emit({
      id: this.coin.value || '',
      code: this.coins.find( c => c.id.toString() === this.coin.value?.toString())?.code || 'EUR'
    })
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ProductOrderSearchResponse } from '../../order/models/products_order_search_response';
import { FilterProductOrderReport } from '../models/filter-product-order-report';
import { IntrastatSearch } from '../models/intrastat-reports';
import { IntrastatResumen } from '../models/intrastat-report-resumen';
import { PoReport } from '../models/report-po';
import { ListPoProfitRes } from '../models/list-po-profit';
import { ReportByPoResumen } from '../models/report-bypo-resumen';
import { Payments } from '../models/payments';
import { PaymentDetail } from '../models/payment-detail';



const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor( private http: HttpClient) { }


  filterProductOrder(filters: FilterProductOrderReport, page:number = 1): Observable<ProductOrderSearchResponse>{
    const params = new HttpParams({
      fromObject: {
      'page': page.toString(),
      'p':  filters.product || '',
      's':  filters.supplier || '',
      'c':  filters.client || '',
      'lp':  filters.loadPlace || '',
      'ulp':  filters.unloadPlace || '',
      'dd_after':  filters.departureDate_after || '',
      'dd_before':  filters.departureDate_before || '',
      'i':  filters.isInvoiced || '',
      'ci': filters.carrierInvoice || [],
      'si':  filters.supplierInvoice || [],
      'page_size': 20
      }
    })


    return this.http.get<ProductOrderSearchResponse>(`${baseUrl}/product-order/filter`, {params})
  }

  getPaymentsList(query = '', page:number = 1): Observable<Payments>{
    const params = new HttpParams({
      fromObject: {
        'page': page.toString(),
        'query': query
      }
    })

    return this.http.get<Payments>(`${baseUrl}/payments-partners/`, {params})
  }

  getDetailPayment(id: number): Observable<PaymentDetail>{
    return this.http.get<PaymentDetail>(`${baseUrl}/payment-detail/${id}`)
  }


  exportExcel(filters: FilterProductOrderReport): Observable<any>{
    return this.http.post(`${baseUrl}/products-order/excel`, filters, {responseType: 'blob'})
  }

  homeResumen(): Observable<any>{
    return this.http.get(`${baseUrl}/jcv-home/resumen`)
  }

  exportIntrastat(filters: IntrastatSearch): Observable<any>{
    const params = new HttpParams()
      .set('after', filters.after?.toString() || '')
      .set('before', filters.before?.toString() || '')

    return this.http.get(`${baseUrl}/intrastat/excel`, {params, responseType: 'blob'})
  }


  resumenIntrastat(filters: IntrastatSearch): Observable<IntrastatResumen>{
    const params = new HttpParams()
      .set('after', filters.after?.toString() || '')
      .set('before', filters.before?.toString() || '')

    return this.http.get<IntrastatResumen>(`${baseUrl}/report/intrastat`, {params})
  }


  showEconomicPoReport(po: string): Observable<PoReport[]>{
    const params =  new HttpParams()
      .set('po', po)

    return this.http.get<PoReport[]>(`${baseUrl}/report/sale`, {params})
  }


  listPoProfit(filters: IntrastatSearch, page = 1): Observable<ListPoProfitRes>{
    const params = new HttpParams()
      .set('departureDate_after', filters.after?.toString() || '')
      .set('departureDate_before', filters.before?.toString() || '')
      .set('client', filters.client?.toString() || '')
      .set('page', page.toString())

    return this.http.get<ListPoProfitRes>(`${baseUrl}/pre-order/po-profit`, {params})
  }


  reportByPo(data: string[]): Observable<ReportByPoResumen>{
    const params = new HttpParams().set('po', data.join(','))
    return this.http.get<ReportByPoResumen>(`${baseUrl}/partners/report-bypo`, {params})
  }

}

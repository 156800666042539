<button type="button"
  (click)="openSupplierFrm()"
  class="relative group block w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center hover:border-green-700 focus:outline-none focus:ring-2 focus:ring-transparent focus:ring-offset-2 mt-4 transition-colors duration-300">


  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
    class="mx-auto h-14 w-14 text-gray-400 group-hover:text-green-700 transition-colors duration-300" stroke-width="1.5"
    stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>


  <span class="mt-2 block text-sm font-semibold text-gray-900">Add supplier</span>
  <span class="mt-2 block text-xs text-gray-900">
    Add an order from a new supplier
  </span>

</button>

<div>
  <mat-form-field appearance="outline" fxFill>
    <mat-label>Client</mat-label>
    <input type="text" placeholder="Search client" matInput [formControl]="client" [matAutocomplete]="autoClient">
    <mat-autocomplete #autoClient="matAutocomplete" [displayWith]="showClient"
      (optionSelected)="changeClient($event)">
      <mat-option *ngFor="let option of clientsFilter" [value]="option.id">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-icon matSuffix color="warn" (click)="clean()">clear</mat-icon>
  </mat-form-field>
</div>

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-po-filter-profit',
  templateUrl: './po-filter-profit.component.html',
  styleUrls: ['./po-filter-profit.component.css']
})
export class PoFilterProfitComponent implements OnInit {

  frm: FormGroup = this.fb.group({
    after: [''],
    before: [''],
    client: [''],
  })

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>()


  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  private _listener(){
    this.frm.valueChanges.subscribe(() => this._emitDataSearch())
  }


  private _emitDataSearch(){
    if (this.frm.invalid) return
    this.onSearch.emit({
      client: this.frm.value.client,
      after: (this.frm.value.after) ? moment(this.frm.value.after).format('YYYY-MM-DD') : '',
      before: (this.frm.value.before) ? moment(this.frm.value.before).format('YYYY-MM-DD') : '',
    })
  }

  changeClient = (client:any) => {
    this.frm.patchValue({client})

  }

  clean(){
      this.frm?.reset()
      this.onSearch.emit({})
    }
}

import { Component, OnInit } from '@angular/core';
import { PackagingService } from '../../../services/packaging.service';
import { Packaging } from '../../../models/packaging';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-packaging-create',
  templateUrl: './packaging-create.component.html',
  styleUrls: ['./packaging-create.component.css']
})
export class PackagingCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Packaging', url: '/dash/admin/packaging'},
    {name: 'New Packaging'},
  ]

  constructor(
    private rt: Router,
    private ps: PackagingService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createSize(size: Packaging): void {
    this.ps.createPackaging(size).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Packaging ${resp.name} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/packaging/edit/', resp.id])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating packaging','snack-error')
      }
    })
  }
}

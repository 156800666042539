<h3 class="!text-lg text-green-900 !my-4"> Change Loads data: {{data.product}} </h3>

<form [formGroup]="frm" (ngSubmit)="send()">

  <mat-form-field appearance="outline">
    <mat-label>Lot / Delivery Note</mat-label>
    <input matInput type="text" formControlName="lot">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>NetWeight</mat-label>
    <input matInput type="number" formControlName="netWeight">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Gross Weight</mat-label>
    <input matInput type="number" formControlName="grossWeight">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Origin</mat-label>
    <input matInput type="text" formControlName="origin">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Packaging</mat-label>
    <mat-select formControlName="packaging">
      <mat-option value=""></mat-option>
      <mat-option *ngFor="let packaging of packaging_list" [value]="packaging.id">
        {{packaging.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-flat-button class="bg-green-700 text-white w-full" type="submit" [disabled]="frm.invalid"> Send
  </button>

</form>

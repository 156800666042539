import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DownloadPlace } from 'src/app/features/admin/models/logistic-clients';
import { ClientsService } from 'src/app/features/admin/services/clients.service';

@Component({
  selector: 'app-input-unloadplaces',
  templateUrl: './input-unloadplaces.component.html',
  styleUrls: ['./input-unloadplaces.component.css']
})
export class InputUnloadplacesComponent implements OnInit {

  @Input() clientInfo?: {id: number, isMe: boolean};
  @Input() unLoadPlaceInfo?: {id:number, name: string};


  @Output('onSelect') onSearch = new EventEmitter<string>()

  unLoadPlace = new FormControl('', Validators.required)

  unLoadPlaces: DownloadPlace[] = []
  unLoadPlacesFilter?: DownloadPlace[]
  unLoadPlaceselected?: any
  search: {text: string, client?: number} = {text: '', client: undefined}

  constructor(
    private cs: ClientsService,
  ) { }

  ngOnInit(): void {
    this._listener()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unLoadPlaceInfo']?.currentValue){
      this.unLoadPlace.setValue(changes['unLoadPlaceInfo']?.currentValue.id)
      this.unLoadPlaceselected = changes['unLoadPlaceInfo']?.currentValue.name
    }

  }

  private _listener():void{
    this.unLoadPlace.valueChanges.subscribe({
      next: (resp: any) => {
        (!this.clientInfo!.isMe)
          ? this.search.client = this.clientInfo!.id
          : this.search.client = undefined


        this.search.text = resp


        if (resp){
          this.cs.searchUnloadPlaces(this.search).subscribe({
            next: (r) => {
              this.unLoadPlacesFilter = r.results
            }
          })
        }
      }
    })
  }


  showUnloadPlace = (valor: any): string => {
    this.unLoadPlacesFilter?.filter( (m) => {
      if(m.id === valor){
        this.unLoadPlaceselected = m.name
      }
    })
    return this.unLoadPlaceselected;
  }

  clean():void{
    this.unLoadPlace.reset()
    this.unLoadPlaceselected = null
    this.unLoadPlacesFilter = []
    this.onSearch.emit('')
  }

  changeUnloadPlace = (event:MatAutocompleteSelectedEvent) => this.onSearch.emit(event.option.value)


}

<main>
  <header class="border-b border-white/5 sm:px-6 lg:px-8">
    <app-breadcrumb [routes]="breadcrumb"></app-breadcrumb>
  </header>

  <div class="mt-8 flex flex-row flex-wrap justify-between gap-4 w-full items-start">

    <h2 class="m-0 p-0 w-7/12">
      Sales Resumen
      <small class="block font-light text-xs text-slate-500 w-full"> Show Profits of Sales.</small>
    </h2>

  </div>

  <!-- Filter-->
  <div class="mt-8 flex flex-row flex-wrap justify-between w-full items-start">
  <div class="w-7/12">
    <app-po-filter-profit (onSearch)="search($event)"></app-po-filter-profit>
  </div>
  <!-- Actions -->
  <div class="w-5/12 px-4">

    <dl
      class="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:divide-x md:divide-y-0">
      <div class="px-4 py-5 sm:p-6">
        <dt class="text-base font-normal text-gray-900">Total Profit</dt>
        <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div class="flex items-baseline text-2xl font-semibold" [ngClass]="{'text-green-800': total_profit > 0, 'text-red-900': total_profit < 0}">
            {{total_profit|currency:'EUR'}}
          </div>

          <div
            [ngClass]="{'bg-green-100 text-green-800': total_profit > 0, 'bg-red-100 text-red-800': total_profit < 0}"
            class="inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium  md:mt-2 lg:mt-0">

            <svg *ngIf="total_profit > 0; else down" class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z"
                clip-rule="evenodd" />
            </svg>
            <ng-template #down>
              <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" />
              </svg>
            </ng-template>


          </div>
        </dd>
      </div>

    </dl>



  </div>
  </div>
  <!-- List -->

  <div class="w-full">
    <app-table-po-profits [searched]="searched" (results)="presentResumen($event)"></app-table-po-profits>
  </div>

</main>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreditFilterComponent } from './components/credit-filter/credit-filter.component';
import { CreditFrmComponent } from './components/credit-frm/credit-frm.component';
import { CreditTableComponent } from './components/credit-table/credit-table.component';
import { CreditCreateComponent } from './pages/credit-create/credit-create.component';
import { CreditEditComponent } from './pages/credit-edit/credit-edit.component';
import { CreditHomeComponent } from './pages/credit-home/credit-home.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    CreditHomeComponent,
    CreditCreateComponent,
    CreditEditComponent,
    CreditTableComponent,
    CreditFrmComponent,
    CreditFilterComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class CreditNoteModule { }

import { Component, OnInit } from '@angular/core';
import { CertificationsService } from '../../../services/certifications.service';
import { Certification } from '../../../models/certifications';
import { Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';

@Component({
  selector: 'app-certification-create',
  templateUrl: './certification-create.component.html',
  styleUrls: ['./certification-create.component.css']
})
export class CertificationCreateComponent implements OnInit {

  public breadcrumb = [
    {name: 'Settings', url: '/dash/admin'},
    {name: 'Certifications', url: '/dash/admin/certifications'},
    {name: 'New certification'},
  ]

  constructor(
    private rt: Router,
    private cs: CertificationsService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
  }


  createCertification(certification: Certification): void {
    this.cs.addCertification(certification).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Certification ${resp.certification} created successfully`,'snack-success')
        this.rt.navigate(['/dash/admin/certifications/'])
      },
      error: (err) => {
        this.ss.nuevaSnack('Error creating certification','snack-error')
      }
    })
  }
}

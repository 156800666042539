<div *ngIf="logisticOrders.length > 0; else noLogistic">

  <div class="flex">


    <button mat-button
      matTooltip="Generate PDF Logistic Order"
      matTooltipClass="bg-red-700 text-xs"
      (click)="generateAllPdfsLogistic()"
      class="!h-12 !w-12 !flex items-center hover:cursor-pointer !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-red-600 object-cover ring-1 ring-red-950/10 hover:bg-red-400 transition-colors duration-500">
      <svg *ngIf="!generateLogistic"
        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
      </svg>
      <mat-spinner *ngIf="generateLogistic" diameter="35" color="warn"></mat-spinner>

    </button>



  </div>


  <div *ngFor="let order of logisticOrders">
  <div class="border border-sky-700 bg-sky-200 px-4 py-5 sm:px-6 rounded mt-4 mb-1">
    <div class="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div class="ml-4 mt-2">
        <h3 class="text-base !font-semibold leading-6 text-gray-900">
          {{order.code}} ( {{order.logisticSupplier.name}} )
        </h3>
      </div>

      <div class="ml-4 mt-2 flex-shrink-0 flex gap-3">
        <app-badget class="!text-sm bg-slate-50 text-slate-600 ring-slate-500/10" [text]="order.totalPallets" subtitle="Pallets"></app-badget>
        <app-badget class="!text-sm bg-slate-50 text-slate-600 ring-slate-500/10" [text]="order.totalCost|currency:'EUR'" ></app-badget>
        <button mat-button type="button" class="!flex bg-slate-100 ms-6 h-7 items-center" (click)="updatePlate(order.id)">
            {{order.plate ? order.plate : 'Assign Plate'}}
        </button>
        <button mat-button type="button" class="!flex bg-sky-700 text-white ms-6 h-7 items-center" (click)="sendEmails(order)">
          Send Mail
        </button>
      </div>
    </div>
  </div>

  <!-- NEW TABLE-->
    <div class="">
      <div class="-my-2">
        <div class="py-2 align-middle">
          <div class="overflow-hidden ring-1 ring-black ring-opacity-5">
            <table class="divide-y divide-gray-300 text-xs min-w-full">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Product
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Pallet</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Mixed Group</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Supplier</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Load Place</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Total</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr *ngFor="let l of order.loads">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                    {{l.product.product.name}} {{l.product.product.description}} - Size: {{l.product.product.size.size}}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.pallets}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    <span class="inline-flex items-center gap-x-1.5 rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700" *ngIf="l.mixedGroup">
                      <svg class="h-1.5 w-1.5 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
                        <circle cx="3" cy="3" r="3" />
                      </svg>
                      {{ l.mixedGroup }}
                    </span>
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.supplier.name}}</td>
                  <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">{{l.product.loadPlace.name}}</td>
                  <td class="whitespace-nowrap py-4 pl-3 text-xs text-gray-500">{{(l.product.palletCost *
                    l.product.pallets)|currency:'EUR'}}</td>
                  <td class="whitespace-nowrap py-4 pl-3 text-xs text-gray-500">
                    <button matTooltip="Move Load to another Logistic Order" matTooltipClass="bg-sky-700 text-xs" mat-button
                      (click)="moveLoad(l.id, order.id)"
                      class="!h-6 !w-5 !flex items-center group hover:cursor-pointer !min-w-0 !min-h-min !my-2 !mx-2 justify-center rounded-lg bg-sky-600 object-cover ring-1 ring-red-950/10 hover:bg-sky-400 transition-colors duration-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="white" class="w-4 h-4 group-hover:animate-spin">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>

                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  <!-- END TABLE -->
  <!-- OLD -->
  <div class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 rounded-b-xl px-4 py-5">
    <ul role="list" class="bg-white shadow-sm">
      <li class="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50 sm:px-6" *ngFor="let file of order.files">
        <div class="flex min-w-0 gap-x-4">

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="red" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>

          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold leading-6 text-gray-900">
              <span class="absolute inset-x-0 -top-px bottom-0"></span>
              {{(file.sendClient) ? order.preorder.client.name : file.supplier?.name}}
            </p>
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-x-4">
          <div class="hidden sm:flex sm:flex-col sm:items-end">
            <button mat-button type="button" (click)="sendEmailSingleFile(file.id, file.sendClient, order)" class="!flex bg-sky-700 text-white ms-6 h-7 items-center w-40 justify-center">
              Send Email {{(file.sendClient) ? 'Client' : 'Supplier'}}
            </button>
          </div>
        </div>
      </li>
    </ul>


  </div>
  </div>
</div>

<ng-template #noLogistic>
  <div class="mt-6">
    <app-empty title="No logistic" text="This preorder does not have the logistics set up"></app-empty>
  </div>
</ng-template>

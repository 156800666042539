import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cost, CostFrm, CostResponse } from '../models/cost-response';


const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class CostsService {

  constructor(
    private http: HttpClient
  ) { }


  searchCosts(search:any, page = 1):Observable<CostResponse>{
    const params = new HttpParams().set('page', page)
    // TODO: Add params to filter
    return this.http.get<CostResponse>(`${baseUrl}/search-cost/`);
  }

  getCost(id:number):Observable<CostResponse>{
    return this.http.get<CostResponse>(`${baseUrl}/cost/${id}`);
  }

  createCost(cost: CostFrm):Observable<Cost>{
    return this.http.post<Cost>(`${baseUrl}/cost/`, cost);
  }

  updateCost(id:number, cost: CostFrm):Observable<Cost>{
    return this.http.put<Cost>(`${baseUrl}/cost/${id}/`, cost);
  }

  deleteCost(id:number):Observable<any>{
    return this.http.delete<any>(`${baseUrl}/cost/${id}/`);
  }


}

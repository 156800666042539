import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor( private usuarioService: AuthService, private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {


    return this.usuarioService.validarToken()
                .pipe(
                  tap( estaAutenticado => {
                    if (!estaAutenticado){
                      this.router.navigateByUrl('/login');
                    }
                  })
                );

  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.usuarioService.validarToken()
                .pipe(
                  tap( estaAutenticado => {
                    if (!estaAutenticado){
                      this.router.navigateByUrl('/login');
                    }
                  })
                );

  }

}

<form [formGroup]="frm" (ngSubmit)="send()" *ngIf="frm" class="flex flex-row flex-wrap gap-4 mt-6">

    <mat-form-field appearance="outline" class="w-8/12">
      <mat-label>Client Name</mat-label>
      <mat-icon matPrefix>supervised_user_circle</mat-icon>
      <input matInput formControlName="name" placeholder="EuroFresh">
    </mat-form-field>
    <div class="w-3/12">
      <app-input-language [languageId]="languageId" (languageChange)="setLanguage($event)"></app-input-language>
    </div>

    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>CIF / VAT </mat-label>
      <input matInput formControlName="vatNumber" placeholder="00000000X">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Company Code</mat-label>
      <input matInput formControlName="companyCode" placeholder="000000000">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>EORI</mat-label>
      <input matInput formControlName="eori" placeholder="00000000">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Delivery days</mat-label>
      <input matInput type="number" formControlName="dayDeparture" placeholder="3">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Comercial Discount</mat-label>
      <input matInput type="number" formControlName="comercialDiscount" placeholder="3">
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-3/12">
      <mat-label>Sale Commission</mat-label>
      <input matInput type="number" formControlName="saleComission" placeholder="3">
    </mat-form-field>

    <div class="self-center bg-slate-100 p-4 w-3/12 rounded-sm -mt-3">
      <mat-checkbox formControlName="hasPercentage">Apply JCV Percentage</mat-checkbox>
    </div>

    <span class="w-full text-lg font-semibold text-slate-700"> Addres Info </span>

    <mat-form-field appearance="outline" class="w-4/12">
      <mat-label>Address</mat-label>
      <input matInput formControlName="address" placeholder="New Street">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-4/12">
      <mat-label>City</mat-label>
      <input matInput formControlName="city" placeholder="Seville">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-2/12">
      <mat-label>Zip Code</mat-label>
      <input matInput formControlName="zipCode" placeholder="00000">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-4/12">
      <mat-label>Province</mat-label>
      <input matInput formControlName="province" placeholder="Seville">
    </mat-form-field>

    <div class="w-4/12">
      <app-input-countries [countryId]="countryId" [countryName]="countryName" (onSelect)="setCountry($event)"></app-input-countries>
    </div>

    <mat-form-field appearance="outline" class="w-full">
      <mat-label>Notes</mat-label>
      <textarea matInput formControlName="notes" placeholder="Ex. This suppliers has..."></textarea>
    </mat-form-field>

  <span class="w-full text-lg font-semibold text-slate-700">
    Pay Info
  </span>

    <div class="w-4/12">
      <app-input-coins [coinId]="coinId" (coinChange)="setCoins($event)"></app-input-coins>
    </div>

    <div class="w-4/12">
      <app-input-bank-account [accountId]="accountId" (accountChange)="setBillingAccount($event)"></app-input-bank-account>
    </div>

    <section class="w-3/12 self-center">
      <mat-checkbox formControlName="payLogistic">Pay logistic</mat-checkbox>
    </section>

    <div class="actions-frm text-end my-8 w-full">
      <button mat-raised-button type="submit" class="w-5/12 bg-green-700 text-white"> {{(client) ? 'Updated' : 'Create'}} client </button>
    </div>
</form>

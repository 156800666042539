import { Component, OnInit } from '@angular/core';
import { Preorder } from '../../models/pre-order';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarsService } from 'src/app/shared/services/snackbars.service';
import { PreorderService } from '../../services/preorder.service';

@Component({
  selector: 'app-buy-preorder-edit',
  templateUrl: './buy-preorder-edit.component.html',
  styleUrls: ['./buy-preorder-edit.component.css']
})
export class BuyPreorderEditComponent implements OnInit {

    idPreorder = this.ar.snapshot.params['id']
    typePreorder?: string
    preOrder?: Preorder

    public breadcrumb = [
    {name: 'Pre-Orders', url: '/dash/pre-orders'},
    {name: 'Update Pre-Order'},
  ]

  constructor(
    private ar: ActivatedRoute,
    private rt: Router,
    private pos: PreorderService,
    private ss: SnackbarsService,
  ) { }

  ngOnInit(): void {
    this._getPreorder()
  }

  private _getPreorder(): void {
    this.pos.getPreoder(this.idPreorder).subscribe({
      next: (resp) => {
        this.preOrder = resp
        this.typePreorder = resp.typeOfPreOrder
      },
      error: (err) => {
        this.ss.nuevaSnack('Error getting pre-order','snack-error')
        this.rt.navigate(['/dash/admin/pre-orders'])
      }
    })
  }

  changeIndex = (event: number) => (event === 0) ? this._getPreorder() : null

  updatePreorder(preOrder: Preorder): void {
    this.pos.updatePreorder(this.idPreorder, preOrder).subscribe({
      next: (resp) => {
        this.ss.nuevaSnack(`Pre-order #${resp.id} updated successfully`,'snack-success')
        this._getPreorder()
      },
      error: (err) => {
        this.ss.nuevaSnack('Error updating preOrder','snack-error')
      }
    })
  }

}
